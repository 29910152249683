import { fakeUsers } from ".";
import { LoggedUser } from "../domain/user/logged.user";

export const fakeLoggedStudentUser: LoggedUser = {
    user: fakeUsers.fakeStudentUser,
    profile: 'student',
    accessToken: 'fjs:' + new Date().getMilliseconds().toString()
};

export const fakeLoggedTeacherUser: LoggedUser = {
    user: fakeUsers.fakeTeacherUser,
    profile: 'teacher',
    accessToken: 'fbt:' + new Date().getMilliseconds().toString()
};