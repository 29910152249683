import { Component, Input } from '@angular/core';
import { GeneralModalComponent } from '../modais.module';

@Component({
  selector: 'go-glossary-modal',
  templateUrl: './glossary-modal.component.html',
  styleUrl: './glossary-modal.component.scss',
})
export class GlossaryModalComponent extends GeneralModalComponent {
  override goComponentId: string = 'glossary-modal';

  @Input() key!: string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() audio?: string;
  @Input() example?: string;
  @Input() exampleTranslate?: string;
  @Input() description?: string;

  isClicked: boolean = false;
  speechStatus: 'speech' | 'listening' | 'concluded' = 'speech';
  speechScore: number = 0;
  speechIntensity: number[] = [5, 4, 5, 3, 4];
  retry: boolean = false; //TODO: Vai ser removido, foi só pra teste

  //? Executa a função de captura de áudio e envia para a api o que é falado
  async speech() {
    if (this.isClicked) return;
    this.isClicked = true;

    //? Aplica aqui a regra para executar o speech.
    this.speechStatus = 'listening';

    //? Regra das ondinhas
    await this.wait(2000);

    //? troca o status
    this.speechStatus = 'concluded';
    this.speechScore = this.retry ? 64 : 89;

    await this.wait(200);
    this.isClicked = false;
  }

  //? Permite o usuário utilizar o speech novamente
  async speechRetry() {
    if (this.isClicked) return;
    this.isClicked = true;

    //TODO: Vai ser removido, foi só pra teste
    let bRetry = this.retry;
    this.retry = !bRetry;

    this.speechStatus = 'speech';
    this.speechScore = 0;

    await this.wait(200);
    this.isClicked = false;
  }

  //? Função para ouvir o próprio áudio falado
  async speechAudio() {
    if (this.isClicked) return;
    this.isClicked = true;

    console.log('to ouvindo, diga ai');

    await this.wait(200);
    this.isClicked = false;
  }

  //? Função só pra dar um delay no código, não é necessário usar.
  private wait(miliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, miliseconds));
  }
}
