import { Stage } from '../../../domain/course/course';
import { StageItem } from './stage-item';
import { StageItemColorsMapper } from './stage-item-colors.mapper';
import { StageItemCoverMapper } from './stage-item-cover.mapper';

export class StageItemMapper {
  private colorsMapper = new StageItemColorsMapper();
  private coversMapper = new StageItemCoverMapper();

  toStageItem(stage: Stage, number: number): StageItem {
    return {
      id: stage.id,
      number: this.addZeroLeft(number ?? 0),
      title: stage.title,
      partialPages: stage.completedPages,
      totalPages: stage.countPages,
      status: 'normal',
      open: false,
      color: this.colorsMapper.toCardColor(stage.title),
      type: stage.type,
      link: '',
      cover: this.coversMapper.toCardCover(stage.title, stage.cover),
    };
  }

  private addZeroLeft(numero: number) {
    return numero < 10 ? '0' + numero : numero.toString();
  }
}
