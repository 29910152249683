import { Component, Input } from '@angular/core';

@Component({
  selector: 'st-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss',
})
export class LoadingPageComponent {
  @Input() title: string = 'Carregando suas aulas!';
  @Input() subtitle: string = 'Por favor aguarde...';
  @Input() source: string =
    'https://go2dev.s3.amazonaws.com/fixed/loading-3.lottie';

  type: 'image' | 'lottie' = 'image';
}
