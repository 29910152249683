import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizStartPageComponent } from './quiz-start-page/quiz-start-page.component';
import { QuizPageComponent } from './quiz-page/quiz-page.component';
import { QuizEndPageComponent } from './quiz-end-page/quiz-end-page.component';
import { ButtonsModule, IconsModule, ImagesModule } from 'go-components';

const components = [
  QuizStartPageComponent,
  QuizPageComponent,
  QuizEndPageComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, IconsModule, ImagesModule],
  exports: components,
})
export class QuizModule {}

export { QuizStartPageComponent, QuizPageComponent, QuizEndPageComponent };
