export default {
  id: 'a45ccdb6-4bc7-45bd-8995-dc367b4a2be1',
  createdAt: '2024-05-28T11:30:16.336Z',
  updatedAt: '2024-06-17T19:57:20.621Z',
  title: 'Did you know?',
  cover: null,
  order: 2,
  status: 'activated',
  countPages: 27,
  completedPages: 0,
  type: 'CONTENTS',
  modules: [],
  steps: [],
  pages: [
    {
      id: '616f38ef-06c3-4445-9839-cc4078613739',
      createdAt: '2024-06-17T19:57:20.634Z',
      updatedAt: '2024-06-17T19:57:20.634Z',
      type: 'content',
      code: 'LIU_Intro',
      order: 1,
      status: 'activated',
      widgets: [
        {
          id: '91445e8b-40fa-4a8e-9021-27423e1402ce',
          createdAt: '2024-06-17T19:57:20.634Z',
          updatedAt: '2024-06-17T19:57:20.634Z',
          order: 1,
          type: 'title',
          value: 'LANGUAGE IN USE',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '22551e72-4703-40ff-a249-1ee897e53a9f',
          createdAt: '2024-06-17T19:57:20.634Z',
          updatedAt: '2024-06-17T19:57:20.634Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-dd801792-4be2-4fc0-a5c9-f325732903dc.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '543c9d5b-d2d8-4ae3-b416-83d2d0afa4fb',
          createdAt: '2024-06-17T19:57:20.634Z',
          updatedAt: '2024-06-17T19:57:20.634Z',
          order: 3,
          type: 'longtext',
          value:
            'Você já percebeu como o inglês está presente no nosso dia a dia?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c0da4530-9c71-4bbd-b346-7874577b90f6',
          createdAt: '2024-06-17T19:57:20.634Z',
          updatedAt: '2024-06-17T19:57:20.634Z',
          order: 4,
          type: 'longtext',
          value:
            'Além do turismo com as placas de sinalização bilíngue e a possibilidade de conhecer estudantes e famílias de imigrantes, você sabe que outras situações do nosso dia a dia o inglês está presente? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'd61b9978-e10a-4e98-bdcb-a565444c9c71',
      createdAt: '2024-06-17T19:57:20.647Z',
      updatedAt: '2024-06-17T19:57:20.647Z',
      type: 'content',
      code: 'WU_DYK_B1_P1',
      order: 2,
      status: 'activated',
      widgets: [
        {
          id: 'd7a24239-d39e-4fad-8fd9-e28ab956a675',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd82a9181-1de5-4695-a08c-d325fc7adbf7',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 2,
          type: 'subtitle',
          value: 'Estrangeirismos',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '207feb47-5905-4b0e-bd00-92e3c38d4a1f',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 3,
          type: 'longtext',
          value:
            'Você sabia que importamos muitas palavras da língua inglesa para o nosso cotidiano? Mas não pegamos palavras emprestadas só do inglês não. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9b99fb58-330c-4a29-9d69-6d8b0773c6e6',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 4,
          type: 'longtext',
          value:
            'Palavras como *bouquet* (francês) e *mezzanine* (italiano) e *milkshake* são <color-3E278F><b>estrangeirismos</b></color>.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c1f5a17e-170b-4d5c-ae10-a66d7d1e3f28',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 5,
          type: 'longtext',
          value:
            'Alguns estrangeirismos se aportuguesaram, como no caso da palavra <color-3E278F><b>GOAL</b></color> e <color-3E278F><b>GOL</b></color>, e outros mantêm a mesma grafia do idioma de origem. Mas atenção com a pronúncia de um idioma para o outro.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '8f46e360-6b54-41bb-8d54-6ba5725a3371',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          order: 6,
          type: 'longtext',
          value: 'Veja os exemplos a seguir:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '1df220cc-c446-42a4-bbdf-2e6855f084b3',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          key: 'milkshake',
          title: 'milkshake',
          subtitle: 'milkshake',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-e54b3116-7600-4f1e-ba8c-e0a71505e93f.mpeg',
          example: 'I love drinking milkshakes.',
          exampleTranslate: 'Amo tomar milkshakes.',
          description: '',
        },
        {
          id: 'f7694c1b-5aa2-4108-b01c-37f47e5a0483',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          key: 'mezzanine',
          title: 'mezzanine',
          subtitle: 'mezanino',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-214d95d9-13fb-48f0-ab34-c8423bcd399c.mpeg',
          example: "We don't have a mezzanine in our school.",
          exampleTranslate: 'Não temos um mezanino na nossa escola.',
          description:
            'É um nível intermediário entre dois andares. Ele é geralmente aberto para o andar inferior, criando um espaço que não chega a ser um andar completo. ',
        },
        {
          id: '41494217-5291-46ea-8e87-e6d79053e330',
          createdAt: '2024-06-17T19:57:20.647Z',
          updatedAt: '2024-06-17T19:57:20.647Z',
          key: 'bouquet',
          title: 'bouquet',
          subtitle: 'buquê',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-99707f9e-a3a4-4a73-864e-1dd25c284c15.mpeg',
          example: 'I bought a bouquet of roses for you.',
          exampleTranslate: 'Eu comprei um buquê de flores para você.',
          description: '',
        },
      ],
    },
    {
      id: 'c7c4d6f9-0a5f-4a19-9602-847d9be74a6a',
      createdAt: '2024-06-17T19:57:20.662Z',
      updatedAt: '2024-06-17T19:57:20.662Z',
      type: 'content',
      code: 'WU_DYK_B1_P2_A1',
      order: 3,
      status: 'activated',
      widgets: [
        {
          id: '5149ebe3-3d0b-4a84-b3ce-e1c6f34cbd4d',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-57667881-ad83-4e0b-85f7-fb9eea110f57.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3ea20532-8817-4387-bcdc-0565a667fc1b',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 2,
          type: 'enunciated',
          value: 'MILKSHAKE',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '83968cb7-2082-4554-a24e-37beaa408d56',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 3,
          type: 'longtext',
          value: 'Bebida feita de leite, sorvete e adicionais como frutas\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '034e50e8-bd28-4bb2-8196-48af3d84e776',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'dd2fe315-ab13-431b-bbd2-281312e41de0',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-7-3-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '022174fe-ae60-4cc7-9b87-fa87fb8ee089',
          createdAt: '2024-06-17T19:57:20.662Z',
          updatedAt: '2024-06-17T19:57:20.662Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-9-3-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '20ac26e7-3bc0-4547-9dd6-b046d57fef0d',
      createdAt: '2024-06-17T19:57:20.674Z',
      updatedAt: '2024-06-17T19:57:20.674Z',
      type: 'content',
      code: 'WU_DYK_B1_P3_A2',
      order: 4,
      status: 'activated',
      widgets: [
        {
          id: '0b1cc680-e251-42fa-a362-9c543ecbe9b7',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-1780c2b2-e29b-40c2-b0d9-853c708b866f.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1440ab77-c5ad-49f0-8657-0e5f48669160',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 2,
          type: 'enunciated',
          value: 'BAR',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '2f05b07a-3369-4e46-b41d-ab8439edc456',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 3,
          type: 'longtext',
          value: ' Local para consumo de bebidas',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '74276e24-aaad-4a3f-b750-f1e5f5c38109',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a92ea592-5eea-4e6f-bd3a-ce519ff05792',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-7-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c91193d5-fe26-4afb-9c31-b883cdbcd210',
          createdAt: '2024-06-17T19:57:20.674Z',
          updatedAt: '2024-06-17T19:57:20.674Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-9-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'be1682bb-1890-46b2-8bb9-2d3503f50881',
      createdAt: '2024-06-17T19:57:20.697Z',
      updatedAt: '2024-06-17T19:57:20.697Z',
      type: 'content',
      code: 'WU_DYK_B1_P4_A3',
      order: 5,
      status: 'activated',
      widgets: [
        {
          id: 'cc1f3ef0-184f-4721-9dce-df23392c6142',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-78127f48-599f-40b5-990e-284b6e015a6c.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'bb2a7206-6533-469f-b763-e47e3c8a6666',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 2,
          type: 'enunciated',
          value: 'INTERNET',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f4bdc092-b84b-4f33-9e7b-9f880b917cd3',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 3,
          type: 'longtext',
          value:
            'Rede de computadores dispersos por todo o planeta que trocam dados e mensagens\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'cc964e28-6aea-4bc9-b989-ec6cb453979a',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd91098e6-6cfd-46de-a15f-a1c37893f9cf',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-8-5-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd334b13c-3f6a-427e-9fbc-32172368ac6b',
          createdAt: '2024-06-17T19:57:20.697Z',
          updatedAt: '2024-06-17T19:57:20.697Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-10-5-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'b4465e6f-9238-414b-8a9b-69a208e5b4e5',
      createdAt: '2024-06-17T19:57:20.709Z',
      updatedAt: '2024-06-17T19:57:20.709Z',
      type: 'content',
      code: 'WU_DYK_B1_P5_A4',
      order: 6,
      status: 'activated',
      widgets: [
        {
          id: '80583d17-e475-430e-bf2d-87c6816d8d4f',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-5e619dea-5c26-4b59-b4b1-a850998cdeec.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '397d7778-7c08-44d6-912f-cd6881b222fd',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 2,
          type: 'enunciated',
          value: 'NOTEBOOK ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '6b5d4dc9-e7f8-4f1e-8c54-ab4fc3246624',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 3,
          type: 'longtext',
          value: 'Caderno mas no Brasil computador móvel\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '8ae326ca-1171-4abb-b48e-1849491137a4',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e4253a6f-3f2b-47a4-865d-16f2ecba837f',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-7-6-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd33791de-a6c1-43d4-8459-59ba65fef7b8',
          createdAt: '2024-06-17T19:57:20.709Z',
          updatedAt: '2024-06-17T19:57:20.709Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-9-6-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '5d5efad9-d229-4f7a-9c56-a0843409fc59',
      createdAt: '2024-06-17T19:57:20.724Z',
      updatedAt: '2024-06-17T19:57:20.724Z',
      type: 'content',
      code: 'WU_DYK_B1_P6_A5',
      order: 7,
      status: 'activated',
      widgets: [
        {
          id: '91db76ed-d0f4-4b8c-a2fd-bf24c01f6252',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-12396856-1b55-473e-bbcf-c5cf0d2f1e47.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c15dfb2e-8f59-4cbb-9dce-f7fecc627e9f',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 2,
          type: 'enunciated',
          value: 'HOBBY',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '64173bbf-e585-4621-92d7-07fbf85ef9ff',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 3,
          type: 'longtext',
          value: 'Passatempo\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'aa6e8e76-f8dc-447f-81a5-dae8a2b74eb2',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '137d0626-8f93-4b1d-8cae-a6de781e345c',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-8-7-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c0f5b853-f2a0-4cbe-b99a-8e184df2f7e9',
          createdAt: '2024-06-17T19:57:20.724Z',
          updatedAt: '2024-06-17T19:57:20.724Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-11-7-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '0cc4e9eb-1621-49ad-bfd9-abb24dbd9522',
      createdAt: '2024-06-17T19:57:20.737Z',
      updatedAt: '2024-06-17T19:57:20.737Z',
      type: 'content',
      code: 'WU_DYK_B1_P7_A6',
      order: 8,
      status: 'activated',
      widgets: [
        {
          id: '4624261b-bf75-43f8-8398-4fb239268512',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-02a1daf9-5160-4112-b68f-ec56f423d287.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f37fe1de-04cd-40dc-aeac-8d536e729181',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 2,
          type: 'enunciated',
          value: 'KIT',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '912eb922-795b-411a-88b2-16dcfdecfef2',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 3,
          type: 'longtext',
          value: 'Conjunto de objetos',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '318d8536-4d1d-48f4-b19b-95fac7a20902',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 4,
          type: 'enunciated',
          value: 'Compare as pronúncias abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b4be4a09-2266-4b7d-bcea-56c20453e2dc',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-e5725763-5d1c-4c74-b3a9-753f4a0dc4fb.mpeg',
          feedback: false,
          label: 'in English',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f8fc47bf-1f5d-4774-bfe2-79fd1fa07488',
          createdAt: '2024-06-17T19:57:20.737Z',
          updatedAt: '2024-06-17T19:57:20.737Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-11-8-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'in Portuguese',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '60e7fe62-8279-406f-8b9e-0eaa29dc7543',
      createdAt: '2024-06-17T19:57:20.748Z',
      updatedAt: '2024-06-17T19:57:20.748Z',
      type: 'content',
      code: 'WU_DYK_B2_P1',
      order: 9,
      status: 'activated',
      widgets: [
        {
          id: '779aff39-fdce-4749-8886-a0ccc0bdeb3e',
          createdAt: '2024-06-17T19:57:20.748Z',
          updatedAt: '2024-06-17T19:57:20.748Z',
          order: 1,
          type: 'subtitle',
          value: 'English around us',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '52bdb165-36c6-413d-9a98-f9488b0af1aa',
          createdAt: '2024-06-17T19:57:20.748Z',
          updatedAt: '2024-06-17T19:57:20.748Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-9-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '2912b8a0-0df3-418f-adee-5132ae14e57f',
          createdAt: '2024-06-17T19:57:20.748Z',
          updatedAt: '2024-06-17T19:57:20.748Z',
          order: 3,
          type: 'longtext',
          value:
            'Você sabe que o inglês está por toda parte, desde músicas e filmes até os games e apps que você usa.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e747b68f-8fcb-4998-9f3f-e53b2dcdb7df',
          createdAt: '2024-06-17T19:57:20.748Z',
          updatedAt: '2024-06-17T19:57:20.748Z',
          order: 4,
          type: 'longtext',
          value:
            'Você também já aprendeu que muitas palavras que  usamos, como <b>notebook</b> e <b>shopping</b>, vêm do inglês. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '02ac086a-a1a5-4432-8667-a4ec37faedd1',
          createdAt: '2024-06-17T19:57:20.748Z',
          updatedAt: '2024-06-17T19:57:20.748Z',
          order: 5,
          type: 'longtext',
          value:
            'Sem perceber você já conhece muitas palavras! Então, quando está aprendendo inglês, na verdade está só expandindo o que já conhece. Legal, né?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '9c4c74cc-efc9-4437-aa7b-95c3447bedf7',
      createdAt: '2024-06-17T19:57:20.760Z',
      updatedAt: '2024-06-17T19:57:20.760Z',
      type: 'content',
      code: 'WU_DYK_B2_P2',
      order: 10,
      status: 'activated',
      widgets: [
        {
          id: 'c88fec55-055f-47d3-8fd2-f524af3d8c0b',
          createdAt: '2024-06-17T19:57:20.760Z',
          updatedAt: '2024-06-17T19:57:20.760Z',
          order: 1,
          type: 'longtext',
          value:
            'Mas claro que há muito o que aprender nessa jornada. Você vai aprender novas palavras e expressões, e entender como elas se organizam em frases para melhorar a sua comunicação.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '7805390d-526a-4a20-a56f-4340aaa9be2d',
          createdAt: '2024-06-17T19:57:20.760Z',
          updatedAt: '2024-06-17T19:57:20.760Z',
          order: 2,
          type: 'longtext',
          value:
            'Além do vocabulário e da gramática, que são super importantes, é essencial ficarmos atentos às dicas de como aprender de forma mais eficaz e prazerosa.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f88b8cc1-394f-4c10-b664-1d68c426bd4e',
          createdAt: '2024-06-17T19:57:20.760Z',
          updatedAt: '2024-06-17T19:57:20.760Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-10-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'fce40d78-f6cd-417a-9ed9-be26bbd5fbdf',
      createdAt: '2024-06-17T19:57:20.771Z',
      updatedAt: '2024-06-17T19:57:20.771Z',
      type: 'content',
      code: 'WU_DYK_B2_P3',
      order: 11,
      status: 'activated',
      widgets: [
        {
          id: '26d5806d-84f1-4688-81f7-55738ca15b82',
          createdAt: '2024-06-17T19:57:20.771Z',
          updatedAt: '2024-06-17T19:57:20.771Z',
          order: 1,
          type: 'longtext',
          value:
            'Imagine que aprender novas palavras é como brincar com blocos de montar. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c78d35f2-89c4-489e-a1a9-2a3727e7f073',
          createdAt: '2024-06-17T19:57:20.771Z',
          updatedAt: '2024-06-17T19:57:20.771Z',
          order: 2,
          type: 'longtext',
          value:
            'Sozinhas, essas palavras são interessantes e cheias de potencial, mas a verdadeira magia acontece quando começamos a colocá-las juntas, formando frases para nos expressar. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '668df80b-19a1-412d-bb6f-db545cbe444f',
          createdAt: '2024-06-17T19:57:20.771Z',
          updatedAt: '2024-06-17T19:57:20.771Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-5-11-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '96d766ca-d79d-4352-a118-2440460dbbc0',
          createdAt: '2024-06-17T19:57:20.771Z',
          updatedAt: '2024-06-17T19:57:20.771Z',
          order: 4,
          type: 'longtext',
          value:
            'Quando você aprende uma palavra, é como se ganhasse um novo bloco para adicionar à sua caixa de recursos. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '658e1edc-8fa7-4aa3-9e56-436bc2028fca',
      createdAt: '2024-06-17T19:57:20.785Z',
      updatedAt: '2024-06-17T19:57:20.785Z',
      type: 'content',
      code: 'WU_DYK_B2_P4',
      order: 12,
      status: 'activated',
      widgets: [
        {
          id: '7f4ae394-a593-4912-a98d-406923fa4d10',
          createdAt: '2024-06-17T19:57:20.785Z',
          updatedAt: '2024-06-17T19:57:20.785Z',
          order: 1,
          type: 'longtext',
          value:
            'Cada palavra tem a sua família, e cada membro dessa família tem a sua função especial, como substantivos ou verbos. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f2c8c0a8-f912-401e-84ba-e3155d1b641d',
          createdAt: '2024-06-17T19:57:20.785Z',
          updatedAt: '2024-06-17T19:57:20.785Z',
          order: 2,
          type: 'longtext',
          value: 'Veja o exemplo da palavra GAME no mapa mental a seguir.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e8000da5-31e3-44b1-a41a-fa8835c5eecc',
          createdAt: '2024-06-17T19:57:20.785Z',
          updatedAt: '2024-06-17T19:57:20.785Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-fe709ef5-d2c4-4d51-93e3-2253a8b6bc3d.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f08b7e0c-6611-4899-ab47-58767e1f631c',
          createdAt: '2024-06-17T19:57:20.785Z',
          updatedAt: '2024-06-17T19:57:20.785Z',
          order: 4,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-5-12-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.mpeg',
          feedback: false,
          label: 'Ouça as palavras da família GAME!',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'a1469c8a-5855-49ce-b1b4-3b39b2ff6928',
      createdAt: '2024-06-17T19:57:20.796Z',
      updatedAt: '2024-06-17T19:57:20.796Z',
      type: 'content',
      code: 'WU_DYK_B2_P5',
      order: 13,
      status: 'activated',
      widgets: [
        {
          id: '2c5fea3b-1104-4a86-91c6-9aaa60036886',
          createdAt: '2024-06-17T19:57:20.796Z',
          updatedAt: '2024-06-17T19:57:20.796Z',
          order: 1,
          type: 'longtext',
          value:
            'Viu só como uma única palavra pode ampliar nosso vocabulário se aprendermos para que serve as terminações S, R, ED e ING? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e4c5a804-54ed-48df-b6cf-361d7f17257e',
          createdAt: '2024-06-17T19:57:20.796Z',
          updatedAt: '2024-06-17T19:57:20.796Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-13-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c36e3cbb-b8f6-4324-808c-fe4db0cb29d2',
          createdAt: '2024-06-17T19:57:20.796Z',
          updatedAt: '2024-06-17T19:57:20.796Z',
          order: 3,
          type: 'longtext',
          value:
            'Tome nota das palavras novas, e crie um dicionário personalizado com as palavras novas e sua família de palavras. Que tal adicionar ainda um desenho e escrever frases exemplos também?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '87a01653-4d29-41e6-910b-8bc6729d8f09',
      createdAt: '2024-06-17T19:57:20.808Z',
      updatedAt: '2024-06-17T19:57:20.808Z',
      type: 'content',
      code: 'WU_DYK_B3_P1',
      order: 14,
      status: 'activated',
      widgets: [
        {
          id: 'ef30f832-82fb-4f7e-981f-484b238c1913',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          order: 1,
          type: 'subtitle',
          value: 'Let’s practice',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c6483b05-306b-4fbe-a5e0-2a0836f86d05',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          order: 2,
          type: 'longtext',
          value:
            'Agora que você já aprendeu sobre as palavras que são: *cognates*, *false friends* and *loanwords*, e como expandir seu vocabulário, vamos colocar esse conhecimento em prática! <br>Realize as atividades a seguir para relembrar o que você aprendeu até aqui. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ea6d6c20-69de-4d95-98b6-d9c65f5626aa',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-14-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'd9fc6e41-9b76-4928-8364-befcafbb71cb',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          key: 'false friends',
          title: 'false friends',
          subtitle: 'Falso amigos',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-8dd8f9cf-af28-440e-ac35-1384e1c647c1.mpeg',
          example: 'Some words are false friends.',
          exampleTranslate: 'Algumas palavras são chamadas de falso amigos.',
          description:
            'False friends são falsos cognatos, ou seja, são parecidas com uma palavra em português, mas tem diferentes significados. Por exemplo. Actual que em português significa Na realidade e não atual. ',
        },
        {
          id: '5800a58c-bb54-41ad-a81a-93d30b8c135d',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          key: 'cognates',
          title: 'cognates',
          subtitle: 'cognatas',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-d2cf39e4-3db5-492a-a862-3f1a344f20d8.mpeg',
          example: 'Pay attention to cognate words. ',
          exampleTranslate: 'Preste atenção às palavras cognatas.',
          description:
            'Palavras cognatas são palavras que são parecidas com palavras as português e tem a mesma origem e significado.',
        },
        {
          id: '680b88c1-ae39-42df-b674-648b2854b300',
          createdAt: '2024-06-17T19:57:20.808Z',
          updatedAt: '2024-06-17T19:57:20.808Z',
          key: 'loanwords',
          title: 'loanwords',
          subtitle: 'palavras emprestadas',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-eeed67ab-1b34-426f-ab16-34144005e57d.mpeg',
          example: 'Internet is a loanword from English.',
          exampleTranslate: 'Internet é uma palavra emprestada do inglês.',
          description:
            'Loanwords são palavras estrangeiras que adotamos no nosso cotidiano. ',
        },
      ],
    },
    {
      id: '8ce77f12-d24c-4f4a-b229-61f22bea460c',
      createdAt: '2024-06-17T19:57:20.824Z',
      updatedAt: '2024-06-17T19:57:20.824Z',
      type: 'content',
      code: 'WU_DYK_B3_P2_A7',
      order: 15,
      status: 'activated',
      widgets: [
        {
          id: '3dd05e55-8f76-433a-b489-c6b15755a033',
          createdAt: '2024-06-17T19:57:20.824Z',
          updatedAt: '2024-06-17T19:57:20.824Z',
          order: 1,
          type: 'enunciated',
          value: 'Estratégia de leitura: *skimming*',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ebe01837-a744-4fda-b3cb-9c53ecd09f5c',
          createdAt: '2024-06-17T19:57:20.824Z',
          updatedAt: '2024-06-17T19:57:20.824Z',
          order: 2,
          type: 'longtext',
          value:
            ' 1. Identifique as palavras que você acha parecida com as palavras em português.<br>2. Anote no seu caderno a palavra em inglês e o que você acha que ela significa.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b175f1e2-50ee-412b-aaa3-973604efca18',
          createdAt: '2024-06-17T19:57:20.824Z',
          updatedAt: '2024-06-17T19:57:20.824Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-45c52d99-6e3e-4dd9-a801-51604a619773.png',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '9e4e6332-9cbd-43ed-95ca-a92d3ca61b99',
          createdAt: '2024-06-17T19:57:20.824Z',
          updatedAt: '2024-06-17T19:57:20.824Z',
          key: 'skimming',
          title: 'skimming',
          subtitle: 'Leitura rápida',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-046c15fc-992d-46c8-a28d-0f3118ad3be1.mpeg',
          example: 'Skimming is a great reading strategy.',
          exampleTranslate:
            'Leitura rápida  é uma ótima estratégia de leitura.',
          description:
            'No momento dessa leitura rápida, observe as palavras cognatas e loanwords para identificar a idéia geral do texto. ',
        },
      ],
    },
    {
      id: 'cb92adca-0ed6-45d4-b021-c8b236f2acad',
      createdAt: '2024-06-17T19:57:20.837Z',
      updatedAt: '2024-06-17T19:57:20.837Z',
      type: 'activity',
      code: 'WU_DYK_B3_P3_A8_A12.1',
      order: 16,
      status: 'activated',
      widgets: [
        {
          id: '44b5b0e7-a1af-4e2d-85db-82c46bd725ef',
          createdAt: '2024-06-17T19:57:20.837Z',
          updatedAt: '2024-06-17T19:57:20.837Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'fd8c6b57-579e-4966-8198-629440d77c53',
          createdAt: '2024-06-17T19:57:20.837Z',
          updatedAt: '2024-06-17T19:57:20.837Z',
          order: 2,
          type: 'enunciated',
          value:
            ' How do you say universe in Português?<br>Como se diz "universe" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'f2b49036-c61c-4fa0-aed5-d546e5e125d5',
          createdAt: '2024-06-17T19:57:20.837Z',
          updatedAt: '2024-06-17T19:57:20.837Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage:
            'Universe e Universo são escritas quase de forma idêntica. ',
          wrongMessage: 'Observe sempre como as palavras são bem parecidas.',
          template: 'default',
          options: [
            {
              id: '57cf15f3-2c19-43db-8266-16903a6b8869',
              createdAt: '2024-06-17T19:57:20.837Z',
              updatedAt: '2024-06-17T19:57:20.837Z',
              order: 0,
              text: 'Universo',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '5b1c1bc8-1dc3-45e5-8abd-a432c3ddd642',
              createdAt: '2024-06-17T19:57:20.837Z',
              updatedAt: '2024-06-17T19:57:20.837Z',
              order: 1,
              text: 'Universidade',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '1a4c3ebc-aca5-4ed3-acfa-865d1f696d91',
              createdAt: '2024-06-17T19:57:20.837Z',
              updatedAt: '2024-06-17T19:57:20.837Z',
              order: 2,
              text: 'Unicórnio',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '5cf5e52e-be8a-4004-8ca6-a7c6dcb36e3e',
      createdAt: '2024-06-17T19:57:20.851Z',
      updatedAt: '2024-06-17T19:57:20.851Z',
      type: 'activity',
      code: 'WU_DYK_B3_P3_A8_A12.2',
      order: 17,
      status: 'activated',
      widgets: [
        {
          id: 'cc787900-a969-431b-aea4-8d92d335cb74',
          createdAt: '2024-06-17T19:57:20.851Z',
          updatedAt: '2024-06-17T19:57:20.851Z',
          order: 1,
          type: 'title',
          value: 'A afirmação abaixo é verdadeira ou falsa?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '1d9e32af-ee7c-4c77-8023-eac4b7eb974b',
          createdAt: '2024-06-17T19:57:20.851Z',
          updatedAt: '2024-06-17T19:57:20.851Z',
          order: 2,
          type: 'enunciated',
          value:
            'A palavra "lecture" em inglês é um cognato da palavra "leitura".',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'ea405e98-00da-44ee-aba0-da8230c8a605',
          createdAt: '2024-06-17T19:57:20.851Z',
          updatedAt: '2024-06-17T19:57:20.851Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Lecture" em inglês significa "palestra" ou "aula" em português, não "leitura". Essa palavra é um falso cognato.',
          template: 'default',
          options: [
            {
              id: '31d763a6-5801-400d-8441-e1221c70fcfc',
              createdAt: '2024-06-17T19:57:20.851Z',
              updatedAt: '2024-06-17T19:57:20.851Z',
              order: 0,
              text: 'Verdadeira',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'e409d9a2-5b37-4bc5-a71d-2dedd7dd5642',
              createdAt: '2024-06-17T19:57:20.851Z',
              updatedAt: '2024-06-17T19:57:20.851Z',
              order: 1,
              text: 'Falsa',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '55cc3a33-7f9b-434c-9e04-2824d04c68f6',
      createdAt: '2024-06-17T19:57:20.865Z',
      updatedAt: '2024-06-17T19:57:20.865Z',
      type: 'activity',
      code: 'WU_DYK_B3_P3_A8_A12.3',
      order: 18,
      status: 'activated',
      widgets: [
        {
          id: 'd6fea91b-54e9-42ce-a10d-c5c860d396ca',
          createdAt: '2024-06-17T19:57:20.865Z',
          updatedAt: '2024-06-17T19:57:20.865Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'f5909e96-b36e-4b58-84b9-fcca513cc7b6',
          createdAt: '2024-06-17T19:57:20.865Z',
          updatedAt: '2024-06-17T19:57:20.865Z',
          order: 2,
          type: 'enunciated',
          value: 'O que significa  "expert" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '880f250f-0dd4-4002-b9a3-676655df09e8',
          createdAt: '2024-06-17T19:57:20.865Z',
          updatedAt: '2024-06-17T19:57:20.865Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            'É muito fácil errar essa aqui. Expert significa especialista. Esse é outro exemplo de falso cognatos, ou seja, que tem um significado diferente do que parece ter.',
          template: 'default',
          options: [
            {
              id: '52419806-3125-469a-b518-1c6859d6263d',
              createdAt: '2024-06-17T19:57:20.865Z',
              updatedAt: '2024-06-17T19:57:20.865Z',
              order: 0,
              text: 'Esperto',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '4e8a6d5b-4a86-4545-a498-5bf610996f79',
              createdAt: '2024-06-17T19:57:20.865Z',
              updatedAt: '2024-06-17T19:57:20.865Z',
              order: 1,
              text: 'Especialista',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '63c151c2-1322-4cf6-8156-d16c03e1d88e',
              createdAt: '2024-06-17T19:57:20.865Z',
              updatedAt: '2024-06-17T19:57:20.865Z',
              order: 2,
              text: 'Experto',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'd73f53f1-ab00-42c8-aae0-0cf3755cb355',
      createdAt: '2024-06-17T19:57:20.879Z',
      updatedAt: '2024-06-17T19:57:20.879Z',
      type: 'activity',
      code: 'WU_DYK_B3_P3_A8_A12.4',
      order: 19,
      status: 'activated',
      widgets: [
        {
          id: 'e822ca3b-67ae-4198-b165-e0111403b78a',
          createdAt: '2024-06-17T19:57:20.879Z',
          updatedAt: '2024-06-17T19:57:20.879Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '7517a553-b6cc-4835-a4a7-b2c285f62c33',
          createdAt: '2024-06-17T19:57:20.879Z',
          updatedAt: '2024-06-17T19:57:20.879Z',
          order: 2,
          type: 'enunciated',
          value: 'Qual é o cognato da palavra "solar system" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '67fe39c3-38e2-4f19-94b8-29affd1cd409',
          createdAt: '2024-06-17T19:57:20.879Z',
          updatedAt: '2024-06-17T19:57:20.879Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            'Lembre-se que os cognatos são muito parecidos porque tem o mesmo significado e origem da palavra.',
          template: 'default',
          options: [
            {
              id: '2127ca4c-ed42-49bf-857e-1de5e522b07d',
              createdAt: '2024-06-17T19:57:20.879Z',
              updatedAt: '2024-06-17T19:57:20.879Z',
              order: 0,
              text: 'Sistema solar',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '4a1246d8-e1b8-409c-83d0-c574d77948c0',
              createdAt: '2024-06-17T19:57:20.879Z',
              updatedAt: '2024-06-17T19:57:20.879Z',
              order: 1,
              text: 'Sistema solário',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '4e275e02-e34f-4a53-a8d3-23b1af9bac43',
              createdAt: '2024-06-17T19:57:20.879Z',
              updatedAt: '2024-06-17T19:57:20.879Z',
              order: 2,
              text: 'Solário',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '6303e75d-7095-40b7-9ae0-e0ccf7b963e9',
      createdAt: '2024-06-17T19:57:20.892Z',
      updatedAt: '2024-06-17T19:57:20.892Z',
      type: 'activity',
      code: 'WU_DYK_B3_P3_A8_A12.5',
      order: 20,
      status: 'activated',
      widgets: [
        {
          id: '5c736194-4a3e-4847-8b01-82a9506308e1',
          createdAt: '2024-06-17T19:57:20.892Z',
          updatedAt: '2024-06-17T19:57:20.892Z',
          order: 1,
          type: 'title',
          value: 'A afirmação abaixo é verdadeira ou falsa?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '58c7e01c-908c-43e2-8df9-2d3034835c13',
          createdAt: '2024-06-17T19:57:20.892Z',
          updatedAt: '2024-06-17T19:57:20.892Z',
          order: 2,
          type: 'enunciated',
          value: 'A palavra "hobby" é um cognato em português. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'ca80a89a-337e-4334-99a9-47b6cb6a1594',
          createdAt: '2024-06-17T19:57:20.892Z',
          updatedAt: '2024-06-17T19:57:20.892Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: 'Isso mesmo. É um exemplo de estrangeirismo.',
          wrongMessage:
            'Não é uma palavra cognata e sim uma palavra emprestada do inglês que significa passatempo. É um exemplo de estrangeirismo. ',
          template: 'default',
          options: [
            {
              id: '8028a784-551d-4f2e-a1a7-4f76dc860530',
              createdAt: '2024-06-17T19:57:20.892Z',
              updatedAt: '2024-06-17T19:57:20.892Z',
              order: 0,
              text: 'Verdadeira',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '67dd02f4-d6c9-4482-962d-89f80d9f0d02',
              createdAt: '2024-06-17T19:57:20.892Z',
              updatedAt: '2024-06-17T19:57:20.892Z',
              order: 1,
              text: 'Falsa',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '902ea071-3d2e-41a4-8d28-df7046a73c76',
      createdAt: '2024-06-17T19:57:20.905Z',
      updatedAt: '2024-06-17T19:57:20.905Z',
      type: 'content',
      code: 'WU_DYK_B4_P1',
      order: 21,
      status: 'activated',
      widgets: [
        {
          id: 'ebceaeb8-1465-42fa-a014-7e80cb4d7198',
          createdAt: '2024-06-17T19:57:20.905Z',
          updatedAt: '2024-06-17T19:57:20.905Z',
          order: 1,
          type: 'subtitle',
          value: 'False friends',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '2f75b96e-4311-4782-8b39-48851cb170bc',
          createdAt: '2024-06-17T19:57:20.905Z',
          updatedAt: '2024-06-17T19:57:20.905Z',
          order: 2,
          type: 'longtext',
          value:
            'Como vou saber quando uma palavra é <b>cognata ou falso cognato</b>?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c215c3d0-ae77-4c94-9b94-5a72d092fb2a',
          createdAt: '2024-06-17T19:57:20.905Z',
          updatedAt: '2024-06-17T19:57:20.905Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-f16c4e5c-5354-4ba3-99cb-2f161419068c.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '621ff2c6-72b6-4693-80e1-2f78f05d3e1d',
          createdAt: '2024-06-17T19:57:20.905Z',
          updatedAt: '2024-06-17T19:57:20.905Z',
          order: 4,
          type: 'longtext',
          value:
            '<b>É simples:</b> preste atenção ao contexto em que ela foi usada. Se a palavra parece não se encaixar ou tem um significado diferente do esperado no contexto, é hora de pesquisar. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '24882291-b372-4f4c-90cf-56b7a9ef4eb3',
          createdAt: '2024-06-17T19:57:20.905Z',
          updatedAt: '2024-06-17T19:57:20.905Z',
          order: 5,
          type: 'longtext',
          value:
            'Use um dicionário online ou impresso para verificar o significado.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '3900cc1e-77e5-4ab7-a590-0a665b328590',
      createdAt: '2024-06-17T19:57:20.917Z',
      updatedAt: '2024-06-17T19:57:20.917Z',
      type: 'content',
      code: 'WU_DYK_B4_P2',
      order: 22,
      status: 'activated',
      widgets: [
        {
          id: 'e55b71d5-ff62-415e-84d3-f7831f8d02f6',
          createdAt: '2024-06-17T19:57:20.917Z',
          updatedAt: '2024-06-17T19:57:20.917Z',
          order: 1,
          type: 'longtext',
          value:
            'Falso cognato, também conhecido como <b>false friends</b> ou <b>false cognate</b> em inglês, refere-se às palavras que parecem ser semelhantes em dois idiomas, mas têm significados diferentes. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3f7f4e8a-4e2f-4390-830e-0c7a4dc55059',
          createdAt: '2024-06-17T19:57:20.917Z',
          updatedAt: '2024-06-17T19:57:20.917Z',
          order: 2,
          type: 'longtext',
          value:
            "Por exemplo, a palavra <b>actual</b> em inglês significa 'real’, e não 'atual', como pode parecer para um falante de português.",
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1f250870-a6c5-49f1-8f1b-8f0b14efaeac',
          createdAt: '2024-06-17T19:57:20.917Z',
          updatedAt: '2024-06-17T19:57:20.917Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-5cdac36f-e284-4f8e-831a-8d787f295330.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '2ab63593-e6cc-4f8d-ab2b-4ae155ea9dd0',
      createdAt: '2024-06-17T19:57:20.928Z',
      updatedAt: '2024-06-17T19:57:20.928Z',
      type: 'content',
      code: 'WU_DYK_B4_P3_A13',
      order: 23,
      status: 'activated',
      widgets: [
        {
          id: 'a46877fc-c173-4f78-9810-524b9a66bf0d',
          createdAt: '2024-06-17T19:57:20.928Z',
          updatedAt: '2024-06-17T19:57:20.928Z',
          order: 1,
          type: 'enunciated',
          value:
            'Compare a lista que você escreveu no seu caderno com as palavras destacadas no texto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e0d9e1d6-6f6a-4f8b-92fb-fb71a7289765',
          createdAt: '2024-06-17T19:57:20.928Z',
          updatedAt: '2024-06-17T19:57:20.928Z',
          order: 2,
          type: 'longtext',
          value: 'Quantas você identificou?\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9aa86826-968e-41b9-bff3-bc573fe365fe',
          createdAt: '2024-06-17T19:57:20.928Z',
          updatedAt: '2024-06-17T19:57:20.928Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-19-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.png',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'efd2230d-1297-4017-8f90-4e87956f094f',
      createdAt: '2024-06-17T19:57:20.938Z',
      updatedAt: '2024-06-17T19:57:20.938Z',
      type: 'content',
      code: 'WU_DYK_B4_P4',
      order: 24,
      status: 'activated',
      widgets: [
        {
          id: '0118897d-9a9b-447a-8e1e-0fa6ad954bde',
          createdAt: '2024-06-17T19:57:20.938Z',
          updatedAt: '2024-06-17T19:57:20.938Z',
          order: 1,
          type: 'longtext',
          value:
            'Ler um texto em inglês no início da nossa jornada é como ser um detetive, curioso e atento às pistas que o texto nos oferece. Procuramos por <b>palavras semelhantes</b> e reconhecemos padrões gramaticais.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'template-1',
          options: [],
        },
        {
          id: '831b997e-b8be-4eb8-a7c6-e072351d9dcd',
          createdAt: '2024-06-17T19:57:20.938Z',
          updatedAt: '2024-06-17T19:57:20.938Z',
          order: 2,
          type: 'longtext',
          value:
            'Usamos o <b>contexto</b> para desvendar os significados. Cada frase se torna um enigma a ser solucionado, tornando o processo de aprendizagem tão emocionante quanto uma investigação.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd6a7f323-8e64-491b-abd8-86058473892f',
          createdAt: '2024-06-17T19:57:20.938Z',
          updatedAt: '2024-06-17T19:57:20.938Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-5956d994-0f89-4ba0-9b3f-25843a0517df.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'd03efe89-3204-4a84-b1f7-8772e29d457e',
      createdAt: '2024-06-17T19:57:20.949Z',
      updatedAt: '2024-06-17T19:57:20.949Z',
      type: 'activity',
      code: 'WU_DYK_B4_P5_A14_A15.1',
      order: 25,
      status: 'activated',
      widgets: [
        {
          id: '8dd0a1cc-cce2-49f9-aeaa-1b41f05c4c32',
          createdAt: '2024-06-17T19:57:20.949Z',
          updatedAt: '2024-06-17T19:57:20.949Z',
          order: 1,
          type: 'title',
          value: 'Encontre os pares.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '9b554145-8a46-41a2-8c8b-d49f534e7d75',
          createdAt: '2024-06-17T19:57:20.949Z',
          updatedAt: '2024-06-17T19:57:20.949Z',
          order: 2,
          type: 'enunciated',
          value:
            'Preste atenção como as palavras são escritas em inglês e português',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '54405e78-885e-4207-827c-de20ca093285',
          createdAt: '2024-06-17T19:57:20.949Z',
          updatedAt: '2024-06-17T19:57:20.949Z',
          order: 3,
          type: 'combination',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '2c4e1ed5-90dc-4f2c-85d4-90323cbf1c59',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 0,
              text: 'astrology',
              textRelated: 'astrologia',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: '397f2874-9662-4591-a5b2-2a9b47993e0e',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 1,
              text: 'astronomer',
              textRelated: 'astrônomo',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: '443f8123-af60-4470-9a62-d564961506e0',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 2,
              text: 'embarrassed',
              textRelated: 'envergonhado(a)',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: '7e99256a-c968-45a3-8cfe-d57947c27e9f',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 3,
              text: 'decided',
              textRelated: 'decidiu',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: '465b0491-ba19-4a77-9b9b-c94a06e42bba',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 3,
              text: 'actually',
              textRelated: 'na verdade',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: 'abc77ab1-267b-4957-8185-c9e7b6fb47f8',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 4,
              text: 'assumption',
              textRelated: 'suposição',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: 'bbabef21-b8bc-468c-b3f6-17f097812e83',
              createdAt: '2024-06-17T19:57:20.949Z',
              updatedAt: '2024-06-17T19:57:20.949Z',
              order: 5,
              text: 'related',
              textRelated: 'relacionado',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '4b2ead3b-fadd-41c9-aa4b-41927ffff7a1',
      createdAt: '2024-06-17T19:57:20.964Z',
      updatedAt: '2024-06-17T19:57:20.964Z',
      type: 'activity',
      code: 'WU_DYK_P26.1',
      order: 26,
      status: 'activated',
      widgets: [
        {
          id: 'ef50614b-5b81-4fbf-a7ff-51e61c01a791',
          createdAt: '2024-06-17T19:57:20.964Z',
          updatedAt: '2024-06-17T19:57:20.964Z',
          order: 1,
          type: 'title',
          value: 'Encontre os pares.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '71e6d1bc-ba2e-4006-ba12-15bd47724442',
          createdAt: '2024-06-17T19:57:20.964Z',
          updatedAt: '2024-06-17T19:57:20.964Z',
          order: 2,
          type: 'enunciated',
          value:
            'Ouça e perceba as diferenças de pronúncia entre o inglês e português.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'c1e638d4-e184-4fe2-80ee-87d9298a2e5d',
          createdAt: '2024-06-17T19:57:20.964Z',
          updatedAt: '2024-06-17T19:57:20.964Z',
          order: 3,
          type: 'combination',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '8994a7db-f17d-49fe-a7e3-7eb7bf862da3',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 0,
              text: '',
              textRelated: 'incorreto',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-c0be491f-acbf-4ce8-a4eb-7a317b2f38e9.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: 'ea9db92c-287d-46a8-9d11-5ff79da325a5',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 1,
              text: '',
              textRelated: 'solar',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-f1d55036-0113-45f1-ae8e-4435dc1a9a34.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: '49acd1d5-c964-4126-af0d-fc822965fc85',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 2,
              text: '',
              textRelated: 'complexo',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-9dabe518-8695-4ee8-8567-9a478a0911ea.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: 'dca0fe58-13c2-4a43-90f2-eac0f80532c4',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 3,
              text: '',
              textRelated: 'confuso',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-3-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-e2f886a3-6dcb-473d-ab10-90501529904c.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: '39198866-5fd8-47dd-8060-31acc482df89',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 3,
              text: '',
              textRelated: 'inicial',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-4-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-8388d979-17bf-4ae8-a1e5-b5cad293dc76.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: 'c7503c9b-3323-4e01-a907-70ffdbaefcce',
              createdAt: '2024-06-17T19:57:20.964Z',
              updatedAt: '2024-06-17T19:57:20.964Z',
              order: 6,
              text: '',
              textRelated: 'tópicos',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-5-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1-25524181-9a73-4c69-9bb3-d295cee07f40.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'ae90f10c-2385-4a06-b33d-410b03bc1dda',
      createdAt: '2024-06-17T19:57:20.978Z',
      updatedAt: '2024-06-17T19:57:20.978Z',
      type: 'content',
      code: 'WU_DYK_end',
      order: 27,
      status: 'activated',
      widgets: [
        {
          id: '983d3e76-0dec-47df-8cd2-d6e17f724b34',
          createdAt: '2024-06-17T19:57:20.978Z',
          updatedAt: '2024-06-17T19:57:20.978Z',
          order: 1,
          type: 'title',
          value: 'CONGRATULATIONS! ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ae518358-b6f2-408a-ab7b-37698c238396',
          createdAt: '2024-06-17T19:57:20.978Z',
          updatedAt: '2024-06-17T19:57:20.978Z',
          order: 2,
          type: 'subtitle',
          value: 'Você concluiu a etapa',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0e704b8b-c62f-4539-b76b-9f5c47c0fe80',
          createdAt: '2024-06-17T19:57:20.978Z',
          updatedAt: '2024-06-17T19:57:20.978Z',
          order: 3,
          type: 'title',
          value: 'DID YOU KNOW',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e91834fa-7836-4d92-a5ac-75068c406dad',
          createdAt: '2024-06-17T19:57:20.978Z',
          updatedAt: '2024-06-17T19:57:20.978Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-22-a45ccdb6-4bc7-45bd-8995-dc367b4a2be1.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e355852e-a117-4484-843f-8cfd136f0f50',
          createdAt: '2024-06-17T19:57:20.978Z',
          updatedAt: '2024-06-17T19:57:20.978Z',
          order: 5,
          type: 'longtext',
          value: '\r\nClique em <b>LEARN MORE</b> para continuar.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
  ],
};
