<st-loading-page
  *ngIf="state == 'loading'"
  source="https://go2dev.s3.amazonaws.com/fixed/loading-3.lottie"
/>

<st-select-learning-steps-page
  *ngIf="state == 'steps'"
  [escapePath]="escapePath"
  [explorer]="explorer"
  [interpreter]="interpreter"
  [communicator]="communicator"
  (cardSelected)="onCardSelected($event)"
  (continueClicked)="onContinue()"
  [disabledButton]="isButtonDisabled"
/>
