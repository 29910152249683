import { Inject, Injectable, InjectionToken } from "@angular/core";
import { KeyStore } from "./key-store";

export const KEYSTORE_PREFIX = new InjectionToken<Function>('Key prefix for all storage items');

@Injectable({
  providedIn: 'root'
})
export class StorageService {

    constructor(@Inject(KEYSTORE_PREFIX) private keyStorePrefix: Function) { }

    private storage: Storage = localStorage;

    storeItem(key: KeyStore, value: string): void {
        this.storage.setItem(this.getFullKeyName(key), value);
    }

    storeBooleanItem(key: KeyStore, value: boolean): void {
        this.storage.setItem(this.getFullKeyName(key), value ? 'true' : 'false');
    }

    clearAll() {
      this.storage.clear();
    }

    retrieveStoredItem(key: KeyStore): string | false {
        const item = this.storage.getItem(this.getFullKeyName(key));
        if (item != null) {
            return item;
        }

        return false;
    }

    retrieveBooleanItem(key: KeyStore): boolean | null {
        const value = this.retrieveStoredItem(key);
        if (value === 'true') {
            return true;
        }

        if (value === 'false') {
            return false;
        }

        return null;
    }

    hasStoredItemByKey(key: KeyStore): boolean {
        return this.retrieveStoredItem(key) != null;
    }

    removeStoredItemByKey(key: KeyStore): boolean {
        if (!this.hasStoredItemByKey(key)) {
            return false;
        }

        this.storage.removeItem(this.getFullKeyName(key));

        return true;
    }

    private getFullKeyName(key: KeyStore): string {
        return this.keyStorePrefix() + key.area.concat(':').concat(key.key);
    }

}