<button #button [ngClass]="boxClasses" (click)="click.emit($event)">
  <ng-container *ngIf="isLoading">
    <div class="spinner"></div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <go-icon
      *ngIf="leftIcon"
      [icon]="leftIcon"
      [pathColor]="iconColor"
      [marginRight]="leftIconMargin"
    />
    <span [ngClass]="labelClasses" *ngIf="label">{{ label }}</span>
    <go-icon
      *ngIf="rightIcon"
      [icon]="rightIcon"
      [pathColor]="iconColor"
      [marginLeft]="rightIconMargin"
    />
  </ng-container>
</button>
