import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icons/icons.module';

import { GeneralProgressComponent } from './general-progress/general-progress.component';
import { SimpleProgressComponent } from './simple-progress/simple-progress.component';
import { CircleProgressComponent } from './circle-progress/circle-progress.component';

import { AbstractProgressComponent } from './abstract.progress.component';

const components = [
  GeneralProgressComponent,
  SimpleProgressComponent,
  CircleProgressComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule],
  exports: components,
})
export class ProgressesModule {}

export {
  GeneralProgressComponent,
  SimpleProgressComponent,
  CircleProgressComponent,
  AbstractProgressComponent,
};
