<section class="general-modal">
  <div class="box">
    <div class="tag go-background-color-purple600">
      <span class="text-poppins-subtitle-bold go-text-color-whiteColor">
        Glossário
      </span>
    </div>
    <div class="close-button">
      <go-general-button
        backgroundColor="salmon800"
        borderColor="salmon800"
        borderRadius="10px"
        leftIcon="close-white"
        leftIconMargin="2px"
        width="34px"
        height="34px"
        (click)="closeModal()"
      />
    </div>

    <header class="header go-border-color-gray300">
      <div class="title" [ngClass]="audio ? 'has-audio' : ''">
        <div *ngIf="audio" class="sound-icon">
          <go-icon icon="volume-up" pathColor="gray800" />
        </div>
        <h2 class="text-baloochettan-h4 go-text-color-gray800">
          {{ title }}
        </h2>
      </div>
      <p class="text-poppins-subtitle-bold go-text-color-purple500">
        {{ subtitle }}
      </p>
    </header>

    <div class="speech" *ngIf="speechStatus === 'speech'">
      <go-mic-button width="64px" height="64px" (click)="speech()" />
      <span class="text-poppins-body-bold go-text-color-gray900">
        Clique e fale
        <b class="text-poppins-body-bold go-text-color-purple500">
          {{ title }}
        </b>
      </span>
    </div>
    <div class="speech" *ngIf="speechStatus === 'listening'">
      <div
        class="waves go-background-color-turquoise500 go-border-color-turquoise100"
      >
        <div
          *ngFor="let intensity of speechIntensity"
          class="go-background-color-whiteColor"
          [style.height]="intensity * 10 + '%'"
        ></div>
      </div>
      <span class="text-poppins-body-bold go-text-color-gray900">
        Capturando áudio...
      </span>
    </div>
    <div class="speech result" *ngIf="speechStatus === 'concluded'">
      <div class="side-item">
        <go-general-button
          width="50px"
          height="50px"
          borderRadius="50px"
          leftIcon="rotate"
          leftIconMargin="0"
          [labelColor]="speechScore > 70 ? 'purple500' : 'whiteColor'"
          [borderColor]="speechScore > 70 ? 'purple500' : 'purple500'"
          [backgroundColor]="speechScore > 70 ? 'whiteColor' : 'purple500'"
          (click)="speechRetry()"
        />
        <span class="text-poppins-caption-bold go-text-color-gray900">
          Refazer
        </span>
      </div>
      <div class="mid-item">
        <div
          class="fake-button"
          [ngClass]="
            speechScore > 70
              ? 'go-background-color-purple500 go-border-color-purple200'
              : 'go-background-color-salmon600 go-border-color-salmon300'
          "
        >
          <span class="text-poppins-headline-bold go-text-color-whiteColor">
            {{ speechScore }}%
          </span>
        </div>
        <span
          class="text-poppins-body-bold"
          [ngClass]="
            speechScore > 70
              ? 'go-text-color-purple500'
              : 'go-text-color-salmon600'
          "
        >
          {{ speechScore > 70 ? "Well done!" : "Try again!" }}
        </span>
      </div>
      <div class="side-item">
        <go-general-button
          width="50px"
          height="50px"
          borderRadius="50px"
          leftIcon="ear"
          leftIconMargin="0"
          [labelColor]="speechScore > 70 ? 'purple500' : 'salmon700'"
          [borderColor]="speechScore > 70 ? 'purple500' : 'salmon700'"
          backgroundColor="whiteColor"
          (click)="speechAudio()"
        />
        <span class="text-poppins-caption-bold go-text-color-gray900">
          Ouvir
        </span>
      </div>
    </div>

    <div class="content">
      <article *ngIf="example" class="examples">
        <h4 class="text-baloochettan-headline go-text-color-gray900">
          Exemplo:
        </h4>
        <p class="text-poppins-subtitle-bold go-text-color-gray950">
          {{ example }}
        </p>
        @if (exampleTranslate) {
        <p class="text-poppins-subtitle-regular go-text-color-gray950">
          {{ exampleTranslate }}
        </p>
        }
      </article>

      <article *ngIf="description" class="description">
        <h4 class="text-baloochettan-headline go-text-color-gray900">
          Definição:
        </h4>
        <p class="text-poppins-subtitle-regular go-text-color-gray950">
          {{ description }}
        </p>
      </article>
    </div>
  </div>
</section>
