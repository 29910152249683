import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { StorageService } from "../../core/storage/storage.service";
import { ApiService } from "../api.service";

Injectable()
export abstract class AbstractApiService {

    constructor(protected storage: StorageService,
        protected api: ApiService,
        protected http: HttpClient) { }

}