import { Injectable } from '@angular/core';
import { ApiService } from '../../../../api/api.service';
import { AbstractMockApi } from '../../../abstract.mock-api';
import { UnitsCoursesMockApi } from '../api';
import { mockApiData } from 'student-lib';

@Injectable({
    providedIn: 'root'
})
export class UnitsDetailMockApi extends AbstractMockApi {

  static readonly unitIdParam = ':unitId';

  constructor(api: ApiService) {
    super(api, `/courses/${UnitsCoursesMockApi.courseClassIdParam}/units/${UnitsDetailMockApi.unitIdParam}`);
  }

  override registerHandlers(): void {
    this.mock.onGet(this.api.getFakePrefixUrl() + this.contextPath)
      .reply(({request}) => {
        return this.okResponse(mockApiData.unitsDetailData.default);
      }
    );
  }

}