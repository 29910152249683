import { Injectable } from '@angular/core';
import { LoggedUser } from 'student-lib';
import { AuthApiService, CommonNegativeLoginResult, authLoginContextPath, authTokenRefreshContextPath } from './auth.api.service';
import { Observable, lastValueFrom } from 'rxjs';
import { keyareas } from '../../shared/all-key-storages';
import { AbstractApiService } from '../core/abstract-api.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../core/storage/storage.service';
import { ApiService } from '../api.service';
import { AuthApiMapper } from './auth.api.mapper';

@Injectable()
export class AuthApiServiceImpl extends AbstractApiService implements AuthApiService {

  private mapper = new AuthApiMapper();

  constructor(storage: StorageService, api: ApiService, http: HttpClient) {
    super(storage, api, http);
  }

  doRefreshToken(): Promise<void> {
    const loggedUser = this.getLoggedUser();
    if (!loggedUser) {
      return Promise.reject(new Error('Not logged yet'));
    }

    const service = this;
    const url = this.api.getNormalizedUrl() + authTokenRefreshContextPath;
    const post = this.http.post(url, undefined);
    const cold = new Observable<void>((sub) => {
      post.subscribe({
        next(data: any) {
          const accessToken = service.mapper.toAccessToken(data);
          if (accessToken instanceof Error) {
            sub.error(accessToken);
            return;
          }

          if (!service.updateAccessToken(accessToken)) {
            sub.error('Access token was not updated');
            
            return;
          }

          sub.complete();
        },
        error(data) {
            if (data?.error?.message) {
                sub.error(data.error.message);
                return;
            }

            sub.error('Erro desconhecido: ' + data);
        },
        complete() {
            sub.complete();
        }
      })
    })

    return lastValueFrom(cold);
  }

  doLogout(): Promise<void> {
    return Promise.resolve(this.clearAllStorageData());
  }

  clearAllStorageData() {
    this.storage.clearAll();
  }

  doLoginWithGoogle(): Promise<LoggedUser | CommonNegativeLoginResult> {
    return Promise.resolve('unavailable');
  }

  doLoginWithEmailAndPassword(email: string, password: string): Promise<LoggedUser | CommonNegativeLoginResult> {
    const url = this.api.getNormalizedUrl() + authLoginContextPath;
    const body = {
      "authProvider": "BASIC_AUTH",
      "email": email,
      "password": password
    };

    const service = this;
    const post = this.http.post(url, body);
    const cold = new Observable<LoggedUser | CommonNegativeLoginResult>((sub) => {
      post.subscribe({
        next(data: any) {
          const loggedUser = service.mapper.toLoggedUser(data);
          if (loggedUser instanceof Error) {
            sub.error(loggedUser);
            return;
          }

          service.setLoggedUser(loggedUser);
          sub.next(loggedUser);
        },
        error(data) {
            if (data?.error?.message) {
                sub.error(data.error.message);
                return;
            }

            sub.error('Erro desconhecido: ' + data);
        },
        complete() {
            sub.complete();
        }
      })
    });

    return lastValueFrom(cold);
  }

  private updateAccessToken(token: string): boolean {
    const loggedUser = this.getLoggedUser();
    if (!loggedUser) {
      return false;
    }

    loggedUser.accessToken = token;
    this.setLoggedUser(loggedUser);

    return true;
  }

  clearLoggedUser(): void {
    this.storage.removeStoredItemByKey(keyareas.auth.userLogged);
  }

  isUserLogged(): boolean {
    const user = this.getLoggedUser();

    return user != undefined;
  }

  getLoggedUser(): LoggedUser | null {
    const loggedUser = this.storage.retrieveStoredItem(keyareas.auth.userLogged);
    if (loggedUser == false) {
      return null;
    }

    return JSON.parse(loggedUser);
  }

  setLoggedUser(loggedUser: LoggedUser): void {
    this.storage.storeItem(keyareas.auth.userLogged, JSON.stringify(loggedUser));
  }

}