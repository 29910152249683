import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThemeIconsTypes } from '../../core/models/enums/icons.enum';
import { iconsPathMap } from '../assets';
import {
  ThemeColors,
  ThemeColorsTypes,
} from '../../core/models/enums/colors.enum';
import { AbstractIconComponent } from '../abstract.icon.component';

@Component({
  selector: 'go-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent implements AbstractIconComponent, OnInit, OnChanges {
  readonly goComponentId: string = 'icon';

  @Input() icon!: ThemeIconsTypes;
  @Input() marginRight?: string;
  @Input() marginLeft?: string;
  @Input() svgColor?: ThemeColorsTypes;
  @Input() pathColor?: ThemeColorsTypes;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.renderIcon();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderIcon();
  }

  private async renderIcon() {
    const iconFile = await this.loadIcon(this.icon);
    try {
      this.el.nativeElement.innerHTML = iconFile;
      if (this.marginRight) {
        this.el.nativeElement.style.marginRight = this.marginRight;
      }
      if (this.marginLeft) {
        this.el.nativeElement.style.marginLeft = this.marginLeft;
      }

      //? SVG styles
      if (this.svgColor) {
        const svgElement = this.el.nativeElement.querySelector('svg');
        if (svgElement) {
          svgElement.style.fill = ThemeColors[this.svgColor];
        }
      }

      //? PATH styles
      if (this.pathColor) {
        const pathElement = this.el.nativeElement.querySelector('path');
        if (pathElement) {
          pathElement.style.fill = ThemeColors[this.pathColor];
        }
      }

      //? Fixed Style
      this.el.nativeElement.style.display = 'flex';
      this.el.nativeElement.style.alignItems = 'center';
      this.el.nativeElement.style.justifyContent = 'center';
    } catch (error) {
      console.error(`Error loading SVG for icon "${this.icon}":`, error);
    }
  }

  private async loadIcon(iconName: ThemeIconsTypes) {
    const iconModule = await iconsPathMap[iconName]();
    return iconModule.default;
  }
}
