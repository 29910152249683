import { Component, EventEmitter, Input, Output, model } from '@angular/core';
import { defaultMenuBarState, defaultMenuItemsPage } from '../../core/default-menu-items.pages';
import { MenuBarState } from 'go-components';
import { PageWithMenu } from '../../core/page-with-menu';
import { CourseOption } from './select-course-to-learn-page.module';
import { SelectAnItemToContinuePage } from '../core/select-an-item-to-continue-page';

@Component({
  selector: 'st-select-course-to-learn-page',
  templateUrl: './select-course-to-learn-page.component.html',
  styleUrl: './select-course-to-learn-page.component.scss',
})
export class SelectCourseToLearnPageComponent implements PageWithMenu, SelectAnItemToContinuePage {

  @Output() backButtonClick = new EventEmitter<void>();
  @Output() continueButtonClick = new EventEmitter<CourseOption | undefined>();

  @Input() menuItens = defaultMenuItemsPage;
  menuBarState = model<MenuBarState>(defaultMenuBarState);

  rawString: string = '**QUAL** é a aula que você está fazendo **AGORA**?';
  processedString: string = '';
  @Input() studentName: string = 'Luiz Fernando Rosso Perraro';

  @Input() options: CourseOption[] = [
    { id: '1', title: '6º ano - 601', isSelected: true },
    { id: '2', title: '6º ano - 603', isSelected: false },
  ];

  @Input() continueButtonDisabled = true;

  @Output() selectedCourse = new EventEmitter<CourseOption>();

  selectedOption?: CourseOption;

  ngOnInit() {
    this.processedString = this.transformStringToHtml(this.rawString);
  }

  onSelectedItem(indexItem: number): void {
    this.options.forEach((item, index) => {
      if (item.isSelected = index === indexItem) {
        this.selectedOption = item;
      }
    });

    if (this.selectedOption) {
      this.selectedCourse.emit(this.selectedOption);
    }
  }

  onContinue(): void {
    const selected = this.options.filter(v => v.isSelected).flatMap(v => v);
    if (selected) {
      this.selectedOption = selected[0];
    }
    
    this.continueButtonClick.emit(this.selectedOption);
  }

  onBack(): void { }

  //? Transforma string no formato apresentado em tela.
  //? Ex.: "**ONDE** você vai ministrar sua aula **AGORA**?"
  transformStringToHtml(input: string): string {
    // Regex para encontrar palavras entre ** e substituir
    const regex = /\*\*(.*?)\*\*/g;
    return input.replace(
      regex,
      '<b class="text-baloochettan-h1 go-text-color-purple700">$1</b>'
    );
  }
}