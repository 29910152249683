import { Provider } from "@angular/core";
import { ApiService } from "./api.service";
import { ApiServiceImpl } from "./api.service.impl";
import { AuthApiService } from "./auth/auth.api.service";
import { AuthApiServiceImpl } from "./auth/auth.api.service.impl";
import { CoursesClassesApiService } from "./courses/courses-classes.api.service";
import { CoursesApiServiceImpl } from "./courses/courses-classes.api.service.impl";

export const apiServicesProviders: Provider[] = [
  {
    provide: ApiService,
    useClass: ApiServiceImpl
  },
  {
    provide: AuthApiService,
    useClass: AuthApiServiceImpl
  },
  {
    provide: CoursesClassesApiService,
    useClass: CoursesApiServiceImpl
  }
];