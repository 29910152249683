<st-challenge-page
  *ngIf="currentStage && currentPage"
  [escapePath]="escapePath"
  [stageTitle]="currentStage.title"
  [page]="page"
  [pages]="loadedPages"
  [widgets]="currentPage.widgets"
  (goNextPage)="goToNextPage()"
  (goToPage)="goToPage($event.page)"
/>
