import { Component } from '@angular/core';

@Component({
  selector: 'go-general-block',
  templateUrl: './general-block.component.html',
  styleUrl: './general-block.component.scss'
})
export class GeneralBlockComponent {

}
