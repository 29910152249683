import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralCardComponent } from './general-card/general-card.component';
import { CardColors } from './card-colors.types';
import { IconsModule } from '../icons/icons.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { GeneralCardItem } from './general-card/general-card-item';
import { AbstractCardComponent } from './abstract.card.component';

const components = [
  GeneralCardComponent
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule, ButtonsModule],
  exports: components
})
export class CardsModule { }

export {
  GeneralCardComponent,
  CardColors,
  GeneralCardItem,
  AbstractCardComponent
}
