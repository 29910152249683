import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { childRoutes } from '../../core/routes/child-routes';
import { keyareas } from '../../shared/all-key-storages';

import { AuthApiService } from '../../api/auth/auth.api.service';
import { CoursesClassesApiService } from '../../api/courses/courses-classes.api.service';
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { ChallengePageModule, Page, Stage } from 'student-lib';
import { ChallengeService } from '../../shared/services/challenges.service';
import { Loading } from '../../shared/decorators/loading.decorator';

class Data {
  pages: Page[] = [];
}

@Component({
  selector: 'app-go-challenges',
  standalone: true,
  imports: [ChallengePageModule, NgIf],
  templateUrl: './go-challenges.component.html',
  styleUrls: ['./go-challenges.component.scss'],
})
export class GoChallengesComponent
  extends AbstractStudentPageComponent<any>
  implements OnInit
{
  escapePath = childRoutes.studentHome;
  data = new Data();
  stageId!: string;
  page!: string;

  loadedPages!: Page[];
  currentPage!: Page;
  currentStage!: Stage;
  isLoading: boolean = false;

  constructor(
    authApi: AuthApiService,
    private coursesClassesApi: CoursesClassesApiService,
    private route: ActivatedRoute,
    private router: Router,
    private challengeService: ChallengeService // Assegure-se de que o serviço está sendo injetado aqui
  ) {
    super(authApi);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateRouteData();
      });

    this.updateRouteData();
  }

  async updateRouteData(): Promise<void> {
    let stageId = this.route.snapshot.params['stageId'];
    let page = this.route.snapshot.params['page'];

    const lastSelectedCourse = this.retrieveStringFromStorage(
      keyareas.student.lastSelectedCourseId
    );
    if (!lastSelectedCourse) {
      this.router.navigate(['/']);
      return;
    }

    let loadedStage = this.challengeService.getStage();
    if (!loadedStage) {
      loadedStage = await this.coursesClassesApi.loadStage(
        lastSelectedCourse,
        stageId
      );
      this.challengeService.setStage(loadedStage);
    }

    let loadedPages = this.challengeService.getPages(stageId);
    if (!loadedPages) {
      loadedPages = await this.coursesClassesApi.loadPages(
        lastSelectedCourse,
        stageId
      );
      this.challengeService.setPages(loadedPages);
      console.log('Loaded pages from API for stage:', stageId);
    } else {
      console.log('Loaded pages from service for stage:', stageId);
    }

    this.stageId = stageId;
    this.page = page;
    this.loadedPages = loadedPages;
    this.currentPage = loadedPages[parseInt(page) - 1];
    this.currentStage = loadedStage;
    // console.log('Current page:', this.currentPage);
  }

  @Loading
  async goToNextPage(): Promise<void> {
    const serviceStage = this.challengeService.getStage();
    if (!serviceStage) return;

    const loadedPages = this.challengeService.getPages(serviceStage.id);
    if (loadedPages && this.page) {
      const currentPageIndex = parseInt(this.page) - 1;
      const nextPageIndex = currentPageIndex + 1;

      if (
        nextPageIndex < loadedPages.length &&
        loadedPages[nextPageIndex].status === 'activated'
      ) {
        this.page = (nextPageIndex + 1).toString();
        this.currentPage = loadedPages[nextPageIndex];

        const userLoggedInfo = this.retrieveStringFromStorage(
          keyareas.auth.userLogged
        );
        if (userLoggedInfo) {
          let userData = JSON.parse(userLoggedInfo);
          await this.router.navigate([
            `${userData.profile}/challenges/${this.stageId}/${this.page}`,
          ]);
        } else {
          await this.router.navigate(['/']);
        }
      } else {
        console.log('No next page or next page is not activated');
      }
    }
  }

  @Loading
  async goToPage(page: number): Promise<void> {
    const serviceStage = this.challengeService.getStage();
    if (!serviceStage) return;

    const loadedPages = this.challengeService.getPages(serviceStage.id);
    if (loadedPages && page > 0 && page <= loadedPages.length) {
      const targetPageIndex = page - 1;
      if (loadedPages[targetPageIndex].status === 'activated') {
        this.page = page.toString();
        this.currentPage = loadedPages[targetPageIndex];

        const userLoggedInfo = this.retrieveStringFromStorage(
          keyareas.auth.userLogged
        );
        if (userLoggedInfo) {
          let userData = JSON.parse(userLoggedInfo);
          await this.router.navigate([
            `${userData.profile}/challenges/${this.stageId}/${this.page}`,
          ]);
        } else {
          await this.router.navigate(['/']);
        }
      } else {
        console.log(`Page ${page} is not activated`);
      }
    } else {
      console.log(`Page ${page} does not exist or is out of bounds`);
    }
  }
}
