import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  input,
  model,
} from '@angular/core';
import { MenuBarState } from 'go-components';
import {
  defaultMenuBarState,
  defaultMenuItemsPage,
} from '../../core/default-menu-items.pages';
import { PageWithMenu } from '../../core/page-with-menu';
import { data } from './data';
import { StageItem } from './stage-item';
import { SelectAnItemToContinuePage } from '../core/select-an-item-to-continue-page';

@Component({
  selector: 'st-select-stage-page',
  templateUrl: './select-stage-page.component.html',
  styleUrl: './select-stage-page.component.scss',
})
export class SelectStagePageComponent
  implements AfterViewInit, PageWithMenu, SelectAnItemToContinuePage
{
  @Input() menuItens = defaultMenuItemsPage;
  menuBarState = model<MenuBarState>(defaultMenuBarState);

  @Input() tagUnit?: string;
  @Input() titleUnit?: string;
  isScrolling = false;

  @Output() startStage = new EventEmitter<StageItem>();
  @Output() selectedStage = new EventEmitter<StageItem>();

  @Input() data = data;

  onSelectedItem(indexItem: number): void {
    let stage;
    this.data.forEach((item, index) => {
      if ((item.open = index === indexItem)) {
        stage = item;
      }
    });

    if (!stage) {
      return;
    }

    this.scrollToSelectedItem(indexItem);
    this.selectedStage.emit(stage);
  }

  onContinue(): void {}

  onBack(): void {}

  ngAfterViewInit(): void {
    const initialIndex = this.data.findIndex((item) => item.open);
    if (initialIndex !== -1) {
      this.scrollToSelectedItem(initialIndex);
    }
  }

  //? Funções de teclas de navegação
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.scrollLeft();
    } else if (event.key === 'ArrowRight') {
      this.scrollRight();
    }
  }

  scrollLeft(): void {
    if (this.isScrolling) return;
    let currentIndex = this.data.findIndex((item) => item.open);
    if (currentIndex > 0) {
      this.isScrolling = true;
      this.onSelectedItem(currentIndex - 1);
      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    }
  }

  scrollRight(): void {
    if (this.isScrolling) return;
    let currentIndex = this.data.findIndex((item) => item.open);
    if (currentIndex < this.data.length - 1) {
      this.isScrolling = true;
      this.onSelectedItem(currentIndex + 1);
      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    }
  }

  private scrollToSelectedItem(selectedIndex: number): void {
    const carousel = document.querySelector('.carousel-itens') as HTMLElement;
    const selectedCard = carousel.children[selectedIndex] as HTMLElement;

    let position =
      selectedCard.clientWidth * selectedIndex - selectedCard.clientWidth;
    if (selectedIndex < 2) {
      position = 0;
    }

    carousel.scrollTo({
      left: position,
      behavior: 'smooth',
    });
  }
}
