export default {
  id: 'db7a7ee3-f691-4161-bada-7eaf2c5f2247',
  title: 'Sentence structure',
  countPages: 34,
  completedPages: 0,
  module: {
    id: '0eb1c258-f3f0-452e-a582-9e345d1e88fe',
    order: 2,
    title: 'Nível Interpreter',
  },
  order: 1,
  pages: [
    {
      id: 'd8abfba2-c32a-4d37-ae16-f59e2a583b55',
      type: 'content',
      code: 'WU_LM_N2_L1_P1',
      order: 1,
      widgets: [
        {
          id: '8295320a-6550-43f4-bcc8-a2a6cf38f2ec',
          order: 1,
          type: 'title',
          value: 'INTERPRETER LEVEL',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8f444569-0416-4492-872f-f9d15fb96fb3',
          order: 2,
          type: 'subtitle',
          value: 'Building blocks',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9b27a3cc-7f6d-4ae0-915f-f7bb304d5222',
          order: 3,
          type: 'longtext',
          value:
            'Nesta etapa, será introduzido conhecimentos básicos sobre a estrutura linguística da língua inglesa para compreender e produzir frases. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '94119fb9-b34a-4c26-b1a8-6cc916279064',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-db7a7ee3-f691-4161-bada-7eaf2c5f2247-013345aa-6e27-4b7c-ae2a-29d64b775324.lottie',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'e2fedfa1-edcd-4572-90ef-dafbff10c9df',
          order: 5,
          type: 'longtext',
          value:
            '🤩Você vai entender como as frases são formadas e o porquê, com explicações e exemplos. Esse conhecimentos lhe ajuará a desenvolver suas habilidades oral e escrita com confiança e fluência.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '2b25ec7a-2c9c-4822-bb55-33083ba39d2f',
          order: 6,
          type: 'longtext',
          value: 'Clique no botão START para começar.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'af6cd140-f69d-4669-bcd8-c257bd528cea',
      type: 'content',
      code: 'WU_LM_N2_L1_P2',
      order: 2,
      widgets: [
        {
          id: '4bc96eac-dc1d-410e-81cc-cb108f0efc19',
          order: 1,
          type: 'title',
          value: 'LESSON 1',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '723a6a3b-9f64-4b10-a1f4-9b43677a4410',
          order: 2,
          type: 'subtitle',
          value: 'Basic English',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1bebb75f-a5cc-4299-81f4-ab09b7424e49',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-db7a7ee3-f691-4161-bada-7eaf2c5f2247-7d770dc4-8231-4512-8758-479b74226906.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '764c0bcf-24f6-4230-8b2b-bab207493008',
          order: 4,
          type: 'subtitle',
          value: 'YES!',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'd8709e4c-edda-42ad-8437-06dca7c8e8f3',
          order: 5,
          type: 'longtext',
          value:
            'A estrutura básica de uma frase em inglês é como em português. Observe os exemplos a seguir e compare.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '1a559521-9d48-4312-a4e1-3dcd05064f87',
      type: 'content',
      code: 'WU_LM_N2_L1_P3',
      order: 3,
      widgets: [
        {
          id: '0f0fb32a-9813-40ad-af29-7a4bd90946b6',
          order: 1,
          type: 'subtitle',
          value: 'Simple sentences',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3e2bde20-5cac-4626-962c-130d0693decd',
          order: 2,
          type: 'longtext',
          value:
            'A estrutura básica de uma frase em inglês super importante para aprender logo no início da nossa jornada é a estrutura que normalmente contém:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '14498b30-4786-40e7-826b-340f7ab5864b',
          order: 3,
          type: 'blocklist',
          feedback: false,
          options: [
            {
              id: '0846d125-080d-45da-9df5-2100b0db617d',
              order: 1,
              text: 'Quem faz a ação: sujeito',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '9d5eaf00-3cd8-4338-b2f3-6882d49a74c1',
              order: 2,
              text: 'A ação ou estado: verbo',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'e38438fa-af6a-4fe7-a6f7-d4614a4b5e2f',
              order: 3,
              text: 'o que vem depois do verbo',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
        },
        {
          id: '6f4a6bac-9de8-4ec6-bea8-63005fb8f6ae',
          order: 4,
          type: 'longtext',
          value: '<b>Exemplos:</b>',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'a43418a2-fe1d-434e-b4c8-94069d8cc45a',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-4-db7a7ee3-f691-4161-bada-7eaf2c5f2247-4983053b-b23e-4d0a-abb1-7ced785c8e35.mpeg',
          feedback: false,
          options: [],
          template: 'default',
          label: 'I am a student.',
        },
        {
          id: '6434a7ff-96ca-463b-88bc-8a2d17f12595',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-5-db7a7ee3-f691-4161-bada-7eaf2c5f2247-1aa220bb-dcea-4433-868c-93ea11509eaf.mpeg',
          feedback: false,
          options: [],
          template: 'default',
          label: 'She plays with my friends.',
        },
        {
          id: '3f1cb91e-e05f-44c6-a46c-30e1865a7f13',
          order: 7,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-6-db7a7ee3-f691-4161-bada-7eaf2c5f2247-f798d70a-9b25-448b-a168-2574b02304ee.mpeg',
          feedback: false,
          options: [],
          template: 'default',
          label: 'They study every day. ',
        },
        {
          id: '332f4709-0122-43be-ac56-8fd62af982b9',
          order: 8,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-7-db7a7ee3-f691-4161-bada-7eaf2c5f2247-bdba46a3-4bc9-4028-9ef7-802ad91fd764.mpeg',
          feedback: false,
          options: [],
          template: 'default',
          label: 'My family is nice.',
        },
      ],
      hints: [],
    },
    {
      id: 'db11e610-4290-400c-9d26-296174bd9ea3',
      type: 'content',
      code: ' WU_LM_N2_L1_P4',
      order: 4,
      widgets: [
        {
          id: '294f9d79-24e9-45aa-8409-bbda6d8f6052',
          order: 1,
          type: 'subtitle',
          value: 'Affirmative sentences',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1dcc9f8b-6c0f-488c-8d42-777bae23b71e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-4845f63b-94a8-40fb-a4fa-6a645d96d380.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '272424b5-5cdc-4abe-a3fb-74cd270df67f',
          order: 3,
          type: 'longtext',
          value:
            'Você já aprendeu que as palavras cognatas, ou seja, palavras que são parecidas com o português, e as palavras emprestadas nos ajudam na compreensão de um texto.  Comparar a estrutura básica de uma frase em língua inglesa com a da língua portuguesa, também é muito útil. ',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '6d1f274a-2373-476e-b6ac-385cd08c53fd',
      type: 'content',
      code: 'WU_LM_N2_L1_P5',
      order: 5,
      widgets: [
        {
          id: 'deb9c344-95ac-4f8f-ae10-5404f15aaca1',
          order: 1,
          type: 'subtitle',
          value: 'Subjects: Who and What',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '323fc721-b744-4452-9f25-ffcb19b6fae2',
          order: 2,
          type: 'longtext',
          value:
            'Quem ou o que vem antes do verbo nas frases afirmativas é primeira informação que precisamos ficar atentos!',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '92905205-44b7-4ffe-b66a-29803063655e',
          order: 3,
          type: 'enunciated',
          value: 'Quem são os sujeitos nas frases abaixo?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'db2a5ac3-e635-4337-b963-34c6b79e34e6',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-db7a7ee3-f691-4161-bada-7eaf2c5f2247-4c26c2e5-cf5c-487e-97f6-562756022e8f.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'c0e029e5-b873-49c7-89c8-6b0a365cc432',
          order: 5,
          type: 'longtext',
          value:
            '<b>The cat</b> e <b>The cats</b> são sujeitos. Eles são seguidos pelos verbos <b>is</b> e <b>sleep</b>. Lembre-se que um verbo exprime uma ação ou um estado. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9307acf1-2b56-4207-b56a-0bf0ba2adec9',
          order: 6,
          type: 'longtext',
          value:
            'Logo após o verbo vem o complemento: <b>hungry</b> e <b>together</b>. O complemento nos dá mais informações sobre o sujeito ou verbo.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '86d43c6c-48cb-423e-a1c9-e6d29295b7b2',
      type: 'content',
      code: 'WU_LM_N2_L1_P6',
      order: 6,
      widgets: [
        {
          id: '50353040-4a54-419e-91e9-e90585938d7d',
          order: 1,
          type: 'enunciated',
          value: 'Identifique o sujeito na frase abaixo.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '046eb657-5b14-43f3-8732-aeed6d7d4d4c',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-266779dd-0b96-4e7e-bd94-f7a02957cea5.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'a18f2bfa-0bc0-47a3-b1a2-a8aabd20827d',
          order: 3,
          type: 'longtext',
          value: 'The dog eats hot dog.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0078db7f-7045-4cd9-8aee-8c128f65d97b',
          order: 4,
          type: 'select',
          feedback: true,
          options: [
            {
              id: '068bcf25-3eda-41ab-8073-82d3a0de64ef',
              order: 1,
              text: 'the dog',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: '4200c6ee-bd14-4d39-a77f-1e8541fc91d2',
              order: 2,
              text: 'eats',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: 'c767fea4-b447-4712-91f2-6cf186ae94eb',
              order: 3,
              text: 'hot dog',
              image: null,
              audio: null,
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: 'c9aee0d7-37aa-48a1-8ea9-bd6c1760bef5',
      type: 'content',
      code: 'WU_LM_N2_L1_P7',
      order: 7,
      widgets: [
        {
          id: 'f6756a66-b100-422b-8197-a68f77079aba',
          order: 1,
          type: 'enunciated',
          value: 'Identifique o verbo na frase abaixo.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'd871e66f-0fe6-4042-bfeb-f3a5e7fc697e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-16b3eb66-4d5c-42db-8327-e0e355a9f349.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '6ac429d5-5b18-4327-b2ac-b604eaf78670',
          order: 3,
          type: 'longtext',
          value: 'The cat likes hamburgers.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8796d2f3-9ad8-4064-9869-26d8614458d8',
          order: 4,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'ccce2a01-5e8a-4092-9ba1-de544f279635',
              order: 1,
              text: 'the cat',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: '41f8cf8a-c9da-446d-99f5-3453461a5fca',
              order: 2,
              text: 'likes',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: 'ac6bded1-e3f5-430c-85e9-f66569e53c22',
              order: 3,
              text: 'hamburgers',
              image: null,
              audio: null,
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: 'fbd3e107-9e7c-4079-8514-5360773c78ce',
      type: 'content',
      code: 'WU_LM_N2_L1_P8',
      order: 8,
      widgets: [
        {
          id: 'ef3ac6af-946d-46bc-a32d-3e5296ec2849',
          order: 1,
          type: 'enunciated',
          value: 'Identifique o complemento da frase.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3961e2f0-2d27-4810-b0a4-84d33a939dd4',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-5cfa8339-d3ec-49e1-92f9-fac91c46edb7.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '588ac920-4be3-4b95-8651-919f894f1b0b',
          order: 3,
          type: 'longtext',
          value: 'The pig loves noodles.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3272aa07-e673-415a-88b4-4c501ac16704',
          order: 4,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'de9b2983-499e-433a-944b-ce9ece1a4165',
              order: 1,
              text: 'the pig',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: 'dfd15fdf-8b96-44c2-af5f-fb52f6bdbbaa',
              order: 2,
              text: 'loves',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: 'f9432d92-9d42-4958-b29d-82a8474d7cf0',
              order: 3,
              text: 'noodles',
              image: null,
              audio: null,
              right: true,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: '26b79e84-f68d-4c1e-9a05-f697abb0cd74',
      type: 'content',
      code: 'WU_LM_N2_L1_P9',
      order: 9,
      widgets: [
        {
          id: '949900fb-a92b-448b-b631-9916d1730c13',
          order: 1,
          type: 'enunciated',
          value:
            'Selecione as opções que completam a frase de acordo com a imagem.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'f31792cd-4dbe-4b36-9234-0deb640a0a1b',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-e06e8456-8ad8-4458-bc88-9bd64a20ecb6.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '99fcec98-28a6-4ed7-b889-1482da48317f',
          order: 3,
          type: 'longtext',
          value: '________ likes noodles.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '21638559-f63b-47cf-b9c9-986608a30304',
          order: 4,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '26d9b88d-6f05-4681-97e9-dca3e20795c6',
              order: 1,
              text: 'It',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: '99905d00-7356-462d-9eee-bfdbe096755d',
              order: 2,
              text: 'The pig',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: 'a992add9-1a11-44fa-9605-72b2b8848db5',
              order: 3,
              text: 'noodles',
              image: null,
              audio: null,
              right: false,
            },
          ],
          template: 'default',
          rightMessage:
            'Parabéns! Você já sabe que it é um pronome e pode substituir a palavra PIG que é um animal.',
          wrongMessage:
            'It é um pronome usado para se referir a animais, coisas ou lugares. Como o PIG é um animal, podemos usar It na posição de sujeito.',
        },
      ],
      hints: [],
    },
    {
      id: 'f99b50ed-b32a-4257-bbcc-5701f1785cf2',
      type: 'content',
      code: ' WU_LM_N2_L1_P10',
      order: 10,
      widgets: [
        {
          id: '5df92996-b028-4cf2-aa2c-7961d317e30f',
          order: 1,
          type: 'subtitle',
          value: 'Personal pronouns',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '6cdad748-e81e-451c-8476-498ebee14a1e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-db7a7ee3-f691-4161-bada-7eaf2c5f2247-2911ce2e-7e9a-4eab-a4bb-75d06550fb7e.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3ba9b9d4-58cc-45de-ba42-9a2fd8a9b7f7',
          order: 3,
          type: 'longtext',
          value:
            'Os pronomes pessoais são <b>personal pronouns</b>. Também conhecidos como Subject pronouns. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '794e9171-ed2a-466d-9868-9cb072f2dba8',
          order: 4,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-3-db7a7ee3-f691-4161-bada-7eaf2c5f2247-0e1fbd3a-2228-4998-a403-c1e8794bded4.mpeg',
          feedback: false,
          options: [],
          template: 'default',
          label: 'Pratique a pronúncia.',
        },
        {
          id: '047bd502-d994-4c84-b6b9-993836a00d95',
          order: 5,
          type: 'longtext',
          value: 'I, you, he, she, it, we, they',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'ba2f3869-6f4f-4513-828d-2a22f4a1158b',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.1',
      order: 11,
      difficulty: 'easy',
      widgets: [
        {
          id: 'ba2f3869-6f4f-4513-828d-2a22f4a1158b',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: 'ba2f3869-6f4f-4513-828d-2a22f4a1158b',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-396f4ea3-c4b2-4b2c-88b0-f524fe9d784c.jpeg',
          template: 'default',
        },
        {
          id: 'ba2f3869-6f4f-4513-828d-2a22f4a1158b',
          order: 3,
          type: 'enunciated',
          value: 'O porco come macarrão oriental.',
          template: 'default',
        },
        {
          id: 'ba2f3869-6f4f-4513-828d-2a22f4a1158b',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '1dd31284-5129-454d-ad4b-21becd8997fa',
              order: 0,
              text: 'The pig eats noodles.',
              textRelated: 'the;pig;eats;noodles',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '608f15b9-3cb1-4027-bd08-687e6ea6ec64',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.2',
      order: 12,
      difficulty: 'easy',
      widgets: [
        {
          id: '608f15b9-3cb1-4027-bd08-687e6ea6ec64',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '608f15b9-3cb1-4027-bd08-687e6ea6ec64',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-5492c5bc-43fd-4fdc-bdc5-21f878cf9f4c.jpeg',
          template: 'default',
        },
        {
          id: '608f15b9-3cb1-4027-bd08-687e6ea6ec64',
          order: 3,
          type: 'enunciated',
          value: 'O gato ama hamburgue.',
          template: 'default',
        },
        {
          id: '608f15b9-3cb1-4027-bd08-687e6ea6ec64',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'e79113c8-c04e-453b-9325-c3cba1a2d36d',
              order: 0,
              text: 'The cat loves hamburger.',
              textRelated: 'the;cat;loves;hamburger',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '07ce5b09-a26a-4379-9474-7301b50818c0',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.3',
      order: 13,
      difficulty: 'easy',
      widgets: [
        {
          id: '07ce5b09-a26a-4379-9474-7301b50818c0',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '07ce5b09-a26a-4379-9474-7301b50818c0',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-04cb9efa-a7c2-4777-a7c8-915cf35d168b.jpeg',
          template: 'default',
        },
        {
          id: '07ce5b09-a26a-4379-9474-7301b50818c0',
          order: 3,
          type: 'enunciated',
          value: 'O cachorro come cachorro quente. ',
          template: 'default',
        },
        {
          id: '07ce5b09-a26a-4379-9474-7301b50818c0',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'f0c9bed0-e265-430e-a222-8c524a908c6a',
              order: 0,
              text: 'The dog eats hot dog.',
              textRelated: 'the;dog;eats;hot;dog',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '9c8104e9-9496-450a-961e-c9068108d016',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.4',
      order: 14,
      difficulty: 'easy',
      widgets: [
        {
          id: '9c8104e9-9496-450a-961e-c9068108d016',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '9c8104e9-9496-450a-961e-c9068108d016',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-3fda068a-a1f0-4931-8bcc-8b1f02e20236.jpeg',
          template: 'default',
        },
        {
          id: '9c8104e9-9496-450a-961e-c9068108d016',
          order: 3,
          type: 'enunciated',
          value: 'O gato come pizza.',
          template: 'default',
        },
        {
          id: '9c8104e9-9496-450a-961e-c9068108d016',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'b44e1905-cf3a-4cdf-bb2d-6a04aa113e39',
              order: 0,
              text: 'The cat eats pizza.',
              textRelated: 'the;cat;eats;pizza',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '3ca3c740-fa94-44e2-bd64-ddd57b3b7461',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.5',
      order: 15,
      difficulty: 'easy',
      widgets: [
        {
          id: '3ca3c740-fa94-44e2-bd64-ddd57b3b7461',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '3ca3c740-fa94-44e2-bd64-ddd57b3b7461',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-ac8ca11e-23c1-4699-80e1-5c096bc5b777.jpeg',
          template: 'default',
        },
        {
          id: '3ca3c740-fa94-44e2-bd64-ddd57b3b7461',
          order: 3,
          type: 'enunciated',
          value: 'Come uma linguiça.',
          template: 'default',
        },
        {
          id: '3ca3c740-fa94-44e2-bd64-ddd57b3b7461',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '3f74df29-7f51-4550-89ff-c95239428dcc',
              order: 0,
              text: 'It eats a sausage.',
              textRelated: 'it;eats;a;sausage',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '8120d84d-887f-4244-8951-ad3f8a4b4d55',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.6',
      order: 16,
      difficulty: 'easy',
      widgets: [
        {
          id: '8120d84d-887f-4244-8951-ad3f8a4b4d55',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '8120d84d-887f-4244-8951-ad3f8a4b4d55',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-e5b849a9-9eb6-4a4b-8c79-6c239157abe7.jpeg',
          template: 'default',
        },
        {
          id: '8120d84d-887f-4244-8951-ad3f8a4b4d55',
          order: 3,
          type: 'enunciated',
          value: 'Gosta de batata frita.',
          template: 'default',
        },
        {
          id: '8120d84d-887f-4244-8951-ad3f8a4b4d55',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '18ba28d6-bc46-4ca5-bd84-1103cd2f5ffd',
              order: 0,
              text: 'It likes French fries.',
              textRelated: 'it;likes;French;fries',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'beb55036-d7f1-4a0a-b6e3-49b16b6268c4',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.7',
      order: 17,
      difficulty: 'easy',
      widgets: [
        {
          id: 'beb55036-d7f1-4a0a-b6e3-49b16b6268c4',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: 'beb55036-d7f1-4a0a-b6e3-49b16b6268c4',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-00a33227-3230-4e35-9270-88b5b383036b.jpeg',
          template: 'default',
        },
        {
          id: 'beb55036-d7f1-4a0a-b6e3-49b16b6268c4',
          order: 3,
          type: 'enunciated',
          value: 'Gosta de Sushi.',
          template: 'default',
        },
        {
          id: 'beb55036-d7f1-4a0a-b6e3-49b16b6268c4',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '15e3ad96-dd34-4ce0-889c-dd0db2a45c04',
              order: 0,
              text: 'It likes Sushi.',
              textRelated: 'it;likes;Sushi',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '4ad8cb50-2913-4468-b535-3545e5008513',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.8',
      order: 18,
      difficulty: 'easy',
      widgets: [
        {
          id: '4ad8cb50-2913-4468-b535-3545e5008513',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '4ad8cb50-2913-4468-b535-3545e5008513',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-29cc5643-5b3a-4700-8594-b2a55ea3c296.jpeg',
          template: 'default',
        },
        {
          id: '4ad8cb50-2913-4468-b535-3545e5008513',
          order: 3,
          type: 'enunciated',
          value: 'Ama rosquinhas com cobertura.',
          template: 'default',
        },
        {
          id: '4ad8cb50-2913-4468-b535-3545e5008513',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '0d1aa874-d89b-4958-bdc1-1ba9b237ac54',
              order: 0,
              text: 'It loves donuts.',
              textRelated: 'it;loves;donuts',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '785f3155-3006-4305-b2ec-a01d88caf918',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.9',
      order: 19,
      difficulty: 'easy',
      widgets: [
        {
          id: '785f3155-3006-4305-b2ec-a01d88caf918',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '785f3155-3006-4305-b2ec-a01d88caf918',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-ea404c6d-9c5e-4dee-8753-fd1f893d4a6d.jpeg',
          template: 'default',
        },
        {
          id: '785f3155-3006-4305-b2ec-a01d88caf918',
          order: 3,
          type: 'enunciated',
          value: 'Ela gosta de espaguete.',
          template: 'default',
        },
        {
          id: '785f3155-3006-4305-b2ec-a01d88caf918',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'dd054ad7-c04d-4f85-8677-801e642e0879',
              order: 0,
              text: 'She likes spaghetti.',
              textRelated: 'she;likes;spaghetti',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '06d024a1-d8b0-46b8-b39b-1b1534a79c8e',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.10',
      order: 20,
      difficulty: 'easy',
      widgets: [
        {
          id: '06d024a1-d8b0-46b8-b39b-1b1534a79c8e',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '06d024a1-d8b0-46b8-b39b-1b1534a79c8e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-65884a62-ffc4-4740-a8f1-7d8dd9226ea4.jpeg',
          template: 'default',
        },
        {
          id: '06d024a1-d8b0-46b8-b39b-1b1534a79c8e',
          order: 3,
          type: 'enunciated',
          value: 'Ele gosta de fast food.',
          template: 'default',
        },
        {
          id: '06d024a1-d8b0-46b8-b39b-1b1534a79c8e',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '01a65ed0-e632-4e63-aee4-1ff24d37cc72',
              order: 0,
              text: 'He likes fast food.',
              textRelated: 'he;likes;fast;food',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '8206fe14-9487-4b1e-aeb0-5962acb0646f',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.11',
      order: 21,
      difficulty: 'easy',
      widgets: [
        {
          id: '8206fe14-9487-4b1e-aeb0-5962acb0646f',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '8206fe14-9487-4b1e-aeb0-5962acb0646f',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-270c7369-a6b1-4b08-87a6-d14ad4fb02c5.jpeg',
          template: 'default',
        },
        {
          id: '8206fe14-9487-4b1e-aeb0-5962acb0646f',
          order: 3,
          type: 'enunciated',
          value: 'Ela come salada.',
          template: 'default',
        },
        {
          id: '8206fe14-9487-4b1e-aeb0-5962acb0646f',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '3c27b026-314d-475f-9bd8-1ad0284a8997',
              order: 0,
              text: 'She eats salad.',
              textRelated: 'she;eats;salad',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '27bc247c-6e64-4661-88d6-8e27a0197fee',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.12',
      order: 22,
      difficulty: 'easy',
      widgets: [
        {
          id: '27bc247c-6e64-4661-88d6-8e27a0197fee',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '27bc247c-6e64-4661-88d6-8e27a0197fee',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-f9e634f0-7988-46ff-b261-f641c4d193d9.jpeg',
          template: 'default',
        },
        {
          id: '27bc247c-6e64-4661-88d6-8e27a0197fee',
          order: 3,
          type: 'enunciated',
          value: 'Eles gostam de pizza.',
          template: 'default',
        },
        {
          id: '27bc247c-6e64-4661-88d6-8e27a0197fee',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '1e88d32b-9d4d-4987-bbbe-9273a505208b',
              order: 0,
              text: 'They like pizza.',
              textRelated: 'they;like;pizza',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '19978b08-276d-4196-8df1-51d26f6d9ed0',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.13',
      order: 23,
      difficulty: 'easy',
      widgets: [
        {
          id: '19978b08-276d-4196-8df1-51d26f6d9ed0',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '19978b08-276d-4196-8df1-51d26f6d9ed0',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-b8460ea4-f5ae-4e85-9a34-83b587898570.jpeg',
          template: 'default',
        },
        {
          id: '19978b08-276d-4196-8df1-51d26f6d9ed0',
          order: 3,
          type: 'enunciated',
          value: 'Nós gostamos de pizza.',
          template: 'default',
        },
        {
          id: '19978b08-276d-4196-8df1-51d26f6d9ed0',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '069fe250-6026-4670-b1e2-995f14eb2ad2',
              order: 0,
              text: 'We like pizza.',
              textRelated: 'we;like;pizza',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '13e28cc9-d3eb-431a-953c-3d5c18c0bde1',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.14',
      order: 24,
      difficulty: 'easy',
      widgets: [
        {
          id: '13e28cc9-d3eb-431a-953c-3d5c18c0bde1',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '13e28cc9-d3eb-431a-953c-3d5c18c0bde1',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-805ea35c-454f-49f4-9b28-9ccdfc13a158.jpeg',
          template: 'default',
        },
        {
          id: '13e28cc9-d3eb-431a-953c-3d5c18c0bde1',
          order: 3,
          type: 'enunciated',
          value: 'Ela detesta salada.',
          template: 'default',
        },
        {
          id: '13e28cc9-d3eb-431a-953c-3d5c18c0bde1',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '725b4f34-ea95-4deb-bbe2-2c7e378f85b1',
              order: 0,
              text: 'She hates salad.',
              textRelated: 'she;hates;salad',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '2412ff14-eac8-4981-8e71-57d32e15077c',
      type: 'activity',
      code: 'WU_LM_N2_L1_P10.15',
      order: 25,
      difficulty: 'easy',
      widgets: [
        {
          id: '2412ff14-eac8-4981-8e71-57d32e15077c',
          order: 1,
          type: 'title',
          value: 'Coloque as palavras na ordem correta.',
          template: 'default',
        },
        {
          id: '2412ff14-eac8-4981-8e71-57d32e15077c',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-db7a7ee3-f691-4161-bada-7eaf2c5f2247-4b2ea046-dac3-4675-bf1a-c3076fc46750.jpeg',
          template: 'default',
        },
        {
          id: '2412ff14-eac8-4981-8e71-57d32e15077c',
          order: 3,
          type: 'enunciated',
          value: 'Ele detesta brocoli. ',
          template: 'default',
        },
        {
          id: '2412ff14-eac8-4981-8e71-57d32e15077c',
          order: 4,
          type: 'blocks',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'b7463ca0-299d-4f20-8fc9-f16e42231976',
              order: 0,
              text: 'He hates broccoli. ',
              textRelated: 'he;hates;broccoli',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '4cb9f048-f7fb-4fa9-ad74-3c7bff0d96b0',
      type: 'activity',
      code: '.1',
      order: 26,
      difficulty: 'easy',
      widgets: [
        {
          id: '4cb9f048-f7fb-4fa9-ad74-3c7bff0d96b0',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: '4cb9f048-f7fb-4fa9-ad74-3c7bff0d96b0',
          order: 2,
          type: 'enunciated',
          value: 'I love spaghetti.',
          template: 'default',
        },
        {
          id: '4cb9f048-f7fb-4fa9-ad74-3c7bff0d96b0',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'f2dd5347-095d-46c9-ba4b-3936340c3c48',
              order: 0,
              text: 'I love spaghetti.',
              textRelated: 'I love spaghetti.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-502a334b-f842-4e47-8a34-40add4a86425.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '71f58fb1-b099-4d31-97fc-dda8ce242788',
      type: 'activity',
      code: '.2',
      order: 27,
      difficulty: 'easy',
      widgets: [
        {
          id: '71f58fb1-b099-4d31-97fc-dda8ce242788',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: '71f58fb1-b099-4d31-97fc-dda8ce242788',
          order: 2,
          type: 'enunciated',
          value: 'He hates salad.',
          template: 'default',
        },
        {
          id: '71f58fb1-b099-4d31-97fc-dda8ce242788',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'c71f2b49-8717-4080-b44e-5fd419306078',
              order: 0,
              text: 'He hates salad.',
              textRelated: 'He hates salad.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-9e773013-b807-4825-8057-b6d3a5e16add.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'cf571dd2-2a36-4224-ab5c-805b1681a12c',
      type: 'activity',
      code: '.3',
      order: 28,
      difficulty: 'easy',
      widgets: [
        {
          id: 'cf571dd2-2a36-4224-ab5c-805b1681a12c',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: 'cf571dd2-2a36-4224-ab5c-805b1681a12c',
          order: 2,
          type: 'enunciated',
          value: 'She loves fast food.',
          template: 'default',
        },
        {
          id: 'cf571dd2-2a36-4224-ab5c-805b1681a12c',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'e7511402-ff95-4092-9532-dccd0f047a56',
              order: 0,
              text: 'She loves fast food.',
              textRelated: 'She loves fast food.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-b27be462-2089-4514-a580-f3ff74af5c18.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '567e5a9e-3082-41cb-a3a7-f5f3748a63f5',
      type: 'activity',
      code: '.4',
      order: 29,
      difficulty: 'easy',
      widgets: [
        {
          id: '567e5a9e-3082-41cb-a3a7-f5f3748a63f5',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: '567e5a9e-3082-41cb-a3a7-f5f3748a63f5',
          order: 2,
          type: 'enunciated',
          value: 'The cat eats pizza.',
          template: 'default',
        },
        {
          id: '567e5a9e-3082-41cb-a3a7-f5f3748a63f5',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '8e7afeca-5511-4fd4-b002-4adba94815e7',
              order: 0,
              text: 'The cat eats pizza.',
              textRelated: 'The cat eats pizza.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-054cb0b0-9fbe-4d9d-ab8e-208b428d5985.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '1c4f2627-be22-4140-81a0-e27c8ded9246',
      type: 'activity',
      code: '.5',
      order: 30,
      difficulty: 'easy',
      widgets: [
        {
          id: '1c4f2627-be22-4140-81a0-e27c8ded9246',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: '1c4f2627-be22-4140-81a0-e27c8ded9246',
          order: 2,
          type: 'enunciated',
          value: 'The dog hates cats.',
          template: 'default',
        },
        {
          id: '1c4f2627-be22-4140-81a0-e27c8ded9246',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '77370a79-c76a-4c46-ae57-7017eaac14eb',
              order: 0,
              text: 'The dog hates cats.',
              textRelated: 'The dog hates cats.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-72a12582-a427-461a-a681-af388282876a.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'bd2c40f4-075f-44b4-8c46-92c81c53d630',
      type: 'activity',
      code: '.6',
      order: 31,
      difficulty: 'easy',
      widgets: [
        {
          id: 'bd2c40f4-075f-44b4-8c46-92c81c53d630',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: 'bd2c40f4-075f-44b4-8c46-92c81c53d630',
          order: 2,
          type: 'enunciated',
          value: 'The pig loves sushi.',
          template: 'default',
        },
        {
          id: 'bd2c40f4-075f-44b4-8c46-92c81c53d630',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '3c0f8c93-db96-4089-bc85-6fc034ca4d7e',
              order: 0,
              text: 'The pig loves sushi.',
              textRelated: 'The pig loves sushi.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-f687f39e-b6ae-43ff-8901-21da6252c37c.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'fe58e9df-3711-44ea-8550-1906c020409d',
      type: 'activity',
      code: '.7',
      order: 32,
      difficulty: 'easy',
      widgets: [
        {
          id: 'fe58e9df-3711-44ea-8550-1906c020409d',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: 'fe58e9df-3711-44ea-8550-1906c020409d',
          order: 2,
          type: 'enunciated',
          value: 'We eat hot dogs.',
          template: 'default',
        },
        {
          id: 'fe58e9df-3711-44ea-8550-1906c020409d',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '4147ebf8-df78-4e95-9faf-57fb4f872a1b',
              order: 0,
              text: 'We eat hot dogs.',
              textRelated: 'We eat hot dogs.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-54a0d654-366d-4add-9d84-23249af73587.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'dd22c8a7-5f44-4528-90e0-1f91fbc1b816',
      type: 'activity',
      code: '.8',
      order: 33,
      difficulty: 'easy',
      widgets: [
        {
          id: 'dd22c8a7-5f44-4528-90e0-1f91fbc1b816',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: 'dd22c8a7-5f44-4528-90e0-1f91fbc1b816',
          order: 2,
          type: 'enunciated',
          value: 'You like hamburgers.',
          template: 'default',
        },
        {
          id: 'dd22c8a7-5f44-4528-90e0-1f91fbc1b816',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '9e2bd1dd-3328-4f83-872d-ccd15e052d64',
              order: 0,
              text: 'You like hamburgers.',
              textRelated: 'You like hamburgers.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-35a6cb68-d282-48f4-8287-de6a39654c3d.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '7ffb815c-310b-43ee-9be9-136e3e829b14',
      type: 'activity',
      code: '.9',
      order: 34,
      difficulty: 'easy',
      widgets: [
        {
          id: '7ffb815c-310b-43ee-9be9-136e3e829b14',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          template: 'default',
        },
        {
          id: '7ffb815c-310b-43ee-9be9-136e3e829b14',
          order: 2,
          type: 'enunciated',
          value: 'They like French Fries.',
          template: 'default',
        },
        {
          id: '7ffb815c-310b-43ee-9be9-136e3e829b14',
          order: 3,
          type: 'speech',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '665959bd-9366-456f-88d4-0be51063df33',
              order: 0,
              text: 'They like French Fries.',
              textRelated: 'They like French Fries.',
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-db7a7ee3-f691-4161-bada-7eaf2c5f2247-07862d12-5e8f-4700-b376-979b58512039.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
  ],
};
