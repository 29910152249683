import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TextProcessingService } from '../text-processing.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractChallengeComponent } from '../abstract.challenge.component';

@Component({
  selector: 'widget-audio',
  templateUrl: './audio.component.html',
  styleUrl: './audio.component.scss',
})
export class AudioComponent
  implements AbstractChallengeComponent, OnInit, OnChanges, AfterViewInit
{
  readonly goComponentId: string = 'audio-challenge';

  @Input() template?: string = 'default';
  @Input() value!: string;
  @Input() label?: string;
  @Input() textAlign?: 'left' | 'right' | 'center' = 'center';
  @Input() marginBottom?: string = '16px';

  @Input() pageStatus?: string;
  @Input() pageType?: string;

  //? Audio atributtes
  @ViewChild('audioPlayer') audioPlayer!: ElementRef;
  isPlaying: boolean = false;
  playbackRate: number = 1.0;
  currentTime: number = 0;
  duration: number = 0;

  processedText: SafeHtml = '';

  constructor(
    private el: ElementRef,
    private textProcessingService: TextProcessingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.applyTextProcessing();
    this.el.nativeElement.style.width = '100%';

    if (this.textAlign) {
      this.el.nativeElement.style.textAlign = this.textAlign;
    }
  }

  ngAfterViewInit(): void {
    this.setDurationAudio();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label']) {
      this.applyTextProcessing();
    }

    if (changes['value']) {
      this.updateAudioSource();
    }
  }

  updateAudioSource() {
    if (this.audioPlayer && this.audioPlayer.nativeElement) {
      this.audioPlayer.nativeElement.pause();
      this.audioPlayer.nativeElement.load(); // Recarrega o áudio com a nova fonte
      this.currentTime = 0;
      this.isPlaying = false;
      this.setDurationAudio(); // Atualiza a duração do novo áudio
    }
  }

  playAudio() {
    this.setPlaybackRate(1.0);

    this.audioPlayer.nativeElement.play();
    this.isPlaying = true;
  }

  playAudioSlow() {
    this.setPlaybackRate(0.5);

    this.audioPlayer.nativeElement.play();
    this.isPlaying = true;
  }

  pauseAudio() {
    this.audioPlayer.nativeElement.pause();
    this.isPlaying = false;
  }

  setPlaybackRate(rate: number) {
    this.playbackRate = rate;
    this.audioPlayer.nativeElement.playbackRate = rate;
  }

  onTimeUpdate() {
    this.currentTime = this.audioPlayer.nativeElement.currentTime;
  }

  seekAudio(event: any) {
    this.audioPlayer.nativeElement.currentTime = event.target.value;
  }

  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const secs: number = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  }

  private setDurationAudio() {
    this.audioPlayer.nativeElement.onloadedmetadata = () => {
      this.duration = this.audioPlayer.nativeElement.duration;
      if (this.duration > 0 && this.duration < 1) {
        this.duration = 1; // Define a duração mínima como 1 segundo
      }

      if (this.template !== 'default') {
        this.template = this.duration > 6 ? 'audioquestion' : 'onlybuttons';
      }
    };

    // Forçar o carregamento dos metadados
    if (this.audioPlayer.nativeElement.readyState >= 1) {
      this.audioPlayer.nativeElement.load();
    }
  }

  private applyTextProcessing() {
    if (this.label) {
      this.textProcessingService
        .processText(
          this.label,
          'text-poppins-body-bold go-text-color-gray900',
          this.callGlossary.bind(this)
        )
        .then((result) => {
          this.processedText = this.sanitizer.bypassSecurityTrustHtml(result);
        });
    }
  }

  callGlossary(term: string) {
    alert(`Glossary term clicked: ${term}`);
  }
}
