interface TitleAndCover {
  title: string;
  cover: string;
}

const coversByStageTitle: TitleAndCover[] = [
  {
    title: 'timetoconnect',
    cover: 'assets/images/time-to-connect.svg',
  },
  {
    title: 'didyouknow',
    cover: 'assets/images/did-you-know.svg',
  },
  {
    title: 'quiztime',
    cover: 'assets/images/quiz-time.svg',
  },
  {
    title: 'learningsteps',
    cover: 'assets/images/learning-steps.svg',
  },
  {
    title: 'takingitfurther',
    cover: 'assets/images/taking-it-further.svg',
  },
  {
    title: 'closing',
    cover: 'assets/images/closing.svg',
  },
  {
    title: 'review',
    cover: 'green',
  },
  {
    title: 'checkpoint',
    cover: 'brown',
  },
  {
    title: 'finalassesment',
    cover: 'blue',
  },
];

export class StageItemCoverMapper {
  toCardCover(title: string, cover?: string): string {
    if (cover != null && cover != '') {
      return cover;
    } else {
      const find = coversByStageTitle.find(
        (c) => c.title == this.removeSpecialCharsAndSpaces(title)
      );

      return find ? find.cover : '';
    }
  }

  private removeSpecialCharsAndSpaces(text: string): string {
    if (!text || text.length === 0) {
      return '';
    } else {
      return text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    }
  }
}
