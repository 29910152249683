import { Component, Input } from '@angular/core';
import { GeneralModalComponent } from '../modais.module';

@Component({
  selector: 'go-wrong-modal',
  templateUrl: './wrong-modal.component.html',
  styleUrl: './wrong-modal.component.scss',
})
export class WrongModalComponent extends GeneralModalComponent {
  override goComponentId: string = 'wrong-modal';

  @Input() title: string = 'KEEP GOING!';
  @Input() description?: string;
}
