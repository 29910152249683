import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoginPageModule } from 'student-lib';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [RouterOutlet, LoginPageModule]
})
export class AppComponent {

  title = 'student-app';

}
