import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractRadioComponent } from '../abstract.radio.component';

@Component({
  selector: 'go-general-radio',
  templateUrl: './general-radio.component.html',
  styleUrl: './general-radio.component.scss',
})
export class GeneralRadioComponent
  implements AbstractRadioComponent, AfterViewInit, OnInit, OnChanges
{
  readonly goComponentId: string = 'general-radio';

  @ViewChild('input') input: any;

  @Input() tag?: string;
  @Input() name?: string;
  @Input() title?: string = 'Título do card';
  @Input() percent?: number;
  @Input() number?: number = 0;
  @Input() isSelected: boolean = false;
  @Input() status: 'activated' | 'completed' | 'disabled' = 'activated';
  @Output() cardSelected = new EventEmitter<void>();

  radioNumber?: string;

  boxClasses!: string;
  radioClasses!: string;
  textClasses!: string;
  tagClasses!: string;
  numberClasses!: string;
  percentClasses!: string;

  ngOnInit(): void {
    this.applyComponentStyleClasses();

    if (this.number != null && this.number > 0) {
      this.radioNumber =
        this.number < 10 ? `0${this.number}` : `${this.number}`;
    }
  }

  ngAfterViewInit(): void {
    var el: ElementRef<HTMLInputElement> = this.input;
    this.updateSelection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSelected']) {
      this.updateSelection();
    }
  }

  //? Função de definição de classes do component
  private updateSelection(): void {
    if (this.status == 'disabled') {
      this.boxClasses = 'go-background-color-gray100 go-border-color-gray300';
      this.textClasses = 'text-poppins-body-semibold go-text-color-gray800';
      this.numberClasses =
        'number text-poppins-headline-bold go-text-color-gray800';
      this.percentClasses =
        'percent text-poppins-caption-bold go-text-color-gray700';
    } else if (this.status == 'completed') {
      this.boxClasses =
        'active go-background-color-turquoise50 go-border-color-turquoise500';
      this.textClasses = 'text-poppins-body-bold go-text-color-turquoise700';
      this.numberClasses =
        'number text-poppins-headline-bold go-text-color-turquoise700';
      this.tagClasses = 'text-poppins-body-bold go-text-color-turquoise800';
      this.percentClasses =
        'percent text-poppins-subtitle-semibold go-text-color-turquoise700';

      if (this.isSelected) {
        this.radioClasses =
          ' active go-background-color-turquoise50 go-border-color-turquoise700';
      } else {
        this.radioClasses =
          ' concluded go-background-color-turquoise500 go-border-color-turquoise500';
      }
    } else {
      if (this.isSelected) {
        this.boxClasses =
          'active go-background-color-purple50 go-border-color-purple500';
        this.radioClasses =
          ' active go-background-color-gray100 go-border-color-purple700';
        this.textClasses = 'text-poppins-body-bold go-text-color-purple700';
        this.numberClasses =
          'number text-poppins-headline-bold go-text-color-gray800';
        this.tagClasses = 'text-poppins-body-bold go-text-color-salmon500';
        this.percentClasses =
          'percent text-poppins-caption-bold go-text-color-purple700';
      } else {
        this.boxClasses =
          'go-background-color-whiteColor go-border-color-gray300';
        this.radioClasses =
          'go-background-color-gray100 go-border-color-gray400';
        this.textClasses = 'text-poppins-body-semibold go-text-color-gray900';
        this.numberClasses =
          'number text-poppins-headline-bold go-text-color-gray800';
        this.tagClasses = 'text-poppins-body-bold go-text-color-salmon500';
        this.percentClasses =
          'percent text-poppins-caption-bold go-text-color-gray700';
      }
    }
  }

  private applyComponentStyleClasses(): void {
    this.updateSelection();
  }
}
