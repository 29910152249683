<section class="widget-combination">
  <div class="widget-combination-selecteds">
    <go-combination-block
      *ngFor="let selectedItem of userSelections; let ui = index"
      type="double"
      [item]="selectedItem.left"
      [secondItem]="selectedItem.right"
      (itemClick)="removeCombination($event.item, 'left')"
    />
  </div>
  <div class="widget-combination-options">
    <div class="left-column">
      <go-combination-block
        *ngFor="let leftItem of leftOptions; let li = index"
        side="left"
        [item]="leftItem"
        [leftSelect]="leftSelect"
        (itemClick)="handleCombination($event.item, 'left')"
      ></go-combination-block>
    </div>
    <div class="right-column">
      <go-combination-block
        *ngFor="let rightItem of rightOptions; let li = index"
        side="right"
        [item]="rightItem"
        [rightSelect]="rightSelect"
        (itemClick)="handleCombination($event.item, 'right')"
      ></go-combination-block>
    </div>
  </div>
</section>
