import { Component } from '@angular/core';
import { AbstractChallengeComponent } from '../abstract.challenge.component';

@Component({
  selector: 'widget-video',
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
})
export class VideoComponent implements AbstractChallengeComponent {

  readonly goComponentId: string = 'video-challenge';
  
}
