import { Component } from '@angular/core';
import { GeneralRadioComponent } from '../general-radio/general-radio.component';

@Component({
  selector: 'go-simple-radio',
  templateUrl: './simple-radio.component.html',
  styleUrl: './simple-radio.component.scss',
})
export class SimpleRadioComponent extends GeneralRadioComponent {

  override readonly goComponentId: string = 'simple-radio';

}
