import { LoggedUser, UserProfile } from "student-lib";

export class AuthApiMapper {

  toAccessToken(data: any): string | Error {
    const accesstoken: string = data?.access_token;
    if (!accesstoken) {
      return new Error('Token de acesso ausente');
    }

    return accesstoken;
  }

  toLoggedUser(data: any): LoggedUser | Error {
    const accesstoken = this.toAccessToken(data);
    if (accesstoken instanceof Error) {
      return accesstoken;
    }

    const user: any = data?.user;
    if (!user) {
      return new Error('Dado de usuário ausente');
    }

    const userId: string = user.id;
    if (!userId) {
      return new Error('Id de usuário ausente');
    }

    const userEmail: string = user.email;
    if (!userEmail) {
      return new Error('E-mail de usuário ausente');
    }

    const userName: string = user.name;
    if (!userName) {
      return new Error('Nome de usuário ausente');
    }

    const userRole: string = user.role;
    if (!userRole) {
      return new Error('Role de usuário ausente');
    }

    let profile: UserProfile;
    if (userRole == 'STUDENT') {
      profile = 'student';
    } else if (userRole == 'TEACHER') {
      profile = 'teacher';
    } else {
      return new Error('Role de usuário ainda não suportado: ' + userRole);
    }

    const loggedUser: LoggedUser = {
      user: {
        id: userId,
        name: userName,
        email: userEmail
      },
      profile: profile,
      accessToken: accesstoken
    };
    
    return loggedUser;
  }

}