import { Component, OnInit } from '@angular/core';
import { GeneralProgressComponent } from '../general-progress/general-progress.component';
import { ThemeColors } from '../../core/models/enums/colors.enum';

@Component({
  selector: 'go-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrl: './circle-progress.component.scss',
})
export class CircleProgressComponent extends GeneralProgressComponent implements OnInit{
  override readonly goComponentId: string = 'circle-progress';

  innerColor!: string;
  barColor!: string;

  ngOnInit() {
    this.innerColor = ThemeColors['purple200'];
    this.barColor = ThemeColors[this.color];
  }
}
