import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TextProcessingService } from '../text-processing.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AbstractChallengeComponent } from '../abstract.challenge.component';

@Component({
  selector: 'widget-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrl: './subtitle.component.scss',
})
export class SubtitleComponent implements AbstractChallengeComponent, OnInit, OnChanges {

  readonly goComponentId: string = 'subtitle-challenge';

  @Input() template?: string = 'default';
  @Input() value!: string;
  @Input() textAlign?: 'left' | 'right' | 'center' = 'center';
  @Input() marginBottom?: string = '16px';

  @Input() pageStatus?: string;
  @Input() pageType?: string;

  processedText: SafeHtml = '';

  constructor(
    private el: ElementRef,
    private textProcessingService: TextProcessingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.applyTextProcessing();

    if (this.textAlign) {
      this.el.nativeElement.style.textAlign = this.textAlign;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.applyTextProcessing();
    }
  }

  private applyTextProcessing() {
    this.textProcessingService
      .processText(
        this.value,
        'text-baloochettan-headline go-text-color-brown500',
        this.callGlossary.bind(this)
      )
      .then((result) => {
        this.processedText = this.sanitizer.bypassSecurityTrustHtml(result);
      });
  }

  callGlossary(term: string) {
    alert(`Glossary term clicked: ${term}`);
  }
}
