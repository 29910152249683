import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  output,
} from '@angular/core';
import { GeneralModalComponent } from '../modais.module';

@Component({
  selector: 'go-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrl: './report-modal.component.scss',
})
export class ReportModalComponent extends GeneralModalComponent {
  override goComponentId: string = 'report-modal';

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() options?: options[];
  @Output() change = new EventEmitter<any>();

  @ViewChild('input') inputElement!: ElementRef;

  selectedItens: options[] = [];
  isFocused: boolean = false;
  textBoxClasses: string = 'go-border-color-gray300';
  maxChars: number = 250;
  charCount: number = 0;

  toggleItem(item: options): void {
    const index = this.selectedItens.indexOf(item);
    if (index > -1) {
      // Item is already selected, remove it
      this.selectedItens.splice(index, 1);
    } else {
      // Item is not selected, add it
      this.selectedItens.push(item);
    }
  }

  isItemSelected(item: options) {
    let selected = this.selectedItens.indexOf(item) > -1;

    if (selected) {
      return 'check-radio go-background-color-purple500 go-border-color-purple700';
    } else {
      return 'check-radio go-background-color-gray100 go-border-color-gray300';
    }
  }

  checkSelected(item: options): boolean {
    return this.selectedItens.indexOf(item) > -1;
  }

  onFocus(): void {
    this.isFocused = true;
    if (this.isFocused) {
      this.textBoxClasses = 'go-border-color-purple500';
    }
  }

  onBlur(): void {
    this.isFocused = false;
    if (!this.isFocused) {
      this.textBoxClasses = 'go-border-color-gray300';
    }
  }

  onInput(event: any): void {
    const value = this.inputElement.nativeElement.value;
    this.charCount = value.length;
    if (this.charCount > this.maxChars) {
      this.inputElement.nativeElement.value = value.slice(0, this.maxChars);
      this.charCount = this.maxChars;
    }
  }
}

interface options {
  id: string;
  text: string;
}
