import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { AuthApiService } from "./auth.api.service";
import { inject } from "@angular/core";

export function authApiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {

    const authToken = inject(AuthApiService).getLoggedUser()?.accessToken;
    if (!authToken) {
        return next(req);
    }

    const clonedReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    return next(clonedReq);

}