<section class="schools-page">
  <div class="schools-page-menu">
    <go-general-menu [menuItens]="menuItens" [(menuBarState)]="menuBarState" width="230px" />
  </div>

  <div class="schools-content ds-flex">
    <header class="schools-selection-info">
      <div class="info-block">
        <h2
          class="text-baloochettan-h1 go-text-color-gray900"
          [innerHTML]="processedString"
        ></h2>
        <p class="text-poppins-body-semibold go-text-color-gray700">
          <span class="text-poppins-body-semibold go-text-color-turquoise700"
            >Teacher:
          </span>
          Luiz Fernando Rosso Perraro
        </p>
      </div>
    </header>

    <div class="schools-selection go-border-color-gray300">
      <div class="schools-content-cards">
        <div class="schools-selection-cards">
          <ng-container *ngFor="let item of schoolItens; let i = index">
            <div style="width: 100%">
              <go-simple-radio
                title="{{ item.title }}"
                name="school"
                [isSelected]="item.isSelected"
                (click)="onCardSelected(i)"
              />
            </div>
          </ng-container>
        </div>
      </div>
      <div class="schools-selection-actions">
        <div class="schools-selection-progress">
          <div class="go-background-color-gray300"></div>
          <div
            class="go-background-color-purple700"
            style="height: 2px; width: 33%"
          ></div>
        </div>
        <go-back-button />
        <div style="width: 15px"></div>
        <go-general-button
          label="Continuar"
          borderRadius="12px"
          [onlyContent]="true"
        />
      </div>
    </div>
  </div>
</section>
