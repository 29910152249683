import { Component, Input, OnInit, ViewChild, output } from '@angular/core';
import { AbstractInputComponent } from '../abstract.input.component';

@Component({
  selector: 'go-general-input',
  templateUrl: './general-input.component.html',
  styleUrl: './general-input.component.scss',
})
export class GeneralInputComponent implements AbstractInputComponent, OnInit {

  readonly goComponentId: string = 'general-input';

  @ViewChild('label') label: any;
  @ViewChild('input', { static: true }) input: any;

  @Input() placeholder?: string;
  @Input() obscureText?: boolean = false;
  @Input() status?: string = 'normal';

  @Input() isDisabled: boolean = false;

  //? Final variables
  inputType: 'text' | 'password' = "text";
  switchObscureText: boolean = false;
  isFocused: boolean = false;
  labelClasses: string = 'text-poppins-body-bold';
  boxClasses: string = 'go-general-input';

  change = output<any>();

  ngOnInit(): void {
    this.applyComponentStyleClasses();
  }

  onFocus(): void {
    this.isFocused = true;
    if (this.isFocused) {
      this.boxClasses = this.removeClassFromString(this.boxClasses, 'go-border-color-gray400');
      this.boxClasses += ' input-focused go-border-color-purple500';
    }
  }

  onBlur(): void {
    this.isFocused = false;
    if (!this.isFocused) {
      this.boxClasses = this.removeClassFromString(this.boxClasses, 'input-focused go-border-color-purple500');
      this.boxClasses += ' go-border-color-gray400';
    }
  }

  toggleObscureText(): void {
    this.obscureText = !this.obscureText;
    this.inputType = this.obscureText ? 'password' : 'text';
  }

  private applyComponentStyleClasses() {
    //? Initial type input
    this.inputType = 'text';
    if (this.obscureText) {
      this.inputType = 'password';
      this.switchObscureText = true;
    }

    //? Component classes
    let boxClasses = this.boxClasses;
    if (this.isDisabled) {
      boxClasses += ` input-disabled go-background-color-gray200 go-border-color-gray200`;
    } else {
      boxClasses += ` go-background-color-whiteColor go-border-color-gray400`;
    }

    this.boxClasses = boxClasses;
  }

  private removeClassFromString(classes: string, classToRemove: string): string {
    const classArray = classes.split(' ').filter((cls) => cls !== classToRemove);
    return classArray.join(' ');
  }
}
