import { Injectable, effect, signal } from '@angular/core';
import { StorageService } from '../core/storage/storage.service';
import { keyareas } from '../shared/all-key-storages';
import { ApiEnvType, ApiUrl } from '../../../environments/api-env.type';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class ApiServiceImpl implements ApiService {

  private readonly envType = signal<ApiEnvType>('test');
  
  constructor(private storage: StorageService) {
    const key = keyareas.api.envType;
    const envType = storage.retrieveStoredItem(key);
    if (envType) {
      storage.storeItem(key, envType);
      this.envType.set(<ApiEnvType> envType);
    }

    effect(() => {
      this.storage.storeItem(key, this.envType());
    });
  }

  getEnvType(): ApiEnvType {
    return this.envType();
  }

  setEnvType(envType: ApiEnvType) {
    this.envType.set(envType);
  }

  getUrl(): ApiUrl | null {
    const env = this.envType();
    
    return environment.api.filter(v => v.env == env).map(v => v.url)[0];
  }

  getNormalizedUrl(context: string = ''): string {
    if (this.isFakeUrl()) {
      return this.getFakePrefixUrl().concat(context);
    }

    const url = this.getUrl();
    if (url == null) {
      return context;
    }

    return url.concat(context);
  }

  isFakeUrl(): boolean {
    return this.getUrl() == 'fake';
  }

  getFakePrefixUrl(): string {
    return 'fake';
  }

}