import { StageItem } from "./stage-item";

export const data: StageItem[] = [
  {
    id: '87bd1fcf-822d-4f37-a6b4-80e5fed31180',
    number: '01',
    cover: 'assets/images/time-to-connect-2.svg',
    title: 'Time To Connect',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'green',
    type: 'steps'
  },
  {
    id: '0db3d6ab-a25e-4a72-8d48-7f9c9a8e52ae',
    number: '02',
    cover: 'assets/images/did-you-know.svg',
    title: 'Did You Know?',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'turquoise',
    type: 'steps'
  },
  {
    id: '0d2fa91c-f866-4fe6-a25e-756708d17468',
    number: '03',
    cover: 'assets/images/quiz-time.svg',
    title: 'Quiz Time',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'salmon',
    type: 'steps'
  },
  {
    id: '518eb658-9765-498e-b16a-b2f34bd13ef5',
    number: '04',
    cover: 'assets/images/learning-steps.svg',
    title: 'Learning Steps',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: true,
    link: '',
    color: 'purple',
    type: 'steps'
  },
  {
    id: 'ddd53bef-237f-4755-93f6-e9c6bf372ba9',
    number: '05',
    cover: 'assets/images/taking-it-further.svg',
    title: 'Taking It Further',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'orange',
    type: 'steps'
  },
  {
    id: 'e4f1f3b9-831d-4d32-8d75-1e56660f7cde',
    number: '06',
    cover: 'assets/images/closing.svg',
    title: 'Closing',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'pink',
    type: 'steps'
  },
  {
    id: '334d6f52-1ebd-46e7-860f-0a406d44d7bf',
    number: '07',
    cover: 'assets/images/closing.svg',
    title: 'Review',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'green',
    type: 'steps'
  },
  {
    id: '7a21580e-f22d-4524-8458-c8326feaee1f',
    number: '08',
    cover: 'assets/images/closing.svg',
    title: 'Checkpoint',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'brown',
    type: 'steps'
  },
  {
    id: '70994182-7cef-4f96-b5b9-baa50b98862e',
    number: '09',
    cover: 'assets/images/closing.svg',
    title: 'Final Assesment',
    partialPages: 0,
    totalPages: 25,
    status: 'normal',
    open: false,
    link: '',
    color: 'blue',
    type: 'steps'
  },
];