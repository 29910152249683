<ng-container *ngIf="type == 'image'">
  <div #box class="widget-image" [style.marginBottom]="marginBottom">
    <img [src]="imagePath" alt="{{ label }}" title="{{ label }}" />
  </div>
</ng-container>

<ng-container *ngIf="type == 'lottie'">
  <div #box class="widget-lottie" [style.marginBottom]="marginBottom">
    <dotlottie-player
      [src]="imagePath"
      background="transparent"
      direction="1"
      playMode="normal"
      loop
      autoplay
    >
    </dotlottie-player>
  </div>
</ng-container>
