import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  Output,
} from '@angular/core';
import { ThemeColors } from '../../../public-api';
import { GeneralChallengeComponent } from '../general-challenge/general-challenge.component';

@Component({
  selector: 'widget-textbox',
  templateUrl: './textbox.component.html',
  styleUrl: './textbox.component.scss',
})
export class TextboxComponent
  extends GeneralChallengeComponent
  implements AfterViewInit
{
  readonly goComponentId: string = 'textbox-challenge';

  @ViewChild('label') label!: ElementRef<HTMLLabelElement>;
  @ViewChild('input') inputElement!: ElementRef;
  @Output() change = new Output();

  isFocused: boolean = false;

  ngAfterViewInit(): void {
    this.label.nativeElement.style.borderColor = ThemeColors['gray300'];

    if (this.marginBottom && this.marginBottom != '0') {
      this.label.nativeElement.style.marginBottom = this.marginBottom;
    }
  }

  onFocus(): void {
    this.isFocused = true;
    if (this.isFocused && this.pageStatus != 'concluded') {
      this.label.nativeElement.style.borderColor = ThemeColors['purple500'];
    }
  }

  onBlur(): void {
    this.isFocused = false;
    if (!this.isFocused && this.pageStatus != 'concluded') {
      this.label.nativeElement.style.borderColor = ThemeColors['gray300'];
    }
  }

  onInput(event: any): void {
    this.answerResult = this.inputElement.nativeElement.value;
    this.updateWidgetState();
  }

  checkCorrection(): boolean {
    let userAnswer = this.removeSpecialCharsAndSpaces(this.answerResult);
    let rightAnswer = this.removeSpecialCharsAndSpaces(this.rightAnswer);

    if (userAnswer == rightAnswer) {
      this.label.nativeElement.style.borderColor = ThemeColors['turquoise500'];
      return true;
    } else {
      // bool checkWrong = answers.any((el) => el.questionId == question.id && el.attempts + 1 >= maxAttempts);
      // return checkWrong ? ResultQuestion.wrong : ResultQuestion.retry;
      this.label.nativeElement.style.borderColor = ThemeColors['salmon500'];
      return false;
    }
  }
}
