import { Injectable } from '@angular/core';
import { ThemeColors } from '../../public-api';

@Injectable({
  providedIn: 'root',
})
export class TextProcessingService {
  async processText(
    input: string,
    baseClass: string,
    callGlossary: (text: string) => void,
    defaultStyle: { [key: string]: string } = {}
  ): Promise<string> {
    let textScale = 1; // Adjust as needed
    let variables = ['$user.first_name', '$user.last_name', '$user.full_name'];

    // Step 1: Substitute user variables
    for (let variable of variables) {
      input = input.replaceAll(variable, await this.replaceVariables(variable));
    }

    // Step 2: Split the string into parts considering the tags
    let parts = this.splitWithTags(input, /(<\/?[\w-]+>|[*].*?[*])/);

    // Step 3: Apply processing to substitute the text inside the tags
    let htmlContent = '';
    let currentStyle = { ...defaultStyle };

    for (let part of parts) {
      if (part) {
        if (part === '<br>' || part === '<br/>') {
          htmlContent += '<br/><br/>';
        } else if (part.startsWith('<')) {
          if (part.startsWith('</')) {
            if (part === '</color>') {
              currentStyle['color'] = defaultStyle['color'] || 'inherit';
            }
            currentStyle = this.getDefaultStyle(
              currentStyle,
              defaultStyle,
              part
            );
          } else {
            currentStyle = this.applyTagStyle(currentStyle, part);
          }
        } else {
          if (part.startsWith('*')) {
            let formattedString = this.removeAsterisks(part);
            htmlContent += `<span class="${baseClass}" style="font-weight: bold; color: ${ThemeColors['purple600']} !important; text-decoration: underline; cursor: pointer;" onclick="callGlossary('${formattedString}')">${formattedString}</span>`;
          } else {
            htmlContent += `<span class="${baseClass}" style="${this.styleToString(
              currentStyle
            )}">${part}</span>`;
          }
        }
      }
    }

    return htmlContent;
  }

  async replaceVariables(variable: string): Promise<string> {
    if (variable === '$user.first_name') {
      return 'John';
    } else if (variable === '$user.last_name') {
      return 'Doe';
    } else if (variable === '$user.full_name') {
      return 'John Doe';
    }
    return variable;
  }

  splitWithTags(input: string, pattern: RegExp): string[] {
    let parts = [];
    let matches = input.matchAll(new RegExp(pattern, 'g'));
    let lastEnd = 0;

    for (let match of matches) {
      parts.push(input.substring(lastEnd, match.index!));
      parts.push(match[0]!);
      lastEnd = match.index! + match[0]!.length;
    }
    parts.push(input.substring(lastEnd));
    return parts;
  }

  applyTagStyle(
    currentStyle: { [key: string]: string },
    tag: string
  ): { [key: string]: string } {
    if (tag.startsWith('<b>')) {
      return { ...currentStyle, 'font-weight': 'bold', color: '#30B0B0' };
    } else if (tag.startsWith('<i>')) {
      return { ...currentStyle, 'font-style': 'italic' };
    } else if (tag.startsWith('<s>')) {
      return { ...currentStyle, 'text-decoration': 'underline' };
    }
    return currentStyle;
  }

  getDefaultStyle(
    currentStyle: { [key: string]: string },
    defaultStyle: { [key: string]: string },
    tag: string
  ): { [key: string]: string } {
    if (tag === '</b>') {
      return {
        ...currentStyle,
        'font-weight': defaultStyle['font-weight'] || 'normal',
        color: defaultStyle['color'] || 'inherit',
      };
    } else if (tag === '</i>') {
      return {
        ...currentStyle,
        'font-style': defaultStyle['font-style'] || 'normal',
      };
    } else if (tag === '</s>') {
      return {
        ...currentStyle,
        'text-decoration': defaultStyle['text-decoration'] || 'none',
      };
    }
    return currentStyle;
  }

  removeAsterisks(input: string): string {
    if (input.startsWith('*') && input.endsWith('*')) {
      return input.substring(1, input.length - 1);
    }
    return input;
  }

  styleToString(style: { [key: string]: string }): string {
    return Object.entries(style)
      .map(([key, value]) => `${key}:${value}`)
      .join(';');
  }
}
