import { Component } from '@angular/core';
import { ItemCard, SelectSchoolPageModule } from "student-lib";
import { AbstractTeacherPageComponent } from '../abstract-teacher-page.component';

@Component({
    selector: 'app-teacher-home',
    standalone: true,
    templateUrl: './teacher-home.component.html',
    styleUrl: './teacher-home.component.scss',
    imports: [SelectSchoolPageModule]
})
export class TeacherHomeComponent extends AbstractTeacherPageComponent<any> {

    private step: 'what-to-do' | 'select-school' | 'select-school-year' | 'select-class' = 'what-to-do';

    actionItems: ItemCard[] = [
        { title: 'Ministrar aula', isSelected: true },
        { title: 'Liberar conteúdo para meus alunos', isSelected: false },
        { title: 'Acessar relatórios de turmas ou alunos', isSelected: false }
      ];

    override onEventPage(event: any): void {
        console.log(event);
        if (event == 'continueAction') {
            console.log('yes continue');
            switch (this.step) {
                case 'what-to-do': {
                    this.actionItems = new WhatToDo().actionItems;
                    this.step = 'select-school';
                    break;
                }
                case 'select-school': {
                    this.actionItems = new SelectSchool().actionItems;
                    this.step = 'select-school-year';
                    break;
                }
                case 'select-school-year': {
                    this.actionItems = new SelectSchoolYear().actionItems;
                    this.step = 'select-class';
                    break;
                }
                case 'select-class': {
                    this.actionItems = new SelectClass().actionItems;
                    this.step = 'select-class';
                    break;
                }
                default:
            }
        } else {
            console.log('is not continue action');
        }
    }

}

class WhatToDo {

    actionItems: ItemCard[] = [
        { title: 'Ministrar aula', isSelected: true },
        { title: 'Liberar conteúdo para meus alunos', isSelected: false },
        { title: 'Acessar relatórios de turmas ou alunos', isSelected: false }
      ];


}

class SelectSchool {

      actionItems: ItemCard[] = [
        { title: 'Pequeno Exemplar - São Francisco, C E I', isSelected: true },
        { title: 'Nossa Senhora Aparecida, C M E I do C', isSelected: false },
        { title: 'Agostinho Stefanello, E E C - M - Ef', isSelected: false },
        { title: 'Jorge Bife, E E C - M - Ef', isSelected: false },
        { title: 'José Do Patrocínio, E E C - M - Ef', isSelected: false },
      ];
    
}

class SelectSchoolYear {

    actionItems: ItemCard[] = [
        { title: '6º Ano', isSelected: true },
        { title: '7º Ano', isSelected: false },
        { title: '8º Ano', isSelected: false }
      ];
    
}

class SelectClass {

    actionItems: ItemCard[] = [
        { title: '6º Ano A', isSelected: true },
        { title: '6º Ano B', isSelected: false },
        { title: '6º Ano C', isSelected: false },
        { title: '6º Ano D', isSelected: false },
        { title: '6º Ano E', isSelected: false }
      ];
    
}