export default {
  id: '14c2c2eb-6e7c-4d83-95d4-6fabbf143aec',
  order: 5,
  title: 'Taking it Further',
  cover: null,
  countPages: 0,
  completedPages: 0,
  type: 'contents',
  pages: [],
};
