<article class="go-progress">
  <div *ngIf="icon" class="go-progress-icon">
    <div class="go-progress-icon-bg go-background-color-{{ color }}"></div>
    <div class="icon"><go-icon [icon]="icon" [pathColor]="color" /></div>
  </div>

  <header class="go-progress-info">
    <div class="go-progress-bar go-background-color-gray100">
      <div
        class="go-progress-innerbar go-background-color-{{ color }}"
        [style.width.%]="percent"
      ></div>
    </div>
    <h3 class="text-poppins-subtitle-bold go-text-color-gray500">
      {{ title }}
    </h3>
  </header>

  <div class="go-progress-percent">
    <p class="text-poppins-body-bold go-text-color-gray800">{{ percent }}%</p>
  </div>
</article>
