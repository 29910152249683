<div class="widget-select-image">
  <ng-container *ngFor="let item of options; let i = index">
    <label
      class="widget-select-image-item"
      [ngClass]="{ active: isActive(item.id) }"
      [style.backgroundColor]="getBackgroundColor(item.id)"
      [style.borderColor]="getBorderColor(item.id)"
      (click)="toggleSelection(item.id)"
    >
      <div
        class="mark-icon"
        [style.backgroundColor]="getIconBackgroundColor(item.id)"
        [style.borderColor]="getIconBorderColor(item.id)"
      >
        <div
          class="mark"
          [style.backgroundColor]="getIconBorderColor(item.id)"
        ></div>
      </div>
      <div class="image">
        <widget-onlyimage
          *ngIf="item.image"
          [value]="item.image!"
          width="100%"
        />
      </div>
    </label>
  </ng-container>
</div>
