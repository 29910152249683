import { UnitOption } from "./select-units-page.module";

export const data: UnitOption[] = [
  {
    id: '1',
    option: {
      name: 'unit',
      percent: 100,
      tag: 'Welcome Unit',
      title: 'First Steps',
      isSelected: false,
      status: 'completed',
    },
  },
  {
    id: '2',
    option: {
      name: 'unit',
      percent: 100,
      tag: 'Unit 1',
      title: 'Eating Habits',
      isSelected: false,
      status: 'completed',
    },
  },
  {
    id: '3',
    option: {
      name: 'unit',
      percent: 25,
      tag: 'Unit 2',
      title: 'Eating Out',
      isSelected: true,
      status: 'activated',
    },
  },
  {
    id: '4',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 3',
      title: 'Traveling Around',
      isSelected: false,
      status: 'activated',
    },
  },
  {
    id: '5',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 4',
      title: 'Going on Vacation',
      isSelected: false,
      status: 'disabled',
    },
  },
  {
    id: '6',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 5',
      title: 'Climate Change',
      isSelected: false,
      status: 'disabled',
    },
  },
  {
    id: '7',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 6',
      title: 'Sustainable Life',
      isSelected: false,
      status: 'disabled',
    },
  },
  {
    id: '8',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 7',
      title: 'Looking Ahead',
      isSelected: false,
      status: 'disabled',
    },
  },
  {
    id: '9',
    option: {
      name: 'unit',
      percent: 0,
      tag: 'Unit 8',
      title: 'Shaping Our Future',
      isSelected: false,
      status: 'disabled',
    },
  },
];
