import { KeyStore } from "../core/storage/key-store";

export namespace keyareas {

    export class api {
        static readonly envType: KeyStore = { area: 'api', key: 'env-type' };
    }

    export class auth {
        static readonly userLogged: KeyStore = { area: 'auth', key: 'user-logged' };
    }

    export class student {
        static readonly lastSelectedCourseId: KeyStore = { area: 'student', key: 'last-selected-course-id' };
        static readonly lastStartedCourseId: KeyStore = { area: 'student', key: 'last-started-course-id' };
        static readonly lastSelectedUnitId: KeyStore = { area: 'student', key: 'last-selected-unit-id' };
        static readonly lastStartedUnitId: KeyStore = { area: 'student', key: 'last-started-unit-id' };
        static readonly lastSelectedStageId: KeyStore = { area: 'student', key: 'last-selected-stage-id' };
        static readonly lastStartedStageId: KeyStore = { area: 'student', key: 'last-started-stage-id' };
    }
    
    export class global {
        static readonly lastMenubarState: KeyStore = { area: 'global', key: 'last-menubar-state' };
    }

    export class devtools {
        static readonly active: KeyStore = { area: 'devtools', key: 'active' };
    }

}