<div class="widget-blocks">
  <div class="line">
    <div
      *ngFor="let item of userBlocks; let i = index"
      class="block go-background-color-gray50 go-border-color-gray300"
      (click)="blockRemove(item)"
    >
      <span class="text-poppins-subtitle-semibold go-text-color-gray950">
        {{ item.label }}
      </span>
    </div>
  </div>
  <div class="blocks-list">
    <div
      *ngFor="let item of blocks; let i = index"
      [ngClass]="getBoxClasses(item)"
      (click)="blockSelection(item)"
    >
      <span [ngClass]="getTextClasses(item)">{{ item.label }}</span>
    </div>
  </div>
</div>
