import { ThemeIconsTypes } from "go-components";

export const statisticItens: {
    icon?: ThemeIconsTypes;
    title: string;
    text: string;
    width: string;
}[] = [
        {
            icon: 'star-full',
            title: '40%',
            text: 'Do 8º ano concluído.',
            width: '2',
        },
        {
            icon: 'trophy',
            title: '2,5h',
            text: 'Tempo médio de atividades e conteúdos realizados.',
            width: '2',
        },
        {
            icon: 'circle-check',
            title: '2000',
            text: 'Atividades corretas.',
            width: '3',
        },
        {
            icon: 'circle-close',
            title: '1000',
            text: 'Atividades incorretas.',
            width: '3',
        },
        { title: '30min', text: 'Tempo médio de estudo.', width: '3' },
    ];

export const tableItens = [
    {
        status: 'done',
        unit: 'Unit 1',
        corrects: '16',
        incorrects: '10',
        level: 'Interpreter',
    },
    {
        status: 'done',
        unit: 'Unit 2',
        corrects: '10',
        incorrects: '20',
        level: 'Explorer',
    },
    {
        status: 'done',
        unit: 'Unit 3',
        corrects: '20',
        incorrects: '10',
        level: 'Communicator',
    },
    { status: 'wait', unit: 'Unit 4', corrects: '', incorrects: '', level: '' },
    { status: 'wait', unit: 'Unit 5', corrects: '', incorrects: '', level: '' },
    { status: 'wait', unit: 'Unit 6', corrects: '', incorrects: '', level: '' },
    { status: 'wait', unit: 'Unit 7', corrects: '', incorrects: '', level: '' },
    { status: 'wait', unit: 'Unit 8', corrects: '', incorrects: '', level: '' },
];