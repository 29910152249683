<article class="go-general-card" [ngClass]="isSelected ? 'card-selected' : ''">
  <div
    class="go-general-card-image"
    [style.backgroundColor]="backgroundColor"
    [style.borderColor]="borderColor"
  >
    <div
      *ngIf="status == 'disabled'"
      class="mask go-background-color-gray400"
    ></div>
    <div *ngIf="number" class="card-number" [style.borderColor]="borderColor">
      <span class="text-poppins-body-semibold" [style.color]="borderColor">
        {{ number }}
      </span>
    </div>
    <img [src]="cover" [alt]="title" [title]="title" />
  </div>

  <header
    *ngIf="status != 'disabled'"
    class="go-general-card-info"
    [style.backgroundColor]="backgroundInfoColor"
    [style.borderColor]="borderInfoColor"
  >
    <h3 class="text-baloochettan-h4" [style.color]="textInfoColor">
      {{ title }}
    </h3>
    <div class="go-general-card-info-progress">
      <go-icon
        icon="rocket"
        [pathColor]="status == 'concluded' ? 'turquoise700' : 'gray900'"
        marginRight="6px"
      />
      @if(status != 'concluded') {
      <p class="text-poppins-subtitle-bold" [style.color]="textInfoColor">
        {{ partialPages }}/{{ totalPages }}
      </p>
      } @else {
      <p class="text-poppins-subtitle-bold" [style.color]="textInfoColor">
        100%
      </p>
      }
    </div>
    <div *ngIf="isSelected" class="go-general-card-info-actions">
      <go-general-button
        *ngIf="status == 'normal'"
        label="Começar esta etapa"
        rightIcon="arrow-right"
        (click)="mainButtonClick.emit()"
      />

      <go-general-button
        *ngIf="status == 'concluded'"
        label="Revisar esta etapa"
        labelColor="turquoise700"
        rightIcon="rotate"
        backgroundColor="whiteColor"
        borderColor="turquoise700"
        (click)="mainButtonClick.emit()"
      />
    </div>
  </header>

  <header
    *ngIf="status == 'disabled'"
    class="go-general-card-info disabled"
    [style.backgroundColor]="backgroundInfoColor"
    [style.borderColor]="borderInfoColor"
  >
    <h3 class="text-baloochettan-h4" [style.color]="textInfoColor">
      {{ title }}
    </h3>
  </header>
</article>
