import { Component, Input } from '@angular/core';
import { ThemeColorsTypes } from '../../core/models/enums/colors.enum';
import { ThemeIconsTypes } from '../../core/models/enums/icons.enum';
import { AbstractProgressComponent } from '../abstract.progress.component';

@Component({
  selector: 'app-general-progress',
  templateUrl: './general-progress.component.html',
  styleUrl: './general-progress.component.scss',
})
export class GeneralProgressComponent implements AbstractProgressComponent {

  readonly goComponentId: string = 'general-progress';

  @Input() icon?: ThemeIconsTypes;
  @Input() title!: string;
  @Input() color!: ThemeColorsTypes;
  @Input() percent!: number;

}
