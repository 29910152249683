<section class="general-modal">
  <div class="box">
    <div class="close-button">
      <go-general-button
        backgroundColor="salmon800"
        borderColor="salmon800"
        borderRadius="10px"
        leftIcon="close-white"
        leftIconMargin="2px"
        width="34px"
        height="34px"
        (click)="closeModal()"
      />
    </div>

    <header class="header go-border-color-gray300">
      <h2 class="text-poppins-headline-bold go-text-color-gray900">
        {{ title }}
      </h2>
      <p class="text-poppins-subtitle-regular go-text-color-gray950">
        {{ subtitle }}
      </p>
    </header>

    <div class="content">
      <div *ngIf="options" class="checklist">
        <div
          *ngFor="let item of options; let i = index"
          class="checklist-item"
          (click)="toggleItem(item)"
        >
          <div [class]="isItemSelected(item)">
            <go-icon
              *ngIf="checkSelected(item)"
              icon="check-radio"
              svgColor="whiteColor"
            />
          </div>
          <h3 class="text text-poppins-body-semibold go-text-color-gray900">
            {{ item.text }}
          </h3>
        </div>
      </div>

      <div
        class="textbox-block go-background-color-gray50"
        [ngClass]="textBoxClasses"
      >
        <textarea
          #input
          rows="6"
          placeholder="Por favor, descreva os problemas encontrados."
          (focus)="onFocus()"
          (blur)="onBlur()"
          (change)="change.emit($event)"
          (input)="onInput($event)"
        ></textarea>
      </div>
      <div class="char-counter">
        <span class="text-poppins-caption-regular go-text-color-gray900">
          {{ charCount }}/{{ maxChars }}
        </span>
      </div>
    </div>

    <div class="actions">
      <go-general-button
        label="Enviar Report"
        rightIcon="flag"
        backgroundColor="purple700"
        borderColor="purple700"
        borderRadius="25px"
        width="220px"
        [onlyContent]="true"
      />
    </div>
  </div>
</section>
