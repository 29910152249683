import { Component } from '@angular/core';

@Component({
  selector: 'st-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrl: './quiz-page.component.scss',
})
export class QuizPageComponent {
  state: 'none' | 'started' | 'ended' | 'loading' = 'none';
}
