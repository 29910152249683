import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { CommonNegativeLoginResult, authLoginContextPath } from '../../api/auth/auth.api.service';
import { LoggedUser, UserProfile } from 'student-lib';
import { AbstractMockApi } from '../abstract.mock-api';
import { mockApiData } from 'student-lib';

@Injectable({
    providedIn: 'root'
})
export class AuthMockApi extends AbstractMockApi {

  constructor(api: ApiService) {
    super(api);
  }

  registerHandlers(): void {
    this.mock.onPost(this.api.getFakePrefixUrl() + authLoginContextPath)
      .reply(({request}) => {
        let loggedUser: LoggedUser | null = null;
        const email = request.body.email;
        if (email == 'student') {
          loggedUser = mockApiData.fakeLoggedUsers.fakeLoggedStudentUser;
        } else if (email == 'teacher') {
          loggedUser = mockApiData.fakeLoggedUsers.fakeLoggedTeacherUser;
        }

        if (!loggedUser) {
          let negative: CommonNegativeLoginResult = 'invalid_credentials';

          return this.badResponse(negative);
        }

        return this.okResponse(this.adaptBodyResponseToApi(loggedUser));
      }
    );
  }

  private adaptBodyResponseToApi(loggedUser: LoggedUser): any {
    const body: any = loggedUser;
    body.access_token = loggedUser.accessToken;
    body.user.role = this.toRole(loggedUser.profile);

    return body;
  }

  private toRole(userProfile: UserProfile): string {
    switch (userProfile) {
      case 'student':
        return 'STUDENT';
      case 'teacher':
        return 'TEACHER';
      default:
        throw 'Unknow userProfile: ' + userProfile;
    }
  }

}