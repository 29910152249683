<label #label class="widget-textbox" [style.marginBottom]="marginBottom">
  <textarea
    #input
    [attr.readonly]="pageStatus == 'concluded' ? true : null"
    [attr.disabled]="pageStatus == 'concluded' ? true : null"
    rows="{{ widget.value ?? 3 }}"
    placeholder="{{ widget.label }}"
    autocomplete="off"
    spellcheck="false"
    autocorrect="off"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (input)="onInput($event)"
  ></textarea>
</label>
