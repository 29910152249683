import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
})
export class PageNotFoundComponent implements OnInit {
  homePage!: string;

  ngOnInit(): void {
    this.loadHomePage();
  }

  private loadHomePage() {
    const userLoggedInfo = localStorage.getItem('v1_auth:user-logged');
    if (userLoggedInfo !== null) {
      let userData = JSON.parse(userLoggedInfo);
      this.homePage = `${userData.profile}/home`;
    } else {
      this.homePage = '/';
    }
  }
}
