<div class="widget-select">
  <go-select-block
    *ngFor="let item of options; let i = index"
    [item]="item"
    [name]="name"
    [itensSelected]="itensSelected"
    [correction]="pageStatus == 'concluded'"
    (itemClick)="toggleSelection(item)"
  />
</div>
