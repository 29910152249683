import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralMenuComponent } from './general-menu/general-menu.component';
import { MenuItem } from './menu.interface';
import { IconsModule } from '../icons/icons.module';
import { MenuBarState } from './menu-bar.state';
import { AbstractMenuComponent } from './abstract.menu.component';

const components = [
  GeneralMenuComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule],
  exports: components
})
export class MenusModule { }

export {
  GeneralMenuComponent,
  MenuItem,
  MenuBarState,
  AbstractMenuComponent
}
