import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'st-quiz-start-page',
  templateUrl: './quiz-start-page.component.html',
  styleUrl: './quiz-start-page.component.scss',
})
export class QuizStartPageComponent {
  @Output() goToQuiz = new EventEmitter();

  startQuiz() {
    this.goToQuiz.emit();
  }
}
