import { Injectable } from '@angular/core';
import { CourseClass, Stage, Unit, Page, Step } from 'student-lib';

@Injectable()
export abstract class CoursesClassesApiService {
  abstract loadCoursesClasses(): Promise<CourseClass[]>;
  abstract loadUnits(courseClassId: string): Promise<Unit[]>;
  abstract loadStages(courseClass: CourseClass, unit: Unit): Promise<Stage[]>;
  abstract loadStage(courseClassId: string, stageId: string): Promise<Stage>;
  abstract loadSteps(courseClassId: string, stageId: string): Promise<Step[]>;
  abstract loadPages(courseClassId: string, stageId: string): Promise<Page[]>;
}
