import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { WidgetsStateService } from '../widget-state.service';
import { WidgetOptions } from '../options.interface';
import { CorrectableWidget } from '../widget.interface';

@Component({
  selector: 'lib-general-challenge',
  templateUrl: './general-challenge.component.html',
  styleUrls: ['./general-challenge.component.scss'],
})
export abstract class GeneralChallengeComponent
  implements OnInit, OnChanges, CorrectableWidget
{
  @Input() widget!: any;
  @Input() marginBottom?: string = '16px';
  @Input() pageStatus?: string;
  @Input() pageType?: string;

  uniqueId!: string;
  template!: string;
  feedback!: boolean;
  options!: WidgetOptions[];
  rightAnswer: string = '';

  answerResult: string = '';
  itensSelected: string[] = [''];

  constructor(
    private el: ElementRef,
    private widgetsStateService: WidgetsStateService
  ) {}

  ngOnInit(): void {
    this.el.nativeElement.style.width = '100%';

    this.template = this.widget.template ?? 'default';
    this.feedback = this.widget.feedback ?? false;
    this.options = this.widget.options ?? [];

    this.applyWidgetState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widget']) {
      this.applyRightAnswer();
      this.applyUserAnswer();
    }
  }

  applyUserAnswer() {
    let widgetId = this.widget.type + '-' + this.widget.id;
    let widgetState = this.widgetsStateService.getWidgetState(widgetId);
    if (widgetState) {
      if (widgetState.status != 'none') {
        this.answerResult = '';
      } else {
        this.resetAnswer();
      }
      console.log('widget existente');
      console.log(widgetState);
    }
  }

  applyWidgetState() {
    this.uniqueId = this.applyUniqueId();
    this.widgetsStateService.registerWidget(this, this.uniqueId, this.feedback);
  }

  updateWidgetState() {
    this.widgetsStateService.updateWidgetState(
      this.uniqueId,
      this.answerResult,
      'answered'
    );
  }

  resetAnswer() {
    this.itensSelected = [''];
    this.answerResult = '';
  }

  applyRightAnswer() {
    if (this.widget.type == 'textbox') {
      if (this.widget.options != null) {
        this.rightAnswer = this.options[0].textRelated ?? '';
      }
    }
  }

  correction() {
    const result = this.checkCorrection();
    this.widgetsStateService.updateWidgetState(
      this.uniqueId,
      this.answerResult,
      result ? 'correct' : 'wrong'
    );
    return result ? 'correct' : 'wrong';
  }

  protected removeSpecialCharsAndSpaces(text: string): string {
    if (!text || text.length === 0) {
      return '';
    } else {
      return text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    }
  }

  private applyUniqueId(): string {
    return this.widget.type + '-' + this.widget.id;
  }

  abstract checkCorrection(): boolean;
}

export interface BaseBlocks {
  label: string;
  value: number;
}
