<section class="challenge-page">
  <div class="challenge-page-menu">
    <go-general-navigation
      width="340px"
      title="Welcome Unit"
      [subtitle]="stageTitle"
      [progress]="percent"
      [navItens]="navPages"
    />
  </div>

  <div class="challenge-page-content">
    <div class="challenge-page-progress go-border-color-gray300">
      <div class="close-button">
        <a [href]="escapePath" title=""><go-icon icon="close" /></a>
      </div>
      <div class="progress-bar">
        <go-simple-progress
          color="purple500"
          [percent]="percent"
          [showPercent]="false"
        />
      </div>
      <div class="current-page go-background-color-purple500">
        <span class="text-poppins-body-semibold go-text-color-whiteColor">
          {{ currentPage }}
        </span>
      </div>
    </div>

    <div class="challenge-page-widgets">
      <div class="center-el">
        @for (widget of widgets; track $index) {
        <ng-container [ngSwitch]="widget.type">
          <widget-title
            *ngSwitchCase="'title'"
            [value]="widget.value!"
            [template]="widget.template"
            textAlign="left"
            [pageType]="pageType"
            [pageStatus]="pageStatus"
          />

          <widget-subtitle
            *ngSwitchCase="'subtitle'"
            [value]="widget.value!"
            [template]="widget.template"
            textAlign="left"
            marginBottom="16px"
          />

          <widget-enunciated
            *ngSwitchCase="'enunciated'"
            [value]="widget.value!"
            [template]="widget.template"
            textAlign="left"
          />

          <widget-longtext
            *ngSwitchCase="'longtext'"
            [value]="widget.value!"
            [template]="widget.template"
            textAlign="left"
          />

          <widget-onlyimage
            *ngSwitchCase="'onlyimage'"
            [value]="widget.value!"
            [template]="widget.template"
            width="100%"
          />

          <widget-audio
            *ngSwitchCase="'audio'"
            [value]="widget.value!"
            [label]="widget.label"
            [template]="widget.template"
          />

          <widget-select
            *ngSwitchCase="'select'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-select-image
            *ngSwitchCase="'selectimage'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-select-voice
            *ngSwitchCase="'selectvoice'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-multiselect
            *ngSwitchCase="'multiselect'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-textbox
            *ngSwitchCase="'textbox'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-combination
            *ngSwitchCase="'combination'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <widget-checklist
            *ngSwitchCase="'checklist'"
            [options]="widget.options"
            [template]="widget.template"
          />

          <widget-blocks
            *ngSwitchCase="'blocks'"
            [widget]="widget"
            [pageStatus]="pageStatus"
          />

          <div *ngSwitchCase="'spacer'" [style.height.px]="widget.value"></div>

          <div *ngSwitchDefault>
            Tipo de widget <b>{{ widget.type }}</b>
          </div>
        </ng-container>
        }
      </div>
    </div>

    <div class="challenge-page-actions go-border-color-gray300">
      <go-general-button
        *ngIf="!correctionButton"
        width="364px"
        label="Continuar"
        rightIcon="arrow-right"
        (click)="nextPage()"
        [disabled]="statusButton == 'disabled'"
      />

      <go-general-button
        *ngIf="correctionButton && pageStatus != 'concluded'"
        width="364px"
        label="Corrigir"
        rightIcon="check-radio"
        [disabled]="statusButton == 'disabled'"
        (click)="correctWidgets($event)"
      />

      @if (correctionButton && pageStatus == 'concluded') {
      <div class="concluded">
        <go-general-button
          width="364px"
          label="Continuar"
          rightIcon="arrow-right"
          (click)="nextPage()"
        />
      </div>
      }

      <!-- <go-general-button
        width="260px"
        label="Anterior"
        labelColor="purple700"
        leftIcon="arrow-left"
        backgroundColor="gray100"
        (click)="previousPage()"
        [disabled]="currentPageIndex === 0"
      />
      <div style="width: 20px"></div>
      <go-general-button
        width="260px"
        label="Próxima"
        rightIcon="arrow-right"
        (click)="nextPage()"
        [disabled]="currentPageIndex === data.pages.length - 1"
      /> -->
    </div>
  </div>
</section>
