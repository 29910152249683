import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeneralModalComponent } from '../modais.module';
import { ThemeColorsTypes } from '../../core/models/enums/colors.enum';

@Component({
  selector: 'go-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrl: './notification-modal.component.scss',
})
export class NotificationModalComponent extends GeneralModalComponent {

  override goComponentId: string = 'notification-modal';

  @Input() title!: string;
  @Input() description?: string;
  @Input() color: ThemeColorsTypes = 'purple500';

  @Output() close = new EventEmitter<void>();
  @Output() mainButtonClick = new EventEmitter<void>();

}
