<st-select-units-page
    [menuItens]="menuItens"
    [units]="options"
    (selectedUnit)="selectedUnit($event)"
    (startUnit)="continue($event)"
/>
<go-notification-modal
    *ngIf="mustStartOneCourseFirst"
    (close)="goSelectCourse()"
    (mainButtonClick)="goSelectCourse()"
    [title]="'Antes de escolhar a unidade...'"
    [description]="'Você precisa informar qual é a sua aula atual. Vou te levar para lá.'"
/>