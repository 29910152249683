<div class="widget-checklist">
  <div
    *ngFor="let item of options; let i = index"
    class="checklist-item"
    (click)="toggleItem(item)"
  >
    <div [class]="isItemSelected(item)">
      <go-icon icon="check-radio" svgColor="whiteColor" />
    </div>
    <h3 class="text text-poppins-body-semibold go-text-color-gray900">
      {{ item.text }}
    </h3>
  </div>
</div>
