<section class="go-ls-page">
  <div class="go-ls-content">
    <section
      *ngIf="explorer != null && explorer.length > 0"
      class="go-ls-steps"
    >
      <header class="go-background-color-turquoise700">
        <h2 class="text-poppins-body-semibold go-text-color-whiteColor">
          Nível Explorer
        </h2>
      </header>

      <div class="go-ls-steps-itens">
        <ng-container *ngFor="let item of explorer; let i = index">
          <div style="width: 100%">
            <go-general-radio
              [name]="item.name"
              [number]="item.number"
              [percent]="item.percent"
              [title]="item.title"
              [isSelected]="item.isSelected"
              [status]="item.status"
              (click)="onCardSelected(i, 'explorer')"
            ></go-general-radio>
          </div>
        </ng-container>
      </div>
    </section>

    <section
      *ngIf="interpreter != null && interpreter.length > 0"
      class="go-ls-steps"
    >
      <header class="go-background-color-blue700">
        <h2 class="text-poppins-body-semibold go-text-color-whiteColor">
          Nível Interpreter
        </h2>
      </header>

      <div class="go-ls-steps-itens go-border-color-pink400">
        <ng-container *ngFor="let item of interpreter; let i = index">
          <div style="width: 100%">
            <go-general-radio
              [name]="item.name"
              [number]="item.number"
              [percent]="item.percent"
              [title]="item.title"
              [isSelected]="item.isSelected"
              [status]="item.status"
              (click)="onCardSelected(i, 'interpreter')"
            ></go-general-radio>
          </div>
        </ng-container>
      </div>
    </section>

    <section
      *ngIf="communicator != null && communicator.length > 0"
      class="go-ls-steps"
    >
      <header class="go-background-color-pink700">
        <h2 class="text-poppins-body-semibold go-text-color-whiteColor">
          Nível Communicator
        </h2>
      </header>

      <div class="go-ls-steps-itens go-border-color-blue400">
        <ng-container *ngFor="let item of communicator; let i = index">
          <div style="width: 100%">
            <go-general-radio
              [name]="item.name"
              [number]="item.number"
              [percent]="item.percent"
              [title]="item.title"
              [isSelected]="item.isSelected"
              [status]="item.status"
              (click)="onCardSelected(i, 'communicator')"
            ></go-general-radio>
          </div>
        </ng-container>
      </div>
    </section>
  </div>

  <div class="go-ls-actions go-border-color-gray300">
    <go-general-button
      width="325px"
      label="Voltar"
      labelColor="purple700"
      leftIcon="arrow-left"
      backgroundColor="purple50"
      borderColor="purple500"
      [back]="true"
    ></go-general-button>
    <div style="width: 15px"></div>
    <go-general-button
      width="325px"
      label="Iniciar Step"
      rightIcon="paw"
      backgroundColor="purple700"
      borderColor="purple700"
      [disabled]="disabledButton"
      (click)="onContinue()"
    ></go-general-button>

    <a
      class="go-ls-report"
      href="#"
      title="Reportar Problema"
      (click)="reportAction($event)"
    >
      <img
        src="assets/icons/fi-ss-flag.svg"
        alt="Reportar Problema"
        title="Reportar Problema"
      />
      <span class="text-poppins-subtitle-semibold go-text-color-gray950"
        >Reportar Problema</span
      >
    </a>
  </div>
</section>
