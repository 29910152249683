import { Component, Output, EventEmitter } from '@angular/core';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'go-mic-button',
  templateUrl: './mic-button.component.html',
  styleUrl: './mic-button.component.scss',
})
export class MicButtonComponent extends GeneralButtonComponent {
  override readonly goComponentId: string = 'mic-button';

  @Output() onPressed = new EventEmitter<void>();
}
