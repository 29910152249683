<aside
  #aside
  class="go-general-navigation go-border-color-gray300"
  [ngClass]="navigationClasses"
>
  <header class="go-background-color-gray50">
    <a
      class="nav-switch"
      href="/student-home"
      title="Navegação"
      (click)="toggleMenu($event)"
    >
      <go-icon icon="menu-burger" />
    </a>
    <h2 class="text-poppins-headline-bold go-text-color-gray800">
      {{ title }}
    </h2>
    <p class="text-poppins-subtitle-bold go-text-color-gray700">
      {{ subtitle }}
    </p>
    <div class="nav-progress">
      <go-simple-progress color="purple600" [percent]="progress" />
    </div>
    <div class="nav-circle-progress">
      <go-circle-progress color="purple600" [percent]="progress" />
    </div>
  </header>

  <div class="nav-content">
    <ng-container *ngFor="let item of navItens; let i = index">
      <article class="nav-content-item">
        <div class="link" (click)="item.action()">
          <div *ngIf="item.status == 'disabled'" class="icon">
            <div class="lock"><go-icon icon="lock" pathColor="gray600" /></div>
          </div>

          <div *ngIf="item.status == 'concluded'" class="icon">
            <div class="radio go-border-color-turquoise700">
              <div
                *ngIf="item.current"
                class="radio-check go-background-color-turquoise700"
              ></div>

              <div *ngIf="!item.current">
                <go-icon icon="check-radio" />
              </div>
            </div>
          </div>

          <div *ngIf="item.status == 'activated'" class="icon">
            <div *ngIf="item.current" class="radio go-border-color-purple700">
              <div class="radio-check go-background-color-purple700"></div>
            </div>

            <div
              *ngIf="!item.current"
              class="radio go-background-color-gray100 go-border-color-gray400"
              [style.borderWidth]="'1px'"
            ></div>
          </div>

          <div class="number">
            <span class="text-poppins-body-semibold go-text-color-gray600">
              {{ item.number }}
            </span>
          </div>
          <div class="text">
            <h3 class="text-poppins-subtitle-semibold go-text-color-gray800">
              {{ transformText(item.title) }}
            </h3>
          </div>
        </div>
      </article>
    </ng-container>
  </div>

  <div class="nav-report-action go-border-color-gray300">
    <a
      *ngIf="report"
      href="#"
      title="Reportar Problema"
      (click)="reportAction($event)"
    >
      <div class="icon"><go-icon icon="flag" pathColor="gray950" /></div>
      <span class="text-poppins-subtitle-semibold go-text-color-gray950">
        Reportar Problema
      </span>
    </a>
  </div>
</aside>
