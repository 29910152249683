import { Component, Input, model } from '@angular/core';
import { MenuBarState } from 'go-components';
import { defaultMenuBarState, defaultMenuItemsPage } from '../../core/default-menu-items.pages';
import { PageWithMenu } from '../../core/page-with-menu';
import { statisticItens, tableItens } from './data';

@Component({
  selector: 'st-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent implements PageWithMenu {
  
  @Input() menuItens = defaultMenuItemsPage;
  menuBarState = model<MenuBarState>(defaultMenuBarState);

  statisticItens = statisticItens;
  tableItens = tableItens;

  logout() {}

}
