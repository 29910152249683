import { User } from "../domain/user/user";

export const fakeStudentUser: User = {
    id: 'fakeJuniorStudent',
    name: 'Junior Young',
    email: 'junior@fake.com'
};

export const fakeTeacherUser: User = {
    id: 'fakeBillieTeacher',
    name: 'Billie Thomas',
    email: 'billie@fake.com'
};

export const defaultFakeUsers: User[] = [fakeStudentUser, fakeTeacherUser];