import { Component, Output, EventEmitter } from '@angular/core';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'go-google-button',
  templateUrl: './google-button.component.html',
  styleUrl: './google-button.component.scss',
})
export class GoogleButtonComponent extends GeneralButtonComponent {

  override readonly goComponentId: string = 'google-button';

  @Output() onPressed = new EventEmitter<void>();

}
