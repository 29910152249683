import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { QuizModule } from 'student-lib';
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';

@Component({
  selector: 'app-go-exames',
  standalone: true,
  imports: [QuizModule, NgIf],
  templateUrl: './go-exames.component.html',
  styleUrl: './go-exames.component.scss',
})
export class GoExamesComponent extends AbstractStudentPageComponent<any> {}
