import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralBlockComponent } from './general-block/general-block.component';
import { CombinationBlockComponent } from './combination-block/combination-block.component';
import { SelectBlockComponent } from './select-block/select-block.component';

import { IconsModule } from '../icons/icons.module';

const components = [
  GeneralBlockComponent,
  CombinationBlockComponent,
  SelectBlockComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule],
  exports: components,
})
export class BlocksModule {}

export {
  GeneralBlockComponent,
  CombinationBlockComponent,
  SelectBlockComponent,
};
