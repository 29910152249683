import { Component, Input, input } from '@angular/core';
import { ThemeColors } from '../../../public-api';
import { WidgetOptions } from '../options.interface';
import { GeneralChallengeComponent } from '../general-challenge/general-challenge.component';

@Component({
  selector: 'widget-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent extends GeneralChallengeComponent {
  readonly goComponentId: string = 'select-challenge';

  @Input() name?: string = 'select';
  @Input() allowMultiple: boolean = false;

  disabled = input(false, {
    transform: (value: boolean | string) =>
      typeof value === 'string' ? value === '' : value,
  });

  backgroundColor!: ThemeColors;
  borderColor!: ThemeColors;
  textColor!: ThemeColors;
  iconBackgroundColor!: ThemeColors;
  iconBorderColor!: ThemeColors;

  toggleSelection(item: WidgetOptions) {
    const index = this.itensSelected.indexOf(item.id);
    if (index === -1) {
      if (!this.allowMultiple) {
        this.itensSelected = [item.id]; // Seleção única
        this.answerResult = item.id;
      } else {
        this.itensSelected.push(item.id); // Seleção múltipla
      }
    } else {
      this.itensSelected.splice(index, 1);
      this.answerResult = '';
    }

    this.updateWidgetState();
  }

  checkCorrection(): boolean {
    if (this.options?.some((el) => el.id == this.answerResult)) {
      return this.options.some((el) => el.id == this.answerResult && el.right);
    }
    return false;
  }
}
