import { Provider } from "@angular/core";
import { KEYSTORE_PREFIX, StorageService } from "./storage.service";

export const storageServicesProviders: Provider[] = [
  {
    provide: KEYSTORE_PREFIX,
    useValue: () => {
      return 'v1_';
    }
  },
  {
    provide: StorageService
  }
];