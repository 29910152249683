import { Component } from '@angular/core';
import { WidgetOptions } from '../options.interface';
import { GeneralChallengeComponent } from '../general-challenge/general-challenge.component';

@Component({
  selector: 'widget-combination',
  templateUrl: './combination.component.html',
  styleUrl: './combination.component.scss',
})
export class CombinationComponent extends GeneralChallengeComponent {
  readonly goComponentId: string = 'combination-challenge';

  userSelections: UserSelections[] = [];
  correctSelections: UserSelections[] = [];
  rightSelections: UserSelections[] = [];
  wrongSelections: UserSelections[] = [];

  leftOptions: WidgetOptions[] = [];
  rightOptions: WidgetOptions[] = [];
  leftSelect: string = '';
  rightSelect: string = '';

  shouldAnimate: boolean = false;
  // correctSelections: Map<string, QuestionAnswerModel>[] = [];
  // rightSelections: Map<string, QuestionAnswerModel>[] = [];
  // wrongSelections: Map<string, QuestionAnswerModel>[] = [];
  // userSelections: Map<string, QuestionAnswerModel>[] = [];

  // ngOnInit() {
  //   this.el.nativeElement.style.width = '100%';

  //   this.initializeOptions();
  // }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['options']) {
  //     this.initializeOptions();

  //     this.uniqueId = this.applyUniqueId();
  //     this.widgetsStateService.updateWidgetState(
  //       this.uniqueId,
  //       this.answerResult !== ''
  //     );
  //     this.widgetsStateService.registerWidget(this);
  //   }
  // }

  initializeOptions() {
    const options = this.updateColumns(this.options);
    this.leftOptions = options[0];
    this.rightOptions = options[1];
  }

  async handleCombination(item: WidgetOptions, type: string) {
    if (type === 'left') {
      this.leftSelect = this.leftSelect === item.id ? '' : item.id;
    } else if (type === 'right') {
      this.rightSelect = this.rightSelect === item.id ? '' : item.id;
    }

    const leftItem = this.leftOptions.find((el) => el.id === this.leftSelect);
    const rightItem = this.rightOptions.find(
      (el) => el.id === this.rightSelect
    );

    if (leftItem && rightItem) {
      this.shouldAnimation(true);
      if (
        this.leftOptions.indexOf(leftItem) !== 0 ||
        this.rightOptions.indexOf(rightItem) !== 0
      ) {
        this.leftOptions = this.leftOptions.filter(
          (el) => el.id !== leftItem.id
        );
        this.rightOptions = this.rightOptions.filter(
          (el) => el.id !== rightItem.id
        );
        this.leftOptions.unshift(leftItem);
        this.rightOptions.unshift(rightItem);
        await new Promise((resolve) => setTimeout(resolve, 400));
        this.shouldAnimation(false);
      }

      this.leftOptions = this.leftOptions.filter((el) => el.id !== leftItem.id);
      this.rightOptions = this.rightOptions.filter(
        (el) => el.id !== rightItem.id
      );
      this.leftSelect = '';
      this.rightSelect = '';
      this.userSelections.push({ left: leftItem, right: rightItem });

      //? Atualiza a string a ser corrigida
      if (this.userSelections.length >= this.options!.length) {
        this.answerResult = this.combinationFormatItems(this.userSelections);

        this.updateWidgetState();
      }
    }
  }

  removeCombination(item: WidgetOptions, type: 'left' | 'right') {
    this.shouldAnimation(false);

    const targetItem = this.userSelections.find(
      (el) => el[type].id === item.id
    );
    if (targetItem) {
      this.leftOptions.unshift(targetItem['left']);
      this.rightOptions.unshift(targetItem['right']);
      this.userSelections = this.userSelections.filter(
        (el) => el[type].id !== item.id
      );

      //? Atualiza a string a ser corrigida
      if (this.userSelections.length >= this.options!.length) {
        this.answerResult = '';
        this.updateWidgetState();
      }
    }
  }

  checkCorrection(): boolean {
    if (this.userSelections.length > 0) {
      for (const item of this.userSelections) {
        if (item['left'] && item['right']) {
          if (item['left'].id === item['right'].id) {
            this.rightSelections.push(item);
          } else {
            this.wrongSelections.push(item);
          }
        }
      }

      if (this.matchColumns(this.userSelections)) {
        return true;
      } else {
        return false;
      }

      // if (retry) {
      //   await new Promise((resolve) => setTimeout(resolve, 800));
      //   this.wrongSelections = [];
      //   this.correctSelections.push(...this.rightSelections);
      //   this.userSelections.forEach((item) => {
      //     if (!this.correctSelections.includes(item)) {
      //       this.leftOptions.push(item['left']);
      //       this.rightOptions.push(item['right']);
      //     }
      //   });
      //   this.userSelections = [];
      // }
    }

    return false;
  }

  // *** PRIVATE FUNCTIONS

  private shouldAnimation(value: boolean) {
    this.shouldAnimate = value;
  }

  private updateColumns(options?: WidgetOptions[]): any[][] {
    if (options && options.length > 0) {
      let leftOptions = [...options];
      let rightOptions = [...options];

      // Shuffle left options
      leftOptions = this.shuffleArray(leftOptions);

      // Shuffle right options
      rightOptions = this.shuffleArray(rightOptions);

      return [leftOptions, rightOptions];
    }

    return [[], []];
  }

  private shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  //? Transforma os itens selecionados em uma string para correção e save
  private combinationFormatItems(items: UserSelections[]): string {
    return items
      .map((item) => `${item['left'].id}|${item['right'].id}`)
      .join(';');
  }

  private matchColumns(blocks: UserSelections[]) {
    if (blocks && blocks.length > 0) {
      for (let item of blocks) {
        if (item['left'] && item['right']) {
          if (item['left'].id !== item['right'].id) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}

interface UserSelections {
  left: WidgetOptions;
  right: WidgetOptions;
}
