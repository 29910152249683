import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface WidgetState {
  id: string;
  userResponse: string;
  status: 'none' | 'answered' | 'correct' | 'wrong';
  feedback: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WidgetsStateService {
  private widgetsState = new BehaviorSubject<Record<string, WidgetState>>({});
  widgetsState$ = this.widgetsState.asObservable();

  private feedbackWidgets: any[] = [];

  registerWidget(widget: any, widgetId: string, feedback: boolean) {
    if (feedback) {
      const currentState = this.widgetsState.value;
      if (!currentState[widgetId]) {
        currentState[widgetId] = {
          id: widgetId,
          userResponse: '',
          status: 'none',
          feedback: feedback,
        };
        this.widgetsState.next(currentState);
      }

      this.feedbackWidgets.push({ id: widgetId, instance: widget });
    }
  }

  updateWidgetState(
    widgetId: string,
    userResponse: string,
    status: 'answered' | 'correct' | 'wrong'
  ) {
    const currentState = this.widgetsState.value;
    if (currentState[widgetId]) {
      currentState[widgetId] = {
        ...currentState[widgetId],
        userResponse: userResponse,
        status: status,
      };
      this.widgetsState.next(currentState);
    }
  }

  getWidgetState(widgetId: string): WidgetState | undefined {
    return this.widgetsState.value[widgetId];
  }

  areAllWidgetsAnswered(widgets: any[]): boolean {
    const currentState = this.widgetsState.value;
    return widgets
      .filter((widget) => widget.feedback)
      .every((widget) => {
        const widgetState = currentState[widget.type + '-' + widget.id];
        return widgetState && widgetState.status !== 'none';
      });
  }

  areAllWidgetsConcluded(widgets: any[]): boolean {
    const currentState = this.widgetsState.value;
    return widgets
      .filter((widget) => widget.feedback)
      .every((widget) => {
        const widgetState = currentState[widget.type + '-' + widget.id];
        return (
          widgetState &&
          (widgetState.status === 'correct' || widgetState.status === 'wrong')
        );
      });
  }

  getFeedbackWidgets(): WidgetState[] {
    const currentState = this.widgetsState.value;
    return Object.values(currentState).filter((widget) => widget.feedback);
  }

  getWidgetById(widgetId: string): any {
    return this.feedbackWidgets.find((widget) => widget.id === widgetId)
      ?.instance;
  }

  clearWidgets() {
    this.feedbackWidgets = [];
    this.widgetsState.next({});
  }
}
