<input
  #input
  type="radio"
  name="{{ name }}"
  [checked]="isSelected"
  value=""
  style="display: none"
  *ngIf="status != 'disabled'"
/>
<label class="go-general-card {{ boxClasses }}">
  <div *ngIf="status == 'activated'" class="radio {{ radioClasses }}">
    <div class="radio-check go-background-color-purple700"></div>
  </div>
  <div *ngIf="status == 'completed'" class="radio {{ radioClasses }}">
    <div
      *ngIf="isSelected"
      class="radio-check go-background-color-turquoise700"
    ></div>

    <div *ngIf="!isSelected">
      <img
        src="assets/icons/check-radio.svg"
        alt="Conteúdo concluído"
        title="Conteúdo concluído"
      />
    </div>
  </div>
  <div *ngIf="status == 'disabled'" class="lock">
    <img
      src="assets/icons/fi-rs-lock.svg"
      alt="Conteúdo bloqueado"
      title="Conteúdo bloqueado"
    />
  </div>
  <h3 class="text">
    <span [class]="numberClasses" *ngIf="radioNumber">{{ radioNumber }}</span>
    <span [class]="tagClasses" *ngIf="tag">{{ tag }}</span>
    <span [class]="textClasses">{{ title }}</span>
  </h3>
  <span
    *ngIf="percent != null && status != 'disabled'"
    [class]="percentClasses"
  >
    {{ percent }}%
  </span>
</label>
