import { ApiEnv } from "./api-env.type";

export const environment = {
    production: false,
    api: <ApiEnv[]> [{
        env: 'production',
        url: 'https://api.go2dev.cloud',
    },{
        env: 'test',
        url: 'https://api.go2dev.cloud',
    },{
        env: 'development',
        url: 'fake',
    }]
};
