import { Component, OnInit } from '@angular/core';
import { CourseClass, CourseOption, SelectCourseToLearnPageModule } from "student-lib";
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { CoursesClassesApiService } from '../../api/courses/courses-classes.api.service';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { Router } from '@angular/router';
import { childRoutes } from '../../core/routes/child-routes';
import { keyareas } from '../../shared/all-key-storages';

@Component({
    selector: 'app-select-course-to-learn',
    standalone: true,
    templateUrl: './select-course-to-learn.component.html',
    styleUrl: './select-course-to-learn.component.scss',
    imports: [SelectCourseToLearnPageModule]
})
export class SelectCourseToLearnComponent extends AbstractStudentPageComponent<void> implements OnInit {

    options: CourseOption[] = [];
    selectedOption?: CourseOption;
    continueButtonDisabled = true;

    constructor(authApi: AuthApiService, private api: CoursesClassesApiService, private router: Router) {
        super(authApi);
    }

    selectedCourse(evt: CourseOption) {
        this.storeCourseId('selected', evt.id);
        this.continueButtonDisabled = false;
    }

    ngOnInit(): void {
        const lastStartedId = this.retrieveStringFromStorage(keyareas.student.lastStartedCourseId);
        const lastSelectedId = this.retrieveStringFromStorage(keyareas.student.lastSelectedCourseId);
        const lastId = lastStartedId ? lastStartedId : lastSelectedId;

        this.api.loadCoursesClasses()
            .then(v => {
                v.forEach(item => {
                    const selected = lastId == item.id;
                    const option = this.toCourseOption(item, selected);
                    this.options.push(option);

                    if (selected) {
                        this.selectedOption = option;
                        this.continueButtonDisabled = false;
                        this.storeCourseId('selected', item.id);
                    }
                })
            }).catch(error => {
                if (error == 'Unauthorized') {
                    this.authApi.doRefreshToken().then(v => {
                        console.log('refreshed successfulll');

                        return;
                    }).catch(error => {
                        console.error('error in refresh token', error);

                        return;
                    })
                }

                console.debug(error);
            })
    }

    goBack() {
        this.router.navigateByUrl(childRoutes.studentHome);
    }

    continue(option?: CourseOption) {
        if (!option) {
            return;
        }

        this.storeCourseId('started', option.id);

        this.router.navigateByUrl(childRoutes.selectUnitStudent);
    }

    private storeCourseId(type: 'selected' | 'started', id: string) {
        const key = type == 'selected' ? keyareas.student.lastSelectedCourseId : keyareas.student.lastStartedCourseId;

        this.storeString(key, id);
    }

    private toCourseOption(v: CourseClass, selected: boolean = false): CourseOption {
        return { id: v.id, title: v.name.concat(' - ').concat(v.course.title), isSelected: selected };
    }

}
