import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule, CardsModule, MenusModule } from 'go-components';
import { SelectStagePageComponent } from './select-stage-page.component';
import { StageItem, StageItemStatus } from './stage-item';
import { data } from './data';
import { StageItemMapper } from './stage-item.mapper';

const components = [SelectStagePageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, MenusModule, CardsModule, ButtonsModule],
  exports: components,
})
export class SelectStagePageModule {}

export { SelectStagePageComponent, StageItem, StageItemStatus, StageItemMapper, data as defaultStageItems };
