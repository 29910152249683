import { Component } from '@angular/core';
import { GeneralInputComponent } from '../general-input/general-input.component';

@Component({
  selector: 'go-input-text',
  templateUrl: './input-text.component.html',
  styleUrl: './input-text.component.scss',
})
export class InputTextComponent extends GeneralInputComponent {

  override readonly goComponentId: string = 'text-input';

}
