import { Injectable, effect, signal } from '@angular/core';
import { StorageService } from '../../../core/storage/storage.service';
import { keyareas } from '../../all-key-storages';

@Injectable({
  providedIn: 'root'
})
export class DevToolsService {

  private readonly active = signal(false);
  
  constructor(private storage: StorageService) {
    const active = storage.retrieveBooleanItem(keyareas.devtools.active);
    if (active != null) {
      this.active.set(active);
    }

    effect(() => {
      this.storage.storeBooleanItem(keyareas.devtools.active, this.active());
    });
  }

  toggleActive(active?: boolean) {
    if (active == undefined) {
      this.active.set(!this.active());
      return;
    }
    
    this.active.set(active);
  }

  isActive(): boolean {
    return this.active();
  }

}
