import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralRadioComponent } from './general-radio/general-radio.component';
import { SimpleRadioComponent } from './simple-radio/simple-radio.component';
import { Radio } from './radio.interface';
import { AbstractRadioComponent } from './abstract.radio.component';

const components = [
  GeneralRadioComponent,
  SimpleRadioComponent
];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components
})
export class RadiosModule { }

export {
  GeneralRadioComponent,
  SimpleRadioComponent,
  Radio,
  AbstractRadioComponent
}