import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSchoolPageComponent } from './select-school-page.component';
import { ButtonsModule, RadiosModule, MenusModule } from 'go-components';

const components = [SelectSchoolPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, RadiosModule, MenusModule],
  exports: components
})
export class SelectSchoolPageModule { }

export { SelectSchoolPageComponent }