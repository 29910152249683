@if (visible) {
<table @openClose>
    <tr>
        <td>
            <p>😎 DevTools: <b><a href="javascript:void(0);" (click)="off()">desativar</a></b></p>
            @if (fakeLogin) {
            <p>Entrar com usuário:
                @for (item of fakeLogin; track $index) {
                <a href="javascript:void(0);" (click)="requestFakeLogin(item)">{{ item }}</a>@if (!$last) { | }
                }
            </p>
            }
            <p>API: 
                <select name="api" (change)="apiChange($event)">
                    @for (item of apiTypeOptions; track item.id) {
                        <option [disabled]="item.disabled" [value]="item.id" [selected]="item.id == apiEnvType">
                            {{ item.id }}
                            {{ item.id == apiEnvType ? ' [active]' : '' }}
                            {{ item.disabled ? ' [disabled]' : '' }}
                        </option>
                    }
                </select>
            </p>
            <p style="font-size: 0.8em;">API URL: {{ getApiUrl() }}</p>
        </td>
    </tr>
</table>
}