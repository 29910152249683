import { LoginPageEvent } from './login-page.event';
import { LoginPageAuthType } from './login-page.auth.type';


export class LoginPageData {

  event: LoginPageEvent = 'login-request';
  type: LoginPageAuthType = 'basic';
  email?: string;
  password?: string;

}
