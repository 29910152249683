import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralInputComponent } from './general-input/general-input.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { AbstractInputComponent } from './abstract.input.component';

const components = [
  GeneralInputComponent,
  InputTextComponent,
  InputPasswordComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components
})
export class InputsModule { }

export {
  GeneralInputComponent,
  InputTextComponent,
  InputPasswordComponent,
  AbstractInputComponent
}