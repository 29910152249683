import { Component } from "@angular/core";
import { AbstractUserPageComponent } from "../shared/abstract-user-page.component";

@Component({ selector: 'lib-abstract-teacher-page-component', template: '' })
export abstract class AbstractTeacherPageComponent<T> extends AbstractUserPageComponent<T> {

    getTeacherName(): string | null {
        return this.getCurrentLoggedUserName();
    }

}