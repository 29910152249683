import {
  ClassFormat,
  CourseClass,
  CourseStatus,
  Stage,
  StageStatus,
  StageType,
  Unit,
  UnitStatus,
  Page,
  PageType,
  PageStatus,
  Step,
  StepStatus,
} from 'student-lib';

export class CoursesClassesApiMapper {
  toUnits(data: any[]): Unit[] | Error {
    if (!Array.isArray(data)) {
      return new Error("Units isn't a array");
    }

    const units: Unit[] = [];
    for (var i = 0; i < data.length; i++) {
      const unit = this.toUnit(data[i]);
      if (unit instanceof Error) {
        return new Error(`Error reading units array at index ${i}: ${unit}`);
      }

      units.push(unit);
    }

    return units;
  }

  toUnit(data: any): Unit | Error {
    if (!data.id) {
      return new Error('Unit id not found');
    }

    const status = this.toUnitStatus(data.status);
    if (status instanceof Error) {
      return status;
    }

    return {
      id: data.id,
      title: data.title,
      subtitle: data.subtitle,
      order: data.order,
      status: status,
      countPages: data.countPages,
      completedPages: data.completedPages,
    };
  }

  toStages(data: any): Stage[] | Error {
    data = data.stages;
    if (!Array.isArray(data)) {
      return new Error("Stage isn't a array");
    }

    const stages: Stage[] = [];
    for (var i = 0; i < data.length; i++) {
      const stage = this.toStage(data[i]);
      if (stage instanceof Error) {
        return new Error(`Error reading stages array at index ${i}: ${stage}`);
      }

      stages.push(stage);
    }

    return stages;
  }

  toStage(data: any): Stage | Error {
    if (!data.id) {
      return new Error('Stage id not found');
    }

    const status = this.toStageStatus(data.status);
    if (status instanceof Error) {
      return status;
    }

    const type = this.toStageType(data.type);
    if (type instanceof Error) {
      return type;
    }

    return {
      id: data.id,
      title: data.title,
      cover: data.cover,
      order: data.order,
      type: data.type,
      status: status,
      countPages: data.countPages,
      completedPages: data.completedPages,
    };
  }

  toSteps(data: any): Step[] | Error {
    if (!Array.isArray(data)) {
      return new Error("Stage isn't a array");
    }

    const steps: Step[] = [];
    for (var i = 0; i < data.length; i++) {
      const step = this.toStep(data[i]);
      if (step instanceof Error) {
        return new Error(`Error reading steps array at index ${i}: ${step}`);
      }

      steps.push(step);
    }

    return steps;
  }

  toStep(data: any): Step | Error {
    if (!data.id) {
      return new Error('Step id not found');
    }

    const status = this.toStepStatus(data.status);
    if (status instanceof Error) {
      return status;
    }

    return {
      id: data.id,
      title: data.title,
      order: data.order,
      status: status,
      module: data.module,
      countPages: data.countPages,
      completedPages: data.completedPages,
    };
  }

  toPages(data: any): Page[] | Error {
    data = data.pages;
    if (!Array.isArray(data)) {
      return new Error("Page isn't a array");
    }

    const pages: Page[] = [];
    for (var i = 0; i < data.length; i++) {
      const page = this.toPage(data[i]);
      if (page instanceof Error) {
        return new Error(`Error reading pages array at index ${i}: ${page}`);
      }

      pages.push(page);
    }

    return pages;
  }

  toPage(data: any): Page | Error {
    if (!data.id) {
      return new Error('Page id not found');
    }

    const status = this.toPageStatus(data.status);
    if (status instanceof Error) {
      return status;
    }

    const type = this.toPageType(data.type);
    if (type instanceof Error) {
      return type;
    }

    return {
      id: data.id,
      code: data.code,
      order: data.order,
      type: data.type,
      status: status,
      widgets: data.widgets,
      hints: data.hints,
    };
  }

  toPageType(data: any): PageType | Error {
    if (data == 'content') {
      return 'content';
    }

    if (data == 'activity') {
      return 'activity';
    }

    return new Error('Invalid page type: ' + data);
  }

  toPageStatus(data: any): PageStatus | Error {
    if (data == 'activated') {
      return 'activated';
    }

    return new Error('Invalid page status:' + data);
  }

  toStageType(data: any): StageType | Error {
    if (data == 'CONTENTS') {
      return 'contents';
    }

    if (data == 'ACTIVITIES') {
      return 'activities';
    }

    if (data == 'STEPS') {
      return 'steps';
    }

    return new Error('Invalid stage type: ' + data);
  }

  toUnitStatus(data: any): UnitStatus | Error {
    if (data == 'activated') {
      return 'activated';
    }

    return new Error('Invalid unit status: ' + data);
  }

  toStageStatus(data: any): StageStatus | Error {
    if (data == 'activated') {
      return 'activated';
    }

    if (data == 'completed') {
      return 'completed';
    }

    return new Error('Invalid stage status: ' + data);
  }

  toStepStatus(data: any): StepStatus | Error {
    if (data == 'activated') {
      return 'activated';
    }

    if (data == 'completed') {
      return 'completed';
    }

    return new Error('Invalid stage status: ' + data);
  }

  toClassFormat(data: any): ClassFormat | Error {
    if (data == 'FREE') {
      return 'free';
    }

    return new Error('Invalid class format: ' + data);
  }

  toCourseStatus(data: any): CourseStatus | Error {
    if (data == 'DRAFT') {
      return 'draft';
    }

    return new Error('Invalid course status: ' + data);
  }

  toCoursesClasses(data: any[]): CourseClass[] | Error {
    if (!Array.isArray(data)) {
      return new Error("Courses classes isn't a array");
    }

    const firstItem: any = data[0];
    const firstItemId = firstItem.id;
    if (!firstItemId) {
      return new Error('The first course class id not found');
    }

    const firstCourse = firstItem.course;
    if (!firstCourse) {
      return new Error(
        'The first course not found inside the first course class'
      );
    }

    const courseFormat = this.toClassFormat(firstItem.format);
    if (courseFormat instanceof Error) {
      return courseFormat;
    }

    const courseStatus = this.toCourseStatus(firstCourse.status);
    if (courseStatus instanceof Error) {
      return courseStatus;
    }

    if (data.length > 1) {
      return new Error(
        'Courses classes with more than 1 are not supported yet, because have to select one course class id first...'
      );
    }

    let value: CourseClass = {
      id: firstItemId,
      name: firstItem.name,
      format: courseFormat,
      course: {
        id: firstCourse.id,
        title: firstCourse.title,
        status: courseStatus,
      },
    };

    return [value];
  }
}
