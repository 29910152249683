import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, input, model } from '@angular/core';
import { MenuItem } from '../menu.interface';
import { MenuBarState } from '../menu-bar.state';
import { AbstractMenuComponent } from '../abstract.menu.component';

@Component({
  selector: 'go-general-menu',
  templateUrl: './general-menu.component.html',
  styleUrl: './general-menu.component.scss',
})
export class GeneralMenuComponent implements AbstractMenuComponent, AfterViewInit, OnInit, OnChanges {

  readonly goComponentId: string = 'general-menu';

  @Input() menuItens: MenuItem[] = [];
  menuBarState = model<MenuBarState>();

  @ViewChild('aside') aside: any;
  @ViewChild('a') link: any;

  @Input() report: boolean = true;
  @Input() expanded: boolean = true;

  @Input() width: string = '100%';

  logo: string = 'go2dev-logo-01.png';
  menuClasses: string = 'go-menu go-background-color-purple50 go-border-color-gray300';

  ngOnInit(): void {
    this.applyStyleClassesMenu();
  }

  ngAfterViewInit(): void {
    var el: ElementRef<HTMLBaseElement> = this.aside;

    if (this.width != '100%') {
      el.nativeElement.style.width = this.width;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['width']) {
    }
  }

  //? Composição de classes do item pelo status
  getItemClasses(status: string): string {
    if (status == 'active') {
      return 'menu-item menu-item-active go-background-color-purple200';
    } else {
      return 'menu-item';
    }
  }

  //? Composição de classes de texto pelo status
  getTextClasses(status: string): string {
    if (status == 'active') {
      return 'text-poppins-subtitle-semibold go-text-color-purple700';
    } else {
      return 'text-poppins-subtitle-semibold go-text-color-gray800';
    }
  }

  //? Aplica a alteração de status do menu
  toggleMenu(event: Event) {
    event.preventDefault();

    this.menuBarState.set(this.menuBarState() == 'minimized' ? 'normal' : 'minimized');

    this.applyStyleClassesMenu();
  }

  //? Função de ação de report
  reportAction(event: Event) {
    event.preventDefault();

    alert('Botão de report');
  }

  //? PRIVATE FUNCTIONS
  private applyStyleClassesMenu() {
    if (this.menuBarState() == 'minimized') {
      this.menuBarState.set('minimized');
      this.logo = 'go2dev-logo-02.svg';
      this.menuClasses = 'go-menu go-menu-minimized go-background-color-purple50 go-border-color-gray300';
    } else {
      this.menuBarState.set('normal');
      this.logo = 'go2dev-logo-01.png';
      this.menuClasses = 'go-menu go-background-color-purple50 go-border-color-gray300';
    }
  }
}
