import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractModalComponent } from '../abstract.modal.component';

@Component({
  selector: 'go-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrl: './general-modal.component.scss',
})
export class GeneralModalComponent implements AbstractModalComponent {
  readonly goComponentId: string = 'general-modal';

  @Output() itemClick = new EventEmitter();

  openModal() {
    console.log('open');
  }
  closeModal() {
    console.log('close');
  }

  //? Função de botão da modal
  actionButton() {
    this.itemClick.emit();
  }
}
