<aside #aside [class]="menuClasses">
  <a
    class="go-menu-toggle-icon"
    href="#"
    title="Menu"
    (click)="toggleMenu($event)"
  >
    <go-icon icon="menu-burger" />
  </a>

  <div class="go-menu-logo">
    <img
      src="assets/images/go2dev/brand/{{ logo }}"
      alt="Go2dev Escola Digital"
      title="Go2dev Escola Digital"
    />
  </div>

  <ul class="go-menu-list">
    <ng-container *ngFor="let item of menuItens">
      <li *ngIf="item.status !== 'hidden'">
        <a
          [class]="getItemClasses(item.status)"
          href="{{ item.link }}"
          title="{{ item.text }}"
          (click)="(item.action ? item.action : null)"
        >
          <div class="icon">
            <go-icon
              [icon]="item.icon"
              [pathColor]="item.status == 'active' ? 'purple700' : 'gray800'"
            />
          </div>
          <span [class]="getTextClasses(item.status)">{{ item.text }}</span>
        </a>
      </li>
    </ng-container>
  </ul>

  <a
    *ngIf="report"
    class="go-menu-report"
    href="#"
    title="Reportar Problema"
    (click)="reportAction($event)"
  >
    <div class="icon"><go-icon icon="flag" pathColor="gray950" /></div>
    <span class="text-poppins-subtitle-semibold go-text-color-gray950"
      >Reportar Problema</span
    >
  </a>
</aside>
