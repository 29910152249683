import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ThemeColorsTypes } from '../../core/models/enums/colors.enum';
import { WidgetOptions } from '../../challenges/options.interface';

@Component({
  selector: 'go-combination-block',
  templateUrl: './combination-block.component.html',
  styleUrl: './combination-block.component.scss',
})
export class CombinationBlockComponent
  implements AfterViewInit, OnChanges, OnInit
{
  readonly goComponentId: string = 'go-combination-block';

  @Input() item!: WidgetOptions;
  @Input() secondItem?: WidgetOptions;
  @Input() side: 'left' | 'right' = 'left';
  @Input() type: 'double' | 'single' = 'single';
  @Input() leftSelect: string = '';
  @Input() rightSelect: string = '';

  @Output() itemClick = new EventEmitter<{
    item: WidgetOptions;
    side: 'left' | 'right';
  }>();

  boxClasses!: string;
  sideClasses!: string;
  textClasses!: string;
  iconColor!: ThemeColorsTypes;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.width = '100%';
  }

  ngAfterViewInit(): void {
    this.applyElementClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyElementClasses();
  }

  applyElementClasses() {
    let selected = this.side == 'left' ? this.leftSelect : this.rightSelect;

    if (this.type == 'double') {
      this.boxClasses = `double-box go-background-color-whiteColor go-border-color-purple500`;
      this.sideClasses = `b go-background-color-whiteColor go-border-color-purple500`;
      this.textClasses = `text-poppins-subtitle-bold go-text-color-purple700`;
      this.iconColor = 'purple700';
    } else {
      if (this.item.id === selected) {
        this.boxClasses = `single-box ${this.side} go-background-color-whiteColor go-border-color-purple500`;
        this.sideClasses = `b go-background-color-whiteColor go-border-color-purple500`;
        this.textClasses = `text-poppins-subtitle-bold go-text-color-purple700`;
        this.iconColor = 'purple700';
      } else {
        this.boxClasses = `single-box ${this.side} go-background-color-whiteColor go-border-color-gray300`;
        this.sideClasses = `b go-background-color-whiteColor go-border-color-gray300`;
        this.textClasses = `text-poppins-subtitle-semibold go-text-color-gray950`;
        this.iconColor = 'gray950';
      }
    }
  }

  //? Função de click
  onItemClick() {
    this.itemClick.emit({ side: this.side, item: this.item });
  }

  //? Função para verificar se o texto existe para decidir se mostra o ícone de áudio
  emptyStr(str?: string | null): boolean {
    return str === null || str === undefined || str.trim() === '';
  }
}
