<div class="go-simple-progress">
  <div class="progress-bar go-background-color-purple200">
    <div
      class="progress-inner-bar go-background-color-{{ color }}"
      [style.width.%]="percent"
    ></div>
  </div>
  <div *ngIf="showPercent" class="progress-percent-info">
    <span class="text-poppins-caption-bold go-text-color-gray700">
      {{ percent }}%
    </span>
  </div>
</div>
