<div class="widget-select">
  <ng-container *ngFor="let item of options; let i = index">
    <label
      class="widget-select-item"
      [ngClass]="{ active: isActive(item.id) }"
      [style.backgroundColor]="getBackgroundColor(item.id)"
      [style.borderColor]="getBorderColor(item.id)"
      (click)="toggleSelection(item.id)"
    >
      <div
        class="mark-icon"
        [style.backgroundColor]="getIconBackgroundColor(item.id)"
        [style.borderColor]="getIconBorderColor(item.id)"
      >
        <div
          class="mark"
          [style.backgroundColor]="getIconBorderColor(item.id)"
        ></div>
      </div>
      <h3
        class="text text-poppins-body-semibold"
        [style.color]="getTextColor(item.id)"
      >
        {{ item.text }}
      </h3>
    </label>
  </ng-container>
</div>
