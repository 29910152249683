export function Loading(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value;

  descriptor.value = async function (...args: any[]) {
    if (!('isLoading' in this)) {
      throw new Error(
        `isLoading property is not defined in ${this.constructor.name}`
      );
    }

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      await originalMethod.apply(this, args);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    }
  };

  return descriptor;
}
