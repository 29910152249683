export default {
  id: '0443f8ff-7985-4dba-bbe0-2c4f3ab8a313',
  createdAt: '2024-05-28T12:25:37.735Z',
  updatedAt: '2024-06-17T19:57:21.552Z',
  title: 'The alphabet and Spelling',
  order: 2,
  status: 'activated',
  module: {
    id: '9a3ee243-6cd5-4c36-aa85-c4a6addd9fa3',
    createdAt: '2024-05-28T12:25:37.377Z',
    updatedAt: '2024-06-17T19:57:20.995Z',
    title: 'Nível Explorer',
    order: 1,
  },
  pages: [
    {
      id: '4fd201e8-4829-4e94-bdd9-40ff90ff95ae',
      createdAt: '2024-06-17T19:57:21.563Z',
      updatedAt: '2024-06-17T19:57:21.563Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P1',
      order: 1,
      status: 'activated',
      widgets: [
        {
          id: '2e5254d3-30bc-49d4-9c9f-c966eda58b28',
          createdAt: '2024-06-17T19:57:21.563Z',
          updatedAt: '2024-06-17T19:57:21.563Z',
          order: 1,
          type: 'title',
          value: 'LESSON 2',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '319bcc3e-9ae2-43dd-bd12-bce79f240ad7',
          createdAt: '2024-06-17T19:57:21.563Z',
          updatedAt: '2024-06-17T19:57:21.563Z',
          order: 2,
          type: 'subtitle',
          value: 'The Alphabet and Spelling',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0b85f21a-34ae-4326-a41a-2d2339d05a75',
          createdAt: '2024-06-17T19:57:21.563Z',
          updatedAt: '2024-06-17T19:57:21.563Z',
          order: 3,
          type: 'longtext',
          value: 'Por que aprender a soletrar em inglês é importante?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a008cf1b-35ff-40d6-b7d2-d388680e3fc5',
          createdAt: '2024-06-17T19:57:21.563Z',
          updatedAt: '2024-06-17T19:57:21.563Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-ff00b244-e929-4968-bd83-109ae886b7e4.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '4732c6b1-6faf-4293-a71b-7f70ac93a052',
          createdAt: '2024-06-17T19:57:21.563Z',
          updatedAt: '2024-06-17T19:57:21.563Z',
          order: 5,
          type: 'longtext',
          value:
            'Soletrar é uma habilidade que nos auxilia na comunicação, principalmente de nomes próprios já que a pronúncia e escrita de uma língua para outra podem ser muito diferentes, dificultando assim a comunicação entre as pessoas. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'bc4ed398-1f3d-4bae-b8d6-de00ea9b1356',
      createdAt: '2024-06-17T19:57:21.575Z',
      updatedAt: '2024-06-17T19:57:21.575Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P2',
      order: 2,
      status: 'activated',
      widgets: [
        {
          id: 'd122995c-9169-4696-a19e-afb6d2a6d192',
          createdAt: '2024-06-17T19:57:21.575Z',
          updatedAt: '2024-06-17T19:57:21.575Z',
          order: 1,
          type: 'longtext',
          value:
            'É comum em situações como no exemplo abaixo, as pessoas pedirem o seu nome para preencher uma ficha ou localizar o seu nome no sistema de cadastro.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '6b0e9b42-1117-4ffe-a43a-a5d98b8fb13e',
          createdAt: '2024-06-17T19:57:21.575Z',
          updatedAt: '2024-06-17T19:57:21.575Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-ff9b9799-1207-4450-b186-398f10b17f15.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9a781fad-bb2f-4445-a1ca-687f197c5de7',
          createdAt: '2024-06-17T19:57:21.575Z',
          updatedAt: '2024-06-17T19:57:21.575Z',
          order: 3,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-2-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-6dcf378e-0d1d-42fa-b6b0-e96aa69e39ea.mpeg',
          feedback: false,
          label: 'Ouça o diálogo.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd4fb1f48-8cc8-4dc4-9697-50fcd573bef0',
          createdAt: '2024-06-17T19:57:21.575Z',
          updatedAt: '2024-06-17T19:57:21.575Z',
          order: 4,
          type: 'longtext',
          value:
            'Que outras situações a gente precisaria soletrar o nosso nome?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'a7956dbb-51ea-412d-af56-b3857f221543',
      createdAt: '2024-06-17T19:57:21.586Z',
      updatedAt: '2024-06-17T19:57:21.586Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P3',
      order: 3,
      status: 'activated',
      widgets: [
        {
          id: 'dd7b5483-b6b3-427d-bddd-02356bc302b8',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          order: 1,
          type: 'longtext',
          value:
            'Imagine que você esteja jogando Minecraft com alguém que está aprendendo a jogar esse jogo. E você para ajudar compartilha alguns itens do seu *inventory*.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '293989c0-bd3d-4a2b-88f8-cda962357c45',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-f194b154-5540-4fe0-a4b4-85bcf728554d.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '4dec290b-6b5b-42f2-8530-23f9467fad9d',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          order: 3,
          type: 'longtext',
          value:
            ' Ele não se lembra como esse item se parece para localizar no inventário dele e precisa procurar pela palavra, mas não sabe como a escreve.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '42d8100a-2571-4cca-8e4c-41d06f10895d',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          order: 4,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-3-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-f6589cd2-4e03-45cb-9f57-1d4e7b40d0fc.mpeg',
          feedback: false,
          label: 'Ouça o diálogo.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0728d2f2-cdfe-427f-889d-58e347041e89',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          order: 5,
          type: 'longtext',
          value:
            'Em muitas situações do cotidiano, perguntamos como se soletra algo. Mesmo na nossa língua, não é mesmo? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'a3b80200-37c4-4c86-9c02-a85bbb238a2e',
          createdAt: '2024-06-17T19:57:21.586Z',
          updatedAt: '2024-06-17T19:57:21.586Z',
          key: 'inventory',
          title: 'inventory',
          subtitle: 'inventário',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-dec12988-7e86-4117-a8a2-a0defae65724.mpeg',
          example: 'I can’t find it in my inventory.',
          exampleTranslate: ' Não consigo achar no meu inventário.',
          description:
            'Inventory é um menu que aparece que o jogador usa para administrar os itens em que carrega',
        },
      ],
    },
    {
      id: 'b1976b69-d536-4b6e-a58e-effd0cc49045',
      createdAt: '2024-06-17T19:57:21.600Z',
      updatedAt: '2024-06-17T19:57:21.600Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P4',
      order: 4,
      status: 'activated',
      widgets: [
        {
          id: '4178687b-ec01-4f55-9c4c-baba0a81e3db',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 1,
          type: 'longtext',
          value:
            'Que tal tentar escrever alguns nomes bem comuns em inglês para ver se você consegue escrever as letras corretamente.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '13ac7a7d-8ea5-4d34-b25f-1d7cc427de3b',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 2,
          type: 'enunciated',
          value: 'Escreva os nomes. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '87abb4a9-aaad-4f06-9cf3-ed1944335006',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 3,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-2-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-aa59f70e-0919-40c4-8e4d-bd6fe033324d.mpeg',
          feedback: false,
          label: 'a) Ouça o nome.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c27563cd-0de2-4923-951f-15a11aaf6ba1',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 4,
          type: 'textbox',
          value: '1',
          feedback: true,
          label: '',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '19aa176f-0c77-489e-9ac7-936cfa4ba287',
              createdAt: '2024-06-17T19:57:21.600Z',
              updatedAt: '2024-06-17T19:57:21.600Z',
              order: 0,
              text: null,
              textRelated: null,
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
        },
        {
          id: '8b4398ab-334a-4568-ac9d-fa671b1df32f',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 5,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-4-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-b5784c44-d92d-4ea2-a046-79bca8882696.mpeg',
          feedback: false,
          label: 'b) Ouça o nome.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '76755407-7e49-4b2d-b58a-995dd018182a',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 6,
          type: 'textbox',
          value: '1',
          feedback: true,
          label: '',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '3b213415-825b-4897-8915-7de94d9a23db',
              createdAt: '2024-06-17T19:57:21.600Z',
              updatedAt: '2024-06-17T19:57:21.600Z',
              order: 0,
              text: null,
              textRelated: null,
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
        },
        {
          id: '8fe9c8be-f674-4638-b7c6-e39993e9f43e',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 7,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-6-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-985bc6b7-78c1-4fbc-8a93-f743465de88f.mpeg',
          feedback: false,
          label: 'c) Ouça o nome.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '7e1330bf-69dc-443a-8413-22c8e618861c',
          createdAt: '2024-06-17T19:57:21.600Z',
          updatedAt: '2024-06-17T19:57:21.600Z',
          order: 8,
          type: 'textbox',
          value: '1',
          feedback: true,
          label: '',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '43aab2eb-5960-4c6b-86b2-60d80134415f',
              createdAt: '2024-06-17T19:57:21.600Z',
              updatedAt: '2024-06-17T19:57:21.600Z',
              order: 0,
              text: null,
              textRelated: null,
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'de4391db-fb58-4730-950a-46048b226dbd',
      createdAt: '2024-06-17T19:57:21.615Z',
      updatedAt: '2024-06-17T19:57:21.615Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P5',
      order: 5,
      status: 'activated',
      widgets: [
        {
          id: '4cfde6b4-e761-442e-852e-d91d90823b0b',
          createdAt: '2024-06-17T19:57:21.615Z',
          updatedAt: '2024-06-17T19:57:21.615Z',
          order: 1,
          type: 'longtext',
          value:
            'Você sabia que podemos agrupar as letras do alfabeto de acordo com os sons finais quando soletramos?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f6117c74-4290-4e54-8e8a-07617a74189f',
          createdAt: '2024-06-17T19:57:21.615Z',
          updatedAt: '2024-06-17T19:57:21.615Z',
          order: 2,
          type: 'enunciated',
          value: 'Observe a tabela abaixo:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0d88add7-0bee-4675-a218-1ba56bde10e3',
          createdAt: '2024-06-17T19:57:21.615Z',
          updatedAt: '2024-06-17T19:57:21.615Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-739a0550-a221-4e7f-bb1f-b1a06f00e1f2.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '363ad9b3-6900-4b4c-be10-ba6bd0f0603c',
      createdAt: '2024-06-17T19:57:21.626Z',
      updatedAt: '2024-06-17T19:57:21.626Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P6',
      order: 6,
      status: 'activated',
      widgets: [
        {
          id: '52df1f03-eaf5-4d0b-bf32-8dd2784d0638',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 1,
          type: 'subtitle',
          value: 'Saying the letters',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '8b5fc186-388f-41a3-bded-31dabf4048b1',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 2,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-6-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-85f07287-1aeb-40c1-ac98-9f0bbc61a039.mpeg',
          feedback: false,
          label: 'Ouça e repita.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b55edc5b-11fb-4230-8730-1667edd1090d',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-9f9bfdfa-aa51-4981-9696-42ff5ead8984.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '6eca55b0-d012-40c7-9980-be5dc5f2634f',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 4,
          type: 'longtext',
          value: 'O que os símbolos acima representam?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '010cceb1-263f-49a9-a8b4-8af332f5e825',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 5,
          type: 'enunciated',
          value: 'Selecione a opção correta. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1505dd5f-ea55-4c08-8a6b-259303b0c5e8',
          createdAt: '2024-06-17T19:57:21.626Z',
          updatedAt: '2024-06-17T19:57:21.626Z',
          order: 6,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage:
            'cada símbolo entre /   / são chamados de símbolos fonéticos.',
          wrongMessage:
            'cada símbolo entre /   / são chamados de símbolos fonéticos e representam os sons das letras.',
          template: 'default',
          options: [
            {
              id: '76e04dff-0e4f-4008-9241-8ab175589641',
              createdAt: '2024-06-17T19:57:21.626Z',
              updatedAt: '2024-06-17T19:57:21.626Z',
              order: 1,
              text: 'a escrita',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: false,
            },
            {
              id: 'fbe905dc-8692-4ad1-aa21-cd9a32ff07af',
              createdAt: '2024-06-17T19:57:21.626Z',
              updatedAt: '2024-06-17T19:57:21.626Z',
              order: 2,
              text: 'os sons',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '476796e7-9c75-409b-b626-bdafc860756a',
      createdAt: '2024-06-17T19:57:21.641Z',
      updatedAt: '2024-06-17T19:57:21.641Z',
      type: 'content',
      code: 'WU_LM_N1_L2_P7',
      order: 7,
      status: 'activated',
      widgets: [
        {
          id: 'a1b97495-7ae9-439b-8d27-4151720a39ac',
          createdAt: '2024-06-17T19:57:21.641Z',
          updatedAt: '2024-06-17T19:57:21.641Z',
          order: 1,
          type: 'longtext',
          value: 'Agora vamos praticar o alfabeto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '52b02852-33b3-4689-bf89-f313b6e0349f',
          createdAt: '2024-06-17T19:57:21.641Z',
          updatedAt: '2024-06-17T19:57:21.641Z',
          order: 2,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-1-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-cbaf3b8a-c84e-493d-9647-12d1e48b3610.mpeg',
          feedback: false,
          label: 'Ouça os sons e repita.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9689e19e-ac55-4c00-9fe4-bc7d86023265',
          createdAt: '2024-06-17T19:57:21.641Z',
          updatedAt: '2024-06-17T19:57:21.641Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-714cead6-b85f-497b-9c8c-5a97074dc415.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'a7246bd1-c397-4974-a2db-36d8ab392128',
      createdAt: '2024-06-17T19:57:21.653Z',
      updatedAt: '2024-06-17T19:57:21.653Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.1',
      order: 8,
      status: 'activated',
      widgets: [
        {
          id: 'f5808524-fbeb-4fe2-b61f-271a83c0a212',
          createdAt: '2024-06-17T19:57:21.653Z',
          updatedAt: '2024-06-17T19:57:21.653Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '99240dda-2e4b-4e71-a973-ccadb1edcf08',
          createdAt: '2024-06-17T19:57:21.653Z',
          updatedAt: '2024-06-17T19:57:21.653Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-ab72e782-c755-47e0-a8d1-1b76ec9e8e6c.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'a96e5334-111e-4fb3-b9d4-59049afa3274',
          createdAt: '2024-06-17T19:57:21.653Z',
          updatedAt: '2024-06-17T19:57:21.653Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '7a5b7559-c140-48aa-b0b7-d6c29ee651f4',
              createdAt: '2024-06-17T19:57:21.653Z',
              updatedAt: '2024-06-17T19:57:21.653Z',
              order: 0,
              text: 'Martin',
              textRelated: 'm;a;r;t;i;n',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '8fcd3def-ad74-4645-a1ea-c17162f89d3c',
      createdAt: '2024-06-17T19:57:21.667Z',
      updatedAt: '2024-06-17T19:57:21.667Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.2',
      order: 9,
      status: 'activated',
      widgets: [
        {
          id: '50a2178a-bfdc-44e2-9333-e7b23b0a4b19',
          createdAt: '2024-06-17T19:57:21.667Z',
          updatedAt: '2024-06-17T19:57:21.667Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'f5d05a16-6e0a-4188-b740-c77283a970ba',
          createdAt: '2024-06-17T19:57:21.667Z',
          updatedAt: '2024-06-17T19:57:21.667Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-a99fa6f4-5b55-4dcd-8894-c39825646da4.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '63aa3374-4fcb-4673-bdfa-d97e2f2d7ea5',
          createdAt: '2024-06-17T19:57:21.667Z',
          updatedAt: '2024-06-17T19:57:21.667Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '494072c4-4179-40fe-9261-f5ed4dee0929',
              createdAt: '2024-06-17T19:57:21.667Z',
              updatedAt: '2024-06-17T19:57:21.667Z',
              order: 0,
              text: 'Barbara',
              textRelated: 'b;a;r;b;a;r;a',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '46c71bb6-6b68-4846-a7f9-b3e2e9bb2010',
      createdAt: '2024-06-17T19:57:21.680Z',
      updatedAt: '2024-06-17T19:57:21.680Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.3',
      order: 10,
      status: 'activated',
      widgets: [
        {
          id: 'bb78fabd-b85d-47f0-b864-40ffe5516a57',
          createdAt: '2024-06-17T19:57:21.680Z',
          updatedAt: '2024-06-17T19:57:21.680Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'c0f36923-01c1-4d38-a4b4-d01cfb238812',
          createdAt: '2024-06-17T19:57:21.680Z',
          updatedAt: '2024-06-17T19:57:21.680Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-0eb164a2-53b8-4199-95ba-17e9c8ef77ce.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'adab12e5-bef4-4f0a-8790-91297bb6a279',
          createdAt: '2024-06-17T19:57:21.680Z',
          updatedAt: '2024-06-17T19:57:21.680Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'd64ec513-6b9f-4715-9228-d931471e6f87',
              createdAt: '2024-06-17T19:57:21.680Z',
              updatedAt: '2024-06-17T19:57:21.680Z',
              order: 0,
              text: 'Emily',
              textRelated: 'e;m;i;l;y',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'a2e57c59-a306-4898-afba-a0f1e2b4121b',
      createdAt: '2024-06-17T19:57:21.695Z',
      updatedAt: '2024-06-17T19:57:21.695Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.4',
      order: 11,
      status: 'activated',
      widgets: [
        {
          id: '6f68f03e-eafc-4de3-89ed-d9ba223b0b42',
          createdAt: '2024-06-17T19:57:21.695Z',
          updatedAt: '2024-06-17T19:57:21.695Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '200aba73-87e6-4d5c-972c-9a413b82f998',
          createdAt: '2024-06-17T19:57:21.695Z',
          updatedAt: '2024-06-17T19:57:21.695Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-647c22c1-5feb-46b0-ac26-eb7b82e29e1f.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '4896fa79-ebec-4218-922a-a1f9b8c120c2',
          createdAt: '2024-06-17T19:57:21.695Z',
          updatedAt: '2024-06-17T19:57:21.695Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '97e89184-32f4-46de-8385-122e84260a77',
              createdAt: '2024-06-17T19:57:21.695Z',
              updatedAt: '2024-06-17T19:57:21.695Z',
              order: 0,
              text: 'Elizabeth',
              textRelated: 'e;l;i;z;a;b;e;t;h',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'a2ad7c8a-efa0-4fa2-ad0a-bf77d5ee5693',
      createdAt: '2024-06-17T19:57:21.709Z',
      updatedAt: '2024-06-17T19:57:21.709Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.5',
      order: 12,
      status: 'activated',
      widgets: [
        {
          id: '915e0f40-f52e-42e1-9d46-3990b27a98cf',
          createdAt: '2024-06-17T19:57:21.709Z',
          updatedAt: '2024-06-17T19:57:21.709Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '2071f9a5-1eb3-48ee-8d91-c202e5ce1ed7',
          createdAt: '2024-06-17T19:57:21.709Z',
          updatedAt: '2024-06-17T19:57:21.709Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-0efbfc54-55ef-450e-8392-b44ad969e7a4.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'b3ac6ca6-310a-4b51-8653-01ee5f4b0f55',
          createdAt: '2024-06-17T19:57:21.709Z',
          updatedAt: '2024-06-17T19:57:21.709Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '52d4e710-4c58-43ee-baef-195870b71fed',
              createdAt: '2024-06-17T19:57:21.709Z',
              updatedAt: '2024-06-17T19:57:21.709Z',
              order: 0,
              text: 'Oliver',
              textRelated: 'o;l;i;v;e;r',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '8104eea5-61c7-446f-8581-9274a420ed4c',
      createdAt: '2024-06-17T19:57:21.722Z',
      updatedAt: '2024-06-17T19:57:21.722Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.6',
      order: 13,
      status: 'activated',
      widgets: [
        {
          id: '1fd5fb77-0dce-4c9c-8f04-b0f305779cde',
          createdAt: '2024-06-17T19:57:21.722Z',
          updatedAt: '2024-06-17T19:57:21.722Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'a142822e-8258-427f-96a7-d562ad144a4d',
          createdAt: '2024-06-17T19:57:21.722Z',
          updatedAt: '2024-06-17T19:57:21.722Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-dbc36424-88a8-4e92-901c-742233c099b3.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '0885ae33-30a4-4f50-b606-641641e2b52d',
          createdAt: '2024-06-17T19:57:21.722Z',
          updatedAt: '2024-06-17T19:57:21.722Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '85f214fa-7ad4-44f5-8857-55992e61beed',
              createdAt: '2024-06-17T19:57:21.722Z',
              updatedAt: '2024-06-17T19:57:21.722Z',
              order: 0,
              text: 'Jackson',
              textRelated: 'j;a;c;k;s;o;n',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '5d4e05ab-88f8-4022-81d1-edab6aacf3ba',
      createdAt: '2024-06-17T19:57:21.736Z',
      updatedAt: '2024-06-17T19:57:21.736Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.7',
      order: 14,
      status: 'activated',
      widgets: [
        {
          id: '6266b4e4-2d59-4078-adee-b8fba9832f59',
          createdAt: '2024-06-17T19:57:21.736Z',
          updatedAt: '2024-06-17T19:57:21.736Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'c955e47f-410c-4201-a316-4b99b91d94c8',
          createdAt: '2024-06-17T19:57:21.736Z',
          updatedAt: '2024-06-17T19:57:21.736Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-42440f04-c4f8-41f3-a607-7bbeb0e729de.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '13f11267-e31c-4927-b118-0f042ebded3f',
          createdAt: '2024-06-17T19:57:21.736Z',
          updatedAt: '2024-06-17T19:57:21.736Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '261b639c-c28a-4b35-a079-5e31e0a61ada',
              createdAt: '2024-06-17T19:57:21.736Z',
              updatedAt: '2024-06-17T19:57:21.736Z',
              order: 0,
              text: 'Henry',
              textRelated: 'h;e;n;r;y',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '649ee4a4-e07d-4a51-9caf-929710506422',
      createdAt: '2024-06-17T19:57:21.752Z',
      updatedAt: '2024-06-17T19:57:21.752Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.8',
      order: 15,
      status: 'activated',
      widgets: [
        {
          id: 'd33a2599-4781-4036-bb09-de67c0442f2e',
          createdAt: '2024-06-17T19:57:21.752Z',
          updatedAt: '2024-06-17T19:57:21.752Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'e43da1cd-d5ab-43c8-a6bf-d0e9ba2f4027',
          createdAt: '2024-06-17T19:57:21.752Z',
          updatedAt: '2024-06-17T19:57:21.752Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-f704fe19-dc3a-4fee-abeb-05de1a4c7f36.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '146dcf52-4d3e-4c0f-9ce3-70936246e4f3',
          createdAt: '2024-06-17T19:57:21.752Z',
          updatedAt: '2024-06-17T19:57:21.752Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '104eb366-8dfe-49d0-9c17-75ed89602c40',
              createdAt: '2024-06-17T19:57:21.752Z',
              updatedAt: '2024-06-17T19:57:21.752Z',
              order: 0,
              text: 'Kate',
              textRelated: 'k;a;t;e',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '10633993-d434-4fa9-83e4-3eefd1ee9c99',
      createdAt: '2024-06-17T19:57:21.765Z',
      updatedAt: '2024-06-17T19:57:21.765Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.9',
      order: 16,
      status: 'activated',
      widgets: [
        {
          id: 'a3a23e30-9295-4756-ac08-9dfe2cbc98ff',
          createdAt: '2024-06-17T19:57:21.765Z',
          updatedAt: '2024-06-17T19:57:21.765Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '5b9587e4-cba3-46df-865b-6729beafd3ec',
          createdAt: '2024-06-17T19:57:21.765Z',
          updatedAt: '2024-06-17T19:57:21.765Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-d186ae93-e172-41be-8d95-15761f9bed0c.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '46d88ea0-171b-46f7-bd3f-3093c90cc1d7',
          createdAt: '2024-06-17T19:57:21.765Z',
          updatedAt: '2024-06-17T19:57:21.765Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '0d7a4a25-d1b5-4ea7-aeda-652a5573f903',
              createdAt: '2024-06-17T19:57:21.765Z',
              updatedAt: '2024-06-17T19:57:21.765Z',
              order: 0,
              text: 'Maria',
              textRelated: 'm;a;r;i;a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'd1f02cca-04ff-444f-a0ce-9e5e5ce7e91e',
      createdAt: '2024-06-17T19:57:21.778Z',
      updatedAt: '2024-06-17T19:57:21.778Z',
      type: 'activity',
      code: 'WU_LM_N1_L2_P8_P17.10',
      order: 17,
      status: 'activated',
      widgets: [
        {
          id: 'de5ca546-8b65-4401-a50e-ff1810c41dae',
          createdAt: '2024-06-17T19:57:21.778Z',
          updatedAt: '2024-06-17T19:57:21.778Z',
          order: 1,
          type: 'title',
          value: 'Ouça e ordene as letras.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '7362f847-8272-4aa8-80ba-636d222ad9b5',
          createdAt: '2024-06-17T19:57:21.778Z',
          updatedAt: '2024-06-17T19:57:21.778Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-51ff1c2e-0008-4aea-9aeb-93514868c3a7.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'e1f73c75-2a47-41b1-817e-c9e57921c692',
          createdAt: '2024-06-17T19:57:21.778Z',
          updatedAt: '2024-06-17T19:57:21.778Z',
          order: 3,
          type: 'blocks',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '65b75a75-808f-4d2a-944f-e3df466b40a9',
              createdAt: '2024-06-17T19:57:21.778Z',
              updatedAt: '2024-06-17T19:57:21.778Z',
              order: 0,
              text: 'Amy',
              textRelated: 'a;m;y',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'd7c35136-fed3-40ca-b9f9-5c2f4b6cdab6',
      createdAt: '2024-06-17T19:57:21.792Z',
      updatedAt: '2024-06-17T19:57:21.792Z',
      type: 'content',
      code: 'WU_LM_N1_L2_end',
      order: 18,
      status: 'activated',
      widgets: [
        {
          id: '1354bb44-eeef-482b-863d-c3ff9140f4df',
          createdAt: '2024-06-17T19:57:21.792Z',
          updatedAt: '2024-06-17T19:57:21.792Z',
          order: 1,
          type: 'title',
          value: 'YOU DID IT!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '84fa3d93-ed09-4f2f-a6d2-fe12a1838edb',
          createdAt: '2024-06-17T19:57:21.792Z',
          updatedAt: '2024-06-17T19:57:21.792Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0443f8ff-7985-4dba-bbe0-2c4f3ab8a313-3ecf6191-b0f0-432e-9e92-749d4e9bc890.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '7bf18361-a06a-4267-adc0-196f7ffb6118',
          createdAt: '2024-06-17T19:57:21.792Z',
          updatedAt: '2024-06-17T19:57:21.792Z',
          order: 3,
          type: 'subtitle',
          value: 'Parabéns! Você concluiu a LIÇÃO 2.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1321c02d-887e-41d7-ba30-4b9303f0acc1',
          createdAt: '2024-06-17T19:57:21.792Z',
          updatedAt: '2024-06-17T19:57:21.792Z',
          order: 4,
          type: 'longtext',
          value: 'Clique em START para iniciar a próxima lição.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
  ],
  countPages: 18,
  completedPages: 0,
};
