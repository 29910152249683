export default {
  id: 'dfab01be-a13c-49e0-beb8-02d4bdea962f',
  order: 6,
  title: 'Closing',
  cover: null,
  countPages: 3,
  completedPages: 0,
  type: 'contents',
  pages: [
    {
      id: '51ee1855-ff20-4c13-a79a-caae23cc5ff5',
      type: 'content',
      code: 'WU_CLOSING_P1',
      order: 1,
      widgets: [
        {
          id: '90ecd0bd-7aad-4d64-a1b9-7e9ad3d0f214',
          order: 1,
          type: 'title',
          value: 'WELL DONE! ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'b66bbbde-b64f-4edd-a234-0b78c7fed9b3',
          order: 2,
          type: 'enunciated',
          value: 'Você finalizou as atividades da Unidade Welcome!',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '4478dd58-5c19-4601-80b9-43fcf9369571',
          order: 3,
          type: 'longtext',
          value: 'Antes de iniciar a unidade 1, faça uma autoavaliação.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1d2535c6-8b12-4f00-8621-4ca7bc339102',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-dfab01be-a13c-49e0-beb8-02d4bdea962f-2c0cba56-10f8-4dff-95f5-9f863f9b26bf.lottie',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'dc0bb680-e9d4-491d-9af8-21f89bc1fd31',
          order: 5,
          type: 'spacer',
          value: '30',
          template: 'spacer',
        },
        {
          id: '673ef7a2-3876-45d8-802f-9288ddf9e0a6',
          order: 6,
          type: 'longtext',
          value: 'Clique em <b>SELF-ASSESSMENT</b> para continuar.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '24a095b6-0841-413f-8085-8ba19d5ac6ff',
      type: 'content',
      code: 'WU_CLOSING_P2',
      order: 2,
      widgets: [
        {
          id: '8e749380-4b15-41e2-865c-fd1e0e2d9653',
          order: 1,
          type: 'title',
          value: 'SELF-ASSESSMENT',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '315dff94-65a1-4952-bd39-e7814e6f61e9',
          order: 2,
          type: 'enunciated',
          value: 'Clique em todas as opções que forem verdadeiras para você.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'ab67e6dc-52eb-422b-9b1a-52bdcca3c9f7',
          order: 3,
          type: 'longtext',
          value: 'Eu consigo:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1427f8a7-760f-4dfe-b479-2fc5acb91c68',
          order: 4,
          type: 'checklist',
          feedback: false,
          options: [
            {
              id: '7d59d52c-9102-4d3a-b366-5413c9eabb46',
              order: 1,
              text: 'compreender a importância que tem aprender a língua inglesa.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '2f767331-a2c2-4196-9640-d09588e6045a',
              order: 2,
              text: 'perceber a presença da língua inglesa no nosso cotidiano.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '480e2c41-def1-4b05-a024-3afbf3d1feae',
              order: 3,
              text: 'identificar as palavras que são similares entre a língua inglesa e portuguesa.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'e26cdd76-ac21-491c-83c7-236daa5df2d5',
              order: 4,
              text: 'distinguir o que são palavras cognatas, falso cognatos e estrangeirismo.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'b85e0ae8-03e6-496f-868d-57815c416797',
              order: 5,
              text: 'reconhecer algumas expressões e frases básicas.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '28bc6e51-da40-4c6f-bc9e-ade3e673c0a6',
              order: 6,
              text: 'reconhecer alguns dos vocabulários básicos.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'd6cb52bc-fb8a-4e77-8aa2-4f219b32bd78',
              order: 7,
              text: 'usar um dicionário impresso ou digital.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '7790ec86-2c9d-4ed3-a246-5612ea3f9171',
              order: 8,
              text: 'soletrar o alfabeto em inglês. ',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '3f229a63-2884-4052-93c2-ddee60e1595d',
      type: 'content',
      code: 'WU_CLOSING_end',
      order: 3,
      widgets: [
        {
          id: '4c00ed0e-50e4-4d04-98db-9ff19714da62',
          order: 1,
          type: 'title',
          value: 'CONGRATULATIONS! ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1e9cc6cb-f342-45f3-a0b9-63bbd4a93013',
          order: 2,
          type: 'subtitle',
          value: 'Você concluiu  a unidade',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'ecc7250a-afac-4349-99ba-5eaee0a7f41d',
          order: 3,
          type: 'subtitle',
          value: 'WELCOME ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0107bc29-0d88-4867-b0fe-a3e09be53036',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-dfab01be-a13c-49e0-beb8-02d4bdea962f-14ad44ac-57e0-465d-90fb-d3619cc6f0c7.lottie',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '337fe1f1-41e8-4457-badc-18bc5553a66e',
          order: 5,
          type: 'longtext',
          value: 'Clique em  <b>GO TO THE NEXT UNIT</b> para continuar.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
  ],
};
