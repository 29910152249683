import { Component } from '@angular/core';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'go-login-button',
  templateUrl: './login-button.component.html',
  styleUrl: './login-button.component.scss',
})
export class LoginButtonComponent extends GeneralButtonComponent {

  override readonly goComponentId: string = 'login-button';

}
