import { Component, OnInit, effect } from '@angular/core';
import { LoginPageData, LoginPageModule, UserProfile } from "student-lib";
import { ButtonsModule } from "go-components";
import { InputsModule } from "go-components";
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { AlertModule } from 'student-lib';
import { AbstractPageComponent } from '../abstract-page.component';
import { DevToolsEventsPage } from '../misc/dev-tools/dev-tools.events.page';
import { DevToolsModule } from '../misc/dev-tools/dev-tools.module';
import { DevToolsService } from '../misc/dev-tools/dev-tools.service';
import { LoggedUser } from 'student-lib';
import { ApiService } from '../../api/api.service';
import { childRoutes } from '../../core/routes/child-routes';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [NgIf, LoginPageModule, ButtonsModule, InputsModule, DevToolsModule, AlertModule]
})
export class LoginComponent extends AbstractPageComponent<void> implements OnInit {

  devCount = 0;
  loggedUser?: LoggedUser;

  constructor(private dev: DevToolsService, private router: Router, private authApi: AuthApiService, private api: ApiService) {
    super();

    effect(() => {
      const user = this.authApi.getLoggedUser();
      this.loggedUser = user ? user : undefined;
    })
  }

  ngOnInit(): void {
    if (this.router.url == `/${childRoutes.logoff}`) {
      this.doLogoff();
    }
  }

  doLogoff(): void {
    this.showAlert('Fazendo logoff...');
    this.authApi.doLogout().then(v => {
      this.router.navigateByUrl(childRoutes.login).catch(error => {
        this.showAlert('Falha ao fazer redirecionamento para login depois do logoff: ' + error);
      });
    });
  }


  onDevtoolsEventPage(evt: DevToolsEventsPage) {
    if (evt.event == 'request-fake-login') {
      if (!evt.loginProfile) {
        this.showAlert('Profile não informado');
        return;
      }

      this.doLogin(this.toFakeLoginData(evt.loginProfile));
      return;
    }

    if (evt.event == 'on' || evt.event == 'off') {
      this.devCount = 0;
    }
  }

  private toFakeLoginData(userProfile: UserProfile): LoginPageData {
    let email, password;
    if (this.api.isFakeUrl()) {
      email = userProfile;
      password = '';
    } else if (this.api.getEnvType() == 'test') {
      const userEmail = userProfile == 'teacher' ? 'teacher' : userProfile == 'student' ? 'student' : new Error('Profile não suportado: ' + userProfile);

      email = userEmail + '@go2dev.cloud';
      password = '12345678';
    } else {
      throw `Login fake não suportado com essa configuração: userProfile=${userProfile}; env:${this.api.getEnvType()}`;
    }

    return {
      event: 'login-request',
      email: email,
      password: password,
      type: 'basic'
    }
  }

  onLoginEventPage(data: LoginPageData): Promise<boolean> {
    switch (data.event) {
      case 'home-request': {
        if (!this.loggedUser) {
          this.showAlert('Usuário não autenticado.');
          
          return Promise.resolve(false);
        }

        return this.navigateToHome(this.loggedUser);
      }        
      case 'login-request':
        this.doLogin(data);
        break;
      case 'logoff-request':
        this.authApi.doLogout().then(() => {
          this.loggedUser = undefined;
          this.clearAlert();
        });
        break;
      case 'forgot-password-request':
        this.forgotPassword(data);
        break;
      case 'brand-click':
        this.toggleDevTools();
        break;
      default:
        break;
    }

    return Promise.resolve(true);
  }

  toggleDevTools() {
    if (++this.devCount >= 3) {
      this.devCount = 0;
      this.dev.toggleActive(true);
    }
  }

  private successLogin(user: LoggedUser): Promise<boolean> {
    this.loggedUser = user;
    this.clearAlert();

    return this.navigateToHome(user);
  }

  private navigateToHome(user: LoggedUser): Promise<boolean> {
    if (!user.profile) {
      this.showAlert('Usuário sem perfil de acesso.');

      return Promise.resolve(false);
    }

    const homePath = this.getHomeUserPath(user.profile);
    if (!homePath) {
      this.showAlert(`Usuário com perfil de acesso '${user.profile}' não tem página inicial.`);

      return Promise.resolve(false);
    }

    return this.navigateTo(homePath);
  }

  private getHomeUserPath(profile: UserProfile): childRoutes.studentHome | childRoutes.teacherHome | void {
    switch (profile) {
      case 'student':
        return childRoutes.studentHome;
      case 'teacher':
        return childRoutes.teacherHome;
    }
  }

  private navigateTo(path: childRoutes.studentHome | childRoutes.teacherHome | childRoutes.login): Promise<boolean> {
    return this.router.navigateByUrl(path);
  }

  doLogin(data: LoginPageData): void {
    if (data.type === 'google') {
      this.authApi.doLoginWithGoogle().then(v => {
        if (v == 'invalid_credentials') {
          this.showAlert('🤨 Autenticação com Google está expirada ou é inválida');
          return;
        } else if (v == 'unavailable') {
          this.showAlert('🤨 Autenticação com Google está temporariamente indisponível');
          return;
        } else if (v == 'user_already_logged') {
          this.showAlert('🤨 Usuário já está logado');
          return;
        }

        this.successLogin(v);
      });

      return;
    }

    if (!data.email) {
      this.showAlert('😅 E-mail é obrigatório.');
      return;
    }

    const email = data.email;
    const password = data.password ? data.password : '';

    this.showAlert('Logando...');
    this.authApi.doLoginWithEmailAndPassword(email, password).then(result => {
      if (result == 'invalid_credentials') {
        this.showAlert('🤨 Email ou senha inválida');
        return;
      }

      if (result == 'unavailable') {
        this.showAlert('😕 Autenticação está temporariamente indisponível.');
        return;
      }

      if (result == 'user_already_logged') {
        this.showAlert('🤨 Usuário já está logado');
        return;
      }

      this.successLogin(result);
    }).catch(error => {
      this.showAlert(error);
    });
  }

  forgotPassword(data: LoginPageData) {
    this.showAlert('😌 Por gentileza, entre em contato com seu gestor para recuperar sua senha.');
  }

}

