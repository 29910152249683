export default [
    {
        "id": "8f32da1f-da68-4d02-bd9f-d4d3793a71d6",
        "createdAt": "2024-05-28T11:30:16.010Z",
        "updatedAt": "2024-06-17T19:57:20.057Z",
        "title": "Welcome Unit",
        "subtitle": "The World of English",
        "order": 1,
        "status": "activated",
        "countPages": 201,
        "completedPages": 0
    },
    {
        "id": "5fd17c4a-12a5-41a3-9fbb-2ecb78b2c385",
        "createdAt": "2024-05-28T12:25:38.614Z",
        "updatedAt": "2024-06-17T19:57:23.047Z",
        "title": "Unit 1",
        "subtitle": "Personal Identification",
        "order": 2,
        "status": "activated",
        "countPages": 261,
        "completedPages": 0
    },
    {
        "id": "094b2100-24f4-470d-b781-f3e82f99c909",
        "createdAt": "2024-05-28T12:25:40.546Z",
        "updatedAt": "2024-06-17T19:57:26.636Z",
        "title": "Unit 2",
        "subtitle": "Classroom Interaction",
        "order": 3,
        "status": "activated",
        "countPages": 185,
        "completedPages": 0
    },
    {
        "id": "4c596fc8-edce-4f17-b100-26c6b31e5108",
        "createdAt": "2024-05-28T12:25:41.723Z",
        "updatedAt": "2024-06-17T19:57:29.099Z",
        "title": "Unit 3",
        "subtitle": "Going global",
        "order": 4,
        "status": "activated",
        "countPages": 196,
        "completedPages": 0
    },
    {
        "id": "9c25c127-2e9d-4c46-b617-b3ca65ca68d6",
        "createdAt": "2024-05-28T12:25:43.074Z",
        "updatedAt": "2024-06-17T19:57:31.735Z",
        "title": "Unit 4",
        "subtitle": "People in my life",
        "order": 5,
        "status": "activated",
        "countPages": 291,
        "completedPages": 0
    },
    {
        "id": "706a5a77-25f7-437d-bac5-de058bf6bfdf",
        "createdAt": "2024-05-28T12:25:45.313Z",
        "updatedAt": "2024-06-17T19:57:35.600Z",
        "title": "Unit 5",
        "subtitle": "Where I live",
        "order": 6,
        "status": "activated",
        "countPages": 289,
        "completedPages": 0
    },
    {
        "id": "94e87bcb-bc5e-4031-a54d-c1f2b54d9306",
        "createdAt": "2024-05-28T12:25:47.286Z",
        "updatedAt": "2024-06-17T19:57:39.481Z",
        "title": "Unit 6",
        "subtitle": "In and out of school",
        "order": 7,
        "status": "activated",
        "countPages": 228,
        "completedPages": 0
    },
    {
        "id": "b606e8d2-397a-421c-89d0-0b6a37ed260f",
        "createdAt": "2024-05-28T12:25:48.766Z",
        "updatedAt": "2024-06-17T19:57:42.642Z",
        "title": "Unit 7",
        "subtitle": "Exploring our community",
        "order": 8,
        "status": "activated",
        "countPages": 231,
        "completedPages": 0
    },
    {
        "id": "ecf119c9-8ceb-45cc-a73d-5a179a547a2d",
        "createdAt": "2024-05-28T12:25:50.238Z",
        "updatedAt": "2024-06-17T19:57:45.857Z",
        "title": "Unit 8",
        "subtitle": "Let's have fun",
        "order": 9,
        "status": "activated",
        "countPages": 234,
        "completedPages": 0
    }
]