export default {
  id: '0b69e3ae-b5ad-41f6-9503-484e330d5f94',
  title: 'Learning tips',
  countPages: 13,
  completedPages: 0,
  module: {
    id: '9a3ee243-6cd5-4c36-aa85-c4a6addd9fa3',
    order: 1,
    title: 'Nível Explorer',
  },
  order: 3,
  pages: [
    {
      id: 'f786af27-391d-49ce-b002-8585c996682b',
      type: 'content',
      code: 'WU_LM_N1_L3_P1',
      order: 1,
      widgets: [
        {
          id: 'bf409413-a8be-4c44-ba99-84307bcdd08b',
          order: 1,
          type: 'title',
          value: 'LESSON 3',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '560ef20f-f58b-4a41-9166-daf23e1a55db',
          order: 2,
          type: 'subtitle',
          value: 'Learning tips',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'f173d3d8-762d-42bc-b9d7-53afbac6fa20',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-0b69e3ae-b5ad-41f6-9503-484e330d5f94-5dd0f421-a495-4383-883c-c662945f13f7.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'e279c0d7-7d12-4398-a9f4-81c7cc72b270',
          order: 4,
          type: 'longtext',
          value:
            'Na sua opinião, o dicionário ainda é importante nos dias de hoje? ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1610fb4f-80fa-4796-8090-7a1303946d3a',
          order: 5,
          type: 'select',
          feedback: false,
          options: [
            {
              id: '2a6aa6f8-f784-4d7f-b70d-23740bce6205',
              order: 1,
              text: 'It is very important.',
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-0-0b69e3ae-b5ad-41f6-9503-484e330d5f94-52d1016c-ea2d-4dca-bbf8-29cbbe43933a.mpeg',
              right: false,
            },
            {
              id: '4122987c-88cc-41a9-b3fc-aa114291a103',
              order: 2,
              text: 'It is important.',
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-af46c4af-6495-43a7-9376-21f2f988e6ec.mpeg',
              right: false,
            },
            {
              id: 'f6b12589-36d7-4ba0-8925-772d3362dceb',
              order: 3,
              text: "It isn't important.",
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-2-0b69e3ae-b5ad-41f6-9503-484e330d5f94-4e163bd0-4e93-42ca-817f-391e11fe3a5e.mpeg',
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: 'c2479ee6-1468-4967-a078-57f291b0abb4',
      type: 'content',
      code: 'WU_LM_N1_L3_P2',
      order: 2,
      widgets: [
        {
          id: 'c7e8de7f-34ff-4c7a-982a-62ac2c0406ba',
          order: 1,
          type: 'subtitle',
          value: 'Bilingual dictionaries',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8a5ad114-843d-4039-90cf-69731e16b13a',
          order: 2,
          type: 'longtext',
          value:
            'São recursos de aprendizagem úteis e confiáveis. E é muito importante aprender a usá-los. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1d349a90-5614-4a19-a71b-ec366e1383c3',
          order: 3,
          type: 'longtext',
          value:
            'Há alguns tipos de dicionários. E temos que aprender a selecionar o que melhor nos auxilia, dependendo dos recursos que temos ao nosso dispor.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0985f86d-7863-4d8b-b512-16d15a4f9d68',
          order: 4,
          type: 'blocklist',
          feedback: false,
          options: [
            {
              id: '97892592-ce18-4901-ae4b-e7aeb7c62360',
              order: 1,
              text: 'Dicionário bilíngue',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '6cc7bf09-1198-4ab8-808e-0a293b868913',
              order: 2,
              text: 'Dicionário monolíngue',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
        },
        {
          id: '36b75e23-da43-44fb-b095-9123bb6f79d7',
          order: 5,
          type: 'longtext',
          value:
            'Um dicionário monolíngue é aquele que geralmente usamos para aprender novas palavras e formas de uso em português. Já os dicionários bilíngues são os que usamos para aprender novas palavras em outra língua. Hoje encontramos ambos os tipos nas versões impressa e digital.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'e0af0a12-c0d8-4666-8840-5637a32069f2',
      type: 'content',
      code: 'WU_LM_N1_L3_P3',
      order: 3,
      widgets: [
        {
          id: 'f68e6445-f358-4c86-b339-3c8574e66238',
          order: 1,
          type: 'subtitle',
          value: 'Printed and Digital Dictionaries',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0ed0bb3a-e25e-4c07-b4d8-0e0247c88ee0',
          order: 2,
          type: 'longtext',
          value:
            'Uma das vantagens de um *printed dictionary* é que ele não depende de acesso à internet ou eletricidade. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0db50db2-0836-4991-afa1-3e975034cb74',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-0b69e3ae-b5ad-41f6-9503-484e330d5f94-c15bcb5f-6d4f-4dd5-8e33-326ca961e4ef.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '92920e26-7980-4352-916f-168210af86de',
          order: 4,
          type: 'longtext',
          value:
            'Por outro lado, *digital dictionaries* estão na palma da mão.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9190ddc2-fe9e-43a9-b27c-f8df31bd5e28',
          order: 5,
          type: 'longtext',
          value:
            'Ao aprender a usar dicionários, aprendemos a buscar informações de forma eficiente e desenvolver uma compreensão mais profunda do contexto e nuances linguísticas.',
          feedback: false,
          options: [],
          template: 'template-3',
        },
      ],
      hints: [
        {
          id: '66766955-14d0-47e7-8bce-c08bc3a8727c',
          key: 'printed dictionary',
          title: 'printed dictionary',
          subtitle: 'Dicionário impresso',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-0b69e3ae-b5ad-41f6-9503-484e330d5f94-139f8c4b-d050-4a1e-9a25-37d9e11f51ad.mpeg',
          example: 'I have a printed dictionary.',
          example_translate: 'Eu tenho um dicionário imrpesso.',
          description: '',
        },
        {
          id: 'de76db02-37e4-4da1-ba1f-39659cf38bf4',
          key: 'digital dictionaries',
          title: 'digital dictionaries',
          subtitle: 'dicionários digitais',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-0d8de10d-444e-4662-a678-22c82430b572.mpeg',
          example: 'I use digital dictionaries.',
          example_translate: 'Eu uso dicionários digitais.',
          description: '',
        },
      ],
    },
    {
      id: '972c69c1-71f1-403d-b4e7-fa9a6f3327b8',
      type: 'content',
      code: 'WU_LM_N1_L3_P4',
      order: 4,
      widgets: [
        {
          id: 'c8fda59e-4149-434a-a691-6575f93e3530',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-8b2d372d-3051-45a8-8e6f-1ade60b4e6c8.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '45716501-fc7b-48a6-b312-9099204b37b3',
          order: 2,
          type: 'enunciated',
          value: 'O exemplo acima é de um verbete presente em um dicionário:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '820d76e8-c2a3-46f1-9284-c198be1282da',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'ea9dadfc-5b84-48c9-b74e-9d662c6588d7',
              order: 1,
              text: 'Bilíngue',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '0d92171e-edbf-47e6-bd16-d94ca9317dee',
              order: 2,
              text: 'Monolíngue',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage:
            'Todas as informações estão em inglês. Portanto, é um dicionário de inglês monolíngue.',
          wrongMessage:
            'Em um dicionário bilíngue, o significado está em outra língua. ',
        },
      ],
      hints: [],
    },
    {
      id: 'e0439b36-2db5-4a68-a1fa-c4477f1c896b',
      type: 'content',
      code: ' WU_LM_N1_L3_P5',
      order: 5,
      widgets: [
        {
          id: '41748d8f-b3c5-4595-a4b5-d8ac51f4ff8b',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-0b69e3ae-b5ad-41f6-9503-484e330d5f94-4db1f5ec-d588-4bea-8187-807a7df33d1a.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9f27d3e9-1f3a-47b9-a230-343a8d99711b',
          order: 2,
          type: 'enunciated',
          value: 'O exemplo acima é um dicionário impresso ou digital?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9d390fad-92c4-43dc-9dbd-25410ead0e06',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: '2d5080ee-58fe-4985-9c3b-2ffde8b73544',
              order: 1,
              text: 'Impresso',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '64bd4b5b-0b5a-4472-bc9d-f16b67411eaf',
              order: 2,
              text: 'Digital',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage:
            'Porque podemos procurar o verbete de forma automatizada. ',
          wrongMessage:
            'Observe que tem uma caixa de busca possibilitando a busca digital.',
        },
      ],
      hints: [],
    },
    {
      id: '769336b8-1182-4a7a-93a9-b51c7c1f73ac',
      type: 'content',
      code: 'WU_LM_N1_L3_P6',
      order: 6,
      widgets: [
        {
          id: 'd8f54f90-9a66-4ba8-80bf-da4c85b4fca5',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-0-0b69e3ae-b5ad-41f6-9503-484e330d5f94-b9e43f5c-eb1c-4122-91d4-8ea170f80d82.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'e0342f34-e566-4e9c-83c7-59e5c93079d7',
          order: 2,
          type: 'longtext',
          value:
            'Dicionários impresso ou digital podem trazer diferentes informações sobre o verbete.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'f51da92a-91aa-41ac-8914-dd3643e09017',
          order: 3,
          type: 'longtext',
          value:
            'Você sabe que tipo de informações você pode obter ao usar um dicionário, seja ele digital ou impresso?',
          feedback: false,
          options: [],
          template: 'template-1',
        },
      ],
      hints: [],
    },
    {
      id: 'da37e707-276a-48cf-9d27-ee5f6f39edde',
      type: 'content',
      code: 'WU_LM_N1_L3_P7',
      order: 7,
      widgets: [
        {
          id: '39fb3712-10b0-45b1-a286-7201cae09162',
          order: 1,
          type: 'enunciated',
          value: 'Observe o verbete a seguir.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'e5b2732f-5a7d-449d-900e-f40840247264',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-dc368f8b-cc09-4932-acc4-52f6af57567f.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'e3663bd0-d91f-4ddd-9a41-8826a09918e6',
          order: 3,
          type: 'longtext',
          value:
            'Que informações sobre o verbete você identifica na imagem acima?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '638852be-e8aa-4570-a74d-84c7c780ab6e',
          order: 4,
          type: 'longtext',
          value:
            'Realize as atividades a seguir identificando os tipos de informações que encontramos em um verbete. ',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '17081c54-719a-43f3-bc7f-746e0e74f8a4',
      type: 'content',
      code: ' WU_LM_N1_L3_P8',
      order: 8,
      widgets: [
        {
          id: '38d1139b-2ed2-4a06-b90f-b425e33be886',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-3d8609dd-cd85-4941-b7de-4cf67ebf7ea7.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '48e8cb19-a393-4823-b1f2-047d67de9173',
          order: 2,
          type: 'enunciated',
          value: 'A informação 1 refere-se:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '18c9dd03-f7ae-42a0-88b5-a9823169ef7b',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'd4660aa3-f920-4afd-9ac7-ebe1aaa19723',
              order: 1,
              text: 'ao significado do verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '806aea67-992e-46cb-b83b-37f7cae03a7d',
              order: 2,
              text: 'a forma de pronunciar o verbete',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: 'b29beb52-751e-4451-a0bd-ef8f88ee00f8',
              order: 3,
              text: 'a classe gramatical do verbete',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage:
            'Refere-se a forma de pronunciar o verbete. Lembra dos símbolos fonéticos e como eles representam os sons da fala de uma palavra?',
        },
      ],
      hints: [],
    },
    {
      id: 'cafdc7d2-c77a-4f80-a7f8-28f87f1b721c',
      type: 'content',
      code: 'WU_LM_N1_L3_P9',
      order: 9,
      widgets: [
        {
          id: '5023cb01-c153-48ed-a0cf-88e1cb60498e',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-abe8c19c-0da9-40de-a71b-e16577f9255b.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8afa1a7a-23ca-4778-a06a-6446d2e46449',
          order: 2,
          type: 'longtext',
          value: 'A informação 2 referem-se:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3fb9fcd8-a0e3-4ea1-b21f-8c08986be9f0',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'fc8e642d-f42f-45c5-ac5b-241908c132c6',
              order: 1,
              text: 'exemplos de uso com o verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '0470ab19-433b-4fc2-8b34-b92a0faeec92',
              order: 2,
              text: 'a classe gramatical do verbete',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '4bb050d2-26f8-4b51-8df0-d3c3ad743770',
              order: 3,
              text: 'ao significado do verbete',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage:
            'Refere-se a classe gramatical. Nesse exemplo, English pode ser um substantivo ou um adjetivo.',
        },
      ],
      hints: [],
    },
    {
      id: 'aa47a5ec-7802-4641-8d83-8acba4031e86',
      type: 'content',
      code: ' WU_LM_N1_L3_P10',
      order: 10,
      widgets: [
        {
          id: 'bdba052b-3aaf-4284-9765-b5a047620273',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-2f1410d1-7b54-442f-b1a9-ca8c4786b912.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9e8da30a-cb23-46a1-b54a-4b45bbe48080',
          order: 2,
          type: 'longtext',
          value: 'A informação 3 refere-se:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '25354c54-9007-4fbd-96ca-1db83da46b67',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: '0c275ed1-7938-44dc-8c05-f9dc4243ee17',
              order: 1,
              text: 'a classe gramatical do verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'c676f374-d3c6-40c9-a244-310a1b75b04c',
              order: 2,
              text: 'aos exemplos de uso do verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '453085da-e813-49aa-9a81-33d300f33023',
              order: 3,
              text: 'ao significado do verbete',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage:
            'Refere-se ao significado do verbete. Como substantivo, por exemplo, os significados são: a língua, o idioma inglês, ',
        },
      ],
      hints: [],
    },
    {
      id: '7932241a-a764-4084-8b6f-2c07511c3b4f',
      type: 'content',
      code: 'WU_LM_N1_L3_P11',
      order: 11,
      widgets: [
        {
          id: '756148bc-6666-4f02-b640-e5528d4620f1',
          order: 1,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-56b830f1-e364-440c-84e1-a5d6e5544ec4.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '4c4f530f-0204-47a8-91b8-1ec35a1fb4dd',
          order: 2,
          type: 'longtext',
          value: 'A informação 4 refere-se:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '953a1608-3dd3-4d56-bd9b-74491568b1e8',
          order: 3,
          type: 'select',
          feedback: true,
          options: [
            {
              id: 'd0284f2c-e200-423a-8854-bd29d855f693',
              order: 1,
              text: 'aos exemplos de uso do verbete',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '2e783056-9488-41c8-a322-717c498579d2',
              order: 2,
              text: 'a como se pronuncia o verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '90b4d8a0-e7c9-492b-849f-2a3288de693e',
              order: 3,
              text: 'o significado do verbete',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage:
            "Refere-se aos exemplos de uso, como 'an English teacher'. ",
        },
      ],
      hints: [],
    },
    {
      id: '2ec1061d-560d-451f-ae14-d8aea951b050',
      type: 'content',
      code: 'WU_LM_N1_L3_P12',
      order: 12,
      widgets: [
        {
          id: 'b89041f8-88e3-4cf4-97d6-f3247a9ebdb1',
          order: 1,
          type: 'subtitle',
          value: 'Dictionary information',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0f3e9e91-7d8c-48b8-b861-35d8c69429f8',
          order: 2,
          type: 'longtext',
          value:
            'As informações básicas presentes em qualquer dicionário inglês - português, impresso ou digital, são:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '628a77b3-9ad3-4d79-af59-365d36fe4cb0',
          order: 3,
          type: 'blocklist',
          feedback: false,
          options: [
            {
              id: '1412b83f-5721-4b03-b38b-e9c157030674',
              order: 1,
              text: 'o verbete',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'ccb650e6-82f9-4eab-84c2-754016f08374',
              order: 2,
              text: 'como se pronúncia em inglês com símbolos fonéticos',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'd99e30fd-31d7-4b8d-aea1-6ca25da4fbd2',
              order: 3,
              text: 'a classe gramatical: ex. substantivo, adjetivo',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '238e44c7-45c6-4191-8e3e-08a42123049b',
              order: 4,
              text: 'o(s) significado(s), pode ter um ou mais.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: 'efb13d98-5086-4ec9-8032-bb908d218aee',
              order: 5,
              text: 'exemplos de uso com ou sem a tradução',
              image: '',
              audio: '',
              right: false,
            },
          ],
          template: 'default',
        },
        {
          id: '7763d7aa-30fd-4b77-8390-d079f100e67b',
          order: 4,
          type: 'spacer',
          value: '20',
          template: 'spacer',
        },
        {
          id: '33b3f6c3-98b9-411b-919b-c1ed10132eb1',
          order: 5,
          type: 'longtext',
          value:
            'Nos dicionários online ainda temos a oportunidade de ouvir como se pronuncia. E alguns dicionários, oferecem outros recursos. No Goteens, você encontra o <b>glossário</b> que são palavras destacadas com o significado, exemplos de uso com a tradução e áudio para praticar a pronúncia.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '9ff9d3ab-d015-4e20-89ff-4e3bd60ea8b5',
      type: 'content',
      code: 'WU_LM_N1_L3_end',
      order: 13,
      widgets: [
        {
          id: '579f1bf7-8f9f-428a-a7b0-8b8cbd66f100',
          order: 1,
          type: 'title',
          value: 'YOU DID IT!',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'eb484315-5aac-420d-b3e5-7abcee9c8e8d',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-0b69e3ae-b5ad-41f6-9503-484e330d5f94-fd5195be-4d1c-46a4-8cbc-a3217e4686fc.lottie',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '67134054-27bd-4bd4-9a53-91f60d193b28',
          order: 3,
          type: 'subtitle',
          value: 'Parabéns! Você concluiu a LIÇÃO 3.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'b51f509b-cdd0-4efb-bd53-8d75aff3a4e6',
          order: 4,
          type: 'longtext',
          value: 'Clique em START para iniciar a próxima lição.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
  ],
};
