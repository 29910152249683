export class ClassesUtils {

    public static toArrayClasses(classes: string): string[] {
        if (!classes || classes.length == 0) {
            return [];
        }

        const out: string[] = [];
        classes.split(' ').map(v => v.trim()).filter(v => v.length > 0).forEach(v => out.push(v));

        return out;
    }

}