import { ApiEnvType } from "../../../../../environments/api-env.type";
import { environment } from "../../../../../environments/environment";

export interface ApiTypeOption {

  id: ApiEnvType;
  disabled: boolean;

}

class InitialOptions {

  options: ApiTypeOption[] = [];

  constructor() {
    environment.api.forEach(api => {
      this.options.push({ id: api.env, disabled: api.url == 'disabled' });
    });
  }

}

export const defaultApiOptions: ApiTypeOption[] = new InitialOptions().options;