import { Component, Input, model } from '@angular/core';
import { defaultMenuBarState, defaultMenuItemsPage } from '../../core/default-menu-items.pages';
import { MenuBarState } from 'go-components';
import { PageWithMenu } from '../../core/page-with-menu';

@Component({
  selector: 'st-select-school-page',
  templateUrl: './select-school-page.component.html',
  styleUrl: './select-school-page.component.scss',
})
export class SelectSchoolPageComponent implements PageWithMenu {

  @Input() menuItens = defaultMenuItemsPage;
  menuBarState = model<MenuBarState>(defaultMenuBarState);

  rawString: string = '**ONDE** você vai ministrar sua aula **AGORA**?';
  processedString: string = '';
  teacherName: string = 'Luiz Fernando Rosso Perraro';

  schoolItens: SchoolCard[] = [
    { title: 'Pequeno Exemplar - São Francisco, C E I', isSelected: true },
    { title: 'Nossa Senhora Aparecida, C M E I do C', isSelected: false },
    { title: 'Agostinho Stefanello, E E C - M - Ef', isSelected: false },
    { title: 'Jorge Bife, E E C - M - Ef', isSelected: false },
    { title: 'José Do Patrocínio, E E C - M - Ef', isSelected: false },
  ];

  logout() {}

  ngOnInit() {
    this.processedString = this.transformStringToHtml(this.rawString);
  }

  onCardSelected(selectedIndex: number): void {
    this.schoolItens.forEach((item, index) => {
      item.isSelected = index === selectedIndex;
    });
  }

  //? Transforma string no formato apresentado em tela.
  //? Ex.: "**ONDE** você vai ministrar sua aula **AGORA**?"
  transformStringToHtml(input: string): string {
    // Regex para encontrar palavras entre ** e substituir
    const regex = /\*\*(.*?)\*\*/g;
    return input.replace(
      regex,
      '<b class="text-baloochettan-h1 go-text-color-purple700">$1</b>'
    );
  }
}

interface SchoolCard {
  title: string;
  isSelected: boolean;
}
