import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { GeneralButtonComponent } from './general-button/general-button.component';
import { GoogleButtonComponent } from './google-button/google-button.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { LoginButtonComponent } from './login-button/login-button.component';
import { MicButtonComponent } from './mic-button/mic-button.component';
import { IconsModule } from '../icons/icons.module';

const components = [
  GeneralButtonComponent,
  GoogleButtonComponent,
  BackButtonComponent,
  LoginButtonComponent,
  MicButtonComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule, NgClass],
  exports: components,
})
export class ButtonsModule {}

export {
  GeneralButtonComponent,
  GoogleButtonComponent,
  BackButtonComponent,
  LoginButtonComponent,
  MicButtonComponent,
};
