import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectAnItemToContinuePage } from '../core/select-an-item-to-continue-page';
import { Radio } from 'go-components';

type itemType = 'explorer' | 'interpreter' | 'communicator';

@Component({
  selector: 'st-select-learning-steps-page',
  templateUrl: './select-learning-steps-page.component.html',
  styleUrl: './select-learning-steps-page.component.scss',
})
export class SelectLearningStepsPageComponent
  implements SelectAnItemToContinuePage
{
  @Input() escapePath: string = '';
  @Input() explorer?: Radio[];
  @Input() interpreter?: Radio[];
  @Input() communicator?: Radio[];
  @Input() disabledButton: boolean = true;

  @Output() cardSelected = new EventEmitter<{
    indexItem: number;
    type: itemType;
  }>();
  @Output() continueClicked = new EventEmitter<void>();

  onSelectedItem(indexItem: number): void {}

  onContinue(): void {
    this.continueClicked.emit();
  }

  onBack(): void {}

  onCardSelected(indexItem: number, type: itemType): void {
    const items = this.getItemsByType(type);
    const selectedItem = items[indexItem];

    if (selectedItem.isSelected) {
      selectedItem.isSelected = false;
      this.cardSelected.emit({ indexItem: -1, type });
    } else {
      items.forEach((item) => (item.isSelected = false));
      selectedItem.isSelected = true;
      this.cardSelected.emit({ indexItem, type });
    }
  }

  getItemsByType(type: itemType): Radio[] {
    switch (type) {
      case 'communicator':
        return this.communicator ?? [];
      case 'explorer':
        return this.explorer ?? [];
      case 'interpreter':
        return this.interpreter ?? [];
      default:
        throw new Error('Tipo desconhecido: ' + type);
    }
  }

  reportAction(event: Event) {
    event.preventDefault();

    alert('Inserir report modal');
  }
}
