import { Injectable } from '@angular/core';
import { CourseClass, Page, Stage, Step, Unit } from 'student-lib';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { StorageService } from '../../core/storage/storage.service';
import { CoursesClassesApiService } from './courses-classes.api.service';
import { Observable, lastValueFrom } from 'rxjs';
import { AbstractApiService } from '../core/abstract-api.service';
import { CoursesClassesApiMapper } from './courses-classes.api.mapper';

@Injectable()
export class CoursesApiServiceImpl
  extends AbstractApiService
  implements CoursesClassesApiService
{
  private mapper = new CoursesClassesApiMapper();

  constructor(storage: StorageService, api: ApiService, http: HttpClient) {
    super(storage, api, http);
  }

  loadPages(courseClassId: string, stageId: string): Promise<Page[]> {
    const url = this.getPagesUrl(courseClassId, stageId);
    const get = this.http.get(url);
    const service = this;
    const cold = new Observable<Page[]>((sub) => {
      get.subscribe({
        next(data: any) {
          const items = service.mapper.toPages(data);
          if (items instanceof Error) {
            sub.error(items);
            return;
          }

          sub.next(items);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  loadSteps(courseClassId: string, stageId: string): Promise<Step[]> {
    const url = this.getStepsUrl(courseClassId, stageId);
    const get = this.http.get(url);
    const service = this;
    const cold = new Observable<Step[]>((sub) => {
      get.subscribe({
        next(data: any) {
          const items = service.mapper.toSteps(data.steps);
          if (items instanceof Error) {
            sub.error(items);
            return;
          }

          sub.next(items);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  loadStage(courseClassId: string, stageId: string): Promise<Stage> {
    const url = this.getPagesUrl(courseClassId, stageId);
    const get = this.http.get(url);
    const service = this;
    const cold = new Observable<Stage>((sub) => {
      get.subscribe({
        next(data: any) {
          const items = service.mapper.toStage(data);
          if (items instanceof Error) {
            sub.error(items);
            return;
          }

          sub.next(items);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  loadStages(courseClass: CourseClass, unit: Unit): Promise<Stage[]> {
    const url = this.getStagesUrl(courseClass.id, unit.id);
    const get = this.http.get(url);
    const service = this;
    const cold = new Observable<Stage[]>((sub) => {
      get.subscribe({
        next(data: any) {
          const items = service.mapper.toStages(data);
          if (items instanceof Error) {
            sub.error(items);
            return;
          }

          sub.next(items);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  loadUnits(courseClassId: string): Promise<Unit[]> {
    const url = this.getUnitsUrl(courseClassId);
    const get = this.http.get(url);
    const service = this;
    const cold = new Observable<Unit[]>((sub) => {
      get.subscribe({
        next(data: any) {
          const items = service.mapper.toUnits(data);
          if (items instanceof Error) {
            sub.error(items);
            return;
          }

          sub.next(items);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  loadCoursesClasses(): Promise<CourseClass[]> {
    const service = this;
    const url = this.api.getNormalizedUrl('/courses');
    const get = this.http.get(url);
    const cold = new Observable<CourseClass[]>((sub) => {
      get.subscribe({
        next(data: any) {
          const courses = service.mapper.toCoursesClasses(data);
          if (courses instanceof Error) {
            sub.error(courses);
            return;
          }

          sub.next(courses);
        },
        error(data) {
          if (data?.error?.message) {
            sub.error(data.error.message);
            return;
          }

          sub.error('Erro desconhecido: ' + data);
        },
        complete() {
          sub.complete();
        },
      });
    });

    return lastValueFrom(cold);
  }

  private getPagesUrl(courseClassId: string, stageId: string) {
    return this.api.getNormalizedUrl(
      '/courses/'.concat(courseClassId).concat('/stages/').concat(stageId)
    );
  }

  private getStepsUrl(courseClassId: string, stageId: string) {
    return this.api.getNormalizedUrl(
      '/courses/'.concat(courseClassId).concat('/stages/').concat(stageId)
    );
  }

  private getStagesUrl(courseClassId: string, unitId: string): string {
    return this.api.getNormalizedUrl(
      '/courses/'.concat(courseClassId).concat('/units/').concat(unitId)
    );
  }

  private getUnitsUrl(courseClassId: string): string {
    return this.api.getNormalizedUrl(
      '/courses/'.concat(courseClassId).concat('/units')
    );
  }
}
