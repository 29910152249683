import { Component, Input, effect } from '@angular/core';
import { DevToolsService } from './dev-tools.service';
import { UserProfile } from 'student-lib';
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';
import { AbstractPageComponent } from '../../abstract-page.component';
import { DevToolsEventsPage } from './dev-tools.events.page';
import { ApiTypeOption, defaultApiOptions } from './api-type.option';
import { ApiService } from '../../../api/api.service';
import { ApiEnvType } from '../../../../../environments/api-env.type';

@Component({
  selector: 'app-dev-tools',
  templateUrl: './dev-tools.component.html',
  styleUrl: './dev-tools.component.scss',
  animations: [
    trigger('openClose', [
      transition(':enter', [style({opacity: 0}), animate('500ms', style({opacity: 1}))]),
      transition(':leave', [animate('300ms', style({opacity: 0}))]),
    ]),
  ],  
})
export class DevToolsComponent extends AbstractPageComponent<DevToolsEventsPage> {

  readonly apiTypeOptions: ApiTypeOption[] = [...defaultApiOptions];
  apiEnvType?: ApiEnvType;

  @Input() visible = true;

  fakeLogin: UserProfile[] = [];

  constructor(private dev: DevToolsService, private api: ApiService) {
    super();

    this.initStorage();

    effect(() => {
      this.visible = this.dev.isActive();
      this.apiEnvType = api.getEnvType();

      switch (this.apiEnvType) {
        case 'development': 
        case 'test': {
          this.fakeLogin = ['student', 'teacher'];
          break;
        }
        default: {
          this.fakeLogin = [];
        }
      }
    });
  }

  apiChange(event: any) {
    const api = event.target.value;
    if (api) {
      this.apiEnvType = api;
      this.api.setEnvType(api);
    }
  }

  getApiUrl(): string | null {
    return this.api.getUrl();
  }

  requestFakeLogin(profile: UserProfile) {
    this.eventPage.emit({ event: 'request-fake-login', loginProfile: profile });
  }

  off() {
    this.dev.toggleActive(false);
    this.eventPage.emit({ event: 'off' });
  }

  on() {
    this.dev.toggleActive(true);
    this.eventPage.emit({ event: 'on' });
  }

}