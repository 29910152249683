import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { WidgetOptions } from '../options.interface';
import { WidgetsStateService } from '../widget-state.service';
import { AbstractChallengeComponent } from '../abstract.challenge.component';
import {
  GeneralChallengeComponent,
  BaseBlocks,
} from '../general-challenge/general-challenge.component';

@Component({
  selector: 'widget-blocks',
  templateUrl: './blocks.component.html',
  styleUrl: './blocks.component.scss',
})
export class BlocksComponent
  extends GeneralChallengeComponent
  implements AbstractChallengeComponent, OnInit, OnChanges
{
  readonly goComponentId: string = 'blocks-challenge';

  @Input() correct: boolean = false;

  answer!: string;
  blocks!: BaseBlocks[];

  userAnswer: string = '';
  userBlocks: BaseBlocks[] = [];

  boxClasses!: string;

  // ngOnInit() {
  //   this.el.nativeElement.style.width = '100%';

  //   if (this.options != null && this.options.length > 0) {
  //     this.initializeBlocks(this.options);
  //   }
  // }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['options']) {
  //     if (this.options != null && this.options.length > 0) {
  //       this.initializeBlocks(this.options);

  //       this.userBlocks = [];
  //       this.answerResult = '';
  //       this.uniqueId = this.applyUniqueId();
  //       this.widgetsStateService.updateWidgetState(
  //         this.uniqueId,
  //         this.answerResult !== ''
  //       );
  //       this.widgetsStateService.registerWidget(this);
  //     }
  //   }
  // }

  //? Faz a seleção dos blocos
  blockSelection(item: BaseBlocks) {
    if (this.pageStatus != 'concluded') {
      //? Verifica se o bloco já está selecionado
      const alreadyListed = this.userBlocks.includes(item);
      if (!alreadyListed) {
        this.userBlocks.push(item);
        this.updateUserAnswer();
      }

      this.answerResult = this.userBlocks.join(';');
      this.updateWidgetState();
    }
  }

  //? Faz a remoção dos blocos
  blockRemove(item: BaseBlocks) {
    if (this.pageStatus != 'concluded') {
      //? Verifica se o bloco já está selecionado
      const alreadyListed = this.userBlocks.includes(item);
      if (alreadyListed) {
        this.userBlocks = this.userBlocks.filter((block) => block !== item);
        this.updateUserAnswer();
      }

      this.answerResult = this.userBlocks.join(';');
      this.updateWidgetState();
    }
  }

  //? Define as classes dos blocos
  getBoxClasses(item: BaseBlocks) {
    let selected = this.userBlocks.includes(item);

    if (selected) {
      return 'block selected go-background-color-gray300 go-border-color-gray300';
    } else {
      return 'block go-background-color-gray50 go-border-color-gray300';
    }
  }

  //? Define as classes dos textos dos blocos
  getTextClasses(item: BaseBlocks) {
    let selected = this.userBlocks.includes(item);

    if (selected) {
      return 'text-poppins-subtitle-semibold go-text-color-gray300';
    } else {
      return 'text-poppins-subtitle-semibold go-text-color-gray950';
    }
  }

  checkCorrection(): boolean {
    let userAnswer = this.removeSpecialCharsAndSpaces(this.answerResult);
    let rightAnswer = this.removeSpecialCharsAndSpaces(this.rightAnswer);

    if (userAnswer == rightAnswer) {
      return true;
    } else {
      // bool checkWrong = answers.any((el) => el.questionId == question.id && el.attempts + 1 >= maxAttempts);
      // return checkWrong ? ResultQuestion.wrong : ResultQuestion.retry;
      return false;
    }
  }

  // *** PRIVATE FUNTIONS
  //? Define os blocos
  private initializeBlocks(options: WidgetOptions[]) {
    let answer = options[0].text;
    let blocks = options[0].textRelated;

    if (blocks && blocks.trim() !== '') {
      let numBlock = 1;
      const makeArrayBlocks = blocks.split(';').map((it) => it.trim());
      const arrayBlocks = [];

      for (let op = 0; op < makeArrayBlocks.length; op++) {
        const value = makeArrayBlocks[op];

        // Tratamento de label e speech
        const explodeLabel = value.split(':').map((item) => item.trim());
        const labelBlock = explodeLabel[0];

        arrayBlocks.push({
          label: labelBlock,
          value: numBlock,
        });

        numBlock++;
      }

      this.answer = answer?.length !== 0 ? answer! : '';
      this.blocks = arrayBlocks.sort(() => Math.random() - 0.5);
    }
  }

  //? Update userAnswer
  private updateUserAnswer() {
    let labelString = '';
    for (const block of this.userBlocks) {
      const label = block.label;
      labelString += `${label} `;
    }

    this.userAnswer = labelString;
  }
}
