import { ThemeIconsTypes } from '../../core/models/enums/icons.enum';

export const iconsPathMap: Record<ThemeIconsTypes, () => Promise<any>> = {
  'angle-left': () => import('./go-icon-angle-left.svg'),
  'angle-right': () => import('./go-icon-angle-right.svg'),
  'arrow-left': () => import('./go-icon-arrow-left.svg'),
  'arrow-right': () => import('./go-icon-arrow-right.svg'),
  'check-radio': () => import('./go-icon-check-radio.svg'),
  'circle-check': () => import('./go-icon-circle-check.svg'),
  'circle-close': () => import('./go-icon-circle-close.svg'),
  'close-white': () => import('./go-icon-close-white.svg'),
  close: () => import('./go-icon-close.svg'),
  ear: () => import('./go-icon-ear.svg'),
  flag: () => import('./go-icon-flag.svg'),
  'google-brand': () => import('./go-icon-google-brand.svg'),
  home: () => import('./go-icon-home.svg'),
  lock: () => import('./go-icon-lock.svg'),
  'menu-burger': () => import('./go-icon-menu-burger.svg'),
  mic: () => import('./go-icon-mic.svg'),
  paw: () => import('./go-icon-paw.svg'),
  play: () => import('./go-icon-play.svg'),
  profile: () => import('./go-icon-profile.svg'),
  question: () => import('./go-icon-question.svg'),
  rocket: () => import('./go-icon-rocket.svg'),
  rotate: () => import('./go-icon-rotate-right.svg'),
  'sign-in': () => import('./go-icon-sign-in.svg'),
  'sign-out-alt': () => import('./go-icon-sign-out-alt.svg'),
  'star-full': () => import('./go-icon-star-full.svg'),
  tasks: () => import('./go-icon-tasks.svg'),
  'thumbs-up': () => import('./go-icon-thumbs-up.svg'),
  trophy: () => import('./go-icon-trophy.svg'),
  turtle: () => import('./go-icon-turtle.svg'),
  units: () => import('./go-icon-units.svg'),
  'volume-up': () => import('./go-icon-volume-up.svg'),
};
