<ng-container *ngIf="template == 'default'">
  <h4 [style.marginBottom]="marginBottom" [innerHTML]="processedText"></h4>
</ng-container>

<ng-container *ngIf="template == 'enunciatedquestion'">
  <h4
    [style.marginBottom]="marginBottom"
    [style.textAlign]="'center'"
    [innerHTML]="processedText"
  ></h4>
</ng-container>
