import { Component } from '@angular/core';

@Component({
  selector: 'st-quiz-end-page',
  templateUrl: './quiz-end-page.component.html',
  styleUrl: './quiz-end-page.component.scss'
})
export class QuizEndPageComponent {

}
