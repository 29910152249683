<section class="container">
  <div class="content">
    <go-general-button
      width="48px"
      height="48px"
      borderRadius="48px"
      leftIcon="angle-left"
      leftIconMargin="3px"
      [back]="true"
    />

    <header class="content-header">
      <div class="lottie-animation">
        <go-general-image
          value="https://go2dev.s3.amazonaws.com/fixed/exame-start.lottie"
        />
      </div>

      <h2 class="text-baloochettan-h2 go-text-color-orange500">Quiz Time!</h2>

      <p class="text-poppins-body-semibold go-text-color-gray900">
        Vamos descobrir seu nível de conhecimento para esta unidade?
      </p>

      <p class="text-poppins-body-semibold go-text-color-gray900">
        Não se preocupe, você terá a chance de subir de nível durante seus
        estudos.
      </p>
    </header>

    <div class="actions">
      <div class="action-button">
        <go-general-button
          width="364px"
          label="Começar"
          rightIcon="arrow-right"
          (click)="startQuiz()"
        />
      </div>

      <p class="text-poppins-body-semibold go-text-color-gray900">
        Tempo para realização do Quiz:
        <strong class="text-poppins-body-bold go-text-color-orange500">
          45 min.
        </strong>
      </p>
    </div>
  </div>
</section>
