import { Injectable } from '@angular/core';
import { LoggedUser } from 'student-lib';

export type CommonNegativeLoginResult = 'user_already_logged' | 'invalid_credentials' | 'unavailable';
export const authLoginContextPath = '/auth/login';
export const authTokenRefreshContextPath = '/auth/token/refresh';

@Injectable()
export abstract class AuthApiService {

  abstract doLogout(): Promise<void>;
  abstract doLoginWithGoogle(): Promise<LoggedUser | CommonNegativeLoginResult>;
  abstract doLoginWithEmailAndPassword(email: string, password: string): Promise<LoggedUser | CommonNegativeLoginResult>;
  abstract clearLoggedUser(): void;
  abstract isUserLogged(): boolean;
  abstract getLoggedUser(): LoggedUser | null;
  abstract doRefreshToken(): Promise<void>;

}