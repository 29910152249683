<ng-container *ngIf="type == 'single'">
  <div [ngClass]="boxClasses" (click)="onItemClick()">
    <div [ngClass]="sideClasses"></div>
    <ng-container *ngIf="side == 'left'">
      <span *ngIf="!emptyStr(item.text)" [class]="textClasses">
        {{ item.text }}
      </span>
      <div *ngIf="emptyStr(item.text) && !emptyStr(item.audio)" class="audio">
        <go-icon icon="volume-up" [pathColor]="iconColor" />
      </div>
    </ng-container>

    <ng-container *ngIf="side == 'right'">
      <span *ngIf="!emptyStr(item.textRelated)" [class]="textClasses">
        {{ item.textRelated }}
      </span>
      <div
        *ngIf="emptyStr(item.textRelated) && !emptyStr(item.audioRelated)"
        class="audio"
      >
        <go-icon icon="volume-up" [pathColor]="iconColor" />
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="type == 'double'">
  <div class="line" (click)="onItemClick()">
    <div class="left" [ngClass]="boxClasses">
      <div [ngClass]="sideClasses"></div>
      <span *ngIf="!emptyStr(item.text)" [class]="textClasses">
        {{ item.text }}
      </span>
      <div *ngIf="emptyStr(item.text) && !emptyStr(item.audio)" class="audio">
        <go-icon icon="volume-up" [pathColor]="iconColor" />
      </div>
    </div>

    <div *ngIf="secondItem" class="right" [ngClass]="boxClasses">
      <div [ngClass]="sideClasses"></div>
      <span *ngIf="!emptyStr(secondItem.textRelated)" [class]="textClasses">
        {{ secondItem.textRelated }}
      </span>
      <div
        *ngIf="
          emptyStr(secondItem.textRelated) && !emptyStr(secondItem.audioRelated)
        "
        class="audio"
      >
        <go-icon icon="volume-up" [pathColor]="iconColor" />
      </div>
    </div>
  </div>
</ng-container>
