export default {
  id: 'fa999171-3f49-4752-a376-7e1777a2e3af',
  createdAt: '2024-05-28T11:30:16.017Z',
  updatedAt: '2024-06-17T19:57:20.072Z',
  title: 'Time to Connect',
  cover: null,
  order: 1,
  status: 'activated',
  countPages: 32,
  completedPages: 0,
  type: 'CONTENTS',
  modules: [],
  steps: [],
  pages: [
    {
      id: '60258857-5509-44d8-b5eb-fa45f50218e0',
      createdAt: '2024-06-17T19:57:20.134Z',
      updatedAt: '2024-06-17T19:57:20.134Z',
      type: 'content',
      code: 'WU_Opening',
      order: 1,
      status: 'activated',
      widgets: [
        {
          id: 'b1a2ca30-54b3-4660-8e6a-525822985733',
          createdAt: '2024-06-17T19:57:20.134Z',
          updatedAt: '2024-06-17T19:57:20.134Z',
          order: 1,
          type: 'title',
          value: 'THE WORLD OF ENGLISH',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'eff64f56-6aad-4a39-bc52-a4326ea969fd',
          createdAt: '2024-06-17T19:57:20.134Z',
          updatedAt: '2024-06-17T19:57:20.134Z',
          order: 2,
          type: 'subtitle',
          value: 'WELCOME UNIT',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c9d2b08d-ded3-49a6-841b-61657a9533a5',
          createdAt: '2024-06-17T19:57:20.134Z',
          updatedAt: '2024-06-17T19:57:20.134Z',
          order: 3,
          type: 'enunciated',
          value: 'Hi, $user.first_name! Welcome to the World of English!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '431e774d-b5c1-46fb-bbe9-9f7dd63d73a0',
          createdAt: '2024-06-17T19:57:20.134Z',
          updatedAt: '2024-06-17T19:57:20.134Z',
          order: 4,
          type: 'longtext',
          value:
            'Você vai embarcar em uma jornada para descobrir o Mundo do Inglês, uma língua que conecta culturas, abre portas e enriquece mentes. Bem-vindo a bordo!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '7d377938-3b70-47fa-baf8-af805abe844a',
          createdAt: '2024-06-17T19:57:20.134Z',
          updatedAt: '2024-06-17T19:57:20.134Z',
          order: 5,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-8-1-5e4a1c16-f3a6-43ad-80af-9f0be82241bc.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '27a82bc5-fcf4-4a58-948b-2b1ca5f5ed05',
      createdAt: '2024-06-17T19:57:20.166Z',
      updatedAt: '2024-06-17T19:57:20.166Z',
      type: 'content',
      code: 'WU_TC_B1_P1',
      order: 2,
      status: 'activated',
      widgets: [
        {
          id: 'd5e96f0a-89cd-45b6-abb4-9f270206ffa2',
          createdAt: '2024-06-17T19:57:20.166Z',
          updatedAt: '2024-06-17T19:57:20.166Z',
          order: 1,
          type: 'title',
          value: 'TIME TO CONNECT',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9633517c-1be7-41b3-8b43-a9fedcc2259a',
          createdAt: '2024-06-17T19:57:20.166Z',
          updatedAt: '2024-06-17T19:57:20.166Z',
          order: 2,
          type: 'subtitle',
          value: 'Para refletir',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '93db1452-1e88-477b-be98-609cb354b156',
          createdAt: '2024-06-17T19:57:20.166Z',
          updatedAt: '2024-06-17T19:57:20.166Z',
          order: 3,
          type: 'longtext',
          value:
            'Você já pensou que o inglês não é apenas uma língua, mas uma ponte que conecta culturas, negócios e pessoas ao redor do mundo.<br>Em nossa jornada para explorar esse idioma dinâmico, começaremos observando sua presença no nosso dia a dia.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '4f04b524-73b2-4f1e-bc54-3d3ccc01eac5',
          createdAt: '2024-06-17T19:57:20.166Z',
          updatedAt: '2024-06-17T19:57:20.166Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-6-fa999171-3f49-4752-a376-7e1777a2e3af-b21a287b-910e-4737-9b87-e6ed29b79b5c.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '202782a4-076e-4efe-8f13-0313c2524432',
      createdAt: '2024-06-17T19:57:20.180Z',
      updatedAt: '2024-06-17T19:57:20.180Z',
      type: 'content',
      code: 'WU_TC_B1_P2_A1',
      order: 3,
      status: 'activated',
      widgets: [
        {
          id: '891fa3ee-71ca-4c08-8a3d-7c8c0e87e9ff',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          order: 1,
          type: 'subtitle',
          value: 'TOURISM & IMMIGRATION',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '582fa6b6-3e96-4c17-ac72-fbf5a17bb0d9',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          order: 2,
          type: 'longtext',
          value:
            'Você já observou ao redor da cidade em que você mora ou que já visitou que há *bilingual traffic signs*?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'aec349b7-ba25-4842-9069-89482de9ab46',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-2-fa999171-3f49-4752-a376-7e1777a2e3af.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '05f1d5ef-5ff0-4baa-9071-4ee908fa83cb',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          order: 4,
          type: 'longtext',
          value:
            'Veja alguns exemplos de cidades que adotaram esses *signs* para orientar *tourists* e *immigrants* pela cidade.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '73091395-0b06-41fa-add4-4bff2853486a',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          key: 'tourists',
          title: 'tourists',
          subtitle: 'turistas',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-2-fa999171-3f49-4752-a376-7e1777a2e3af-8409fe64-8b3a-41d6-b038-3c47c446056d.mpeg',
          example: 'A lot of tourists visit our city every year.',
          exampleTranslate:
            'Muitos turistas visitam a nossa cidade todos os anos.',
          description: '',
        },
        {
          id: '2e3477fb-8886-4fcd-abe0-b68817d19588',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          key: 'bilingual traffic signs',
          title: 'bilingual traffic signs',
          subtitle: 'placas de sinalização bilíngue',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-70af38c0-225e-4521-8c7f-85feaa06151b.mpeg',
          example: 'My city has bilingual traffic signs.',
          exampleTranslate: 'Minha cidade tem placas de sinalização bilíngue.',
          description: '',
        },
        {
          id: '73d4bb77-efd1-49a6-ab5a-1a2106d65301',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          key: 'immigrants',
          title: 'immigrants',
          subtitle: 'imigrantes',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-3-fa999171-3f49-4752-a376-7e1777a2e3af-1c18767a-eb90-413b-9c56-a40aca3905e2.mpeg',
          example: 'We have immigrants that study in our school. ',
          exampleTranslate: 'Temos  imigrantes que estudam na nossa escola.',
          description: '',
        },
        {
          id: '5ace6ea0-3d21-4834-8312-ce93d0c8ae6d',
          createdAt: '2024-06-17T19:57:20.180Z',
          updatedAt: '2024-06-17T19:57:20.180Z',
          key: 'signs',
          title: 'signs',
          subtitle: 'placas',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-fe6da04e-f599-41e3-9ce2-616b7640c306.mpeg',
          example: 'There are different types of signs.',
          exampleTranslate: 'Existem diferentes tipos de placas.',
          description: '',
        },
      ],
    },
    {
      id: 'bfdb8ad5-5356-4b73-80c9-331b32c61133',
      createdAt: '2024-06-17T19:57:20.198Z',
      updatedAt: '2024-06-17T19:57:20.198Z',
      type: 'content',
      code: 'WU_TC_B1_P3',
      order: 4,
      status: 'activated',
      widgets: [
        {
          id: '09a708bc-710a-4eeb-a5ee-580e76089a76',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 1,
          type: 'subtitle',
          value: 'Traffic sign 1',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9cb83561-107d-4301-aaee-a9c810fe130f',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-fa999171-3f49-4752-a376-7e1777a2e3af-f42d19de-08d7-4af4-8ecc-c5786e4e1c61.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9e4cf2be-7c39-4dfd-ac65-be4dca68fca2',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 3,
          type: 'title',
          value: 'Fonte da imagem: sctododia.com.br',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'imagetitle',
          options: [],
        },
        {
          id: 'c2513438-2486-4e53-bd3f-16c42afecc53',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 4,
          type: 'longtext',
          value:
            'Cada *traffic sign* tem  palavra em inglês e em português mais um *icon* que representa aquele local.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ac7ec2dd-8006-4dc5-9908-34305712a10a',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 5,
          type: 'enunciated',
          value: 'Leia e repita.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '650be674-d278-4190-ab89-23dfbce544ea',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          order: 6,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-6-3-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: 'Bus station',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '5706cef1-dacd-4544-b9c4-d25d580ada54',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          key: 'icon',
          title: 'icon',
          subtitle: 'ícone',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-4bea7bac-6be7-4f94-9910-744eb55d7b5f.mpeg',
          example: 'Icons are visual representations.',
          exampleTranslate: 'Ícones são representações visuais.',
          description: '',
        },
        {
          id: '4bec5346-a829-42da-b80b-b7d5df694cd8',
          createdAt: '2024-06-17T19:57:20.198Z',
          updatedAt: '2024-06-17T19:57:20.198Z',
          key: 'traffic sign',
          title: 'traffic sign',
          subtitle: 'placa de trânsito',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-d3e9483e-714d-4cb4-a968-e8f8c07c7199.mpeg',
          example: 'Traffic signs are very important.',
          exampleTranslate: 'Placas de trânsito são muito importantes.',
          description: '',
        },
      ],
    },
    {
      id: 'f773623a-c998-406a-a778-0dfd38ed76b4',
      createdAt: '2024-06-17T19:57:20.238Z',
      updatedAt: '2024-06-17T19:57:20.238Z',
      type: 'content',
      code: 'WU_TC_B1_P4',
      order: 5,
      status: 'activated',
      widgets: [
        {
          id: 'fb4550a7-860e-4f21-a5bb-6ff28999c243',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 1,
          type: 'subtitle',
          value: 'Traffic sign 2',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '824cf1d4-a3a8-4c72-8108-37c863b587ba',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 2,
          type: 'enunciated',
          value: 'Observe a imagem abaixo e responda.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a5dea0f0-092e-44a1-89b5-fb769b9508c9',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-f570d7d3-aaf0-4851-ba08-a995c33d51fa.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '612ef628-e7fe-4775-ac38-1132dbd0131a',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 4,
          type: 'title',
          value: 'Fonte da imagem: https://www.curitiba.pr.gov.br/',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'imagetitle',
          options: [],
        },
        {
          id: '2aa28b40-f15b-40a2-8b50-eb06da95eef9',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 5,
          type: 'longtext',
          value: 'Is this a bilingual traffic sign?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e33914e4-938c-4da7-b18e-31479a8adbd5',
          createdAt: '2024-06-17T19:57:20.238Z',
          updatedAt: '2024-06-17T19:57:20.238Z',
          order: 6,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'c7f9eec0-67e7-4ecf-82b2-5145631d78a8',
              createdAt: '2024-06-17T19:57:20.238Z',
              updatedAt: '2024-06-17T19:57:20.238Z',
              order: 1,
              text: 'Yes, it is.',
              textRelated: null,
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-0-fa999171-3f49-4752-a376-7e1777a2e3af-57a6eee7-4d39-451b-a25e-2e823281ad4f.mpeg',
              audioRelated: null,
              right: true,
            },
            {
              id: 'dff54921-b029-4dc7-8805-2e70866a88cb',
              createdAt: '2024-06-17T19:57:20.238Z',
              updatedAt: '2024-06-17T19:57:20.238Z',
              order: 2,
              text: "No, it isn't.",
              textRelated: null,
              image: null,
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-1-fa999171-3f49-4752-a376-7e1777a2e3af-811170e0-4fee-4ba9-890f-22a9453cd29c.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '6270e684-2511-40de-b2da-732b6b96bb8e',
      createdAt: '2024-06-17T19:57:20.254Z',
      updatedAt: '2024-06-17T19:57:20.254Z',
      type: 'content',
      code: 'WU_TC_B1_P5',
      order: 6,
      status: 'activated',
      widgets: [
        {
          id: 'e86114f9-4cde-4b65-b337-48517105c4a2',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          order: 1,
          type: 'subtitle',
          value: 'Traffic sign 3',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'fb0a52df-0a69-4de2-9677-a4abaeee59ee',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          order: 2,
          type: 'enunciated',
          value:
            'Observe os *icons* que são usados para representar os lugares e responda.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b3be9552-efe4-403b-b913-c1310e7fe7b4',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-fa999171-3f49-4752-a376-7e1777a2e3af-0d3e1781-3297-4eca-87ac-14468aabd3ca.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '75921bbe-0751-4c02-b70f-fc7598480234',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          order: 4,
          type: 'title',
          value: 'Fonte da imagem: itajai.sc.gov.br',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'imagetitle',
          options: [],
        },
        {
          id: '34e84c54-4c98-465f-8dff-8c300b912a5b',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          order: 5,
          type: 'longtext',
          value:
            'Na sua opinião, os icons usados no traffic sign acima representam bem as palavras *beaches* e *cruise terminal*?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '17548128-5fac-43a4-80c4-06d83948f6c4',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          key: 'icons',
          title: 'icons',
          subtitle: 'ícones',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-0b8c6770-4124-42f6-807b-a9b04b60fcbc.mpeg',
          example: 'Icons are visual representations.',
          exampleTranslate: 'Ícones são representações visuais.',
          description: '',
        },
        {
          id: 'd9527107-5c91-4070-865e-d48ff8336393',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          key: 'cruise terminal',
          title: 'cruise terminal',
          subtitle: 'terminal de cruzeiros',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-f3122e53-e356-4c2f-9a50-a40c26f302eb.mpeg',
          example: "I can't find the cruises terminal.",
          exampleTranslate: 'Não consigo achar o terminal de cruzeiros.',
          description: '',
        },
        {
          id: '3ca910c0-ead2-4a1c-bad9-ced13618cdd3',
          createdAt: '2024-06-17T19:57:20.254Z',
          updatedAt: '2024-06-17T19:57:20.254Z',
          key: 'beaches',
          title: 'beaches',
          subtitle: 'praias',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-cf772a6a-cbab-4e4f-8c21-7c73d9d0f43e.mpeg',
          example: 'There is a beach near by.',
          exampleTranslate: 'Tem uma praia próxima daqui.',
          description: '',
        },
      ],
    },
    {
      id: '84a075be-c01d-463c-b906-6231e9ef32af',
      createdAt: '2024-06-17T19:57:20.269Z',
      updatedAt: '2024-06-17T19:57:20.269Z',
      type: 'content',
      code: 'WU_TC_B1_P6',
      order: 7,
      status: 'activated',
      widgets: [
        {
          id: 'cd6d6088-95ec-41e9-a51e-da59f8855def',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          order: 1,
          type: 'subtitle',
          value: 'Traffic sign 4',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0dca73f4-1c60-4632-a523-28ea69a97c34',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          order: 2,
          type: 'enunciated',
          value: 'Observe the traffic sign *below*.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'af53167e-7ef1-47bb-9567-28da02c10373',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-928835d1-b451-49f1-b3fb-0cadc7dd04ba.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3f613154-9267-4a02-bbc8-8d62e4ff091e',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          order: 4,
          type: 'title',
          value: 'Fonte da Imagem: informacaolusonda.blogspot.com',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'imagetitle',
          options: [],
        },
        {
          id: 'a5643cbb-c00c-4633-9a81-c8598619fc15',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          order: 5,
          type: 'longtext',
          value:
            'Você percebeu como podemos aprender o inglês e quem sabe até outras línguas com a *traffic sign*?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '1266bfba-3760-4e0e-a8ea-933256b27da3',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          key: 'below',
          title: 'below',
          subtitle: 'abaixo',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-5004daf7-27cd-4a11-84e8-62a74efe7f5b.mpeg',
          example: 'Observe the traffic sign below.',
          exampleTranslate: 'Observe a placa de trânsito abaixo.',
          description: '',
        },
        {
          id: '77bac9f6-2782-4be3-94cb-52a8c3056d42',
          createdAt: '2024-06-17T19:57:20.269Z',
          updatedAt: '2024-06-17T19:57:20.269Z',
          key: 'traffic sign',
          title: 'traffic sign',
          subtitle: 'placa de sinalização',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-9c54adda-dddf-4f51-a78e-e06303870d80.mpeg',
          example: 'There is a traffic sign ahead.',
          exampleTranslate: 'Tem uma placa de sinalização logo a frente.',
          description: '',
        },
      ],
    },
    {
      id: 'b3e1d229-463a-46e9-981c-515e3a387edb',
      createdAt: '2024-06-17T19:57:20.285Z',
      updatedAt: '2024-06-17T19:57:20.285Z',
      type: 'content',
      code: 'WU_TC_B1_P7',
      order: 8,
      status: 'activated',
      widgets: [
        {
          id: '0f9945fb-8a77-4c64-9871-c0033a242fd4',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          order: 1,
          type: 'subtitle',
          value: 'Traffic sign 5',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1f89855d-0dd6-461c-a4b2-ab937f5c4dfb',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          order: 2,
          type: 'enunciated',
          value: 'Observe this traffic sign *now*.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ffd96773-23f7-41f4-abd4-f51a64839943',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-9c376d34-4380-48f6-b3cc-03edcdbfc2ae.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'af7a6714-13c1-4a51-be4f-f3bf610b8347',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          order: 4,
          type: 'title',
          value: 'Fonte da imagem: curitiba.pr.gov.br',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'imagetitle',
          options: [],
        },
        {
          id: 'f225a8eb-42c1-4cc4-b421-bdb414298f5e',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          order: 5,
          type: 'longtext',
          value:
            'Você percebeu que a palavra <b>Liberdade</b> foi traduzida por <b>Liberty</b>, enquanto Guaíra manteve sua escrita no idioma original?<br>\r\nIsso ocorre porque existe uma palavra equivalente em inglês. Mas a regra, se a palavra for usada como nome próprio, é mantê-la no idioma original. Imagine dizer "Grande Ben" ao invé de Big Ben para o relógio mais famoso do mundo! ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'a6a31be8-077a-4847-b2fa-2f50340f2af1',
          createdAt: '2024-06-17T19:57:20.285Z',
          updatedAt: '2024-06-17T19:57:20.285Z',
          key: 'now',
          title: 'now',
          subtitle: 'agora',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-bb761e44-6cf1-4961-9292-c8d6dffb3def.mpeg',
          example: 'Repeat now.',
          exampleTranslate: 'Repita agora.',
          description: '',
        },
      ],
    },
    {
      id: 'cc206220-e730-4119-affa-4a2a950c4a27',
      createdAt: '2024-06-17T19:57:20.299Z',
      updatedAt: '2024-06-17T19:57:20.299Z',
      type: 'activity',
      code: 'WU_TC_B2_P8_P13.1',
      order: 9,
      status: 'activated',
      widgets: [
        {
          id: '807a8b49-5738-493c-877e-7a1f8c42a244',
          createdAt: '2024-06-17T19:57:20.299Z',
          updatedAt: '2024-06-17T19:57:20.299Z',
          order: 1,
          type: 'title',
          value: 'Selecione o ícone correto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '543a2bc5-f902-4c6d-a3ad-a2093d8b876b',
          createdAt: '2024-06-17T19:57:20.299Z',
          updatedAt: '2024-06-17T19:57:20.299Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-2-enunciated-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'f520d341-e67e-4ddc-8f59-d2649b586407',
          createdAt: '2024-06-17T19:57:20.299Z',
          updatedAt: '2024-06-17T19:57:20.299Z',
          order: 3,
          type: 'enunciated',
          value: 'BEACH',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '5d335276-4ed4-4bbc-98ed-b3d891f5378d',
          createdAt: '2024-06-17T19:57:20.299Z',
          updatedAt: '2024-06-17T19:57:20.299Z',
          order: 4,
          type: 'selectimage',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'aa58a30c-c116-4a95-9466-e2438694ccf5',
              createdAt: '2024-06-17T19:57:20.299Z',
              updatedAt: '2024-06-17T19:57:20.299Z',
              order: 0,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-0-fa999171-3f49-4752-a376-7e1777a2e3af-d827c405-d8be-4d7b-89f8-b39ccf75ffc4.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '4d06d96c-7d23-46c5-a007-543edf8ac209',
              createdAt: '2024-06-17T19:57:20.299Z',
              updatedAt: '2024-06-17T19:57:20.299Z',
              order: 1,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-1-fa999171-3f49-4752-a376-7e1777a2e3af-fce670b6-a6ee-480f-95e8-0ecd4457f20b.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '297cc850-710e-446c-b164-77560843ebd7',
      createdAt: '2024-06-17T19:57:20.313Z',
      updatedAt: '2024-06-17T19:57:20.313Z',
      type: 'activity',
      code: 'WU_TC_B2_P8_P13.2',
      order: 10,
      status: 'activated',
      widgets: [
        {
          id: '6febbf1f-766f-475c-b1cb-7b7c472fab82',
          createdAt: '2024-06-17T19:57:20.313Z',
          updatedAt: '2024-06-17T19:57:20.313Z',
          order: 1,
          type: 'title',
          value: 'Selecione o ícone correto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '0a08fbcd-b0da-452a-86a2-7b395c8d4175',
          createdAt: '2024-06-17T19:57:20.313Z',
          updatedAt: '2024-06-17T19:57:20.313Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-0-enunciated-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '284b483d-a99c-4978-92a6-ce78814ae4f7',
          createdAt: '2024-06-17T19:57:20.313Z',
          updatedAt: '2024-06-17T19:57:20.313Z',
          order: 3,
          type: 'enunciated',
          value: 'BUS STATION',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '72da7e3e-fa55-46e4-9df9-033a7d6f8e96',
          createdAt: '2024-06-17T19:57:20.313Z',
          updatedAt: '2024-06-17T19:57:20.313Z',
          order: 4,
          type: 'selectimage',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '57ba2f84-50f7-4784-9d5b-0410cde7d288',
              createdAt: '2024-06-17T19:57:20.313Z',
              updatedAt: '2024-06-17T19:57:20.313Z',
              order: 0,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-0-fa999171-3f49-4752-a376-7e1777a2e3af-a7a65832-db43-4684-8476-0f3b8bf37a99.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: 'dd4445f7-f66a-41b1-b142-694f06ac2ad3',
              createdAt: '2024-06-17T19:57:20.313Z',
              updatedAt: '2024-06-17T19:57:20.313Z',
              order: 1,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-1-fa999171-3f49-4752-a376-7e1777a2e3af-3646b9c4-1bee-4743-a778-b156ec790c03.jpeg',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '69180b75-fa64-4d9e-8ea4-50050e6ccaff',
      createdAt: '2024-06-17T19:57:20.332Z',
      updatedAt: '2024-06-17T19:57:20.332Z',
      type: 'activity',
      code: 'WU_TC_B2_P8_P13.3',
      order: 11,
      status: 'activated',
      widgets: [
        {
          id: 'b172c963-a9af-44a4-8781-ca0333378216',
          createdAt: '2024-06-17T19:57:20.332Z',
          updatedAt: '2024-06-17T19:57:20.332Z',
          order: 1,
          type: 'title',
          value: 'Selecione o ícone correto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'a33d725c-dea7-45ce-b15b-258ccba3557e',
          createdAt: '2024-06-17T19:57:20.332Z',
          updatedAt: '2024-06-17T19:57:20.332Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-enunciated-fa999171-3f49-4752-a376-7e1777a2e3af-319b3ef0-c9f8-458e-8a27-33c5c7dac1d7.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: 'e545d35c-866a-4c36-93b4-a09f89930231',
          createdAt: '2024-06-17T19:57:20.332Z',
          updatedAt: '2024-06-17T19:57:20.332Z',
          order: 3,
          type: 'enunciated',
          value: 'CRUISE TERMINAL',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '6e8d671b-ebc8-4ef9-89b2-a16872a7f515',
          createdAt: '2024-06-17T19:57:20.332Z',
          updatedAt: '2024-06-17T19:57:20.332Z',
          order: 4,
          type: 'selectimage',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '9741c35e-8077-49a1-a1e3-d620d9bbaabf',
              createdAt: '2024-06-17T19:57:20.332Z',
              updatedAt: '2024-06-17T19:57:20.332Z',
              order: 0,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-0-fa999171-3f49-4752-a376-7e1777a2e3af-d128e253-44c5-45bb-b1d9-a60691d52d7e.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '48b09a09-02c0-4d94-bfcd-8bc89662fafa',
              createdAt: '2024-06-17T19:57:20.332Z',
              updatedAt: '2024-06-17T19:57:20.332Z',
              order: 1,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-1-fa999171-3f49-4752-a376-7e1777a2e3af-e9414ded-b108-4f9d-a332-90652a591cf1.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '708312ca-5000-44a8-99c2-73f568280c69',
      createdAt: '2024-06-17T19:57:20.346Z',
      updatedAt: '2024-06-17T19:57:20.346Z',
      type: 'activity',
      code: 'WU_TC_B2_P8_P13.4',
      order: 12,
      status: 'activated',
      widgets: [
        {
          id: '17bd1c0b-23bf-447d-b891-27dbb04326e5',
          createdAt: '2024-06-17T19:57:20.346Z',
          updatedAt: '2024-06-17T19:57:20.346Z',
          order: 1,
          type: 'title',
          value: 'Selecione o ícone correto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'fcb3bffd-830e-4996-a67b-8d2cd4eda918',
          createdAt: '2024-06-17T19:57:20.346Z',
          updatedAt: '2024-06-17T19:57:20.346Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-4-enunciated-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '268c897b-ac7d-4b5b-a593-9e5ac8194e5d',
          createdAt: '2024-06-17T19:57:20.346Z',
          updatedAt: '2024-06-17T19:57:20.346Z',
          order: 3,
          type: 'enunciated',
          value: 'STADIUM',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '2ff1e0a9-ad5a-4024-bff7-2f5d9ac86950',
          createdAt: '2024-06-17T19:57:20.346Z',
          updatedAt: '2024-06-17T19:57:20.346Z',
          order: 4,
          type: 'selectimage',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '1c0210a9-7e3f-47c1-8f07-bb52c1e5b483',
              createdAt: '2024-06-17T19:57:20.346Z',
              updatedAt: '2024-06-17T19:57:20.346Z',
              order: 0,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-0-fa999171-3f49-4752-a376-7e1777a2e3af-6eb2a14a-b92c-4974-96a5-0e2754727bd8.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: 'b8fb0ea8-a76c-4e83-b260-55468be30e0b',
              createdAt: '2024-06-17T19:57:20.346Z',
              updatedAt: '2024-06-17T19:57:20.346Z',
              order: 1,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-1-fa999171-3f49-4752-a376-7e1777a2e3af-a448058e-6cb7-4a7a-b082-01ed65fc51a7.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '22355287-facd-4ebe-99f4-08245234af33',
      createdAt: '2024-06-17T19:57:20.362Z',
      updatedAt: '2024-06-17T19:57:20.362Z',
      type: 'activity',
      code: 'WU_TC_B2_P8_P13.5',
      order: 13,
      status: 'activated',
      widgets: [
        {
          id: 'b2bbc65a-ce82-4c7c-ae1e-dd3665de4181',
          createdAt: '2024-06-17T19:57:20.362Z',
          updatedAt: '2024-06-17T19:57:20.362Z',
          order: 1,
          type: 'title',
          value: 'Selecione o ícone correto.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '4e007288-22c0-4cb9-a565-8037bfb37277',
          createdAt: '2024-06-17T19:57:20.362Z',
          updatedAt: '2024-06-17T19:57:20.362Z',
          order: 2,
          type: 'audio',
          value:
            'https://go2dev.s3.amazonaws.com/questions/audios/question-5-enunciated-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'audioquestion',
          options: [],
        },
        {
          id: '9ce84bdf-7e88-4be1-9968-a2f50ae0a5db',
          createdAt: '2024-06-17T19:57:20.362Z',
          updatedAt: '2024-06-17T19:57:20.362Z',
          order: 3,
          type: 'enunciated',
          value: 'THEATER',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '541036a1-e9e6-467d-ab02-b21da0910075',
          createdAt: '2024-06-17T19:57:20.362Z',
          updatedAt: '2024-06-17T19:57:20.362Z',
          order: 4,
          type: 'selectimage',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '1cac8f22-b20f-47ac-a0d8-e2428e170c51',
              createdAt: '2024-06-17T19:57:20.362Z',
              updatedAt: '2024-06-17T19:57:20.362Z',
              order: 0,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-0-fa999171-3f49-4752-a376-7e1777a2e3af-9d359e81-e471-490d-a419-110ca5f52526.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '54d078c3-bdeb-43c4-8f56-2b53f91d2935',
              createdAt: '2024-06-17T19:57:20.362Z',
              updatedAt: '2024-06-17T19:57:20.362Z',
              order: 1,
              text: '',
              textRelated: '',
              image:
                'https://go2dev.s3.amazonaws.com/questions/answer-one-1-fa999171-3f49-4752-a376-7e1777a2e3af-ce880e7c-93a3-40bb-ae32-a96fdda49a34.png',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b0355d18-c6c7-4e25-9bd1-96df36c08d1b',
      createdAt: '2024-06-17T19:57:20.379Z',
      updatedAt: '2024-06-17T19:57:20.379Z',
      type: 'content',
      code: 'WU_TC_B2_P1',
      order: 14,
      status: 'activated',
      widgets: [
        {
          id: 'bf477904-6dab-4e0d-ab99-313680f402f4',
          createdAt: '2024-06-17T19:57:20.379Z',
          updatedAt: '2024-06-17T19:57:20.379Z',
          order: 1,
          type: 'subtitle',
          value: 'Palavras cognatas',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '22c9ba04-e0cf-46e7-ac93-1f1a8cbee0be',
          createdAt: '2024-06-17T19:57:20.379Z',
          updatedAt: '2024-06-17T19:57:20.379Z',
          order: 2,
          type: 'longtext',
          value:
            'Você percebeu que algumas palavras são bem parecidas com o português e você consegue facilmente descobrir o seu significado?<br><b>Veja os exemplos a seguir:</b><br>Que palavras em português você se lembra quando lê as palavras <b>CONTINUE, IMAGE, TOURISM e IMMIGRATION</b>? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '39700bc6-7fb1-4c88-8c0a-07440b5f441c',
          createdAt: '2024-06-17T19:57:20.379Z',
          updatedAt: '2024-06-17T19:57:20.379Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-6-8-fa999171-3f49-4752-a376-7e1777a2e3af.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '22817fd7-e013-427b-906c-c2fc716af35a',
      createdAt: '2024-06-17T19:57:20.390Z',
      updatedAt: '2024-06-17T19:57:20.390Z',
      type: 'content',
      code: 'WU_TC_B2_P2',
      order: 15,
      status: 'activated',
      widgets: [
        {
          id: '8c3a246f-a1e1-4179-95a0-0834240e6df6',
          createdAt: '2024-06-17T19:57:20.390Z',
          updatedAt: '2024-06-17T19:57:20.390Z',
          order: 1,
          type: 'longtext',
          value:
            'Você provavelmente pensou em <b>continuar, imagem, turismo e imigração</b>. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f740334b-3a14-4af3-b5d7-0a794c0aa790',
          createdAt: '2024-06-17T19:57:20.390Z',
          updatedAt: '2024-06-17T19:57:20.390Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-9-fa999171-3f49-4752-a376-7e1777a2e3af.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '91de0c48-77f2-4a94-b01a-79f8a00f343b',
          createdAt: '2024-06-17T19:57:20.390Z',
          updatedAt: '2024-06-17T19:57:20.390Z',
          order: 3,
          type: 'longtext',
          value:
            'Essas palavras em inglês e português não só se parecem, mas também compartilham os mesmos significados, mostrando como os dois idiomas estão conectados!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '0f391060-3e1d-4a0f-b267-edbd92bdf193',
      createdAt: '2024-06-17T19:57:20.402Z',
      updatedAt: '2024-06-17T19:57:20.402Z',
      type: 'content',
      code: 'WU_TC_B2_P4',
      order: 16,
      status: 'activated',
      widgets: [
        {
          id: '4e4f4ecb-be9a-4a61-b794-2cc8d3e705ea',
          createdAt: '2024-06-17T19:57:20.402Z',
          updatedAt: '2024-06-17T19:57:20.402Z',
          order: 1,
          type: 'enunciated',
          value:
            'Você viu como a palavra <b>ICONS</b> também se parece com o português? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '03c05ccf-e9f7-4031-a091-42b851afba06',
          createdAt: '2024-06-17T19:57:20.402Z',
          updatedAt: '2024-06-17T19:57:20.402Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-73805ba4-08b7-42a2-8cab-8ed52f6a9706.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'e4ceb270-831d-449e-a208-101ef5d567d6',
          createdAt: '2024-06-17T19:57:20.402Z',
          updatedAt: '2024-06-17T19:57:20.402Z',
          order: 3,
          type: 'longtext',
          value:
            'Assim como as palavras <b><i>continue</i></b>, e <b><i>image</i></b>, a palavra <b><i>icons</i></b> é uma palavra <color-6268e2><b>COGNATA</b></color>, ou seja, ela tem a mesma origem e significado em ambos os idiomas.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '54d6a8ff-de90-4e9c-ac76-019bbbf7e251',
          createdAt: '2024-06-17T19:57:20.402Z',
          updatedAt: '2024-06-17T19:57:20.402Z',
          order: 4,
          type: 'longtext',
          value:
            '<b>Icons</b> são símbolos gráficos ou imagens que representam algo como você viu nas placas de sinalização bilíngue e na atividade anterior.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '1342a536-d23d-40ca-9b2c-41efed264c89',
      createdAt: '2024-06-17T19:57:20.414Z',
      updatedAt: '2024-06-17T19:57:20.414Z',
      type: 'content',
      code: 'WU_TC_B2_P5_A8',
      order: 17,
      status: 'activated',
      widgets: [
        {
          id: 'c2203511-2c8b-413d-8df6-c8d00a76598f',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          order: 1,
          type: 'longtext',
          value:
            'Falando em placa de sinalização. Você sabia que cada *color* representa um tipo de *traffic sign*?\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '26dc06c2-48e9-4d71-b867-09944d55111e',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          order: 2,
          type: 'audio',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/audios/page-widget-6-12-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
          feedback: false,
          label: 'Ouça e repita as cores.',
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '09287a17-f53b-4ed9-8cc4-8b676005f168',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-c10f26e5-e5ca-4834-9717-6a74378f5e5c.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '5ff189fa-2916-49cb-9a41-2461b0784b38',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          order: 4,
          type: 'longtext',
          value:
            'Você notou que as palavras regulatory, construction, informative, public e recreation são cognatas? Safety, warning e guidance já não são fáceis de identificar sem um dicionário. Use o *glossary* ou um dicionário para checar o significado de palavras novas.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'c478b387-7000-4bc4-8561-d04312b08b7c',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          key: 'color',
          title: 'color',
          subtitle: 'cor',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-35463558-83e7-4873-a16f-250dfeee3e40.mpeg',
          example: 'This color is beautiful.',
          exampleTranslate: 'Essa cor é linda.',
          description: '',
        },
        {
          id: '913303da-12eb-4a01-9912-41be12fb98f0',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          key: 'traffic sign',
          title: 'traffic sign',
          subtitle: 'placa de sinalização',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-93943f30-a5fc-4ebd-a9b0-415a4b9fbae7.mpeg',
          example: 'We need more traffic signs here.',
          exampleTranslate: 'Precisamos de mais placas de sinalização aqui.',
          description: '',
        },
        {
          id: '72e774ec-84dd-429a-b3b1-7ee43364a369',
          createdAt: '2024-06-17T19:57:20.414Z',
          updatedAt: '2024-06-17T19:57:20.414Z',
          key: 'glossary',
          title: 'glossary',
          subtitle: 'glossário',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-2-fa999171-3f49-4752-a376-7e1777a2e3af-92fdc43f-ba8e-4173-8809-7328f8112446.mpeg',
          example: 'Use the glossary whenever you need.',
          exampleTranslate: 'Use o glossário sempre que você precisar.',
          description: '',
        },
      ],
    },
    {
      id: '79750fac-b921-47ef-9be6-21c3cd23811c',
      createdAt: '2024-06-17T19:57:20.429Z',
      updatedAt: '2024-06-17T19:57:20.429Z',
      type: 'content',
      code: 'WU_TC_B3_P1',
      order: 18,
      status: 'activated',
      widgets: [
        {
          id: '9fdcafd3-fd5d-480a-b62f-c9180ccc0c89',
          createdAt: '2024-06-17T19:57:20.429Z',
          updatedAt: '2024-06-17T19:57:20.429Z',
          order: 1,
          type: 'subtitle',
          value: 'Bilingual Traffic Signs',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '07f21533-df16-4bff-a307-bf0f0606832b',
          createdAt: '2024-06-17T19:57:20.429Z',
          updatedAt: '2024-06-17T19:57:20.429Z',
          order: 2,
          type: 'spacer',
          value: '20',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'spacer',
          options: [],
        },
        {
          id: '63dd1f2d-7ef7-467b-a6e0-53ce3e4df51a',
          createdAt: '2024-06-17T19:57:20.429Z',
          updatedAt: '2024-06-17T19:57:20.429Z',
          order: 3,
          type: 'longtext',
          value:
            'Agora que vimos alguns exemplos de placas de sinalização bilíngue, ou *Bilingual Traffic Signs*, que estão aparecendo mais em cidades e áreas turísticas, vamos ler alguns trechos de matérias sobre essas placas e entender sua importância e finalidade.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a10490e5-bed2-4bdb-bc6d-f5d94fe85de3',
          createdAt: '2024-06-17T19:57:20.429Z',
          updatedAt: '2024-06-17T19:57:20.429Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-13-fa999171-3f49-4752-a376-7e1777a2e3af.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'e1cac35c-245c-4afd-9d76-2e65aefd02f3',
          createdAt: '2024-06-17T19:57:20.429Z',
          updatedAt: '2024-06-17T19:57:20.429Z',
          key: 'Bilingual Traffic Signs',
          title: 'Bilingual Traffic Signs',
          subtitle: 'Placas de trânsito bilíngues',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-5e816586-7e01-43e6-8b1a-a733b0bc3bec.mpeg',
          example: 'Can you read the bilingual traffic sign?',
          exampleTranslate: 'Você consegue ler a placa de trânsito bilíngue?',
          description: '',
        },
      ],
    },
    {
      id: 'bd89cbf6-fdb2-4d9c-b7ab-8d9fea69586d',
      createdAt: '2024-06-17T19:57:20.442Z',
      updatedAt: '2024-06-17T19:57:20.442Z',
      type: 'content',
      code: 'WU_TC_B3_P2_A9',
      order: 19,
      status: 'activated',
      widgets: [
        {
          id: '85719f3d-d10b-4b26-b48f-423ff2b55ec4',
          createdAt: '2024-06-17T19:57:20.442Z',
          updatedAt: '2024-06-17T19:57:20.442Z',
          order: 1,
          type: 'enunciated',
          value: 'Leia o trecho da matéria A.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '5ab63f0d-7048-40d3-bb57-3e0265834d83',
          createdAt: '2024-06-17T19:57:20.442Z',
          updatedAt: '2024-06-17T19:57:20.442Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-fa999171-3f49-4752-a376-7e1777a2e3af-f1e8ad2d-f581-4b27-a984-af46eb09c096.png',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '53216da5-43e9-4751-bc54-07c364d3b9eb',
      createdAt: '2024-06-17T19:57:20.452Z',
      updatedAt: '2024-06-17T19:57:20.452Z',
      type: 'content',
      code: 'WU_TC_B3_P3_A10',
      order: 20,
      status: 'activated',
      widgets: [
        {
          id: 'b3a6c18b-f15d-418f-89ce-458b573888b4',
          createdAt: '2024-06-17T19:57:20.452Z',
          updatedAt: '2024-06-17T19:57:20.452Z',
          order: 1,
          type: 'enunciated',
          value: 'Responda.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3f76c61e-4c01-4c80-bccb-d6e4560419e1',
          createdAt: '2024-06-17T19:57:20.452Z',
          updatedAt: '2024-06-17T19:57:20.452Z',
          order: 2,
          type: 'longtext',
          value:
            'De acordo com o texto A, quantas rotas turísticas foram incluídas no projeto de sinalização bilíngue na cidade de Fortaleza?\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '78c90228-8793-4b98-b9aa-60caf689e89a',
          createdAt: '2024-06-17T19:57:20.452Z',
          updatedAt: '2024-06-17T19:57:20.452Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'efe0ff1b-8738-4aed-9ac0-a7e703c81fd8',
              createdAt: '2024-06-17T19:57:20.452Z',
              updatedAt: '2024-06-17T19:57:20.452Z',
              order: 1,
              text: 'six',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-15-option-0-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: 'd54e7017-a5b8-429e-acd7-bb4feb6f7bea',
              createdAt: '2024-06-17T19:57:20.452Z',
              updatedAt: '2024-06-17T19:57:20.452Z',
              order: 2,
              text: 'sixteen',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-option-1-fa999171-3f49-4752-a376-7e1777a2e3af-2de591aa-65eb-4d82-9630-ddf28a88a8bd.mpeg',
              audioRelated: null,
              right: true,
            },
            {
              id: 'f249217d-c5eb-4297-af97-7e6264af0a08',
              createdAt: '2024-06-17T19:57:20.452Z',
              updatedAt: '2024-06-17T19:57:20.452Z',
              order: 3,
              text: 'ten',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-15-option-2-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '05bb1f47-fc81-4c2c-89d5-33ba6ae90129',
      createdAt: '2024-06-17T19:57:20.466Z',
      updatedAt: '2024-06-17T19:57:20.466Z',
      type: 'content',
      code: 'WU_TC_B3_P4_A11',
      order: 21,
      status: 'activated',
      widgets: [
        {
          id: 'e48b1599-e43a-4235-befc-3b3b6004e4be',
          createdAt: '2024-06-17T19:57:20.466Z',
          updatedAt: '2024-06-17T19:57:20.466Z',
          order: 1,
          type: 'enunciated',
          value: '🗣️Dê a sua opinião.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b9f804ad-b30a-4b2c-9e1f-ddb35cab45c7',
          createdAt: '2024-06-17T19:57:20.466Z',
          updatedAt: '2024-06-17T19:57:20.466Z',
          order: 2,
          type: 'longtext',
          value:
            'Por que há uma preocupação cada vez maior com a *bilingual communication* em estradas, ruas e espaços públicos?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '82df5c8e-1d80-41bb-8981-b0045ba31903',
          createdAt: '2024-06-17T19:57:20.466Z',
          updatedAt: '2024-06-17T19:57:20.466Z',
          order: 3,
          type: 'textbox',
          value: '5',
          feedback: false,
          label: 'Write your answer here.',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '269eadf0-d911-40d0-af92-113189cbae96',
          createdAt: '2024-06-17T19:57:20.466Z',
          updatedAt: '2024-06-17T19:57:20.466Z',
          key: 'bilingual communication',
          title: 'bilingual communication',
          subtitle: 'comunicação bilíngue',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-0aac4557-d90a-4904-bb55-3e3e51288cc1.mpeg',
          example: 'Bilíngual communication is really importante nowadays.',
          exampleTranslate:
            'Nos dias de hoje, comunicação bilíngue é muito importante.',
          description: '',
        },
      ],
    },
    {
      id: 'fbd632c3-1633-4bb6-89bc-10f0c39f443b',
      createdAt: '2024-06-17T19:57:20.479Z',
      updatedAt: '2024-06-17T19:57:20.479Z',
      type: 'content',
      code: 'WU_TC_B3_P5_A12',
      order: 22,
      status: 'activated',
      widgets: [
        {
          id: 'bfa7b6bc-08f6-4557-b9e0-7bbe5e0e23fc',
          createdAt: '2024-06-17T19:57:20.479Z',
          updatedAt: '2024-06-17T19:57:20.479Z',
          order: 1,
          type: 'enunciated',
          value: 'Leia o trecho da matéria B:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a6699623-81b9-458b-886c-cb9181d6b577',
          createdAt: '2024-06-17T19:57:20.479Z',
          updatedAt: '2024-06-17T19:57:20.479Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-17-fa999171-3f49-4752-a376-7e1777a2e3af.png',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '0bbc306d-9919-4ab2-ae6c-72f63dcbaeab',
      createdAt: '2024-06-17T19:57:20.490Z',
      updatedAt: '2024-06-17T19:57:20.490Z',
      type: 'content',
      code: 'WU_TC_B3_P6_A13',
      order: 23,
      status: 'activated',
      widgets: [
        {
          id: '43e32a55-def0-456b-a24b-483ece40e8c8',
          createdAt: '2024-06-17T19:57:20.490Z',
          updatedAt: '2024-06-17T19:57:20.490Z',
          order: 1,
          type: 'enunciated',
          value: 'Responda de acordo com o texto B.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f1cfe9e0-df4f-40a1-9f93-1d6119923ca9',
          createdAt: '2024-06-17T19:57:20.490Z',
          updatedAt: '2024-06-17T19:57:20.490Z',
          order: 2,
          type: 'longtext',
          value:
            'Em que idiomas as informações nas placas dos pontos turísticos serão disponibilizadas?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'template-1',
          options: [],
        },
        {
          id: 'a66ebbf8-978f-4285-8aff-49122e0523c3',
          createdAt: '2024-06-17T19:57:20.490Z',
          updatedAt: '2024-06-17T19:57:20.490Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'ea2cd8ae-1ad9-4ab6-81cd-e4d9a78303fa',
              createdAt: '2024-06-17T19:57:20.490Z',
              updatedAt: '2024-06-17T19:57:20.490Z',
              order: 1,
              text: 'English and Spanish',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-18-option-0-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: '2b08c085-10f4-4475-8a88-b1c43fd83f8e',
              createdAt: '2024-06-17T19:57:20.490Z',
              updatedAt: '2024-06-17T19:57:20.490Z',
              order: 2,
              text: 'Portuguese and Spanish',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-18-option-1-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: '8c6e14af-65dc-4d9a-85b5-f0d03198f982',
              createdAt: '2024-06-17T19:57:20.490Z',
              updatedAt: '2024-06-17T19:57:20.490Z',
              order: 3,
              text: 'Portuguese and English',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-18-option-2-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '36d6e2b8-11ed-4110-a6c7-78950f953367',
      createdAt: '2024-06-17T19:57:20.507Z',
      updatedAt: '2024-06-17T19:57:20.507Z',
      type: 'content',
      code: 'WU_TC_B3_P7_A14',
      order: 24,
      status: 'activated',
      widgets: [
        {
          id: 'f17c9423-e5d2-4d9a-b158-8e4e0d1f8d63',
          createdAt: '2024-06-17T19:57:20.507Z',
          updatedAt: '2024-06-17T19:57:20.507Z',
          order: 1,
          type: 'enunciated',
          value:
            'Selecione a alternativa que indica o motivo da colocação de placas de sinalização bilíngue.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f09c8eac-698c-4faf-8722-86ec2e918199',
          createdAt: '2024-06-17T19:57:20.507Z',
          updatedAt: '2024-06-17T19:57:20.507Z',
          order: 2,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '1efcb88d-8193-4d74-8494-a5066bf99d53',
              createdAt: '2024-06-17T19:57:20.507Z',
              updatedAt: '2024-06-17T19:57:20.507Z',
              order: 1,
              text: 'immigration',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-19-option-0-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
            {
              id: '1914eeaa-24fb-4244-82a5-a941813b4598',
              createdAt: '2024-06-17T19:57:20.507Z',
              updatedAt: '2024-06-17T19:57:20.507Z',
              order: 2,
              text: 'tourism',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-19-option-1-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: true,
            },
            {
              id: 'e2a5d347-0bee-43af-861b-b22c37ba7f89',
              createdAt: '2024-06-17T19:57:20.507Z',
              updatedAt: '2024-06-17T19:57:20.507Z',
              order: 3,
              text: 'learn English',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-2-19-option-2-fa999171-3f49-4752-a376-7e1777a2e3af.mpeg',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '422d4e65-1c8e-4dbc-b132-c5fe3497bc00',
      createdAt: '2024-06-17T19:57:20.521Z',
      updatedAt: '2024-06-17T19:57:20.521Z',
      type: 'content',
      code: 'WU_TC_B4_P1',
      order: 25,
      status: 'activated',
      widgets: [
        {
          id: 'ca0cc30d-6c7b-4e4e-8998-2a59efb89b49',
          createdAt: '2024-06-17T19:57:20.521Z',
          updatedAt: '2024-06-17T19:57:20.521Z',
          order: 1,
          type: 'subtitle',
          value: 'Tourists & Immigrants',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3b19909b-c35e-4d94-a4b3-6db806befde8',
          createdAt: '2024-06-17T19:57:20.521Z',
          updatedAt: '2024-06-17T19:57:20.521Z',
          order: 2,
          type: 'longtext',
          value:
            'Além dos *tourists*, há ainda os *immigrants*, pessoas que se mudam para outros países por diversos motivos. Lembre-se que, quando essas pessoas migram, elas levam consigo suas culturas, línguas e histórias de vida. <br>A seguir, você vai conhecer a <i>história de diferentes alunos imigrantes</i>.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'bf91c34f-266b-45da-8cb3-54e2fb28f07e',
          createdAt: '2024-06-17T19:57:20.521Z',
          updatedAt: '2024-06-17T19:57:20.521Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-4-fa999171-3f49-4752-a376-7e1777a2e3af-5fcde02a-9531-496f-bc45-b11b79b49671.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'd423f8c6-2f74-442d-9da1-b436c60fe7eb',
          createdAt: '2024-06-17T19:57:20.521Z',
          updatedAt: '2024-06-17T19:57:20.521Z',
          key: 'tourists',
          title: 'tourists',
          subtitle: 'turistas',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-a42c0dee-2f9a-47c2-935c-1ef59298f273.mpeg',
          example: 'There are many tourists here now.',
          exampleTranslate: 'Há muitos turistas aqui agora.',
          description: '',
        },
        {
          id: '5bc2f7d3-a62e-4a41-a8d5-bec8abb20b91',
          createdAt: '2024-06-17T19:57:20.521Z',
          updatedAt: '2024-06-17T19:57:20.521Z',
          key: 'immigrants',
          title: 'immigrants',
          subtitle: 'imigrantes',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-1-fa999171-3f49-4752-a376-7e1777a2e3af-ec70d9a8-deb5-44db-b2b7-b0cb9179200b.mpeg',
          example: 'Immigrants come from other countries. ',
          exampleTranslate: 'Imigrantes vêm de outros países.',
          description: '',
        },
      ],
    },
    {
      id: '267bc27c-bf68-4c53-a20c-06c0da801f8c',
      createdAt: '2024-06-17T19:57:20.535Z',
      updatedAt: '2024-06-17T19:57:20.535Z',
      type: 'content',
      code: 'WU_TC_B4_P2_A15',
      order: 26,
      status: 'activated',
      widgets: [
        {
          id: 'd72a9a70-51a3-4165-bff6-4cfbb00bec0e',
          createdAt: '2024-06-17T19:57:20.535Z',
          updatedAt: '2024-06-17T19:57:20.535Z',
          order: 1,
          type: 'enunciated',
          value: 'Assista o vídeo A com atenção.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c9ac065c-f22b-481b-b9bf-295023be2e66',
          createdAt: '2024-06-17T19:57:20.535Z',
          updatedAt: '2024-06-17T19:57:20.535Z',
          order: 2,
          type: 'video',
          value: 'aIFaYzephA4',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '1c304627-6453-4a44-9bde-6b82dcb36248',
          createdAt: '2024-06-17T19:57:20.535Z',
          updatedAt: '2024-06-17T19:57:20.535Z',
          order: 3,
          type: 'longtext',
          value: 'Fonte: TV cultura\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '503a862e-f1e2-4b49-91cf-8beca3c5baf0',
          createdAt: '2024-06-17T19:57:20.535Z',
          updatedAt: '2024-06-17T19:57:20.535Z',
          order: 4,
          type: 'longtext',
          value:
            'A seguir você vai responder uma pergunta sobre o vídeo. Leia a pergunte e assista novamente para revisar.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '93ae16da-4a1e-4aba-a4eb-39fbd1296bc9',
      createdAt: '2024-06-17T19:57:20.547Z',
      updatedAt: '2024-06-17T19:57:20.547Z',
      type: 'content',
      code: 'WU_TC_B4_P3_A16',
      order: 27,
      status: 'activated',
      widgets: [
        {
          id: '7638d38a-d9fb-4dc0-973b-f61a32be97f5',
          createdAt: '2024-06-17T19:57:20.547Z',
          updatedAt: '2024-06-17T19:57:20.547Z',
          order: 1,
          type: 'enunciated',
          value: 'Responda.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '2f7848ec-2d6f-4778-abbf-3bda3617b2d7',
          createdAt: '2024-06-17T19:57:20.547Z',
          updatedAt: '2024-06-17T19:57:20.547Z',
          order: 2,
          type: 'longtext',
          value:
            'Que estratégia a escola EMEF Infante Dom Henrique usou para ser mais inclusiva com os *foreign students*?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'd65c3205-4159-43e4-9520-ac0e6d7331db',
          createdAt: '2024-06-17T19:57:20.547Z',
          updatedAt: '2024-06-17T19:57:20.547Z',
          order: 3,
          type: 'longtext',
          value: 'Marque as alternativas conforme o vídeo A.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '8669e252-cd01-41ad-bb99-d903ad814fe0',
          createdAt: '2024-06-17T19:57:20.547Z',
          updatedAt: '2024-06-17T19:57:20.547Z',
          order: 4,
          type: 'multiselect',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '3fd7f003-2539-491c-bee4-eb97eb02c65f',
              createdAt: '2024-06-17T19:57:20.547Z',
              updatedAt: '2024-06-17T19:57:20.547Z',
              order: 1,
              text: 'Cartazes escritos nas línguas dos immigrant students.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: true,
            },
            {
              id: '2e700d65-aa2b-4fc7-9966-408666713bc7',
              createdAt: '2024-06-17T19:57:20.547Z',
              updatedAt: '2024-06-17T19:57:20.547Z',
              order: 2,
              text: 'Tem uma professora para cada aluno imigrante.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: false,
            },
            {
              id: '0dfd43c5-c507-46c0-b588-995e4a4914bf',
              createdAt: '2024-06-17T19:57:20.547Z',
              updatedAt: '2024-06-17T19:57:20.547Z',
              order: 3,
              text: 'As salas têm signs escritas em diferentes languages.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: true,
            },
            {
              id: '119aba97-8379-4ef9-8fee-9bf83dbbe9d6',
              createdAt: '2024-06-17T19:57:20.547Z',
              updatedAt: '2024-06-17T19:57:20.547Z',
              order: 4,
              text: 'Todo o material didático é apresentado na língua do immigrant student.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [
        {
          id: 'c8f02a15-fe15-44fa-8f6c-4622cca15639',
          createdAt: '2024-06-17T19:57:20.547Z',
          updatedAt: '2024-06-17T19:57:20.547Z',
          key: 'foreign students',
          title: 'foreign students',
          subtitle: 'aluno estrangeiro',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-fa999171-3f49-4752-a376-7e1777a2e3af-d6abe06d-16e0-4de0-a6b4-f9ed58bf522e.mpeg',
          example: 'There is a foreign student in my school.',
          exampleTranslate: 'Tem um aluno estrangeiro na minha escola.',
          description: '',
        },
      ],
    },
    {
      id: '13cf8c61-f5f3-44ad-bbac-d493685f0a7d',
      createdAt: '2024-06-17T19:57:20.564Z',
      updatedAt: '2024-06-17T19:57:20.564Z',
      type: 'content',
      code: 'WU_TC_B4_P4_A17',
      order: 28,
      status: 'activated',
      widgets: [
        {
          id: 'c156c084-7453-493b-a351-06fa22b831e5',
          createdAt: '2024-06-17T19:57:20.564Z',
          updatedAt: '2024-06-17T19:57:20.564Z',
          order: 1,
          type: 'enunciated',
          value: 'Assista o vídeo  B com atenção.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'aca32904-7728-4ed4-8b3b-aabcac136061',
          createdAt: '2024-06-17T19:57:20.564Z',
          updatedAt: '2024-06-17T19:57:20.564Z',
          order: 2,
          type: 'video',
          value: '7IsOx9THVQk',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '073f3a17-eb98-4ffa-9cc2-0b9ec9e21f7d',
          createdAt: '2024-06-17T19:57:20.564Z',
          updatedAt: '2024-06-17T19:57:20.564Z',
          order: 3,
          type: 'longtext',
          value: 'Fonte: Jornal da Gazeta\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0a76bfc5-ee6f-4a48-b0bb-b40e07ef2416',
          createdAt: '2024-06-17T19:57:20.564Z',
          updatedAt: '2024-06-17T19:57:20.564Z',
          order: 4,
          type: 'longtext',
          value:
            'A seguir você vai responder uma pergunta sobre o vídeo. Leia a pergunte e assista novamente para revisar.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '9a5bd878-86a8-49dc-851e-11e009ebcc6a',
      createdAt: '2024-06-17T19:57:20.574Z',
      updatedAt: '2024-06-17T19:57:20.574Z',
      type: 'content',
      code: 'WU_TC_B4_P5_A18',
      order: 29,
      status: 'activated',
      widgets: [
        {
          id: 'b26b36ba-a1c7-44ef-81a7-c56fb1c948c1',
          createdAt: '2024-06-17T19:57:20.574Z',
          updatedAt: '2024-06-17T19:57:20.574Z',
          order: 1,
          type: 'enunciated',
          value: 'Responda conforme o vídeo B.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '546fdfd5-6279-466c-b767-01ffdf07b014',
          createdAt: '2024-06-17T19:57:20.574Z',
          updatedAt: '2024-06-17T19:57:20.574Z',
          order: 2,
          type: 'longtext',
          value:
            'A reportagem mostrou os estudantes Breno e Rasnatti. Como o estudante Breno da Escola do Brás estava auxiliando o seu colega Rasnatti na adaptação?\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '621750fe-baf9-46f3-8095-af5d28fc2075',
          createdAt: '2024-06-17T19:57:20.574Z',
          updatedAt: '2024-06-17T19:57:20.574Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '72554e3e-6a20-4d10-baf0-7a97b2f8e201',
              createdAt: '2024-06-17T19:57:20.574Z',
              updatedAt: '2024-06-17T19:57:20.574Z',
              order: 1,
              text: 'Dando aulas de inglês para o Rasnatti.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: false,
            },
            {
              id: '0ac15537-f5a7-49c8-97e6-0d4c2d6f8077',
              createdAt: '2024-06-17T19:57:20.574Z',
              updatedAt: '2024-06-17T19:57:20.574Z',
              order: 2,
              text: 'Sendo solidário e auxiliando Rasnatti com a comunicação em português.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: true,
            },
            {
              id: 'fe0fc296-fc5f-40b4-a8c0-226b09587bb3',
              createdAt: '2024-06-17T19:57:20.574Z',
              updatedAt: '2024-06-17T19:57:20.574Z',
              order: 3,
              text: 'Fazendo as atividades pelo Rasnatti.',
              textRelated: null,
              image: '',
              imageRelated: null,
              audio: '',
              audioRelated: null,
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '4f432b24-f711-4848-8284-5c697202d96e',
      createdAt: '2024-06-17T19:57:20.589Z',
      updatedAt: '2024-06-17T19:57:20.589Z',
      type: 'content',
      code: 'WU_TC_B4_P6_A19',
      order: 30,
      status: 'activated',
      widgets: [
        {
          id: '350350e3-2d57-40e0-b952-eae66e0630fa',
          createdAt: '2024-06-17T19:57:20.589Z',
          updatedAt: '2024-06-17T19:57:20.589Z',
          order: 1,
          type: 'enunciated',
          value: '🗣 ️ Dê a sua opinião.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '9338009b-b085-4177-9415-59225cd30148',
          createdAt: '2024-06-17T19:57:20.589Z',
          updatedAt: '2024-06-17T19:57:20.589Z',
          order: 2,
          type: 'longtext',
          value:
            'Imagine que você é estudante imigrante em sua primeira semana de aula. Quais seriam seus maiores desafios?\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'b4bf0116-b74b-4661-bbef-9499b4c49923',
          createdAt: '2024-06-17T19:57:20.589Z',
          updatedAt: '2024-06-17T19:57:20.589Z',
          order: 3,
          type: 'longtext',
          value: 'Escreva pelo menos dois exemplos.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '05cf4eb6-30ff-47a9-b4e5-5eb19edc8d41',
          createdAt: '2024-06-17T19:57:20.589Z',
          updatedAt: '2024-06-17T19:57:20.589Z',
          order: 4,
          type: 'textbox',
          value: '5',
          feedback: false,
          label: 'Write your answer here.',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: 'caba5dad-b97f-476e-8b38-f8aff0a6f069',
      createdAt: '2024-06-17T19:57:20.600Z',
      updatedAt: '2024-06-17T19:57:20.600Z',
      type: 'content',
      code: 'WU_TC_B4_P7_A20',
      order: 31,
      status: 'activated',
      widgets: [
        {
          id: '84a86d8c-d814-4df1-93ec-bb6c194ff51d',
          createdAt: '2024-06-17T19:57:20.600Z',
          updatedAt: '2024-06-17T19:57:20.600Z',
          order: 1,
          type: 'enunciated',
          value: '🗣 ️ Dê a sua opinião.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '47523295-71c3-4bab-b5c7-9ba817c06c77',
          createdAt: '2024-06-17T19:57:20.600Z',
          updatedAt: '2024-06-17T19:57:20.600Z',
          order: 2,
          type: 'longtext',
          value:
            'Se você visse um colega imigrante com dificuldade em se adaptar, o que você faria para ajudá-lo a se sentir mais bem-vindo?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '51e218a9-101e-4f59-ab92-aa422787a38d',
          createdAt: '2024-06-17T19:57:20.600Z',
          updatedAt: '2024-06-17T19:57:20.600Z',
          order: 3,
          type: 'textbox',
          value: '5',
          feedback: false,
          label: 'Write your answer here.',
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '558e1a39-c0a1-4cd2-849f-152e88740d87',
      createdAt: '2024-06-17T19:57:20.611Z',
      updatedAt: '2024-06-17T19:57:20.611Z',
      type: 'content',
      code: 'WU_TC_end',
      order: 32,
      status: 'activated',
      widgets: [
        {
          id: '7e2db018-90df-4f8d-b719-b7be1d677d92',
          createdAt: '2024-06-17T19:57:20.611Z',
          updatedAt: '2024-06-17T19:57:20.611Z',
          order: 1,
          type: 'title',
          value: 'CONGRATULATIONS! ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '587d350e-ac96-4789-9bb8-5f81cb644a54',
          createdAt: '2024-06-17T19:57:20.611Z',
          updatedAt: '2024-06-17T19:57:20.611Z',
          order: 2,
          type: 'subtitle',
          value: 'Você concluiu  a etapa',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'ed201a29-1ba1-47f6-a64f-fbba373799aa',
          createdAt: '2024-06-17T19:57:20.611Z',
          updatedAt: '2024-06-17T19:57:20.611Z',
          order: 3,
          type: 'title',
          value: 'TIME TO CONNECT',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3e422000-2d3b-44b9-a7b9-f054f289b69f',
          createdAt: '2024-06-17T19:57:20.611Z',
          updatedAt: '2024-06-17T19:57:20.611Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-27-fa999171-3f49-4752-a376-7e1777a2e3af.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '3ad40e67-cc8e-4f80-b1ee-ef22037ea6d8',
          createdAt: '2024-06-17T19:57:20.611Z',
          updatedAt: '2024-06-17T19:57:20.611Z',
          order: 5,
          type: 'longtext',
          value:
            '\r\nAperte o botão CONTINUE para avançar para a próxima etapa.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
  ],
};
