import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { mockApiData } from 'student-lib';
import { AbstractMockApi } from '../abstract.mock-api';

@Injectable({
    providedIn: 'root'
})
export class CoursesClassesMockApi extends AbstractMockApi {

  constructor(api: ApiService) {
    super(api, '/courses');
  }

  override registerHandlers(): void {
    this.mock.onGet(this.api.getFakePrefixUrl() + this.contextPath)
      .reply(({request}) => {
        return this.okResponse(mockApiData.coursesData.default);
      }
    );
  }

}