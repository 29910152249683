<ng-container *ngIf="template == 'default'">
  <h2 [style.marginBottom]="marginBottom" [innerHTML]="processedText"></h2>
</ng-container>

<ng-container *ngIf="template == 'titlequestion'">
  <h2
    [style.marginBottom]="marginBottom"
    [style.textAlign]="'center'"
    [innerHTML]="processedText"
  ></h2>
</ng-container>

<ng-container *ngIf="template == 'imagetitle'">
  <p
    style="margin-top: -20px; text-align: center"
    [innerHTML]="processedText"
  ></p>
</ng-container>
