import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralImageComponent } from './general-image/general-image.component';
import { AbstractImageComponent } from './abstract.image.component';

const components = [GeneralImageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesModule {}

export { GeneralImageComponent, AbstractImageComponent };
