import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUnitsPageComponent } from './select-units-page.component';
import { MenusModule, ButtonsModule, RadiosModule, Radio } from 'go-components';

const components = [SelectUnitsPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, RadiosModule, MenusModule],
  exports: components,
})
export class SelectUnitsPageModule {}

export interface UnitOption {
  id: string
  option: Radio
}

export { SelectUnitsPageComponent };
