<section class="stages-page">
  <div class="stages-page-menu">
    <go-general-menu
      [menuItens]="menuItens"
      [(menuBarState)]="menuBarState"
      width="230px"
    />
  </div>
  <div class="stages-page-content">
    <header class="stage-page-header">
      <h2>
        <span class="text-baloochettan-h3 go-text-color-purple700">
          {{ tagUnit }}:
        </span>
        <span class="text-baloochettan-h3 go-text-color-gray900">
          {{ titleUnit }}
        </span>
      </h2>
    </header>

    <div class="stages-page-corousel">
      <div class="carousel-navigation-arrow-left">
        <go-general-button
          labelColor="purple700"
          leftIcon="angle-left"
          leftIconMargin="2px"
          width="44px"
          height="44px"
          borderRadius="44px"
          borderColor="purple100"
          backgroundColor="purple100"
          (click)="scrollLeft()"
        />
      </div>

      <div class="carousel-navigation-arrow-right">
        <go-general-button
          labelColor="purple700"
          leftIcon="angle-right"
          leftIconMargin="2px"
          width="44px"
          height="44px"
          borderRadius="44px"
          borderColor="purple100"
          backgroundColor="purple100"
          (click)="scrollRight()"
        />
      </div>

      <div class="carousel-itens">
        <ng-container *ngFor="let item of data; let i = index">
          <go-general-card
            [number]="item.number"
            [cover]="item.cover"
            [title]="item.title"
            [partialPages]="item.partialPages"
            [totalPages]="item.totalPages"
            [status]="item.status"
            [isSelected]="item.open"
            [link]="item.link"
            [color]="item.color"
            (click)="onSelectedItem(i)"
            (mainButtonClick)="startStage.emit(item)"
          />
        </ng-container>
      </div>

      <div class="carousel-navigation-dots">
        <ng-container *ngFor="let item of data; let i = index">
          <div
            class="dot"
            [ngClass]="
              item.open
                ? 'active go-background-color-purple700'
                : 'go-background-color-gray500'
            "
            (click)="onSelectedItem(i)"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
