export default {
  id: '31b9ab45-d88b-48eb-928b-bbf678a58521',
  title: 'Word groups',
  countPages: 16,
  completedPages: 0,
  module: {
    id: '0eb1c258-f3f0-452e-a582-9e345d1e88fe',
    order: 2,
    title: 'Nível Interpreter',
  },
  order: 2,
  pages: [
    {
      id: 'e8e82ea3-acf4-47d3-94ac-5e2290090ac7',
      type: 'content',
      code: 'WU_LM_N2_L2_P1',
      order: 1,
      widgets: [
        {
          id: 'b8adf575-d284-404b-8e17-3ac0d60b58e7',
          order: 1,
          type: 'title',
          value: 'LESSON 2',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8ea1cdce-1060-4c65-9cc8-b487280c3399',
          order: 2,
          type: 'subtitle',
          value: 'Word groups',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9e38f4e7-9712-4f35-a064-f74824934db6',
          order: 3,
          type: 'enunciated',
          value: 'Observe o quadro a seguir.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'b515dac7-df2b-4a0d-bf0b-21c4016c75d4',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-31b9ab45-d88b-48eb-928b-bbf678a58521-4a67d7ba-2022-497d-84ef-95b0b85d3f6e.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'cc9a3362-f747-41a4-978d-ae0bdee73aaa',
          order: 5,
          type: 'longtext',
          value:
            'Cada palavra pertence a um grupo específico. A cada lição, você vai aprender mais e mais sobre cada um.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '2b71febc-c49e-4af9-bb21-e2df7c064563',
      type: 'content',
      code: 'WU_LM_N2_L2_P2',
      order: 2,
      widgets: [
        {
          id: 'f8059b09-78aa-411c-94ef-ad31ff4c1ede',
          order: 1,
          type: 'subtitle',
          value: 'What is it?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8232d066-6d1d-4b6a-93a5-3cbde5e4b965',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-31b9ab45-d88b-48eb-928b-bbf678a58521-c487e3ae-c993-4e5f-9afc-93dbfe29c22a.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'd9dc9ebf-8ad4-43cd-aa54-30dd4b75568a',
          order: 3,
          type: 'longtext',
          value:
            "Como em português, representam coisas, pessoas, etc. 'Nouns' são importantes, mas sozinhos não fazem muita coisa. não é? ",
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'a75045f7-a4f3-4ae8-b28e-32d307674861',
          order: 4,
          type: 'longtext',
          value:
            'Por isso precisamos de outros tipos de palavras para formar uma frase e se comunicar bem. ',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'f7ed94fc-12eb-49be-a78a-3ceea8f221d8',
      type: 'content',
      code: ' WU_LM_N2_L2_P3',
      order: 3,
      widgets: [
        {
          id: '98e02dbc-3f7d-4186-8f0e-4cceb6e34cec',
          order: 1,
          type: 'subtitle',
          value: 'Is it an adjective?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '99bf9383-06c9-42b6-9b41-162a5afe1839',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-31b9ab45-d88b-48eb-928b-bbf678a58521-c1e1aee4-87be-49c4-a238-39eeaea0d5e2.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '2733bb77-34db-4f2e-9e99-453621273ec0',
          order: 3,
          type: 'longtext',
          value:
            'Adjetivos são aquelas coisas legais, ou nem tão legais assim, que usamos para falar sobre as coisas, pessoas, ideias, etc. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '86639397-20c3-4969-910e-b41c9f5d7996',
          order: 4,
          type: 'enunciated',
          value: 'Aprender inglês, por exemplo, pode ser:',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '10c95ea7-806b-4e42-a783-8018635beb0d',
          order: 5,
          type: 'blocklist',
          feedback: false,
          options: [
            {
              id: '715fa217-fe2b-47d0-8a87-33ab7eda70ba',
              order: 1,
              text: 'cool: legal',
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-0-31b9ab45-d88b-48eb-928b-bbf678a58521-c9c3513c-43f4-452e-a0f8-a1df09c7bbb0.mpeg',
              right: false,
            },
            {
              id: 'b82b5983-5ca7-45d3-8877-e81ed69bac15',
              order: 2,
              text: 'interesting: interessante',
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-1-31b9ab45-d88b-48eb-928b-bbf678a58521-1a2067de-cc1a-429e-8d36-9dbb806896ad.mpeg',
              right: false,
            },
            {
              id: 'aa15108f-1192-48bc-8319-88f2c2caa13d',
              order: 3,
              text: 'fun: divertido',
              image: '',
              audio:
                'https://go2dev.s3.amazonaws.com/pages/audios/page-widget-4-option-2-31b9ab45-d88b-48eb-928b-bbf678a58521-8506a5f7-3d75-4992-8d37-408877a97f70.mpeg',
              right: false,
            },
          ],
          template: 'default',
        },
        {
          id: '8b5ae6a8-f6b5-47be-a24c-bf3934ca2cd0',
          order: 6,
          type: 'longtext',
          value: 'Só depende de você!',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '0277d18a-b059-462a-a090-afb5ab65e8af',
      type: 'content',
      code: 'WU_LM_N2_L2_P4',
      order: 4,
      widgets: [
        {
          id: '989a72e4-6b9d-40f1-86be-c2fbbaa4cae6',
          order: 1,
          type: 'subtitle',
          value: 'Word order',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9e72ea55-52c3-4d3c-bf79-dbe54debe149',
          order: 2,
          type: 'longtext',
          value:
            'Você percebeu que os adjetivos <b>great</b> e <b>yellow</b> vieram antes dos substantivos <b>student</b> e <b>bus</b>? Os adjetivos nesse caso, modificam a qualidade dos substantivos. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1656c68e-0142-4a02-aa8c-ab4a11b303f9',
          order: 3,
          type: 'longtext',
          value: '<b>Outros exemplo:</b>',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'eb67e436-cf65-4f79-a966-792ec440881c',
          order: 4,
          type: 'longtext',
          value: 'They are <b>great</b> students.',
          feedback: false,
          options: [],
          template: 'template-1',
        },
        {
          id: '65232a5d-6566-4cb5-9733-03cc212f0ba4',
          order: 5,
          type: 'longtext',
          value: 'This is my <b>yellow</b> pencil.',
          feedback: false,
          options: [],
          template: 'template-1',
        },
        {
          id: 'a6b5a99d-59d7-4dc1-b5b1-11bd0f7dd77e',
          order: 6,
          type: 'longtext',
          value: '<b>Compare com o exemplo abaixo:</b>',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'a132b4d9-f79d-416f-80cd-51ef136afe1a',
          order: 7,
          type: 'longtext',
          value: '<b>The children</b> are happy.',
          feedback: false,
          options: [],
          template: 'template-1',
        },
        {
          id: 'ddfd3233-8a6a-41b7-a14d-b1f29d4e9fe5',
          order: 8,
          type: 'longtext',
          value: '<b>They</b> are happy.',
          feedback: false,
          options: [],
          template: 'template-1',
        },
        {
          id: 'ec40872d-2548-4f3f-97e8-d06e263e4a91',
          order: 9,
          type: 'longtext',
          value:
            'O adjetivo <b>happy</b> refere-se aos substantivo <b>children</b> e o pronome <b>they</b>.',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '4c79e213-8bbe-4242-b4c3-1a31bb3a61a2',
      type: 'content',
      code: ' WU_LM_N2_L2_P5',
      order: 5,
      widgets: [
        {
          id: '38f61ade-6b26-40d1-9643-e639f69f7b1b',
          order: 1,
          type: 'subtitle',
          value: 'A or AN?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '5a0b70b3-7a8a-49e1-b364-4d459547c35e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-31b9ab45-d88b-48eb-928b-bbf678a58521-5bfbaf0a-7031-4b5b-af74-892106780e49.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8aefecaf-716d-47ea-b10f-d4f93f31d3e2',
          order: 3,
          type: 'enunciated',
          value: 'Observe os exemplos e responda.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '334846b3-6e17-4595-b3a6-56c42bab7ed1',
          order: 4,
          type: 'longtext',
          value: 'Quais das explicações abaixo estão corretas? ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '88fe394f-5da1-4454-99cc-78d0e03e5d00',
          order: 5,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '71841abd-21d7-47bf-98fe-115f4f7e1d60',
              order: 1,
              text: 'A ou AN são usados antes de substantivos no singular.',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '6fd48824-525f-42cd-8b53-4cacfc4e9044',
              order: 2,
              text: 'A ou AN são usados antes de substantantivos no plural.',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '434644ec-291b-4d34-9455-401bc5c39d7a',
              order: 3,
              text: 'Não usamos A ou AN antes de substantivos no plural.',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: 'dd757db2-e369-4e59-88ef-72194b3b3fd5',
      type: 'content',
      code: 'WU_LM_N2_L2_P6',
      order: 6,
      widgets: [
        {
          id: '43099bca-2d70-4ab3-81c3-5cb0aacbd9c7',
          order: 1,
          type: 'enunciated',
          value: 'Observe os exemplos 1 e 2 abaixo e responda.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '6baa0f86-ed6b-4f06-abc2-2e99fb2eb0f5',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-31b9ab45-d88b-48eb-928b-bbf678a58521-efd96a11-4392-4f1a-b41a-c143bfa6b761.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3f410ad3-533a-40b7-9f74-8e29974a15c1',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-31b9ab45-d88b-48eb-928b-bbf678a58521-ab7d4a86-ff46-4e98-b525-d7e293cab3c2.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'd8a73aa3-cb18-47ea-8c49-5de948fadb82',
          order: 4,
          type: 'longtext',
          value: 'Quais das explicações abaixo estão corretas.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '177ddd35-342e-4301-b78c-20a8fe332b9c',
          order: 5,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '56822b94-cff2-4c65-8eff-1219b6ec9e73',
              order: 1,
              text: 'A é usado antes de palavras com sons consonantais.',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: 'add83f39-206d-45f7-9f88-b97c8e1bc4e4',
              order: 2,
              text: 'An é usado antes de palavras com sons de vogais. ',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '86738b1f-5431-4f61-b05e-4bec6b3d9944',
              order: 3,
              text: 'Precisamos prestar atenção no som no início do substantivo.',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: 'eda5f931-0402-4657-9158-39039bca6545',
      type: 'activity',
      code: 'WU_LM_N2_L2_P7_P11.1',
      order: 7,
      difficulty: 'easy',
      widgets: [
        {
          id: 'eda5f931-0402-4657-9158-39039bca6545',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'eda5f931-0402-4657-9158-39039bca6545',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-a7d75d5d-437c-4b6b-83ad-f2a11ffb390c.jpeg',
          template: 'default',
        },
        {
          id: 'eda5f931-0402-4657-9158-39039bca6545',
          order: 3,
          type: 'enunciated',
          value: 'This is _______ book.',
          template: 'default',
        },
        {
          id: 'eda5f931-0402-4657-9158-39039bca6545',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra book inicia com uma consoante, portanto, usamos A e não AN antes dela. ',
          wrongMessage:
            'A palavra book inicia com uma consoante, portanto, usamos A e não AN antes dela. ',
          options: [
            {
              id: '8e790376-f96e-4445-bbaa-2bfd5e2fa382',
              order: 0,
              text: '',
              textRelated: 'a',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '2873a637-6884-4113-a8c9-4ae7837d6027',
              order: 2,
              text: '',
              textRelated: 'an',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'd832bed6-d358-454b-9030-8948107c39a2',
      type: 'activity',
      code: 'WU_LM_N2_L2_P7_P11.2',
      order: 8,
      difficulty: 'easy',
      widgets: [
        {
          id: 'd832bed6-d358-454b-9030-8948107c39a2',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'd832bed6-d358-454b-9030-8948107c39a2',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-364297b2-1372-4dc4-91d6-146b36258d42.jpeg',
          template: 'default',
        },
        {
          id: 'd832bed6-d358-454b-9030-8948107c39a2',
          order: 3,
          type: 'enunciated',
          value: 'He is ______ waiter.',
          template: 'default',
        },
        {
          id: 'd832bed6-d358-454b-9030-8948107c39a2',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra Waiter iniciar com a letra W que é uma consoante e tem som de consoante no início da palavra.',
          wrongMessage:
            'A palavra Waiter iniciar com a letra W que é uma consoante e tem som de consoante no início da palavra.',
          options: [
            {
              id: 'c40bad89-3ebb-4272-b054-8a80d6295709',
              order: 2,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
            {
              id: 'a0bd1f99-bc56-445a-8aad-5ef0012a147a',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'dedd9af5-8feb-4738-9c16-f6cb9aee1dd6',
      type: 'activity',
      code: 'WU_LM_N2_L2_P7_P11.3',
      order: 9,
      difficulty: 'easy',
      widgets: [
        {
          id: 'dedd9af5-8feb-4738-9c16-f6cb9aee1dd6',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'dedd9af5-8feb-4738-9c16-f6cb9aee1dd6',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-4011f7b0-eb59-4f7c-8c05-c070ba6b12c6.jpeg',
          template: 'default',
        },
        {
          id: 'dedd9af5-8feb-4738-9c16-f6cb9aee1dd6',
          order: 3,
          type: 'enunciated',
          value: 'She is ____ architect.',
          template: 'default',
        },
        {
          id: 'dedd9af5-8feb-4738-9c16-f6cb9aee1dd6',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra architect inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          wrongMessage:
            'A palavra architect inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          options: [
            {
              id: 'da7b5e1b-207b-4b36-b89d-0c7d1f7defea',
              order: 2,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: 'eff087d4-e32c-454b-914f-f76425da2e66',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'c80b7edf-1852-4740-a8b6-594831f73ce9',
      type: 'activity',
      code: 'WU_LM_N2_L2_P7_P11.4',
      order: 10,
      difficulty: 'easy',
      widgets: [
        {
          id: 'c80b7edf-1852-4740-a8b6-594831f73ce9',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'c80b7edf-1852-4740-a8b6-594831f73ce9',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-f5a6cc4d-ee9e-4b0d-a76e-db33810d3ac3.jpeg',
          template: 'default',
        },
        {
          id: 'c80b7edf-1852-4740-a8b6-594831f73ce9',
          order: 3,
          type: 'enunciated',
          value: 'That is _____ apple.',
          template: 'default',
        },
        {
          id: 'c80b7edf-1852-4740-a8b6-594831f73ce9',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra apple inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          wrongMessage:
            'A palavra apple inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          options: [
            {
              id: 'ff361cbb-a514-4512-9b04-a3b80d3fdd2f',
              order: 2,
              text: '',
              textRelated: 'a',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '0cdc4425-4da8-438a-b63d-313892bc1587',
              order: 3,
              text: '',
              textRelated: 'an',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'b7611df8-0533-49f6-a54d-c401ec6925b2',
      type: 'activity',
      code: 'WU_LM_N2_L2_P7_P11.5',
      order: 11,
      difficulty: 'easy',
      widgets: [
        {
          id: 'b7611df8-0533-49f6-a54d-c401ec6925b2',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'b7611df8-0533-49f6-a54d-c401ec6925b2',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-45690566-2652-4f6c-9b47-8bb544db40b2.jpeg',
          template: 'default',
        },
        {
          id: 'b7611df8-0533-49f6-a54d-c401ec6925b2',
          order: 3,
          type: 'enunciated',
          value: 'I have _____ umbrella.',
          template: 'default',
        },
        {
          id: 'b7611df8-0533-49f6-a54d-c401ec6925b2',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra umbrella inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          wrongMessage:
            'A palavra umbrella inicia com uma vogal e tem som de vogal, portanto, usamos AN e não A. ',
          options: [
            {
              id: 'abbcced7-eaa6-4ffb-ba97-d5ef7a42ddd4',
              order: 2,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: 'e289234f-acba-4f45-8756-49bd20f2d97b',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '777e3c9c-a007-443f-9181-d60d8ba4a80f',
      type: 'content',
      code: 'WU_LM_N2_L2_P12',
      order: 12,
      widgets: [
        {
          id: 'b8daab7e-7961-432c-9baa-5f53218d138e',
          order: 1,
          type: 'subtitle',
          value: 'Word sound',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9e93918f-8486-4f08-8134-a4bb6a373603',
          order: 2,
          type: 'longtext',
          value:
            'Atenção em como as letras são pronunciadas no início. Palavras como <b>yellow</b> e <b>waiter</b>, por exemplo, podem parecer que tem som de vogal, mas em inglês elas são consoantes e têm som de consoante e não vogal. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '47823686-bef7-4b93-bf36-2eb1180345ef',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-31b9ab45-d88b-48eb-928b-bbf678a58521-ce1ceb8b-abf4-4155-8285-f5c7bb82f351.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3eacb19e-ff2a-4881-9f29-63d9bfc18598',
          order: 4,
          type: 'longtext',
          value:
            'É importante prestar atenção nos sons da letra inicial da palavra para que possamos usar A ou AN de forma correta.',
          feedback: false,
          options: [],
          template: 'template-2',
        },
      ],
      hints: [],
    },
    {
      id: '8cbf69da-e4cc-4a9c-a408-aebab8b58135',
      type: 'content',
      code: 'WU_LM_N2_L2_P13',
      order: 13,
      widgets: [
        {
          id: '11830da2-4636-4c7e-b6dd-83ce2e1f0fc9',
          order: 1,
          type: 'subtitle',
          value: 'A or AN?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '885a9843-f543-457d-b4c3-1cb0971cfa49',
          order: 2,
          type: 'enunciated',
          value: 'Leia os exemplos e selecione as explicações corretas.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'ab339824-5954-4b07-8e44-5dd04654459d',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-31b9ab45-d88b-48eb-928b-bbf678a58521-15903a6b-69b0-4f79-8901-225e1bbfafa6.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '3491fc51-e26d-4a75-adc0-9e4dd0eff932',
          order: 4,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '732fb9ac-b7b7-4c2f-975b-8864ac134c87',
              order: 1,
              text: 'A letra U tem som de vogal ou consoante dependendo da palavra.',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: 'e1bd2733-d396-4c20-9623-745ffd74f376',
              order: 2,
              text: 'O exemplo 2 está incorreto. ',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '17d10e3f-60d0-4f15-96d0-3d5666827664',
              order: 3,
              text: 'FBI é uma abreviação, e portanto, pronunciamos letra a letra. ',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '7f4b8493-f907-40bc-9e67-d2cd58b58da3',
              order: 4,
              text: 'Ao soletrar a letra F, ouvimos o som /e/.',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage: '',
          wrongMessage: '',
        },
      ],
      hints: [],
    },
    {
      id: '30b028e4-d00d-4bf7-a5e7-ed2f2a08514e',
      type: 'activity',
      code: ' WU_LM_N2_L2_P14__P16.1',
      order: 14,
      difficulty: 'easy',
      widgets: [
        {
          id: '30b028e4-d00d-4bf7-a5e7-ed2f2a08514e',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: '30b028e4-d00d-4bf7-a5e7-ed2f2a08514e',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-052cfaea-9a4b-40a6-b339-01b126567b42.jpeg',
          template: 'default',
        },
        {
          id: '30b028e4-d00d-4bf7-a5e7-ed2f2a08514e',
          order: 3,
          type: 'enunciated',
          value: 'This is _____ MP3 player.',
          template: 'default',
        },
        {
          id: '30b028e4-d00d-4bf7-a5e7-ed2f2a08514e',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A abreviação MP3 começa com a letra M e pronunciamos /em/. Portanto, quando soletramos faz o som de vogal. ',
          wrongMessage:
            'A abreviação MP3 começa com a letra M e pronunciamos /em/. Portanto, quando soletramos faz o som de vogal. ',
          options: [
            {
              id: '69681c4d-5c9b-46f8-b168-0c7610d62e36',
              order: 2,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
            {
              id: '9d937d8e-6d0d-48d4-80eb-b38509ea083e',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'b6ce3784-e40e-4744-8f9b-8aab2c3ffdc8',
      type: 'activity',
      code: ' WU_LM_N2_L2_P14__P16.2',
      order: 15,
      difficulty: 'easy',
      widgets: [
        {
          id: 'b6ce3784-e40e-4744-8f9b-8aab2c3ffdc8',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'b6ce3784-e40e-4744-8f9b-8aab2c3ffdc8',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-3c94bb7d-2366-4f89-b29f-64a07cd9ae5e.jpeg',
          template: 'default',
        },
        {
          id: 'b6ce3784-e40e-4744-8f9b-8aab2c3ffdc8',
          order: 3,
          type: 'enunciated',
          value: 'This is ______ uniform.',
          template: 'default',
        },
        {
          id: 'b6ce3784-e40e-4744-8f9b-8aab2c3ffdc8',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            'A palavra uniform começa com a letra U e quando pronunciado tem som de consoante /ju/.',
          wrongMessage:
            'A palavra uniform começa com a letra U e quando pronunciado tem som de consoante /ju/.',
          options: [
            {
              id: '04750f00-5ca7-4379-a129-66d6fe9d844e',
              order: 3,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
            {
              id: 'b023f73c-9ec2-4d89-abaf-23f64899e5b9',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'c85ebd24-5a68-4184-b6b3-2c9d87f61150',
      type: 'activity',
      code: ' WU_LM_N2_L2_P14__P16.3',
      order: 16,
      difficulty: 'easy',
      widgets: [
        {
          id: 'c85ebd24-5a68-4184-b6b3-2c9d87f61150',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          template: 'default',
        },
        {
          id: 'c85ebd24-5a68-4184-b6b3-2c9d87f61150',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-31b9ab45-d88b-48eb-928b-bbf678a58521-3fe1ee0b-9694-4de7-a893-a0f66dd76b53.jpeg',
          template: 'default',
        },
        {
          id: 'c85ebd24-5a68-4184-b6b3-2c9d87f61150',
          order: 3,
          type: 'enunciated',
          value: 'This is ______ unicorn.',
          template: 'default',
        },
        {
          id: 'c85ebd24-5a68-4184-b6b3-2c9d87f61150',
          order: 4,
          type: 'select',
          feedback: true,
          rightMessage:
            "Você notou que 'unicorn', uniform' e 'university' todos começam com 'UNI'? ",
          wrongMessage:
            "Você notou que 'unicorn', uniform' e 'university' todos começam com 'UNI'?  A resposta correta é 'a unicorn'.",
          options: [
            {
              id: 'b36cfee1-9c10-47e6-8a05-b5af3caf38df',
              order: 2,
              text: '',
              textRelated: 'a',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: true,
            },
            {
              id: '6a94a97b-a93a-4080-b7a3-f6d7fa59566d',
              order: 3,
              text: '',
              textRelated: 'an',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
  ],
};
