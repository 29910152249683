import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetOptions } from '../options.interface';

@Component({
  selector: 'widget-checklist',
  templateUrl: './checklist.component.html',
  styleUrl: './checklist.component.scss',
})
export class ChecklistComponent {
  readonly goComponentId: string = 'combination-challenge';

  @Input() template?: string = 'default';
  @Input() options?: WidgetOptions[];
  @Input() marginBottom?: string = '16px';

  @Input() pageStatus?: string;
  @Input() pageType?: string;

  selectedItens: WidgetOptions[] = [];

  toggleItem(item: WidgetOptions): void {
    const index = this.selectedItens.indexOf(item);
    if (index > -1) {
      // Item is already selected, remove it
      this.selectedItens.splice(index, 1);
    } else {
      // Item is not selected, add it
      this.selectedItens.push(item);
    }
  }

  isItemSelected(item: WidgetOptions) {
    let selected = this.selectedItens.indexOf(item) > -1;

    if(selected) {
      return 'check-radio go-background-color-turquoise500 go-border-color-turquoise700';
    } else {
      return 'check-radio go-background-color-gray100 go-border-color-gray300';
    }
  }
}
