import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavItem, WidgetsStateService } from 'go-components';
import { Page, Widget } from '../../../public-api';

@Component({
  selector: 'st-challenge-page',
  templateUrl: './challenge-page.component.html',
  styleUrl: './challenge-page.component.scss',
})
export class ChallengePageComponent implements OnInit, OnChanges {
  @Input() escapePath: string = '';
  @Input() stageTitle!: string;
  @Input() page!: string;
  @Input() pages!: Page[];
  @Input() widgets!: Widget[];

  @Output() goNextPage = new EventEmitter();
  @Output() goToPage = new EventEmitter<{ page: number }>();

  //? Page Controlls
  navPages!: NavItem[];
  correctionButton: boolean = false;
  statusButton: 'concluded' | 'normal' | 'disabled' = 'normal';
  pageStatus: 'normal' | 'concluded' = 'normal';

  //? Page Info
  unitTitle?: string;
  pageType!: string;
  percent: number = 0;
  currentPage: number = 0;
  isLoading: boolean = false;

  constructor(private widgetsStateService: WidgetsStateService) {}

  ngOnInit(): void {
    this.widgetsStateService.widgetsState$.subscribe(() => {
      this.checkButtonEnabled(this.widgets);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page']) {
      this.updateCurrentPage(this.page);
    }

    if (changes['widgets']) {
      this.checkStatusPage(this.widgets);
      this.checkButtonEnabled(this.widgets);
    }
  }

  //? Método para avançar para a próxima página
  nextPage(): void {
    this.goNextPage.emit();
  }

  //? Função chamada no OnChanges para atualizar o progresso do usuário
  private updateCurrentPage(page: string): void {
    let curPage = parseInt(page);

    this.currentPage = curPage;
    this.percent = this.calcProgressPage(this.pages.length, curPage);
    this.generatePageList();
  }

  private checkStatusPage(widgets: any[]): void {
    if (widgets.some((el) => el.feedback)) {
      if (this.widgetsStateService.areAllWidgetsConcluded(widgets)) {
        this.pageStatus = 'concluded';
        return;
      } else {
        this.pageStatus = 'normal';
        return;
      }
    }

    this.pageStatus = 'normal';
    return;
  }

  //? Verifica a disponibilidade do botão de corrigir
  private checkButtonEnabled(widgets: any[]) {
    if (widgets.some((el: any) => el.feedback)) {
      if (this.widgetsStateService.areAllWidgetsAnswered(widgets)) {
        this.statusButton = 'normal';
      } else {
        this.statusButton = 'disabled';
      }
      this.correctionButton = true;
    } else {
      this.statusButton = 'normal';
      this.correctionButton = false;
    }
  }

  //? Cálculo de porcentagem
  private calcProgressPage(totalPages: number, currentPage: number): number {
    if (totalPages <= 0) return 0;

    if (currentPage < 0 || currentPage > totalPages) return 0;

    // Calculo da porcentagem
    let progress = (currentPage / totalPages) * 100;

    // Arredondamento para o inteiro mais próximo
    progress = Math.round(progress);

    return progress;
  }

  //? Método para gerar a lista de páginas com os dados necessários
  private generatePageList(): void {
    this.navPages = this.pages.map((page: any) => {
      const order = page.order;
      const number = order < 10 ? `0${order}` : `${order}`;
      let title =
        page.type == 'activity' ? 'Página de Atividade' : 'Página de Conteúdo';

      return {
        number: number,
        title: title,
        current: order === parseInt(this.page),
        status: page.status,
        action: () => {
          this.goToPage.emit({ page: page.order });
        },
      };
    });
  }

  //? Função de correção dos widgets da página
  correctWidgets(event: any): void {
    event.preventDefault();
    this.isLoading = true;

    setTimeout(() => {
      if (this.statusButton != 'disabled') {
        const feedbackWidgets = this.widgetsStateService.getFeedbackWidgets();
        const correctionResults = feedbackWidgets.map((widget) => {
          console.log(widget.id);
          const correctableWidget = this.widgetsStateService.getWidgetById(
            widget.id
          );
          return correctableWidget.correction();
        });

        if (
          correctionResults.some((result) => result === 'wrong') ||
          correctionResults.some((result) => result === 'correct')
        ) {
          this.pageStatus = 'concluded';
        } else {
          this.pageStatus = 'normal';
        }
      }

      this.isLoading = false;
    }, 100);
  }
}
