import { Component, Input } from '@angular/core';
import { GeneralProgressComponent } from '../general-progress/general-progress.component';

@Component({
  selector: 'go-simple-progress',
  templateUrl: './simple-progress.component.html',
  styleUrl: './simple-progress.component.scss',
})
export class SimpleProgressComponent extends GeneralProgressComponent {
  override readonly goComponentId: string = 'simple-progress';

  @Input() showPercent: boolean = true;
}
