<label #label [ngClass]="boxClasses">
  <input
    #input
    type="{{ inputType }}"
    placeholder="{{ placeholder }}"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (change)="change.emit($event)"
  />
  <div
    *ngIf="switchObscureText"
    class="toggle-visibility text-poppins-caption-regular go-text-color-gray600"
    (click)="toggleObscureText()"
  >
    {{ obscureText ? "Mostrar" : "Ocultar" }}
  </div>
</label>
