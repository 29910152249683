import { Injectable } from '@angular/core';
import { Page, Stage } from 'student-lib';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  private loadedStage?: Stage;
  private loadedPages?: Page[];

  setStage(stage: Stage): void {
    this.loadedStage = stage;
  }

  getStage(): Stage | undefined {
    return this.loadedStage;
  }

  setPages(pages: Page[]): void {
    this.loadedPages = pages;
  }

  getPages(stageId: string): Page[] | undefined {
    if (this.loadedStage != null && this.loadedStage.id === stageId) {
      return this.loadedPages;
    }
    return undefined;
  }
}
