import { Component, EventEmitter, Input, Output, model } from '@angular/core';
import { MenuBarState } from 'go-components';
import { defaultMenuBarState, defaultMenuItemsPage } from '../../core/default-menu-items.pages';
import { PageWithMenu } from '../../core/page-with-menu';
import { data } from './data';
import { UnitOption } from './select-units-page.module';
import { SelectAnItemToContinuePage } from '../core/select-an-item-to-continue-page';

@Component({
  selector: 'st-select-units-page',
  templateUrl: './select-units-page.component.html',
  styleUrl: './select-units-page.component.scss',
})
export class SelectUnitsPageComponent implements PageWithMenu, SelectAnItemToContinuePage {
  
  @Input() menuItens = defaultMenuItemsPage;
  menuBarState = model<MenuBarState>(defaultMenuBarState);

  @Input() units: UnitOption[] = data;
  @Output() startUnit = new EventEmitter<UnitOption>();

  @Output() selectedUnit = new EventEmitter<UnitOption>();

  selectedOption?: UnitOption;

  onSelectedItem(indexItem: number): void {
    this.units.forEach((item, index) => {
      if (item.option.status != 'disabled') {
        if (item.option.isSelected = index === indexItem) {
          this.selectedOption = item;
        }
      }
    });

    if (this.selectedOption) {
      this.selectedUnit.emit(this.selectedOption);
    }
  }

  onContinue(): void { }

  onBack(): void { }

}
