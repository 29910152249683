export default {
  id: '6773c6b3-32fd-4684-9d0c-b9664a2be91b',
  createdAt: '2024-05-28T12:25:37.385Z',
  updatedAt: '2024-06-17T19:57:21.005Z',
  title: 'Useful Sentences',
  order: 1,
  status: 'activated',
  module: {
    id: '9a3ee243-6cd5-4c36-aa85-c4a6addd9fa3',
    createdAt: '2024-05-28T12:25:37.377Z',
    updatedAt: '2024-06-17T19:57:20.995Z',
    title: 'Nível Explorer',
    order: 1,
  },
  pages: [
    {
      id: '893602c2-36aa-44eb-b2e9-273b8d1479bb',
      createdAt: '2024-06-17T19:57:21.020Z',
      updatedAt: '2024-06-17T19:57:21.020Z',
      type: 'content',
      code: 'WU_LM_B1_P1',
      order: 1,
      status: 'activated',
      widgets: [
        {
          id: 'f83071c9-3196-4590-9023-f2a26481dc04',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          order: 1,
          type: 'title',
          value: 'LESSON 1',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '7bdbc486-1b5a-4dbe-9e24-c33638f4f0f9',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          order: 2,
          type: 'subtitle',
          value: 'Useful sentences',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'c2099306-4270-4cb6-a715-07a01133490b',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          order: 3,
          type: 'longtext',
          value:
            'Na leitura de textos, as dicas que você já recebeu são super úteis. <br>Agora, vamos mergulhar no mundo da comunicação oral em inglês. Isso significa aprender a entender o que os outros dizem e como responder de forma apropriada. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '60b4f99b-323d-46ad-beff-dfc1a416a009',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-6-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'a0ca3148-99e8-41f8-bd75-ae9dd762696d',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          order: 5,
          type: 'longtext',
          value:
            'Pronto para esta nova etapa? Vamos conhecer algumas *useful sentences*.\r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: '6b196188-03fc-4ad6-a022-386e5dece6dd',
          createdAt: '2024-06-17T19:57:21.020Z',
          updatedAt: '2024-06-17T19:57:21.020Z',
          key: 'useful sentences',
          title: 'useful sentences',
          subtitle: 'frases úteis',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-8b8c630e-676e-4219-9231-c6d649599167.mpeg',
          example: 'I know many useful sentences.',
          exampleTranslate: 'Eu sei muitas frases úteis.',
          description: '',
        },
      ],
    },
    {
      id: '605a7f9e-166b-4df7-96a0-408c1c72db83',
      createdAt: '2024-06-17T19:57:21.034Z',
      updatedAt: '2024-06-17T19:57:21.034Z',
      type: 'content',
      code: 'WU_LM_B1_P2',
      order: 2,
      status: 'activated',
      widgets: [
        {
          id: '18dfb3c3-8a10-495d-a735-b4f41fcdfb45',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 1,
          type: 'longtext',
          value:
            'Seja para pedir informações, expressar gratidão ou entender melhor o que alguém diz, conhecer essas frases pode te ajudar muito na comunicação. \r\n',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '01c76f9a-eb3f-46a9-bfe5-cdbfd919ab65',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 2,
          type: 'longtext',
          value:
            'Realize as atividades a seguir e descubra quantas você já conhece. Mas não se preocupe se não souber todos as respostas. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'template-2',
          options: [],
        },
        {
          id: 'ff47a351-72b1-4e82-b855-e8f3facf96d9',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 3,
          type: 'longtext',
          value:
            'O importante é aprender enquanto tenta.  Vamos lá descobrir quantas dessas frases você já domina ou vai aprender hoje! 😉 ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '4a854c32-217c-42c7-9588-d4b1dc1eb43a',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 4,
          type: 'spacer',
          value: '20',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'spacer',
          options: [],
        },
        {
          id: '5cfc3f7f-98f4-4a26-a1bf-a72aa361bb08',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 5,
          type: 'longtext',
          value: '<b>Leia o feedback com atenção a cada atividade. </b>',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '22590382-9b88-4deb-b77d-84425167e4e1',
          createdAt: '2024-06-17T19:57:21.034Z',
          updatedAt: '2024-06-17T19:57:21.034Z',
          order: 6,
          type: 'enunciated',
          value: 'Clique em START para iniciar.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '93321a1f-89a9-4792-b35d-01051f3bd68c',
      createdAt: '2024-06-17T19:57:21.047Z',
      updatedAt: '2024-06-17T19:57:21.047Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.1',
      order: 3,
      status: 'activated',
      widgets: [
        {
          id: '94c51c2a-a1d6-4d27-922a-fe6266db9a3f',
          createdAt: '2024-06-17T19:57:21.047Z',
          updatedAt: '2024-06-17T19:57:21.047Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '84f85a34-db2e-4ab1-8b44-030a3b2d193b',
          createdAt: '2024-06-17T19:57:21.047Z',
          updatedAt: '2024-06-17T19:57:21.047Z',
          order: 2,
          type: 'enunciated',
          value: 'Como se diz "What\'s this?" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '6719a8fd-fece-4f03-9cc6-7c17f9cbc58e',
          createdAt: '2024-06-17T19:57:21.047Z',
          updatedAt: '2024-06-17T19:57:21.047Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            'How significa como e Where onde! Portanto, a resposta correta é O que é isso?',
          template: 'default',
          options: [
            {
              id: '1ca0b1a7-eee3-4dcc-91b6-6ca585f460b5',
              createdAt: '2024-06-17T19:57:21.047Z',
              updatedAt: '2024-06-17T19:57:21.047Z',
              order: 0,
              text: 'Como é isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '562d17d5-fdbd-4d3a-a7d9-e69bc645ba6e',
              createdAt: '2024-06-17T19:57:21.047Z',
              updatedAt: '2024-06-17T19:57:21.047Z',
              order: 1,
              text: ' O que é isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '0c543b38-1cb7-46c7-9ebb-df6a95d7bd15',
              createdAt: '2024-06-17T19:57:21.047Z',
              updatedAt: '2024-06-17T19:57:21.047Z',
              order: 2,
              text: 'Onde é isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'ef9e9d9d-bdaa-4204-88e1-c3a8ace020e7',
      createdAt: '2024-06-17T19:57:21.061Z',
      updatedAt: '2024-06-17T19:57:21.061Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.2',
      order: 4,
      status: 'activated',
      widgets: [
        {
          id: 'a3a2e821-edd2-4620-b35d-3822cbcdb9cb',
          createdAt: '2024-06-17T19:57:21.061Z',
          updatedAt: '2024-06-17T19:57:21.061Z',
          order: 1,
          type: 'title',
          value: 'Complete a frase em inglês com a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '03095a0d-5b38-4f55-93c0-5edcfbd0311e',
          createdAt: '2024-06-17T19:57:21.061Z',
          updatedAt: '2024-06-17T19:57:21.061Z',
          order: 2,
          type: 'enunciated',
          value: '"This is ________" .',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '597844dd-4ec4-4571-882e-0fbde1f3c95d',
          createdAt: '2024-06-17T19:57:21.061Z',
          updatedAt: '2024-06-17T19:57:21.061Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage:
            "Isso mesmo. These are significa 'Esses são' e that is significa 'aquele é'.",
          wrongMessage:
            'This is" é seguido por um objeto ou pessoa, como em "This is a pen" (Isto é uma caneta)',
          template: 'default',
          options: [
            {
              id: '65789035-a756-4bfe-b24a-05b6dd8556e7',
              createdAt: '2024-06-17T19:57:21.061Z',
              updatedAt: '2024-06-17T19:57:21.061Z',
              order: 0,
              text: 'these are',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '4f025d29-af7c-432b-add5-7d9d74074806',
              createdAt: '2024-06-17T19:57:21.061Z',
              updatedAt: '2024-06-17T19:57:21.061Z',
              order: 1,
              text: 'that is',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'f44ccfad-c42a-4386-a079-10034de23c61',
              createdAt: '2024-06-17T19:57:21.061Z',
              updatedAt: '2024-06-17T19:57:21.061Z',
              order: 2,
              text: 'a pen',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b2934500-8a59-4425-82f8-362f27533116',
      createdAt: '2024-06-17T19:57:21.076Z',
      updatedAt: '2024-06-17T19:57:21.076Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.3',
      order: 5,
      status: 'activated',
      widgets: [
        {
          id: '068fc13d-25b7-4e8b-bbd5-db7534a1068a',
          createdAt: '2024-06-17T19:57:21.076Z',
          updatedAt: '2024-06-17T19:57:21.076Z',
          order: 1,
          type: 'title',
          value: 'Qual é a tradução de:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '7838e042-0dcd-47f1-96cc-4965a2f84ab9',
          createdAt: '2024-06-17T19:57:21.076Z',
          updatedAt: '2024-06-17T19:57:21.076Z',
          order: 2,
          type: 'enunciated',
          value: ' "What are these?"',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '6ba55b69-c1ff-45aa-9fa2-c1796c83c290',
          createdAt: '2024-06-17T19:57:21.076Z',
          updatedAt: '2024-06-17T19:57:21.076Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"What are these?" significa "O que são estes?", perguntando sobre múltiplos itens.',
          template: 'default',
          options: [
            {
              id: '1e4e017d-a0df-42a3-8e5f-601473bbc9bd',
              createdAt: '2024-06-17T19:57:21.076Z',
              updatedAt: '2024-06-17T19:57:21.076Z',
              order: 1,
              text: 'O que são estes? ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '02ae22e4-c581-4a57-a892-238c94e30700',
              createdAt: '2024-06-17T19:57:21.076Z',
              updatedAt: '2024-06-17T19:57:21.076Z',
              order: 2,
              text: 'Como são estes? ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'e0109417-97b8-4f66-a0c9-c9084950d76f',
              createdAt: '2024-06-17T19:57:21.076Z',
              updatedAt: '2024-06-17T19:57:21.076Z',
              order: 3,
              text: 'Onde são estes?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '07301a0a-e7f8-4a29-950a-60cd2996138e',
      createdAt: '2024-06-17T19:57:21.091Z',
      updatedAt: '2024-06-17T19:57:21.091Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.4',
      order: 6,
      status: 'activated',
      widgets: [
        {
          id: 'ebe2cb60-9b0b-48e7-96f1-e718ffab096c',
          createdAt: '2024-06-17T19:57:21.091Z',
          updatedAt: '2024-06-17T19:57:21.091Z',
          order: 1,
          type: 'title',
          value: 'Complete a frase com a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '048cb93a-a194-406a-babc-6fa1242b1fcb',
          createdAt: '2024-06-17T19:57:21.091Z',
          updatedAt: '2024-06-17T19:57:21.091Z',
          order: 2,
          type: 'enunciated',
          value: ' "These are ________"',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '55dcf609-fab4-416b-a7e9-737f73bb62d8',
          createdAt: '2024-06-17T19:57:21.091Z',
          updatedAt: '2024-06-17T19:57:21.091Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"These are" é usado com substantivos no plural, como "books" (livros).',
          template: 'default',
          options: [
            {
              id: '365f7b8c-d74a-47e8-b7ee-8872fa5f382a',
              createdAt: '2024-06-17T19:57:21.091Z',
              updatedAt: '2024-06-17T19:57:21.091Z',
              order: 0,
              text: 'books',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '3af09051-b0af-4121-bb1b-722231852404',
              createdAt: '2024-06-17T19:57:21.091Z',
              updatedAt: '2024-06-17T19:57:21.091Z',
              order: 1,
              text: 'book',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'e94c7b07-db40-4575-82f3-c1ca628b3a1b',
              createdAt: '2024-06-17T19:57:21.091Z',
              updatedAt: '2024-06-17T19:57:21.091Z',
              order: 2,
              text: 'a book',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '3d1e8333-1412-482e-b0f6-26b9b708deff',
      createdAt: '2024-06-17T19:57:21.105Z',
      updatedAt: '2024-06-17T19:57:21.105Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.5',
      order: 7,
      status: 'activated',
      widgets: [
        {
          id: 'da232802-d329-4ddb-87ed-e6f0673224f5',
          createdAt: '2024-06-17T19:57:21.105Z',
          updatedAt: '2024-06-17T19:57:21.105Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'bce58b3b-aa6c-48b8-bcfe-cf94492ce8c4',
          createdAt: '2024-06-17T19:57:21.105Z',
          updatedAt: '2024-06-17T19:57:21.105Z',
          order: 2,
          type: 'enunciated',
          value: '"How do you spell it?" significa:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '0b25f607-3a63-4912-821f-a1175ea85dfd',
          createdAt: '2024-06-17T19:57:21.105Z',
          updatedAt: '2024-06-17T19:57:21.105Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"How do you spell it?" é uma pergunta sobre como soletrar uma palavra.',
          template: 'default',
          options: [
            {
              id: 'a81e63a8-6dff-4bb4-939e-a464be24bee3',
              createdAt: '2024-06-17T19:57:21.105Z',
              updatedAt: '2024-06-17T19:57:21.105Z',
              order: 0,
              text: 'Como você soletra isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '12258d96-58c3-4582-bd16-e5bee5ada774',
              createdAt: '2024-06-17T19:57:21.105Z',
              updatedAt: '2024-06-17T19:57:21.105Z',
              order: 1,
              text: 'Como você escreve isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'ddb8c491-3f07-4911-abce-8c655a46d9eb',
              createdAt: '2024-06-17T19:57:21.105Z',
              updatedAt: '2024-06-17T19:57:21.105Z',
              order: 2,
              text: 'Como você diz isso?',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '8a959545-ca96-41bf-aa33-a99e9d8583e6',
      createdAt: '2024-06-17T19:57:21.120Z',
      updatedAt: '2024-06-17T19:57:21.120Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.6',
      order: 8,
      status: 'activated',
      widgets: [
        {
          id: 'c7f72ebb-8ba0-49b0-a6d3-c07b1bd3407c',
          createdAt: '2024-06-17T19:57:21.120Z',
          updatedAt: '2024-06-17T19:57:21.120Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '440b4347-0813-490b-8722-24bcaa868129',
          createdAt: '2024-06-17T19:57:21.120Z',
          updatedAt: '2024-06-17T19:57:21.120Z',
          order: 2,
          type: 'enunciated',
          value: 'Qual é a tradução de "Speak slowly, please"?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '5ec30f2b-860a-43ad-84c4-616f3dec8066',
          createdAt: '2024-06-17T19:57:21.120Z',
          updatedAt: '2024-06-17T19:57:21.120Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Speak slowly" significa falar de forma lenta ou devagar.',
          template: 'default',
          options: [
            {
              id: '17131d27-ad17-4e4f-b87a-301d62e3eafb',
              createdAt: '2024-06-17T19:57:21.120Z',
              updatedAt: '2024-06-17T19:57:21.120Z',
              order: 0,
              text: 'Fale devagar, por favor. ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: 'd6f80211-c3c2-4b12-838d-05dced098272',
              createdAt: '2024-06-17T19:57:21.120Z',
              updatedAt: '2024-06-17T19:57:21.120Z',
              order: 1,
              text: 'Fale rapidamente, por favor. ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '70b0c06b-4315-4fac-a5e4-88c5c80facc0',
              createdAt: '2024-06-17T19:57:21.120Z',
              updatedAt: '2024-06-17T19:57:21.120Z',
              order: 2,
              text: 'Fale alto, por favor.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '76fb83f0-762c-4091-96db-cb15b330c220',
      createdAt: '2024-06-17T19:57:21.134Z',
      updatedAt: '2024-06-17T19:57:21.134Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.7',
      order: 9,
      status: 'activated',
      widgets: [
        {
          id: 'cb7043a6-3120-4bbe-8f91-cd0bc7191a73',
          createdAt: '2024-06-17T19:57:21.134Z',
          updatedAt: '2024-06-17T19:57:21.134Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '2d8c8d20-9515-4192-8786-a00fa49ac87d',
          createdAt: '2024-06-17T19:57:21.134Z',
          updatedAt: '2024-06-17T19:57:21.134Z',
          order: 2,
          type: 'enunciated',
          value: '"Repeat, please" em português é:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'ead7fd0e-17b7-4aa0-b271-12981972daac',
          createdAt: '2024-06-17T19:57:21.134Z',
          updatedAt: '2024-06-17T19:57:21.134Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Repeat" significa "repetir", usado para pedir que algo seja dito novamente.',
          template: 'default',
          options: [
            {
              id: 'babf2777-b5a2-4c3b-804d-c421b79e6806',
              createdAt: '2024-06-17T19:57:21.134Z',
              updatedAt: '2024-06-17T19:57:21.134Z',
              order: 0,
              text: 'Escreva, por favor. ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '5ab5fe2f-da44-49f2-9025-0250bbca4f0b',
              createdAt: '2024-06-17T19:57:21.134Z',
              updatedAt: '2024-06-17T19:57:21.134Z',
              order: 1,
              text: 'Repita, por favor.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '356e2ced-8849-48f6-b4b1-2f392ef8022b',
              createdAt: '2024-06-17T19:57:21.134Z',
              updatedAt: '2024-06-17T19:57:21.134Z',
              order: 2,
              text: 'Ouça, por favor.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '76e12369-0acf-49ab-b933-8ceac470599d',
      createdAt: '2024-06-17T19:57:21.148Z',
      updatedAt: '2024-06-17T19:57:21.148Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.8',
      order: 10,
      status: 'activated',
      widgets: [
        {
          id: '7162e5ea-4adf-419a-928c-6c4cd4637e96',
          createdAt: '2024-06-17T19:57:21.148Z',
          updatedAt: '2024-06-17T19:57:21.148Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '1a9fb01d-7690-44ed-9266-d2e71614e629',
          createdAt: '2024-06-17T19:57:21.148Z',
          updatedAt: '2024-06-17T19:57:21.148Z',
          order: 2,
          type: 'enunciated',
          value: 'Como se diz "Thank you" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'ec6b2b2d-b43a-4090-b953-255b58c9883c',
          createdAt: '2024-06-17T19:57:21.148Z',
          updatedAt: '2024-06-17T19:57:21.148Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Thank you" é uma expressão de gratidão, equivalente a "Obrigado".',
          template: 'default',
          options: [
            {
              id: '6155103d-32d1-4e97-96d0-bbc5051abba3',
              createdAt: '2024-06-17T19:57:21.148Z',
              updatedAt: '2024-06-17T19:57:21.148Z',
              order: 0,
              text: 'De nada.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '638c2870-1ee8-400c-81eb-816957cadb62',
              createdAt: '2024-06-17T19:57:21.148Z',
              updatedAt: '2024-06-17T19:57:21.148Z',
              order: 1,
              text: 'Por favor.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '799bccb6-d3ae-4f36-905f-0243ce6f4cef',
              createdAt: '2024-06-17T19:57:21.148Z',
              updatedAt: '2024-06-17T19:57:21.148Z',
              order: 2,
              text: 'Obrigada (o). ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b5e2dfae-1a66-49c0-91ba-df6202096ab3',
      createdAt: '2024-06-17T19:57:21.162Z',
      updatedAt: '2024-06-17T19:57:21.162Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.9',
      order: 11,
      status: 'activated',
      widgets: [
        {
          id: '6d838f54-06f3-4a89-85b9-6d88f2e95810',
          createdAt: '2024-06-17T19:57:21.162Z',
          updatedAt: '2024-06-17T19:57:21.162Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'e818f7cc-bb13-4495-8e36-93db00f8dc4a',
          createdAt: '2024-06-17T19:57:21.162Z',
          updatedAt: '2024-06-17T19:57:21.162Z',
          order: 2,
          type: 'enunciated',
          value: ' A tradução de "Sorry" é:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'ea67a6d3-c4b5-4755-b778-c9844184501a',
          createdAt: '2024-06-17T19:57:21.162Z',
          updatedAt: '2024-06-17T19:57:21.162Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Sorry" é usado para expressar desculpas ou lamentar algo.',
          template: 'default',
          options: [
            {
              id: '1b7af82a-2817-449a-b2e5-4ccac3922be3',
              createdAt: '2024-06-17T19:57:21.162Z',
              updatedAt: '2024-06-17T19:57:21.162Z',
              order: 0,
              text: 'Por favor.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'e78f8104-9cce-4917-8fcb-e8da654ac620',
              createdAt: '2024-06-17T19:57:21.162Z',
              updatedAt: '2024-06-17T19:57:21.162Z',
              order: 1,
              text: 'Obrigada.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '11b4b777-eef5-429b-b64f-76bd4f93d7bc',
              createdAt: '2024-06-17T19:57:21.162Z',
              updatedAt: '2024-06-17T19:57:21.162Z',
              order: 2,
              text: 'Desculpa.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'c45a0f33-91e6-45ba-b83a-8c632645c721',
      createdAt: '2024-06-17T19:57:21.176Z',
      updatedAt: '2024-06-17T19:57:21.176Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.10',
      order: 12,
      status: 'activated',
      widgets: [
        {
          id: '87a7edae-26bb-421a-85fe-c4798905836f',
          createdAt: '2024-06-17T19:57:21.176Z',
          updatedAt: '2024-06-17T19:57:21.176Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'e4680b0f-4378-427a-87ce-27ae9e36d21b',
          createdAt: '2024-06-17T19:57:21.176Z',
          updatedAt: '2024-06-17T19:57:21.176Z',
          order: 2,
          type: 'enunciated',
          value: ' "You\'re welcome" em português significa:',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '1deaa503-7365-4494-af68-192520ce1c89',
          createdAt: '2024-06-17T19:57:21.176Z',
          updatedAt: '2024-06-17T19:57:21.176Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"You\'re welcome" é uma resposta comum a "Thank you", significando "De nada".',
          template: 'default',
          options: [
            {
              id: '2762da68-bcb2-40e0-bcd0-e52330e174cb',
              createdAt: '2024-06-17T19:57:21.176Z',
              updatedAt: '2024-06-17T19:57:21.176Z',
              order: 0,
              text: 'De nada.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '9c5616e4-15b8-48f5-bcbe-1c16466dfe06',
              createdAt: '2024-06-17T19:57:21.176Z',
              updatedAt: '2024-06-17T19:57:21.176Z',
              order: 1,
              text: 'Bem-vindo.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'e816ecc6-bba8-415f-869d-efd325ee04c1',
              createdAt: '2024-06-17T19:57:21.176Z',
              updatedAt: '2024-06-17T19:57:21.176Z',
              order: 2,
              text: 'Obrigada.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '52f6fdab-e33c-49f5-8cad-b08339822835',
      createdAt: '2024-06-17T19:57:21.189Z',
      updatedAt: '2024-06-17T19:57:21.189Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.11',
      order: 13,
      status: 'activated',
      widgets: [
        {
          id: '62ce9966-bcd0-4f5c-ba89-e552bd7bd447',
          createdAt: '2024-06-17T19:57:21.189Z',
          updatedAt: '2024-06-17T19:57:21.189Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '9680e3a2-b060-477f-b9a8-cf0447cde45c',
          createdAt: '2024-06-17T19:57:21.189Z',
          updatedAt: '2024-06-17T19:57:21.189Z',
          order: 2,
          type: 'enunciated',
          value: 'Qual é a tradução de "Excuse me"?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '4b6ace6a-bcc9-480b-8e9a-7ab0d4bc60de',
          createdAt: '2024-06-17T19:57:21.189Z',
          updatedAt: '2024-06-17T19:57:21.189Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Excuse me" pode ser usado para pedir permissão para passar ou chamar atenção.',
          template: 'default',
          options: [
            {
              id: '48a8b4a2-7709-43e7-b4d9-0d7be26eb1f4',
              createdAt: '2024-06-17T19:57:21.189Z',
              updatedAt: '2024-06-17T19:57:21.189Z',
              order: 0,
              text: 'Obrigado.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: '329683d7-8499-4859-8635-23606ca97f93',
              createdAt: '2024-06-17T19:57:21.189Z',
              updatedAt: '2024-06-17T19:57:21.189Z',
              order: 1,
              text: 'Com licença.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: '700202eb-c542-4242-872d-f5582d3a758e',
              createdAt: '2024-06-17T19:57:21.189Z',
              updatedAt: '2024-06-17T19:57:21.189Z',
              order: 2,
              text: 'Desculpa.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'ea2985aa-c3bd-425b-94fe-d305d0a65343',
      createdAt: '2024-06-17T19:57:21.204Z',
      updatedAt: '2024-06-17T19:57:21.204Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.12',
      order: 14,
      status: 'activated',
      widgets: [
        {
          id: '3057c9dc-b7a3-447b-b001-0ad810723556',
          createdAt: '2024-06-17T19:57:21.204Z',
          updatedAt: '2024-06-17T19:57:21.204Z',
          order: 1,
          type: 'title',
          value: 'Correlacione as USEFUL PHRASES abaixo.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '5a24b307-6736-4547-adb0-73ae282e6e5e',
          createdAt: '2024-06-17T19:57:21.204Z',
          updatedAt: '2024-06-17T19:57:21.204Z',
          order: 2,
          type: 'combination',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '99f67c3d-aa46-4ec9-bcc2-ae3b6c848ec7',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 0,
              text: "What's this?",
              textRelated: 'O que é isso?',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-2127899d-68a0-4819-b2b3-24f89d5eee0f.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '4efed1e8-b564-4703-aa14-a2c285325cb8',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 1,
              text: 'Speak slowly, please.',
              textRelated: 'Fale devagar, por favor.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-4392596f-9991-4dd5-af2a-6aa48ae65c66.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '87c8930f-bf43-49fd-a47d-79d81bca4d2b',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 2,
              text: 'Thank you.',
              textRelated: 'Obrigado',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-9988cf44-17f9-410b-aa14-efbf774ea949.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '51644761-f6af-482e-bf94-b22116dcaddb',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 3,
              text: 'This is a book.',
              textRelated: 'Isso é um livro.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-3-6773c6b3-32fd-4684-9d0c-b9664a2be91b-0336aed0-0584-4fb5-bea5-d744afd8b000.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '082f4f80-f846-4e3d-82d4-5d4019dd8f85',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 4,
              text: 'What are these?',
              textRelated: 'O que esses são?',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-4-6773c6b3-32fd-4684-9d0c-b9664a2be91b-a6f821ff-95ad-440f-8f77-21ef79a752aa.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '30f0c62b-f163-4b7e-be6b-7ef9f74e277f',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 5,
              text: 'These are books.',
              textRelated: 'Esses são livros.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-5-6773c6b3-32fd-4684-9d0c-b9664a2be91b-64aef777-3bfc-45e1-9cbc-5680d182a8d9.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: 'ba2716c7-8e81-4eab-96e0-bcd5991b49cd',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 6,
              text: 'How do you spell it?',
              textRelated: 'Como se soletra?',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-6-6773c6b3-32fd-4684-9d0c-b9664a2be91b-22162d6e-0137-4e32-b25c-bffb6cdbeef7.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: 'b2a258b0-15a8-4e47-80ff-c2a75eac95b1',
              createdAt: '2024-06-17T19:57:21.204Z',
              updatedAt: '2024-06-17T19:57:21.204Z',
              order: 7,
              text: 'Repeat, please.',
              textRelated: 'Repita, por favor.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-7-6773c6b3-32fd-4684-9d0c-b9664a2be91b-b16193ea-ff30-46b2-a7cd-fcb3194c58dd.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'c8eee718-7961-400a-abab-f24c1f334e7d',
      createdAt: '2024-06-17T19:57:21.218Z',
      updatedAt: '2024-06-17T19:57:21.218Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.13',
      order: 15,
      status: 'activated',
      widgets: [
        {
          id: 'af6fc2b5-a79e-4a7c-be83-48ee8e80060c',
          createdAt: '2024-06-17T19:57:21.218Z',
          updatedAt: '2024-06-17T19:57:21.218Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'da1db904-077c-414b-9124-66076ecbde38',
          createdAt: '2024-06-17T19:57:21.218Z',
          updatedAt: '2024-06-17T19:57:21.218Z',
          order: 2,
          type: 'enunciated',
          value:
            'Em uma situação em que você não entendeu algo, qual frase é apropriada?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '8cac3135-64bd-4993-8eb4-e3dcec1d300c',
          createdAt: '2024-06-17T19:57:21.218Z',
          updatedAt: '2024-06-17T19:57:21.218Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Can you repeat, please?" é usado para pedir que algo seja dito novamente.',
          template: 'default',
          options: [
            {
              id: '5649c401-a24c-4ef1-a6fd-0e771fbfaf07',
              createdAt: '2024-06-17T19:57:21.218Z',
              updatedAt: '2024-06-17T19:57:21.218Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-12.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '9a72ef48-c8a1-400e-84e6-3b8aae717a4f',
              createdAt: '2024-06-17T19:57:21.218Z',
              updatedAt: '2024-06-17T19:57:21.218Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-12.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '31271317-7c26-4745-81c6-6b39964ac58d',
              createdAt: '2024-06-17T19:57:21.218Z',
              updatedAt: '2024-06-17T19:57:21.218Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-12.mpeg',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'bd4cbc13-d83c-49db-808b-70dd21b5948c',
      createdAt: '2024-06-17T19:57:21.231Z',
      updatedAt: '2024-06-17T19:57:21.231Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.14',
      order: 16,
      status: 'activated',
      widgets: [
        {
          id: '2788ba86-104a-4c72-a9d8-88909330b5ee',
          createdAt: '2024-06-17T19:57:21.231Z',
          updatedAt: '2024-06-17T19:57:21.231Z',
          order: 1,
          type: 'title',
          value: 'Selecione a tradução correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '36957234-679b-4ae7-b69e-e8a2b98af1ca',
          createdAt: '2024-06-17T19:57:21.231Z',
          updatedAt: '2024-06-17T19:57:21.231Z',
          order: 2,
          type: 'enunciated',
          value: 'Como se diz "I\'m sorry, I don\'t understand" em português?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '7de3d1de-a3fe-41ee-a095-1f6b1a2bb588',
          createdAt: '2024-06-17T19:57:21.231Z',
          updatedAt: '2024-06-17T19:57:21.231Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            'Esta frase é usada para expressar que você não entendeu algo.',
          template: 'default',
          options: [
            {
              id: '4f21dd1d-f0b6-49e8-8c6a-de13fec7b358',
              createdAt: '2024-06-17T19:57:21.231Z',
              updatedAt: '2024-06-17T19:57:21.231Z',
              order: 0,
              text: 'Por favor, fale mais alto. ',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
            {
              id: 'a32ad958-bac6-44f7-877f-ae617e5bf7b7',
              createdAt: '2024-06-17T19:57:21.231Z',
              updatedAt: '2024-06-17T19:57:21.231Z',
              order: 1,
              text: 'Desculpe, eu não compreendo.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: true,
            },
            {
              id: 'ea94fc90-d809-4eb5-8ce9-9dddc89ec75c',
              createdAt: '2024-06-17T19:57:21.231Z',
              updatedAt: '2024-06-17T19:57:21.231Z',
              order: 2,
              text: 'Obrigado, eu entendo.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '83103409-4503-43a7-8c61-948950867717',
      createdAt: '2024-06-17T19:57:21.245Z',
      updatedAt: '2024-06-17T19:57:21.245Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.15',
      order: 17,
      status: 'activated',
      widgets: [
        {
          id: '8f9dac31-1fd4-4359-a895-fe910bbc67a0',
          createdAt: '2024-06-17T19:57:21.245Z',
          updatedAt: '2024-06-17T19:57:21.245Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '62d2bd8c-1196-4338-b6b9-6c3e1db78b72',
          createdAt: '2024-06-17T19:57:21.245Z',
          updatedAt: '2024-06-17T19:57:21.245Z',
          order: 2,
          type: 'enunciated',
          value: 'Como se diz "Por favor, fale mais devagar" em inglês?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '9624daa1-65ce-4080-a280-7c07f028476d',
          createdAt: '2024-06-17T19:57:21.245Z',
          updatedAt: '2024-06-17T19:57:21.245Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'fd375309-98a8-44e8-844f-ae8eadcc46d7',
              createdAt: '2024-06-17T19:57:21.245Z',
              updatedAt: '2024-06-17T19:57:21.245Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-cb3d02d6-ff06-40f6-b015-b33c4321645f.mpeg',
              audioRelated: '',
              right: true,
            },
            {
              id: '8a4ee6f0-98cf-46ce-9968-e5535bd1ba54',
              createdAt: '2024-06-17T19:57:21.245Z',
              updatedAt: '2024-06-17T19:57:21.245Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-612dd707-2ed6-4b0c-9b14-480c4bad947c.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '8784413f-ea13-4031-a115-878ba1731d80',
              createdAt: '2024-06-17T19:57:21.245Z',
              updatedAt: '2024-06-17T19:57:21.245Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-dfdfcb16-ed6d-4ae4-abe2-04b0e849244b.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b6feba8f-380b-4fab-bbe2-098fcf72f76a',
      createdAt: '2024-06-17T19:57:21.259Z',
      updatedAt: '2024-06-17T19:57:21.259Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.16',
      order: 18,
      status: 'activated',
      widgets: [
        {
          id: 'a7b959e4-89fa-4cd1-b6b3-cfa707c6ea2a',
          createdAt: '2024-06-17T19:57:21.259Z',
          updatedAt: '2024-06-17T19:57:21.259Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '402e3f66-4a32-476f-b8c9-706062b4f007',
          createdAt: '2024-06-17T19:57:21.259Z',
          updatedAt: '2024-06-17T19:57:21.259Z',
          order: 2,
          type: 'enunciated',
          value:
            'Em uma situação, em que você precisa passar por alguém, qual frase você usaria em inglês?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '35be614e-eb53-43ed-9e62-b48efdef2910',
          createdAt: '2024-06-17T19:57:21.259Z',
          updatedAt: '2024-06-17T19:57:21.259Z',
          order: 3,
          type: 'select',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage:
            '"Excuse me" é usado para pedir permissão ou desculpas em uma situação como essa.',
          template: 'default',
          options: [
            {
              id: 'e6d47f51-657f-43ff-963b-13fe3190fef2',
              createdAt: '2024-06-17T19:57:21.259Z',
              updatedAt: '2024-06-17T19:57:21.259Z',
              order: 3,
              text: 'Sorry.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-e034d429-c20f-4f7b-ae07-ea50cd5dbb99.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '8d0220aa-6a29-4e76-9331-c8e6dccc53b4',
              createdAt: '2024-06-17T19:57:21.259Z',
              updatedAt: '2024-06-17T19:57:21.259Z',
              order: 4,
              text: 'Excuse me.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-2ca101b2-81a7-47b7-8041-3123006b9e74.mpeg',
              audioRelated: '',
              right: true,
            },
            {
              id: '3292ed6a-d7ac-40a5-8510-d8b4bc37a368',
              createdAt: '2024-06-17T19:57:21.259Z',
              updatedAt: '2024-06-17T19:57:21.259Z',
              order: 5,
              text: 'Thank you.',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-22b039cd-4b6f-4f2f-8916-dd7848b2f920.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'a87e194d-24a2-472b-87df-dba618872498',
      createdAt: '2024-06-17T19:57:21.280Z',
      updatedAt: '2024-06-17T19:57:21.280Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.17',
      order: 19,
      status: 'activated',
      widgets: [
        {
          id: '41660b57-faa9-4704-a490-01feb8cd861f',
          createdAt: '2024-06-17T19:57:21.280Z',
          updatedAt: '2024-06-17T19:57:21.280Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'be1991a3-a860-45ed-b37b-f4d5b5f82dff',
          createdAt: '2024-06-17T19:57:21.280Z',
          updatedAt: '2024-06-17T19:57:21.280Z',
          order: 2,
          type: 'enunciated',
          value:
            'Como se diz "Obrigado" em inglês quando alguém lhe oferece ajuda?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '988571aa-53be-4965-bfe8-bee8393a90d9',
          createdAt: '2024-06-17T19:57:21.280Z',
          updatedAt: '2024-06-17T19:57:21.280Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'e19a6c81-5ea3-4d0b-b2e1-a1fec468638a',
              createdAt: '2024-06-17T19:57:21.280Z',
              updatedAt: '2024-06-17T19:57:21.280Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-23.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '20e08988-4d05-4936-93dc-37356eef385b',
              createdAt: '2024-06-17T19:57:21.280Z',
              updatedAt: '2024-06-17T19:57:21.280Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-23.mpeg',
              audioRelated: '',
              right: true,
            },
            {
              id: 'b43ec3b5-0d43-496a-9583-1b2b57f161e4',
              createdAt: '2024-06-17T19:57:21.280Z',
              updatedAt: '2024-06-17T19:57:21.280Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-23.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '9c90d5c6-9d5f-4291-af77-f51e89ae2da0',
      createdAt: '2024-06-17T19:57:21.298Z',
      updatedAt: '2024-06-17T19:57:21.298Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.18',
      order: 20,
      status: 'activated',
      widgets: [
        {
          id: '378ce168-4bf6-4b7b-bd39-4b1a95676da2',
          createdAt: '2024-06-17T19:57:21.298Z',
          updatedAt: '2024-06-17T19:57:21.298Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'b0830dc1-4d61-43c3-8f09-328ac6753506',
          createdAt: '2024-06-17T19:57:21.298Z',
          updatedAt: '2024-06-17T19:57:21.298Z',
          order: 2,
          type: 'enunciated',
          value:
            'Se você acidentalmente esbarra em alguém, qual expressão você usaria em inglês?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '1d4e2f4c-8999-43a7-8cc4-97a66c3dbe45',
          createdAt: '2024-06-17T19:57:21.298Z',
          updatedAt: '2024-06-17T19:57:21.298Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '0f0d57f8-819f-4956-a51c-42f1fccc3dd2',
              createdAt: '2024-06-17T19:57:21.298Z',
              updatedAt: '2024-06-17T19:57:21.298Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-24.mpeg',
              audioRelated: '',
              right: true,
            },
            {
              id: '96f7318f-b775-411b-ad16-24b3546bc46c',
              createdAt: '2024-06-17T19:57:21.298Z',
              updatedAt: '2024-06-17T19:57:21.298Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-24.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '22724788-c044-4970-81a4-1fc7ba4aebdc',
              createdAt: '2024-06-17T19:57:21.298Z',
              updatedAt: '2024-06-17T19:57:21.298Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-24.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b6d3bd20-3b93-44a0-aeda-f54b7fe49a3e',
      createdAt: '2024-06-17T19:57:21.312Z',
      updatedAt: '2024-06-17T19:57:21.312Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.19',
      order: 21,
      status: 'activated',
      widgets: [
        {
          id: '187147b5-f071-4bca-bb78-4ff0b1c09b28',
          createdAt: '2024-06-17T19:57:21.312Z',
          updatedAt: '2024-06-17T19:57:21.312Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '197f9045-f1bd-44f3-b292-2cbcc6d43d5a',
          createdAt: '2024-06-17T19:57:21.312Z',
          updatedAt: '2024-06-17T19:57:21.312Z',
          order: 2,
          type: 'enunciated',
          value:
            'Como se diz "Com licença" quando você quer chamar a atenção de alguém em inglês?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '916ffd80-30b9-46e1-9395-5cac4cc9cd02',
          createdAt: '2024-06-17T19:57:21.312Z',
          updatedAt: '2024-06-17T19:57:21.312Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '3773745f-9bd0-4993-927c-d62eeaf2b9f2',
              createdAt: '2024-06-17T19:57:21.312Z',
              updatedAt: '2024-06-17T19:57:21.312Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-25.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '565802c2-6a75-4596-89fd-c526e1821bbc',
              createdAt: '2024-06-17T19:57:21.312Z',
              updatedAt: '2024-06-17T19:57:21.312Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-25.mpeg',
              audioRelated: '',
              right: true,
            },
            {
              id: '491fc3e5-704b-40d2-bd53-2515bf0b6a03',
              createdAt: '2024-06-17T19:57:21.312Z',
              updatedAt: '2024-06-17T19:57:21.312Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-25.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '8e434e63-4a5b-4736-b05f-1195983d3f24',
      createdAt: '2024-06-17T19:57:21.327Z',
      updatedAt: '2024-06-17T19:57:21.327Z',
      type: 'activity',
      code: 'WU_LM_B1_P3_A1_A20.20',
      order: 22,
      status: 'activated',
      widgets: [
        {
          id: '8ef54b8d-4f8f-4f2b-82ff-3db183102ae5',
          createdAt: '2024-06-17T19:57:21.327Z',
          updatedAt: '2024-06-17T19:57:21.327Z',
          order: 1,
          type: 'title',
          value: 'Selecione a opção correta.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'b72e9276-1697-4d72-b5d0-f23095cac00c',
          createdAt: '2024-06-17T19:57:21.327Z',
          updatedAt: '2024-06-17T19:57:21.327Z',
          order: 2,
          type: 'enunciated',
          value:
            'Qual é a maneira correta de pedir que alguém repita algo em inglês?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '1a6b987d-4212-4180-aea3-12f1b2113844',
          createdAt: '2024-06-17T19:57:21.327Z',
          updatedAt: '2024-06-17T19:57:21.327Z',
          order: 3,
          type: 'selectvoice',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'f02def90-e998-4ade-a251-090b618a6bbe',
              createdAt: '2024-06-17T19:57:21.327Z',
              updatedAt: '2024-06-17T19:57:21.327Z',
              order: 0,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-26.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: '7371ec5a-0066-4168-9622-ed783312d174',
              createdAt: '2024-06-17T19:57:21.327Z',
              updatedAt: '2024-06-17T19:57:21.327Z',
              order: 1,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-26.mpeg',
              audioRelated: '',
              right: false,
            },
            {
              id: 'c20e0fc7-7b88-4ec2-9c3f-cb54c3571f7e',
              createdAt: '2024-06-17T19:57:21.327Z',
              updatedAt: '2024-06-17T19:57:21.327Z',
              order: 2,
              text: '',
              textRelated: '',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-26.mpeg',
              audioRelated: '',
              right: true,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '52070be8-9a19-47df-8e4c-434670ca678e',
      createdAt: '2024-06-17T19:57:21.341Z',
      updatedAt: '2024-06-17T19:57:21.341Z',
      type: 'content',
      code: 'WU_LM_B1_P4',
      order: 23,
      status: 'activated',
      widgets: [
        {
          id: '003b6d92-65e3-42f9-baae-c58341dd689e',
          createdAt: '2024-06-17T19:57:21.341Z',
          updatedAt: '2024-06-17T19:57:21.341Z',
          order: 1,
          type: 'longtext',
          value:
            'Não se preocupe se você ainda está bem no início da sua jornada, e não conhecia essas frases úteis. Você terá muitas oportunidades de praticar e aprendê-las.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0a1b985e-d01a-43f4-9096-89cf2aeedaf2',
          createdAt: '2024-06-17T19:57:21.341Z',
          updatedAt: '2024-06-17T19:57:21.341Z',
          order: 2,
          type: 'longtext',
          value: 'Aqui vai uma dica legal!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '0ccdb88d-d0a5-4ac6-a529-819c3072b2b3',
          createdAt: '2024-06-17T19:57:21.341Z',
          updatedAt: '2024-06-17T19:57:21.341Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-0b4adac2-36ea-49d0-9b5b-7b62bbb74592.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '8719f274-0047-4894-89f1-94419855a3f3',
          createdAt: '2024-06-17T19:57:21.341Z',
          updatedAt: '2024-06-17T19:57:21.341Z',
          order: 4,
          type: 'longtext',
          value:
            'Crie seu próprio dicionário! Faça desenhos. Crie frases como exemplo. Lembra do mapinha mental? Modifique palavras, e descubra mais possibilidades de uso. ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'template-1',
          options: [],
        },
      ],
      hints: [],
    },
    {
      id: '5a4102e7-fbc6-4f50-aa99-15cf7bb19ad2',
      createdAt: '2024-06-17T19:57:21.352Z',
      updatedAt: '2024-06-17T19:57:21.352Z',
      type: 'content',
      code: 'WU_LM_N1_L1_P24',
      order: 24,
      status: 'activated',
      widgets: [
        {
          id: '63392915-9404-4882-8a07-4dab39c3b995',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          order: 1,
          type: 'subtitle',
          value: 'Keep practicing!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '89f950b9-78c9-4c5c-89cb-f5d211924353',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          order: 2,
          type: 'longtext',
          value:
            'Você viu como você aprendeu frases super úteis enquanto testava os seus conhecimentos? ',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '446634aa-8c53-49e0-ab1e-95f48510938d',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          order: 3,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-2-6773c6b3-32fd-4684-9d0c-b9664a2be91b-072db7f9-86fb-40a7-9441-27adbd6b7c69.jpeg',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '27a5ee68-0c31-4743-b58f-277cc216c715',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          order: 4,
          type: 'longtext',
          value:
            'Agora você vai realizar algumas atividades para praticar a pronúncia de <b>5 frases mágicas</b> que não podem faltar no seu vocabulário de jeito nenhum.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '918c5995-f032-47e3-b0a9-220948a833f9',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          order: 5,
          type: 'longtext',
          value: "*Let's go!*",
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [
        {
          id: 'e09e8a18-e43b-4388-bf27-f191cf55e473',
          createdAt: '2024-06-17T19:57:21.352Z',
          updatedAt: '2024-06-17T19:57:21.352Z',
          key: "Let's go!",
          title: "Let's go!",
          subtitle: 'Vamos!',
          audio:
            'https://go2dev.s3.amazonaws.com/questions/audios/hint-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-894b2b33-490f-46bf-8bc4-3e322ece5a9d.mpeg',
          example: "Let's go!",
          exampleTranslate: 'Vamos!',
          description:
            "Let's é usado para convidar alguém a fazer algo. Let's + o verbo.",
        },
      ],
    },
    {
      id: '7376f979-5679-4ab4-b2ed-753224464933',
      createdAt: '2024-06-17T19:57:21.366Z',
      updatedAt: '2024-06-17T19:57:21.366Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.1',
      order: 25,
      status: 'activated',
      widgets: [
        {
          id: '600a7703-c236-418d-a5c8-80b44dc93324',
          createdAt: '2024-06-17T19:57:21.366Z',
          updatedAt: '2024-06-17T19:57:21.366Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'f34a93b1-302d-4725-8a10-d52a5b0aa93e',
          createdAt: '2024-06-17T19:57:21.366Z',
          updatedAt: '2024-06-17T19:57:21.366Z',
          order: 2,
          type: 'enunciated',
          value: 'Excuse me.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'e91584a4-fc5f-48ae-9a7b-882280b8a583',
          createdAt: '2024-06-17T19:57:21.366Z',
          updatedAt: '2024-06-17T19:57:21.366Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'cd575447-2ef3-4cc7-ad13-dc0c8a8155f6',
              createdAt: '2024-06-17T19:57:21.366Z',
              updatedAt: '2024-06-17T19:57:21.366Z',
              order: 0,
              text: 'Excuse me.',
              textRelated: 'Excuse me.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-2c2d29b4-368e-4adc-bc26-57847750ff12.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '30d28335-4012-40ef-bd04-b1231612bfbc',
      createdAt: '2024-06-17T19:57:21.379Z',
      updatedAt: '2024-06-17T19:57:21.379Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.2',
      order: 26,
      status: 'activated',
      widgets: [
        {
          id: '6bb2a0f1-f38c-4fa1-bf34-da52fc20e0bb',
          createdAt: '2024-06-17T19:57:21.379Z',
          updatedAt: '2024-06-17T19:57:21.379Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '5c66da6d-cdbf-4be9-822d-73f5947449f1',
          createdAt: '2024-06-17T19:57:21.379Z',
          updatedAt: '2024-06-17T19:57:21.379Z',
          order: 2,
          type: 'enunciated',
          value: 'Sorry.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'd7824de8-7cc6-40ca-b3c1-fd873578fb48',
          createdAt: '2024-06-17T19:57:21.379Z',
          updatedAt: '2024-06-17T19:57:21.379Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '2bec0614-1b0f-420e-9877-e30443133f0f',
              createdAt: '2024-06-17T19:57:21.379Z',
              updatedAt: '2024-06-17T19:57:21.379Z',
              order: 0,
              text: 'Sorry.',
              textRelated: 'Sorry.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-8697a5f6-3178-48e4-8ebf-c1ab2f738b46.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '3f29e9d0-1603-4029-85aa-ff8a11e5c15b',
      createdAt: '2024-06-17T19:57:21.392Z',
      updatedAt: '2024-06-17T19:57:21.392Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.3',
      order: 27,
      status: 'activated',
      widgets: [
        {
          id: '85e4c0b5-8fb7-4285-a2c4-a5957422b0a6',
          createdAt: '2024-06-17T19:57:21.392Z',
          updatedAt: '2024-06-17T19:57:21.392Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '8afb6103-c8d7-41cc-8a5e-8ec9e0e41e89',
          createdAt: '2024-06-17T19:57:21.392Z',
          updatedAt: '2024-06-17T19:57:21.392Z',
          order: 2,
          type: 'enunciated',
          value: 'Please.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'fc50749b-0371-451e-af2b-b44bc486487a',
          createdAt: '2024-06-17T19:57:21.392Z',
          updatedAt: '2024-06-17T19:57:21.392Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '33a069a1-3f04-43f6-93dc-63da535d7bd9',
              createdAt: '2024-06-17T19:57:21.392Z',
              updatedAt: '2024-06-17T19:57:21.392Z',
              order: 0,
              text: 'Please.',
              textRelated: 'Please.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-7bdc2d53-736a-4c00-a1e5-0562d41ed188.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '7ff04a96-bf9d-41e4-9ef9-792032c5a823',
      createdAt: '2024-06-17T19:57:21.412Z',
      updatedAt: '2024-06-17T19:57:21.412Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.4',
      order: 28,
      status: 'activated',
      widgets: [
        {
          id: '03091844-a29a-4f5e-9b19-cebcbe6d5c88',
          createdAt: '2024-06-17T19:57:21.412Z',
          updatedAt: '2024-06-17T19:57:21.412Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: 'b1ecbd5b-5004-4425-9ef7-007c44433ef1',
          createdAt: '2024-06-17T19:57:21.412Z',
          updatedAt: '2024-06-17T19:57:21.412Z',
          order: 2,
          type: 'enunciated',
          value: 'Thank you.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'f695f3a7-611a-4a5e-968f-2ddc180af4f6',
          createdAt: '2024-06-17T19:57:21.412Z',
          updatedAt: '2024-06-17T19:57:21.412Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '00d336a1-f53c-4c88-846c-a65b6a76ea05',
              createdAt: '2024-06-17T19:57:21.412Z',
              updatedAt: '2024-06-17T19:57:21.412Z',
              order: 0,
              text: 'Thank you.',
              textRelated: 'Thank you.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-702ae83a-a745-4107-b0ad-4ea8c8c3e17c.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '3cc32117-29f8-4fd3-a2e9-8426bcb41181',
      createdAt: '2024-06-17T19:57:21.459Z',
      updatedAt: '2024-06-17T19:57:21.459Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.5',
      order: 29,
      status: 'activated',
      widgets: [
        {
          id: '50aa4ae5-d9f6-499d-8d10-5aad209df46d',
          createdAt: '2024-06-17T19:57:21.459Z',
          updatedAt: '2024-06-17T19:57:21.459Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '195be5a3-e2a1-47c8-bf6b-8034303855ff',
          createdAt: '2024-06-17T19:57:21.459Z',
          updatedAt: '2024-06-17T19:57:21.459Z',
          order: 2,
          type: 'enunciated',
          value: "You're welcome.",
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '23de6415-6262-4ecc-a2c1-617186581253',
          createdAt: '2024-06-17T19:57:21.459Z',
          updatedAt: '2024-06-17T19:57:21.459Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '4dde0fd3-0cbe-45ff-b6db-85a9cf82d9ca',
              createdAt: '2024-06-17T19:57:21.459Z',
              updatedAt: '2024-06-17T19:57:21.459Z',
              order: 0,
              text: "You're welcome.",
              textRelated: "You're welcome.",
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-7d534d00-2a41-4e46-be7d-762a70e73f14.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '5ec379ec-cb7e-459c-b18f-89e3e66271e6',
      createdAt: '2024-06-17T19:57:21.473Z',
      updatedAt: '2024-06-17T19:57:21.473Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.6',
      order: 30,
      status: 'activated',
      widgets: [
        {
          id: 'c0bea18e-b963-457f-a05e-3177b8c1ab18',
          createdAt: '2024-06-17T19:57:21.473Z',
          updatedAt: '2024-06-17T19:57:21.473Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '78b57d83-60cb-4aab-8d92-971ffe97c9f2',
          createdAt: '2024-06-17T19:57:21.473Z',
          updatedAt: '2024-06-17T19:57:21.473Z',
          order: 2,
          type: 'enunciated',
          value: 'How do you spell it?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '1ce156c4-33c6-4456-9fea-39fea35ff5f0',
          createdAt: '2024-06-17T19:57:21.473Z',
          updatedAt: '2024-06-17T19:57:21.473Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '66fb5907-758c-4539-a18b-709854fed0a9',
              createdAt: '2024-06-17T19:57:21.473Z',
              updatedAt: '2024-06-17T19:57:21.473Z',
              order: 0,
              text: 'How do you spell it?',
              textRelated: 'How do you spell it?',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-6728304a-b5d8-489a-9838-abf8545f62c0.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'afda0279-cc91-45d6-8d6c-f4890518fb88',
      createdAt: '2024-06-17T19:57:21.487Z',
      updatedAt: '2024-06-17T19:57:21.487Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.7',
      order: 31,
      status: 'activated',
      widgets: [
        {
          id: '8a837f6f-c9e0-4fd8-8af1-dc72b5e587dd',
          createdAt: '2024-06-17T19:57:21.487Z',
          updatedAt: '2024-06-17T19:57:21.487Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '2bd07f03-33d2-4212-af34-3af6a212cad3',
          createdAt: '2024-06-17T19:57:21.487Z',
          updatedAt: '2024-06-17T19:57:21.487Z',
          order: 2,
          type: 'enunciated',
          value: 'What is this?',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '42924121-e305-4e0a-bc83-1f9eebdc71fb',
          createdAt: '2024-06-17T19:57:21.487Z',
          updatedAt: '2024-06-17T19:57:21.487Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: 'a2b33101-64f0-44f9-b156-9ddee03b01ff',
              createdAt: '2024-06-17T19:57:21.487Z',
              updatedAt: '2024-06-17T19:57:21.487Z',
              order: 0,
              text: 'What is this?',
              textRelated: "What is this?|What's this?",
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-f2a7dc2e-aacd-4156-afba-8860afa23d87.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'b33358da-663a-49ad-be43-6932b13a9764',
      createdAt: '2024-06-17T19:57:21.501Z',
      updatedAt: '2024-06-17T19:57:21.501Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.8',
      order: 32,
      status: 'activated',
      widgets: [
        {
          id: 'fc558863-e0a4-4d46-a8a3-c83385755b3f',
          createdAt: '2024-06-17T19:57:21.501Z',
          updatedAt: '2024-06-17T19:57:21.501Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '002e90f6-337a-45f2-b2d9-a262f8fce58b',
          createdAt: '2024-06-17T19:57:21.501Z',
          updatedAt: '2024-06-17T19:57:21.501Z',
          order: 2,
          type: 'enunciated',
          value: 'Speak slowly, please.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '0aa1b210-95b4-4579-8686-240e000ce80c',
          createdAt: '2024-06-17T19:57:21.501Z',
          updatedAt: '2024-06-17T19:57:21.501Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '10618433-ba81-43ef-825b-0c0dfa720932',
              createdAt: '2024-06-17T19:57:21.501Z',
              updatedAt: '2024-06-17T19:57:21.501Z',
              order: 0,
              text: 'Speak slowly, please.',
              textRelated: 'Speak slowly, please.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-042bbaaf-701f-4236-8ea7-22b850f2adb3.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '37b60909-c490-4ff7-ba39-d98983a19d8d',
      createdAt: '2024-06-17T19:57:21.515Z',
      updatedAt: '2024-06-17T19:57:21.515Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.9',
      order: 33,
      status: 'activated',
      widgets: [
        {
          id: 'cbcd3559-9e51-4e3e-936f-68d276b2d3c5',
          createdAt: '2024-06-17T19:57:21.515Z',
          updatedAt: '2024-06-17T19:57:21.515Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '83ac5d18-fafd-402e-bebf-851680294aab',
          createdAt: '2024-06-17T19:57:21.515Z',
          updatedAt: '2024-06-17T19:57:21.515Z',
          order: 2,
          type: 'enunciated',
          value: 'Repeat, please.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: '81cc98e0-dfe1-4a74-b4fe-55ed93695404',
          createdAt: '2024-06-17T19:57:21.515Z',
          updatedAt: '2024-06-17T19:57:21.515Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '998c96c7-63cb-4a83-bb43-46ec7162c4d7',
              createdAt: '2024-06-17T19:57:21.515Z',
              updatedAt: '2024-06-17T19:57:21.515Z',
              order: 0,
              text: 'Repeat, please.',
              textRelated: 'Repeat, please.',
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-040b8cc1-7d39-4d44-b73e-6a9ee14db86d.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: '74106d99-77f3-4abd-bd72-058e5f5909c7',
      createdAt: '2024-06-17T19:57:21.528Z',
      updatedAt: '2024-06-17T19:57:21.528Z',
      type: 'activity',
      code: 'WU_LM_N1_L1_P25_P34.10',
      order: 34,
      status: 'activated',
      widgets: [
        {
          id: '9bab8c27-d126-466f-8ff4-ec18c7c8ebdd',
          createdAt: '2024-06-17T19:57:21.528Z',
          updatedAt: '2024-06-17T19:57:21.528Z',
          order: 1,
          type: 'title',
          value: 'Repita a frase em inglês.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'titlequestion',
          options: [],
        },
        {
          id: '7d1d4850-060a-439f-b80e-d934f46516a7',
          createdAt: '2024-06-17T19:57:21.528Z',
          updatedAt: '2024-06-17T19:57:21.528Z',
          order: 2,
          type: 'enunciated',
          value: "Sorry. I don't understand.",
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'enunciatedquestion',
          options: [],
        },
        {
          id: 'b7ab66dc-3d90-4bc1-894d-eff3c8c3e288',
          createdAt: '2024-06-17T19:57:21.528Z',
          updatedAt: '2024-06-17T19:57:21.528Z',
          order: 3,
          type: 'speech',
          value: null,
          feedback: true,
          label: null,
          rightMessage: '',
          wrongMessage: '',
          template: 'default',
          options: [
            {
              id: '903f0e51-7c65-415b-8d02-a2fdbe32a8e8',
              createdAt: '2024-06-17T19:57:21.528Z',
              updatedAt: '2024-06-17T19:57:21.528Z',
              order: 0,
              text: "Sorry. I don't understand.",
              textRelated: "Sorry. I don't understand.",
              image: '',
              imageRelated: '',
              audio:
                'https://go2dev.s3.amazonaws.com/questions/audios/answer-one-0-6773c6b3-32fd-4684-9d0c-b9664a2be91b-eb5a062d-d4f0-4911-8658-b2e48c126978.mpeg',
              audioRelated: '',
              right: false,
            },
          ],
        },
      ],
      hints: [],
    },
    {
      id: 'a7696ba7-2531-40b7-9e00-f335d3e53bd5',
      createdAt: '2024-06-17T19:57:21.542Z',
      updatedAt: '2024-06-17T19:57:21.542Z',
      type: 'content',
      code: 'WU_LM_N1_L1_end',
      order: 35,
      status: 'activated',
      widgets: [
        {
          id: '7498345f-b324-4964-8539-b45239bdf3e7',
          createdAt: '2024-06-17T19:57:21.542Z',
          updatedAt: '2024-06-17T19:57:21.542Z',
          order: 1,
          type: 'title',
          value: 'YOU DID IT!',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '50180393-717b-4082-bd48-a767f0b119b8',
          createdAt: '2024-06-17T19:57:21.542Z',
          updatedAt: '2024-06-17T19:57:21.542Z',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-6773c6b3-32fd-4684-9d0c-b9664a2be91b-11430cd0-b72a-4a66-9a09-fbd7090f5f0f.lottie',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: 'f9c97644-708e-4f10-84c1-d2b9f57406b2',
          createdAt: '2024-06-17T19:57:21.542Z',
          updatedAt: '2024-06-17T19:57:21.542Z',
          order: 3,
          type: 'subtitle',
          value: 'Parabéns! Você concluiu a LIÇÃO 1.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
        {
          id: '4ce30444-1da4-4855-973d-ddbca43f57cc',
          createdAt: '2024-06-17T19:57:21.542Z',
          updatedAt: '2024-06-17T19:57:21.542Z',
          order: 4,
          type: 'longtext',
          value: 'Clique em START para iniciar a próxima lição.',
          feedback: false,
          label: null,
          rightMessage: null,
          wrongMessage: null,
          template: 'default',
          options: [],
        },
      ],
      hints: [],
    },
  ],
  countPages: 35,
  completedPages: 0,
};
