import { Component } from '@angular/core';
import { GeneralInputComponent } from '../general-input/general-input.component';

@Component({
  selector: 'go-input-password',
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.scss',
})
export class InputPasswordComponent extends GeneralInputComponent {

  override readonly goComponentId: string = 'password-input';

}
