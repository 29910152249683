import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralModalComponent } from './general-modal/general-modal.component';
import { CorrectModalComponent } from './correct-modal/correct-modal.component';
import { GlossaryModalComponent } from './glossary-modal/glossary-modal.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { WrongModalComponent } from './wrong-modal/wrong-modal.component';

import { ButtonsModule } from '../buttons/buttons.module';
import { IconsModule } from '../icons/icons.module';
import { ImagesModule } from '../images/images.module';

const components = [
  GeneralModalComponent,
  CorrectModalComponent,
  GlossaryModalComponent,
  NotificationModalComponent,
  ReportModalComponent,
  WrongModalComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, IconsModule, ImagesModule],
  exports: components,
})
export class ModaisModule {}

export {
  GeneralModalComponent,
  CorrectModalComponent,
  GlossaryModalComponent,
  NotificationModalComponent,
  ReportModalComponent,
  WrongModalComponent,
};
