import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLearningStepsPageComponent } from './select-learning-steps-page.component';
import { ButtonsModule, RadiosModule } from 'go-components';

const components = [SelectLearningStepsPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, RadiosModule],
  exports: components,
})
export class SelectLearningStepsPageModule {}

export { SelectLearningStepsPageComponent };
