import { Injectable } from '@angular/core';
import { ApiEnvType, ApiUrl } from '../../../environments/api-env.type';

@Injectable()
export abstract class ApiService {

  abstract getEnvType(): ApiEnvType;
  abstract setEnvType(envType: ApiEnvType): void;
  abstract getUrl(): ApiUrl | null;
  abstract getNormalizedUrl(context?: string): string;
  abstract isFakeUrl(): boolean;
  abstract getFakePrefixUrl(): string;

}