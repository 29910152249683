import { Component, inject, output } from "@angular/core";
import { StorageService } from "../core/storage/storage.service";
import { KeyStore } from "../core/storage/key-store";

@Component({ selector: 'app-abstract-page-component', template: '' })
export abstract class AbstractPageComponent<T> {
    
    private readonly storageService = inject(StorageService);
    readonly eventPage = output<T>();
    alert?: string | any;

    onEventPage(event: T): void { }

    showAlert(alert?: string | any) {
        this.alert = alert;
    }

    clearAlert() {
        this.alert = null;
    }

    retrieveStringFromStorage(key: KeyStore): string | false {
        const storedItem = this.storageService.retrieveStoredItem(key);
        if (storedItem == false) {
            return false;
        }
        
        return storedItem;
    }

    storeString(key: KeyStore, value: string): void {
        this.storageService.storeItem(key, value);
    }

    //? Transforma string no formato apresentado em tela.
    //? Ex.: "**ONDE** você vai ministrar sua aula **AGORA**?"
    transformStringToHtml(input: string): string {
        // Regex para encontrar palavras entre ** e substituir
        const regex = /\*\*(.*?)\*\*/g;
        return input.replace(regex,
            '<b class="text-baloochettan-h1 go-text-color-purple700">$1</b>'
        );
    }

    initStorage() { }

}