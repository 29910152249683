import { inject } from "@angular/core";
import { FuseMockApiService } from "fuse-lib";
import { ApiService } from "../api/api.service";

export abstract class AbstractMockApi {

    protected mock = inject(FuseMockApiService);

    constructor(protected api: ApiService, protected contextPath: string = '/') {
        this.registerHandlers();
    }

    abstract registerHandlers(): void;

    bodyResponse(code: number, body: any): [number, any] {
        return [code, body];
    }

    okResponse(body: any): [200, any] {
        return [200, body];
    }

    badResponse(body: any): [400, any] {
        return [400, body];
    }

}