import { Component } from '@angular/core';
import {
  CourseClass,
  SelectStagePageModule,
  Stage,
  StageItem,
  Unit,
  LoadingPageModule,
} from 'student-lib';
import { NgIf } from '@angular/common';
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { SelectStageToLearnComponent } from '../select-stage-to-learn/select-stage-to-learn.component';
import { GoChallengesComponent } from '../go-challenges/go-challenges.component';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { CoursesClassesApiService } from '../../api/courses/courses-classes.api.service';
import { SelectCourseToLearnComponent } from '../select-course-to-learn/select-course-to-learn.component';
import { SelectUnitToLearnComponent } from '../select-unit-to-learn/select-unit-to-learn.component';
import { keyareas } from '../../shared/all-key-storages';
import { Router } from '@angular/router';
import { childRoutes } from '../../core/routes/child-routes';

type LoadingWhat = 'coursesClasses' | 'units' | 'stages';

class Data {
  coursesClasses: CourseClass[] = [];
  units: Unit[] = [];
  stages: Stage[] = [];
  currentCourse!: CourseClass;
  currentUnit!: Unit;
}

@Component({
  selector: 'app-student-home',
  standalone: true,
  templateUrl: './student-home.component.html',
  styleUrl: './student-home.component.scss',
  imports: [
    SelectStagePageModule,
    NgIf,
    GoChallengesComponent,
    SelectStageToLearnComponent,
    LoadingPageModule,
    SelectCourseToLearnComponent,
    SelectUnitToLearnComponent,
  ],
})
export class StudentHomeComponent extends AbstractStudentPageComponent<any> {
  data = new Data();
  loading?: LoadingWhat;
  state: 'loading' | 'page' = 'loading';

  forceSelectCourse = false;
  forceSelectUnit = false;

  constructor(
    authApi: AuthApiService,
    private coursesClassesApi: CoursesClassesApiService,
    private router: Router
  ) {
    super(authApi);

    this.loadData();
  }

  private loadData() {
    this.loadCoursesClasses();
  }

  private loadCoursesClasses() {
    this.loading = 'coursesClasses';
    this.coursesClassesApi
      .loadCoursesClasses()
      .then((coursesClasses) => {
        const lastSelectedCourse = this.retrieveStringFromStorage(
          keyareas.student.lastSelectedCourseId
        );
        if (!lastSelectedCourse) {
          this.storeString(
            keyareas.student.lastSelectedCourseId,
            coursesClasses[0].id
          );
        }
        const currentCourse = coursesClasses.find(
          (el) => el.id == lastSelectedCourse
        );
        const firstCourseClass = coursesClasses[0];

        this.data.currentCourse = currentCourse ?? firstCourseClass;
        this.loadUnits(currentCourse ?? firstCourseClass);
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  private async loadUnits(course: CourseClass) {
    this.loading = 'units';
    await this.coursesClassesApi
      .loadUnits(course.id)
      .then((units) => {
        const lastSelectedUnit = this.retrieveStringFromStorage(
          keyareas.student.lastSelectedUnitId
        );
        if (!lastSelectedUnit) {
          this.storeString(keyareas.student.lastSelectedUnitId, units[0].id);
        }
        const currentUnit = units.find((el) => el.id == lastSelectedUnit);

        this.data.currentUnit = currentUnit ?? units[0];
        this.loadStages(course, currentUnit ?? units[0]);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  //v1_student:last-selected-stage-id
  private loadStages(course: CourseClass, unit: Unit) {
    this.loading = 'stages';
    this.coursesClassesApi
      .loadStages(course, unit)
      .then((stages) => {
        this.data.stages = stages.filter((el) => el.countPages != 0);
        this.loading = undefined;
        this.state = 'page';

        const lastSelectedStage = this.retrieveStringFromStorage(
          keyareas.student.lastSelectedStageId
        );
        if (!lastSelectedStage) {
          this.storeString(keyareas.student.lastSelectedStageId, stages[0].id);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  override onEventPage(event: any): void {
    const stage = <StageItem>event;
    if (!stage) {
      return;
    }

    let stageType = stage.type.toLowerCase();
    switch (this.state) {
      case 'loading': {
        break;
      }
      case 'page': {
        if (stageType == 'contents') {
          this.redirectToChallenges(stage);
        } else if (stageType == 'steps') {
          this.redirectToSteps(stage);
        } else if (stageType == 'activities') {
          this.redirectToQuiz(stage);
        } else {
          console.log(stageType);
        }
        break;
      }
      default:
    }
  }

  private redirectToChallenges(stage: StageItem) {
    const userLoggedInfo = this.retrieveStringFromStorage(
      keyareas.auth.userLogged
    );
    if (userLoggedInfo) {
      let userData = JSON.parse(userLoggedInfo);
      let page = 1;
      this.router.navigate([
        `${userData.profile}/challenges/${stage.id}/${page}`,
      ]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private redirectToSteps(stage: StageItem) {
    const userLoggedInfo = this.retrieveStringFromStorage(
      keyareas.auth.userLogged
    );
    if (userLoggedInfo) {
      let userData = JSON.parse(userLoggedInfo);
      let page = 1;
      this.router.navigate([`${userData.profile}/steps/${stage.id}`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private redirectToQuiz(stage: StageItem) {
    const userLoggedInfo = this.retrieveStringFromStorage(
      keyareas.auth.userLogged
    );
    if (userLoggedInfo) {
      let userData = JSON.parse(userLoggedInfo);
      this.router.navigate([`${userData.profile}/quiz/${stage.id}`]);
    } else {
      this.router.navigate(['/']);
    }
  }
}
