import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  input,
  output,
} from '@angular/core';
import { ThemeColorsTypes } from '../../core/models/enums/colors.enum';
import { ThemeIconsTypes } from '../../core/models/enums/icons.enum';
import { AbstractButtonComponent } from '../abstract.button.component';

@Component({
  selector: 'go-general-button',
  templateUrl: './general-button.component.html',
  styleUrl: './general-button.component.scss',
})
export class GeneralButtonComponent
  implements AbstractButtonComponent, AfterViewInit, OnInit, OnChanges
{
  readonly goComponentId: string = 'general-button';

  @ViewChild('button') button: any;

  @Input() label?: string;
  @Input() labelColor?: ThemeColorsTypes = 'whiteColor';
  @Input() leftIcon?: ThemeIconsTypes;
  @Input() rightIcon?: ThemeIconsTypes;
  @Input() leftIconMargin?: string = '10px';
  @Input() rightIconMargin?: string = '10px';

  @Input() backgroundColor: ThemeColorsTypes = 'purple700';
  @Input() borderColor: ThemeColorsTypes = 'purple700';
  @Input() borderRadius: string = '25px';

  @Input() onlyContent: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;

  @Input() link?: string;
  @Input() back?: boolean;

  disabled = input(false, {
    transform: (value: boolean | string) =>
      typeof value === 'string' ? value === '' : value,
  });

  @Input() width?: string;
  @Input() height?: string;

  //? Final variables
  iconColor: ThemeColorsTypes = 'whiteColor';
  labelClasses?: string;
  boxClasses?: string;
  click = output<any>();

  ngOnInit(): void {
    this.applyComponentStyleClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyComponentStyleClasses();
  }

  ngAfterViewInit(): void {
    var el: ElementRef<HTMLButtonElement> = this.button;

    if (this.borderRadius != '25px') {
      el.nativeElement.style.borderRadius = this.borderRadius;
    }

    if (this.width) {
      el.nativeElement.style.width = this.width;
    }

    if (this.height) {
      el.nativeElement.style.height = this.height;
    }

    el.nativeElement.addEventListener('click', () => {
      if (this.link) {
        window.location.href = this.link;
      } else if (this.back) {
        window.history.back();
      }
    });
  }

  //? Função de definição de classes do component
  private applyComponentStyleClasses() {
    const disabled = this.disabled();

    this.boxClasses = this.getBoxClasses(disabled, this.onlyContent);
    this.labelClasses = this.getLabelClasses(disabled);
    this.iconColor = this.getIconColor(disabled, this.labelColor);
  }

  private getBoxClasses(disabled = false, onlyContent = false): string {
    var classes = 'go-general-button';
    if (disabled) {
      classes +=
        ' button-disabled go-background-color-gray200 go-border-color-gray200';
    } else {
      classes += ` go-background-color-${this.backgroundColor} with-hover go-border-color-${this.borderColor}`;
    }

    if (onlyContent) {
      classes += ' only-content';
    }

    return classes;
  }

  private getLabelClasses(disabled = false): string {
    var classes = 'text-poppins-body-bold';
    if (disabled) {
      classes += ' go-text-color-gray700';
    } else {
      classes += ` go-text-color-${this.labelColor}`;
    }

    return classes;
  }

  private getIconColor(
    disabled = false,
    labelColor: ThemeColorsTypes = 'whiteColor'
  ): ThemeColorsTypes {
    if (disabled) {
      return 'gray700';
    }

    return labelColor;
  }
}
