import { Component } from '@angular/core';
import { GeneralButtonComponent } from '../general-button/general-button.component';

@Component({
  selector: 'go-back-button',
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
})
export class BackButtonComponent extends GeneralButtonComponent {

  override readonly goComponentId: string = 'back-button';

}
