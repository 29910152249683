<div class="button-border go-border-color-turquoise100">
  <go-general-button
    [width]="width"
    [height]="height"
    leftIcon="mic"
    leftIconMargin="0"
    labelColor="whiteColor"
    backgroundColor="turquoise500"
    borderColor="turquoise500"
    borderRadius="100%"
  />
</div>
