
<section class="profile-page">
  <ng-container select="menu">
    <div class="profile-page-menu">
      <go-general-menu [menuItens]="menuItens" width="230px" />
    </div>
  </ng-container>
  <div class="profile-page-content">
    <header class="profile-page-header">
      <h2 class="text-baloochettan-h2 go-text-color-gray900">
        Dados do
        <span class="text-baloochettan-h2 go-text-color-salmon500"
          >8º ano B</span
        >
      </h2>
      <p class="text-baloochettan-body go-text-color-purple700">
        Luiz Fernando Perraro
      </p>
      <p class="text-poppins-caption-body go-text-color-gray700">
        Professor desde 12 de janeiro de 2024
      </p>
    </header>

    <div class="profile-page-columns">
      <div class="profile-page-column">
        <article class="profile-page-percents">
          <header>
            <h3 class="text-poppins-h4-bold go-text-color-gray800">
              Indicadores
            </h3>
          </header>

          <app-general-progress
            icon="units"
            title="Execução dos exercícios propostos"
            color="pink500"
            [percent]="100"
          />
          <app-general-progress
            icon="question"
            title="Aproveitamento médio nos Quizzes"
            color="green500"
            [percent]="80"
          />
          <app-general-progress
            icon="tasks"
            title="Aproveitamento médio nas tarefas"
            color="blue500"
            [percent]="74"
          />
        </article>

        <div class="profile-page-statistics">
          <ng-container *ngFor="let item of statisticItens; let i = index">
            <div
              class="statistic-item number-{{
                item.width
              }} go-background-color-background1 go-border-color-gray300"
            >
              <div class="statistic-item-title">
                <div *ngIf="item.icon" class="icon">
                  <!-- <go-icon *ngIf="item.icon" [icon]="item.icon" /> -->
                </div>
                <h3 class="text-baloochettan-headline go-text-color-purple500">
                  {{ item.title }}
                </h3>
              </div>
              <p class="text-poppins-subtitle-regular go-text-color-gray700">
                {{ item.text }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="profile-page-column">
        <article class="profile-page-table">
          <header>
            <h3 class="text-poppins-h4-bold go-text-color-gray800">
              Resultado dos Quizzes
            </h3>
          </header>

          <div class="table-box go-border-color-gray400">
            <div class="table-box-header">
              <span class="text-poppins-body-bold go-text-color-gray800">
                Unidade
              </span>
              <span class="text-poppins-body-bold go-text-color-gray800">
                Acertos
              </span>
              <span class="text-poppins-body-bold go-text-color-gray800">
                Erros
              </span>
              <span class="text-poppins-body-bold go-text-color-gray800">
                Meu Nível
              </span>
            </div>

            <div class="table-box-content">
              <ng-container *ngFor="let item of tableItens">
                <div *ngIf="item.status == 'done'" class="line">
                  <p class="text-poppins-subtitle-bold go-text-color-gray800">
                    {{ item.unit }}
                  </p>
                  <p class="text-poppins-subtitle-bold go-text-color-gray800">
                    {{ item.corrects }}
                  </p>
                  <p class="text-poppins-subtitle-bold go-text-color-gray800">
                    {{ item.incorrects }}
                  </p>
                  <p class="text-poppins-subtitle-bold go-text-color-gray800">
                    {{ item.level }}
                  </p>
                </div>

                <div *ngIf="item.status == 'wait'" class="line disabled">
                  <p class="text-poppins-subtitle-bold go-text-color-gray500">
                    {{ item.unit }}
                  </p>

                  <p
                    class="overlay text-poppins-subtitle-bold go-text-color-gray500"
                  >
                    Ops, você não fez este Quiz ainda!
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
