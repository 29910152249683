<div class="ds-flex ds-position-center-center">
  <div class="auth-content">
    <div class="auth-content-brand">
      <img
        src="assets/images/go2dev/brand/go2dev-logo-01.png"
        alt="Go2dev Treinamentos"
        title="Go2dev Treinamentos"
        (click)="emitEventPage('brand-click')"
      />
    </div>

    <ng-content></ng-content>

    <h3
      class="text-baloochettan-headline go-text-color-purple700"
      style="margin-bottom: 10px"
      [style]="logged ? 'text-align: center; margin-bottom: 2em' : ''"
    >
      <ng-container *ngIf="!loggedUser">Entrar</ng-container>
      <ng-container *ngIf="loggedUser">
        <ng-container *ngIf="loggedUser">
          {{ loggedUser.user.name }}<br>
          Você entrou como {{ loggedUser.profile == 'student' ? 'aluno' : loggedUser.profile == 'teacher' ? 'professor' : 'usuário' }}.<br><br>
          Sair do sistema?
        </ng-container>
        <ng-container *ngIf="!loggedUser">
          Você já está autenticado.
        </ng-container>
      </ng-container>
    </h3>

    <ng-container *ngIf="!loggedUser">
      <go-input-text (change)="emailChange($event.target.value)" />
      <go-input-password (change)="passwordChange($event.target.value)" />

      <a (click)="emitEventPage('forgot-password-request')"
        class="text-poppins-subtitle-bold go-text-color-purple700 width-hover"
        style="display: inline-block; margin: 16px 0"
        href="javascript:void(0);"
        title="Esqueceu a senha?"
        >Esqueceu a senha?</a
      >

      <go-login-button (click)="emitEventPage('login-request', 'basic')" />
      <p
        class="text-poppins-subtitle-regular go-text-color-gray700"
        style="width: 100%; padding: 8px 0; text-align: center"
      >
        ou
      </p>
      <go-google-button (click)="emitEventPage('login-request', 'google')" />

    </ng-container>
    <ng-container *ngIf="loggedUser">
      <go-general-button label="Sair" [rightIcon]="'sign-out-alt'" (click)="emitEventPage('logoff-request')"></go-general-button>
      <br>
      <go-general-button label="Ir para início" [leftIcon]="'home'" (click)="emitEventPage('home-request')"></go-general-button>
    </ng-container>

  </div>
</div>
