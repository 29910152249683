<ng-container *ngIf="template == 'default'">
  <div class="widget-audio" [style.marginBottom]="marginBottom">
    <audio #audioPlayer (timeupdate)="onTimeUpdate()">
      <source [src]="value" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <div class="actions">
      <go-general-button
        width="44px"
        height="44px"
        borderRadius="44px"
        backgroundColor="blue50"
        borderColor="blue900"
        label=""
        labelColor="gray900"
        leftIcon="turtle"
        leftIconMargin="0"
        (click)="playAudioSlow()"
      ></go-general-button>
      <go-general-button
        width="44px"
        height="44px"
        borderRadius="44px"
        backgroundColor="blue600"
        borderColor="blue900"
        label=""
        leftIcon="play"
        leftIconMargin="0"
        (click)="playAudio()"
      ></go-general-button>
    </div>

    <div *ngIf="label" class="info">
      <p style="text-align: left" [innerHTML]="processedText"></p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template == 'onlybuttons'">
  <div class="widget-audio-onlybuttons" [style.marginBottom]="marginBottom">
    <audio #audioPlayer (timeupdate)="onTimeUpdate()">
      <source [src]="value" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <div class="actions">
      <go-general-button
        width="88px"
        height="88px"
        borderRadius="88px"
        backgroundColor="blue50"
        borderColor="blue900"
        label=""
        labelColor="gray900"
        leftIcon="turtle"
        leftIconMargin="0"
        (click)="playAudioSlow()"
      ></go-general-button>
      <go-general-button
        width="88px"
        height="88px"
        borderRadius="88px"
        backgroundColor="blue600"
        borderColor="blue900"
        label=""
        leftIcon="play"
        leftIconMargin="0"
        (click)="playAudio()"
      ></go-general-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template == 'audioquestion'">
  <div class="widget-audio-player" [style.marginBottom]="marginBottom">
    <audio #audioPlayer (timeupdate)="onTimeUpdate()">
      <source [src]="value" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>

    <div class="controls">
      <go-general-button
        width="56px"
        height="56px"
        borderRadius="56px"
        backgroundColor="blue50"
        borderColor="blue900"
        label=""
        labelColor="gray900"
        leftIcon="turtle"
        leftIconMargin="0"
        (click)="playAudioSlow()"
      ></go-general-button>
      <go-general-button
        width="56px"
        height="56px"
        borderRadius="56px"
        backgroundColor="blue600"
        borderColor="blue900"
        label=""
        leftIcon="play"
        leftIconMargin="0"
        (click)="playAudio()"
      ></go-general-button>
    </div>

    <div class="progress-bar">
      <span class="text-poppins-caption-bold go-text-color-gray500">
        {{ formatTime(currentTime) }}
      </span>
      <input
        type="range"
        class="custom-progress-bar"
        [value]="currentTime"
        [max]="duration"
        (input)="seekAudio($event)"
      />
      <span class="text-poppins-caption-bold go-text-color-gray500">
        {{ formatTime(duration) }}
      </span>
    </div>
  </div>
</ng-container>
