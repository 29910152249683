import { Component, Input, OnInit } from '@angular/core';
import { SelectStagePageModule, Stage, StageItem, Unit } from 'student-lib';
import { NgIf } from '@angular/common';
import { ChallengePageModule } from 'student-lib';
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { defaultStageItems } from './default-stage-items';
import { keyareas } from '../../shared/all-key-storages';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { StageItemMapper } from 'student-lib';

export interface DataId {
  currentSelected: string;
  lastStarted: string;
}

@Component({
  selector: 'app-select-stage-learn',
  standalone: true,
  imports: [SelectStagePageModule, NgIf, ChallengePageModule],
  templateUrl: './select-stage-to-learn.component.html',
  styleUrl: './select-stage-to-learn.component.scss',
})
export class SelectStageToLearnComponent
  extends AbstractStudentPageComponent<StageItem>
  implements OnInit
{
  @Input() unit!: Unit;
  @Input() stages: Stage[] = [];

  tagUnit!: string;
  titleUnit!: string;
  stageItems = defaultStageItems;
  currentSelectedStageItemId?: string;
  lastStartedStageItemId?: string;

  courseData?: DataId;
  stageData?: DataId;

  constructor(authApi: AuthApiService) {
    super(authApi);
  }

  ngOnInit(): void {
    this.convertStagesToStagesItem();
    this.loadLastState();
  }

  private convertStagesToStagesItem() {
    const mapper = new StageItemMapper();
    const newItems: StageItem[] = [];
    let number = 0;
    this.stages.forEach((v) => {
      number++;
      newItems.push(mapper.toStageItem(v, number));
    });

    this.stageItems = [...newItems];
    this.tagUnit = this.unit.title;
    this.titleUnit = this.unit.subtitle;
  }

  private loadLastState() {
    const selectedStageId = this.retrieveStringFromStorage(
      keyareas.student.lastSelectedStageId
    );
    if (selectedStageId) {
      this.currentSelectedStageItemId = selectedStageId;
      this.stageItems.forEach((item, index) => {
        item.open = item.id === selectedStageId;
      });
    }

    const startedStageId = this.retrieveStringFromStorage(
      keyareas.student.lastStartedStageId
    );
    if (startedStageId) {
      this.lastStartedStageItemId = startedStageId;
    }
  }

  selectedStage(stage: StageItem) {
    this.currentSelectedStageItemId = stage.id;
    this.storeString(keyareas.student.lastSelectedStageId, stage.id);
  }

  startStage(stage: StageItem) {
    this.lastStartedStageItemId = stage.id;
    this.storeString(keyareas.student.lastStartedStageId, stage.id);
    this.eventPage.emit(stage);
  }
}
