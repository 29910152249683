<section class="general-modal">
  <div class="box">
    <div class="close-button">
      <go-general-button
        backgroundColor="salmon800"
        borderColor="salmon800"
        borderRadius="10px"
        leftIcon="close-white"
        leftIconMargin="0"
        width="34px"
        height="34px"
        (click)="close.emit()"
      />
    </div>

    <header>
      <h2 class="text-baloochettan-h4 go-text-color-{{ color }}">
        {{ title }}
      </h2>
      <p
        *ngIf="description"
        class="text-poppins-subtitle-regular go-text-color-gray950"
      >
        {{ description }}
      </p>
    </header>

    <div class="actions">
      <go-general-button
        (click)="mainButtonClick.emit()"
        backgroundColor="purple700"
        borderColor="purple700"
        borderRadius="25px"
        label="Entendi"
        rightIcon="thumbs-up"
      />
    </div>
  </div>
</section>
