export enum ThemeIcons {
  'angle-left' = 'go-icon-angle-left.svg.ts',
  'angle-right' = 'go-icon-angle-right.svg.ts',
  'arrow-left' = 'go-icon-arrow-left.svg.ts',
  'arrow-right' = 'go-icon-arrow-right.svg.ts',
  'check-radio' = 'go-icon-check-radio.svg.ts',
  'circle-check' = 'go-icon-circle-check.svg.ts',
  'circle-close' = 'go-icon-circle-close.svg.ts',
  'close-white' = 'go-icon-close-white.svg.ts',
  'close' = 'go-icon-close.svg.ts',
  'ear' = 'go-icon-ear.svg.ts',
  'flag' = 'go-icon-flag.svg.ts',
  'google-brand' = 'go-icon-google-brand.svg.ts',
  'home' = 'go-icon-home.svg.ts',
  'lock' = 'go-icon-lock.svg.ts',
  'menu-burger' = 'go-icon-menu-burger.svg.ts',
  'mic' = 'go-icon-mic.svg.ts',
  'paw' = 'go-icon-paw.svg.ts',
  'play' = 'go-icon-play.svg.ts',
  'profile' = 'go-icon-profile.svg.ts',
  'question' = 'go-icon-question.svg.ts',
  'rocket' = 'go-icon-rocket.svg.ts',
  'rotate' = 'go-icon-rotate.svg.ts',
  'sign-in' = 'go-icon-sign-in.svg.ts',
  'sign-out-alt' = 'go-icon-sign-out-alt.svg.ts',
  'star-full' = 'go-icon-star-full.svg.ts',
  'tasks' = 'go-icon-tasks.svg.ts',
  'thumbs-up' = 'go-icon-thumbs-up.svg.ts',
  'trophy' = 'go-icon-trophy.svg.ts',
  'turtle' = 'go-icon-turtle.svg.ts',
  'units' = 'go-icon-units.svg.ts',
  'volume-up' = 'go-icon-volume-up.svg.ts',
}
export type ThemeIconsTypes = keyof typeof ThemeIcons;
