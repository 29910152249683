import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCourseToLearnPageComponent } from './select-course-to-learn-page.component';
import { ButtonsModule, RadiosModule, MenusModule } from 'go-components';

const components = [SelectCourseToLearnPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, RadiosModule, MenusModule],
  exports: components
})
export class SelectCourseToLearnPageModule { }

export interface CourseOption {
  id: string;
  title: string;
  isSelected: boolean;
}

export { SelectCourseToLearnPageComponent }