@switch (template) { @case ('default') {
<p [style.marginBottom]="marginBottom" [innerHTML]="processedText"></p>
} @case ('template-1') {
<div class="" [style.marginBottom]="marginBottom">
  <p [innerHTML]="processedText"></p>
</div>
} @case ('template-2') {
<div class="" [style.marginBottom]="marginBottom">
  <p [innerHTML]="processedText"></p>
</div>
} @case ('template-3') {
<div class="" [style.marginBottom]="marginBottom">
  <p [innerHTML]="processedText"></p>
</div>
}}
