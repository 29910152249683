<section class="units-page">
  <div class="units-page-menu">
    <go-general-menu
      [menuItens]="menuItens"
      width="230px"
    />
  </div>
  <div class="units-page-content">
    <header>
      <h2 class="text-baloochettan-h3 go-text-color-gray900">Unidades</h2>
      <p class="text-poppins-body-bold go-text-color-gray700">
        Escolha uma unidade para estudar!
      </p>
    </header>

    <div class="units-page-itens">
      <div class="column">
        <ng-container *ngFor="let item of units.slice(0, 5); let i = index">
          <div style="width: 100%">
            <go-general-radio
              [name]="item.option.name"
              [percent]="item.option.percent"
              [tag]="item.option.tag"
              [title]="item.option.title"
              [isSelected]="item.option.isSelected"
              [status]="item.option.status"
              (click)="onSelectedItem(i)"
            ></go-general-radio>
          </div>
        </ng-container>
      </div>

      <div class="units-separator go-background-color-purple400"></div>

      <div class="column">
        <ng-container *ngFor="let item of units.slice(5); let i = index">
          <div style="width: 100%">
            <go-general-radio
              [name]="item.option.name"
              [percent]="item.option.percent"
              [tag]="item.option.tag"
              [title]="item.option.title"
              [isSelected]="item.option.isSelected"
              [status]="item.option.status"
              (click)="onSelectedItem(i + 5)"
            ></go-general-radio>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="units-page-actions go-border-color-gray300">
      <go-general-button
        width="325px"
        label="Iniciar Unidade"
        rightIcon="units"
        backgroundColor="purple700"
        borderColor="purple700"
        [isDisabled]="true"
        (click)="startUnit.emit(this.selectedOption)"
      />
    </div>
  </div>
</section>
