export default {
  id: '54b59a27-667d-4e04-b2b3-aa20cefd006a',
  title: 'Plural nouns',
  countPages: 20,
  completedPages: 0,
  module: {
    id: '0eb1c258-f3f0-452e-a582-9e345d1e88fe',
    order: 2,
    title: 'Nível Interpreter',
  },
  order: 3,
  pages: [
    {
      id: '74a20a01-19eb-4ae9-9974-800517cf6987',
      type: 'content',
      code: 'WU_LM_N2_L3_P1',
      order: 1,
      widgets: [
        {
          id: '2a106de2-3964-4aa9-bb0b-50ba1200cc64',
          order: 1,
          type: 'title',
          value: 'LESSON 3',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '68c3fbeb-41cd-477a-b37c-1539997ee01e',
          order: 2,
          type: 'subtitle',
          value: 'Plural nouns',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '62ab9b78-ab4b-4813-9912-06cf16418585',
          order: 3,
          type: 'longtext',
          value:
            'É essencial aprender como os substantivos se formam no singular no plural.  Vamos iniciar com a regra básica.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '88107e5f-330f-47c6-b1a8-c358db1c89a9',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-54b59a27-667d-4e04-b2b3-aa20cefd006a-dabca4f9-8a13-449c-91d5-0e3a1c22092c.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '0fbe4808-c5e2-4b28-b360-4db91df6470c',
          order: 5,
          type: 'longtext',
          value:
            'As regras de formação do plural depende do final de cada substantivo. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '5cf64d87-e75b-4448-8a3f-e38a6c7d743c',
          order: 6,
          type: 'longtext',
          value:
            'Realize as atividades a seguir para praticar com a regra número 1!',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '87bf1d7b-5941-4688-b8c4-c40157135c2a',
      type: 'activity',
      code: 'WU_LM_N2_L3_P2_P6.1',
      order: 2,
      difficulty: 'easy',
      widgets: [
        {
          id: '87bf1d7b-5941-4688-b8c4-c40157135c2a',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 1.',
          template: 'default',
        },
        {
          id: '87bf1d7b-5941-4688-b8c4-c40157135c2a',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-37821029-ed09-4994-8083-ddfb7b2564d4.jpeg',
          template: 'default',
        },
        {
          id: '87bf1d7b-5941-4688-b8c4-c40157135c2a',
          order: 3,
          type: 'enunciated',
          value: 'girl_',
          template: 'default',
        },
        {
          id: '87bf1d7b-5941-4688-b8c4-c40157135c2a',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '1d1fa16b-9861-4a5f-b818-de53d3eac011',
              order: 0,
              text: '',
              textRelated: 'girls',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '6e1c7321-1c06-41d0-af1a-8253d32537fe',
      type: 'activity',
      code: 'WU_LM_N2_L3_P2_P6.2',
      order: 3,
      difficulty: 'easy',
      widgets: [
        {
          id: '6e1c7321-1c06-41d0-af1a-8253d32537fe',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 1.',
          template: 'default',
        },
        {
          id: '6e1c7321-1c06-41d0-af1a-8253d32537fe',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-3b4a342c-1028-4d9c-be48-b714c6296d9a.jpeg',
          template: 'default',
        },
        {
          id: '6e1c7321-1c06-41d0-af1a-8253d32537fe',
          order: 3,
          type: 'enunciated',
          value: 'notebook_',
          template: 'default',
        },
        {
          id: '6e1c7321-1c06-41d0-af1a-8253d32537fe',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '23b00ed8-7bf1-41a7-96c8-6a93b5cab0cc',
              order: 0,
              text: '',
              textRelated: 'notebooks',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'dcf04f8f-fdd9-4468-8761-bec4d9bc15dc',
      type: 'activity',
      code: 'WU_LM_N2_L3_P2_P6.3',
      order: 4,
      difficulty: 'easy',
      widgets: [
        {
          id: 'dcf04f8f-fdd9-4468-8761-bec4d9bc15dc',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 1.',
          template: 'default',
        },
        {
          id: 'dcf04f8f-fdd9-4468-8761-bec4d9bc15dc',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-97da95db-e8b2-45ba-8c18-52311edab13c.jpeg',
          template: 'default',
        },
        {
          id: 'dcf04f8f-fdd9-4468-8761-bec4d9bc15dc',
          order: 3,
          type: 'enunciated',
          value: 'cup_',
          template: 'default',
        },
        {
          id: 'dcf04f8f-fdd9-4468-8761-bec4d9bc15dc',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'c56e818a-9764-4012-86a9-5b61efcc9c65',
              order: 0,
              text: '',
              textRelated: 'cups',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '1cd883c8-a635-4879-95fd-c27b74b59421',
      type: 'activity',
      code: 'WU_LM_N2_L3_P2_P6.4',
      order: 5,
      difficulty: 'easy',
      widgets: [
        {
          id: '1cd883c8-a635-4879-95fd-c27b74b59421',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 1.',
          template: 'default',
        },
        {
          id: '1cd883c8-a635-4879-95fd-c27b74b59421',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-58817910-fa2b-40a1-8461-42aa5c345817.jpeg',
          template: 'default',
        },
        {
          id: '1cd883c8-a635-4879-95fd-c27b74b59421',
          order: 3,
          type: 'enunciated',
          value: 'eraser_',
          template: 'default',
        },
        {
          id: '1cd883c8-a635-4879-95fd-c27b74b59421',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '25a975c1-f8f4-4de2-99c8-9edead8c9270',
              order: 0,
              text: '',
              textRelated: 'erasers',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '8bdf45fa-4641-4e8c-8fd0-9998fc0e10cc',
      type: 'activity',
      code: 'WU_LM_N2_L3_P2_P6.5',
      order: 6,
      difficulty: 'easy',
      widgets: [
        {
          id: '8bdf45fa-4641-4e8c-8fd0-9998fc0e10cc',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 1.',
          template: 'default',
        },
        {
          id: '8bdf45fa-4641-4e8c-8fd0-9998fc0e10cc',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-19fe6f8d-26ee-4e9b-9051-bd127ee62d60.jpeg',
          template: 'default',
        },
        {
          id: '8bdf45fa-4641-4e8c-8fd0-9998fc0e10cc',
          order: 3,
          type: 'enunciated',
          value: 'cellphone_',
          template: 'default',
        },
        {
          id: '8bdf45fa-4641-4e8c-8fd0-9998fc0e10cc',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'f9c42c69-6ad1-4bdd-893a-cc60f2e699c3',
              order: 0,
              text: '',
              textRelated: 'cellphones',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '93fac01e-45a7-4745-8842-752b32cc8f8e',
      type: 'content',
      code: 'WU_LM_N2_L3_P7',
      order: 7,
      widgets: [
        {
          id: 'bd2cb8f9-bbb2-4716-8cad-b515a43b69d2',
          order: 1,
          type: 'enunciated',
          value: 'Observe a regra abaixo e selecione a opção correta.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '981e88cd-4652-443b-8778-0c6497876752',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-54b59a27-667d-4e04-b2b3-aa20cefd006a-574b3bd3-753f-4cc0-a09f-31d411ac5c51.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'a5d06ed7-252e-414b-8528-d6464b6cf77d',
          order: 3,
          type: 'longtext',
          value:
            'Quais as palavras abaixo foram escritas no plural corretamente?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'd9b6cd1f-4211-42cb-96e0-d41ee8c3be0d',
          order: 4,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '2c5829aa-3073-49d4-ae4b-b219ff7e6c87',
              order: 1,
              text: 'Igreja: Church - Churches',
              image: '',
              audio: '',
              right: true,
            },
            {
              id: '09f7ef79-0553-48de-86e1-b14ee763e6c3',
              order: 2,
              text: 'Livro: Book - Bookes',
              image: '',
              audio: '',
              right: false,
            },
            {
              id: '2c9dff69-cbf2-42d4-a2d7-077aa9521dcc',
              order: 3,
              text: 'Vestido: Dress - Dresses',
              image: '',
              audio: '',
              right: true,
            },
          ],
          template: 'default',
          rightMessage:
            'O plural de book é books e segue a regra básica de adicionar o -s. Palavras terminadas em -ch e -ss seguem a regra 2!',
          wrongMessage:
            'O plural de book é books e segue a regra básica de adicionar o -s. Palavras terminadas em -ch e -ss seguem a regra 2!',
        },
      ],
      hints: [],
    },
    {
      id: '0d3c4911-e85c-4a0a-b85a-c7d7072932f4',
      type: 'activity',
      code: 'WU_LM_N2_L3_P8_P12.1',
      order: 8,
      difficulty: 'easy',
      widgets: [
        {
          id: '0d3c4911-e85c-4a0a-b85a-c7d7072932f4',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 2.',
          template: 'default',
        },
        {
          id: '0d3c4911-e85c-4a0a-b85a-c7d7072932f4',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-a6487345-8a41-43b7-8f20-62554e5eff1b.jpeg',
          template: 'default',
        },
        {
          id: '0d3c4911-e85c-4a0a-b85a-c7d7072932f4',
          order: 3,
          type: 'enunciated',
          value: 'kiss__',
          template: 'default',
        },
        {
          id: '0d3c4911-e85c-4a0a-b85a-c7d7072932f4',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '93fe3c8b-473a-412f-9cd9-83c75b218721',
              order: 0,
              text: '',
              textRelated: 'kisses',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'e62be64c-0b43-44c5-8396-b1a876b06e9a',
      type: 'activity',
      code: 'WU_LM_N2_L3_P8_P12.2',
      order: 9,
      difficulty: 'easy',
      widgets: [
        {
          id: 'e62be64c-0b43-44c5-8396-b1a876b06e9a',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 2.',
          template: 'default',
        },
        {
          id: 'e62be64c-0b43-44c5-8396-b1a876b06e9a',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-6d5864fb-efbd-4091-9ad4-e2f9c862b60f.jpeg',
          template: 'default',
        },
        {
          id: 'e62be64c-0b43-44c5-8396-b1a876b06e9a',
          order: 3,
          type: 'enunciated',
          value: 'fox__',
          template: 'default',
        },
        {
          id: 'e62be64c-0b43-44c5-8396-b1a876b06e9a',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '99a6edf6-6b17-4fe6-be2b-ce4430d5af56',
              order: 0,
              text: '',
              textRelated: 'foxes',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '3b230df7-a2b7-4f00-b25d-1357bc5107e9',
      type: 'activity',
      code: 'WU_LM_N2_L3_P8_P12.3',
      order: 10,
      difficulty: 'easy',
      widgets: [
        {
          id: '3b230df7-a2b7-4f00-b25d-1357bc5107e9',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 2.',
          template: 'default',
        },
        {
          id: '3b230df7-a2b7-4f00-b25d-1357bc5107e9',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-4ab7a204-9a65-49f1-8dff-0ea19c31866d.jpeg',
          template: 'default',
        },
        {
          id: '3b230df7-a2b7-4f00-b25d-1357bc5107e9',
          order: 3,
          type: 'enunciated',
          value: 'watch__',
          template: 'default',
        },
        {
          id: '3b230df7-a2b7-4f00-b25d-1357bc5107e9',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '00fb71d6-3ab2-41cd-b14e-16a06a186234',
              order: 0,
              text: '',
              textRelated: 'watches',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '917c052d-1e31-4236-9548-dc175ca3f4e1',
      type: 'activity',
      code: 'WU_LM_N2_L3_P8_P12.4',
      order: 11,
      difficulty: 'easy',
      widgets: [
        {
          id: '917c052d-1e31-4236-9548-dc175ca3f4e1',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 2.',
          template: 'default',
        },
        {
          id: '917c052d-1e31-4236-9548-dc175ca3f4e1',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-8d85b796-67a8-4027-932b-f34c20ce03ff.jpeg',
          template: 'default',
        },
        {
          id: '917c052d-1e31-4236-9548-dc175ca3f4e1',
          order: 3,
          type: 'enunciated',
          value: 'hairbrush__',
          template: 'default',
        },
        {
          id: '917c052d-1e31-4236-9548-dc175ca3f4e1',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '16e8f44d-3a62-4cfa-b98d-db7de210bdb5',
              order: 0,
              text: '',
              textRelated: 'hairbrushes',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '8f16e374-76c9-45fc-9682-afe2e58297f0',
      type: 'activity',
      code: 'WU_LM_N2_L3_P8_P12.5',
      order: 12,
      difficulty: 'easy',
      widgets: [
        {
          id: '8f16e374-76c9-45fc-9682-afe2e58297f0',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 2.',
          template: 'default',
        },
        {
          id: '8f16e374-76c9-45fc-9682-afe2e58297f0',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-d6a480e9-3d61-4314-89d3-1436faf8a89e.jpeg',
          template: 'default',
        },
        {
          id: '8f16e374-76c9-45fc-9682-afe2e58297f0',
          order: 3,
          type: 'enunciated',
          value: 'glass__',
          template: 'default',
        },
        {
          id: '8f16e374-76c9-45fc-9682-afe2e58297f0',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'bb14ff06-15d4-4e5b-a9af-89a5f656644e',
              order: 0,
              text: '',
              textRelated: 'glasses',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: 'ca8872a6-3e92-4823-a740-5888c4fb3de7',
      type: 'content',
      code: 'WU_LM_N2_L3_P13',
      order: 13,
      widgets: [
        {
          id: '4a7abf2e-0440-4122-a237-8c2043e6dd7d',
          order: 1,
          type: 'enunciated',
          value: 'Observe a regra abaixo e selecione a opção correta.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '19694308-fbec-4b11-ac83-edf9d44a7350',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-54b59a27-667d-4e04-b2b3-aa20cefd006a-4f3f38e8-21c1-4138-aecd-ba9858d7fed2.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '982bf326-4ffe-421e-a55a-8e62f9ac041c',
          order: 3,
          type: 'longtext',
          value:
            'Quais as palavras abaixo foram escritas no plural corretamente?',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '8d2bbf5f-7b26-4928-8f9e-3d3293d6b938',
          order: 4,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: '598011c0-2c8d-4220-8904-55984abd338c',
              order: 1,
              text: 'Caixa: Box - Boxies',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: 'fde7d9d3-e942-48b4-8059-5a70ded08e7d',
              order: 2,
              text: 'Festa: Party - Parties',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: '3f60fadd-964e-427a-9ac1-2d04274191ae',
              order: 3,
              text: 'Menino: Boy - Boies',
              image: null,
              audio: null,
              right: false,
            },
          ],
          template: 'default',
          rightMessage:
            'O plural de box é boxes, e de boy é boys. Boy termina em Y, mas é precedido de uma vogal e não uma consoante.  Somente Parties está correto.',
          wrongMessage:
            'O plural de box é boxes, e de boy é boys. Boy termina em Y, mas é precedido de uma vogal e não uma consoante.  Somente Parties está correto.',
        },
      ],
      hints: [],
    },
    {
      id: '2e19afaf-d81a-4cbd-b900-a412e55a1511',
      type: 'activity',
      code: 'WU_LM_N2_L3_P14_P18.1',
      order: 14,
      difficulty: 'easy',
      widgets: [
        {
          id: '2e19afaf-d81a-4cbd-b900-a412e55a1511',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 3.',
          template: 'default',
        },
        {
          id: '2e19afaf-d81a-4cbd-b900-a412e55a1511',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-031197fc-eb30-4765-b34f-8983f7512eb6.jpeg',
          template: 'default',
        },
        {
          id: '2e19afaf-d81a-4cbd-b900-a412e55a1511',
          order: 3,
          type: 'enunciated',
          value: 'city',
          template: 'default',
        },
        {
          id: '2e19afaf-d81a-4cbd-b900-a412e55a1511',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '55961af8-2351-4e68-975d-7ba6a7d97e0a',
              order: 0,
              text: '',
              textRelated: 'cities',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '61a7a5ef-6e64-4eae-a7a1-180af7adb575',
      type: 'activity',
      code: 'WU_LM_N2_L3_P14_P18.2',
      order: 15,
      difficulty: 'easy',
      widgets: [
        {
          id: '61a7a5ef-6e64-4eae-a7a1-180af7adb575',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 3.',
          template: 'default',
        },
        {
          id: '61a7a5ef-6e64-4eae-a7a1-180af7adb575',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-d6a0f30a-9b55-48ab-a4da-3a20df34c3a5.jpeg',
          template: 'default',
        },
        {
          id: '61a7a5ef-6e64-4eae-a7a1-180af7adb575',
          order: 3,
          type: 'enunciated',
          value: 'lady',
          template: 'default',
        },
        {
          id: '61a7a5ef-6e64-4eae-a7a1-180af7adb575',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'bbaa71b2-6647-43d6-a716-619004556eae',
              order: 0,
              text: '',
              textRelated: 'ladies',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '35b4b7e2-3244-4b37-bd80-d412710cae37',
      type: 'activity',
      code: 'WU_LM_N2_L3_P14_P18.3',
      order: 16,
      difficulty: 'easy',
      widgets: [
        {
          id: '35b4b7e2-3244-4b37-bd80-d412710cae37',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 3.',
          template: 'default',
        },
        {
          id: '35b4b7e2-3244-4b37-bd80-d412710cae37',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-c0cce9c8-54b3-4050-b589-f1cd61e755d2.jpeg',
          template: 'default',
        },
        {
          id: '35b4b7e2-3244-4b37-bd80-d412710cae37',
          order: 3,
          type: 'enunciated',
          value: 'butterfly',
          template: 'default',
        },
        {
          id: '35b4b7e2-3244-4b37-bd80-d412710cae37',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '783d81af-523d-412c-add5-a05032aa7bb6',
              order: 0,
              text: '',
              textRelated: 'butterflies',
              image: null,
              imageRelated: null,
              audio: null,
              audioRelated: null,
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '8ad122c6-89cc-47e0-9b13-553d9f004701',
      type: 'activity',
      code: 'WU_LM_N2_L3_P14_P18.4',
      order: 17,
      difficulty: 'easy',
      widgets: [
        {
          id: '8ad122c6-89cc-47e0-9b13-553d9f004701',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 3.',
          template: 'default',
        },
        {
          id: '8ad122c6-89cc-47e0-9b13-553d9f004701',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-88bf972c-7658-4c4a-b87f-dc5b74ccc731.jpeg',
          template: 'default',
        },
        {
          id: '8ad122c6-89cc-47e0-9b13-553d9f004701',
          order: 3,
          type: 'enunciated',
          value: 'family',
          template: 'default',
        },
        {
          id: '8ad122c6-89cc-47e0-9b13-553d9f004701',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: 'f4e112d4-d794-477e-a37d-29431926d199',
              order: 0,
              text: '',
              textRelated: 'families',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '0439b917-8234-409d-b639-2a8ab4d63bb6',
      type: 'activity',
      code: 'WU_LM_N2_L3_P14_P18.5',
      order: 18,
      difficulty: 'easy',
      widgets: [
        {
          id: '0439b917-8234-409d-b639-2a8ab4d63bb6',
          order: 1,
          type: 'title',
          value: 'Reescreva a palavra com o plural seguindo a regra 3.',
          template: 'default',
        },
        {
          id: '0439b917-8234-409d-b639-2a8ab4d63bb6',
          order: 2,
          type: 'onlyimage',
          value:
            'https://go2dev.s3.amazonaws.com/questions/question-enunciated-54b59a27-667d-4e04-b2b3-aa20cefd006a-9600c966-06d2-47d1-b480-cd40d7a9b2ea.jpeg',
          template: 'default',
        },
        {
          id: '0439b917-8234-409d-b639-2a8ab4d63bb6',
          order: 3,
          type: 'enunciated',
          value: 'country',
          template: 'default',
        },
        {
          id: '0439b917-8234-409d-b639-2a8ab4d63bb6',
          order: 4,
          type: 'text',
          feedback: true,
          rightMessage: '',
          wrongMessage: '',
          options: [
            {
              id: '0e3abeb5-85f3-478e-8e11-9d4ffd334794',
              order: 0,
              text: '',
              textRelated: 'countries',
              image: '',
              imageRelated: '',
              audio: '',
              audioRelated: '',
              right: false,
            },
          ],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '46891ec1-74b2-4052-a726-ddfb854c579c',
      type: 'content',
      code: 'WU_LM_N2_L3_P19',
      order: 19,
      widgets: [
        {
          id: 'ea246a06-88ad-4082-80ae-65a7d38bcd10',
          order: 1,
          type: 'subtitle',
          value: 'Other rules',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '497d15c9-1401-4181-b082-456c0a8a0b55',
          order: 2,
          type: 'longtext',
          value: 'Temos ainda outras regrinhas. ',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'ead38a5c-8013-42fc-b041-c65761cb6721',
          order: 3,
          type: 'enunciated',
          value: 'Observe os exemplos abaixo.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '1c8fa7c5-90b5-4fae-aed5-53cd6b724ea5',
          order: 4,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-3-54b59a27-667d-4e04-b2b3-aa20cefd006a-36dd98fa-1742-4244-8d09-995d72060c30.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
      ],
      hints: [],
    },
    {
      id: '28e6fdec-8fe9-49ae-91b4-1b18907cdfbf',
      type: 'content',
      code: ' WU_LM_N2_L3_P20',
      order: 20,
      widgets: [
        {
          id: 'c8da2158-0f0b-4942-9d83-4db9f1894346',
          order: 1,
          type: 'enunciated',
          value:
            'Selecione as regras corretas conforme os exemplos dados abaixo.',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: 'b04afc12-1ccd-47da-bf6a-9d0600b086fd',
          order: 2,
          type: 'onlyimage',
          value:
            'https://d33t1fehisrxit.cloudfront.net/pages/page-widget-1-54b59a27-667d-4e04-b2b3-aa20cefd006a-7915c1ac-9f23-455b-a7e4-ed4783033a23.jpeg',
          feedback: false,
          options: [],
          template: 'default',
        },
        {
          id: '9123ef3c-2e3e-470a-b70d-fa500a3b5acb',
          order: 3,
          type: 'multiselect',
          feedback: true,
          options: [
            {
              id: 'd71b915d-e491-4b50-8156-a9add8f2ae56',
              order: 1,
              text: 'Alguns substantivos no singular não mudam no plural.',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: 'a95719b3-c7b3-4f02-ab8e-42262bd39ba7',
              order: 2,
              text: 'Depois de mudar a forma no final, ainda acrescentamos -s.',
              image: null,
              audio: null,
              right: false,
            },
            {
              id: 'a157ca26-8c07-45ff-87a7-3dce54297c93',
              order: 3,
              text: 'Substantivos terminados em -o, basta adicionar -es ao singular. ',
              image: null,
              audio: null,
              right: true,
            },
            {
              id: 'f0e7fe05-d270-41a2-bff4-954c0f02e42d',
              order: 4,
              text: 'Tem plural que não leva -s no final, apenas mudam a forma.',
              image: null,
              audio: null,
              right: true,
            },
          ],
          template: 'default',
          rightMessage:
            'No exemplo 1, basta acrescentar -es aos substantivos terminados em -o. No exemplo 2, não muda a forma singular não muda no plural. E no 3, temos o plural irregular, ou seja, não acrescentamos -s ao plural. ',
          wrongMessage:
            'No exemplo 1, basta acrescentar -es aos substantivos terminados em -o. No exemplo 2, não muda a forma singular não muda no plural. E no 3, temos o plural irregular, ou seja, não acrescentamos -s ao plural. ',
        },
      ],
      hints: [],
    },
  ],
};
