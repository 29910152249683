import { ApplicationConfig, inject, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

import { routes } from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { KEYSTORE_PREFIX, StorageService } from './modules/core/storage/storage.service';
import { provideFuse } from 'fuse-lib';
import { mockApiServices } from './modules/mock-api';
import { authApiInterceptor } from './modules/api/auth/auth.api.interceptor';
import { apiServicesProviders } from './modules/api/api.service.providers';
import { ApiService } from './modules/api/api.service';
import { storageServicesProviders } from './modules/core/storage/storage.service.providers';

export const appConfig: ApplicationConfig = {

  providers: [
    provideHttpClient(
      withFetch(),
      withInterceptors([authApiInterceptor])
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideLottieOptions({
      player: () => player,
    }),
    provideAnimationsAsync(),
    storageServicesProviders,
    apiServicesProviders,
    provideFuse({
      mockApi: {
        delay: 250,
        services: mockApiServices,
      }
    })
  ]

};