export default {
    "id": "8f32da1f-da68-4d02-bd9f-d4d3793a71d6",
    "createdAt": "2024-05-28T11:30:16.010Z",
    "updatedAt": "2024-06-17T19:57:20.057Z",
    "title": "Welcome Unit",
    "subtitle": "The World of English",
    "order": 1,
    "status": "activated",
    "countPages": 201,
    "completedPages": 0,
    "stages": [
        {
            "id": "fa999171-3f49-4752-a376-7e1777a2e3af",
            "createdAt": "2024-05-28T11:30:16.017Z",
            "updatedAt": "2024-06-17T19:57:20.072Z",
            "title": "Time to Connect",
            "cover": null,
            "order": 1,
            "status": "activated",
            "type": "CONTENTS",
            "countPages": 32,
            "completedPages": 0
        },
        {
            "id": "a45ccdb6-4bc7-45bd-8995-dc367b4a2be1",
            "createdAt": "2024-05-28T11:30:16.336Z",
            "updatedAt": "2024-06-17T19:57:20.621Z",
            "title": "Did you know?",
            "cover": null,
            "order": 2,
            "status": "activated",
            "type": "CONTENTS",
            "countPages": 27,
            "completedPages": 0
        },
        {
            "id": "bcf7c45c-eca0-4b07-8710-5045503eb7ba",
            "createdAt": "2024-05-28T11:30:16.547Z",
            "updatedAt": "2024-06-17T19:57:20.988Z",
            "title": "Quiz Time",
            "cover": null,
            "order": 3,
            "status": "completed",
            "type": "ACTIVITIES",
            "countPages": 0,
            "completedPages": 0
        },
        {
            "id": "72ba5690-bba3-4ed9-99b9-babe426d1282",
            "createdAt": "2024-05-28T11:30:16.547Z",
            "updatedAt": "2024-06-17T19:57:20.991Z",
            "title": "Learning Steps",
            "cover": null,
            "order": 4,
            "status": "activated",
            "type": "STEPS",
            "countPages": 139,
            "completedPages": 0
        },
        {
            "id": "14c2c2eb-6e7c-4d83-95d4-6fabbf143aec",
            "createdAt": "2024-05-28T12:25:38.584Z",
            "updatedAt": "2024-06-17T19:57:22.996Z",
            "title": "Taking it Further",
            "cover": null,
            "order": 5,
            "status": "completed",
            "type": "CONTENTS",
            "countPages": 0,
            "completedPages": 0
        },
        {
            "id": "dfab01be-a13c-49e0-beb8-02d4bdea962f",
            "createdAt": "2024-05-28T12:25:38.586Z",
            "updatedAt": "2024-06-17T19:57:22.999Z",
            "title": "Closing",
            "cover": null,
            "order": 6,
            "status": "activated",
            "type": "CONTENTS",
            "countPages": 3,
            "completedPages": 0
        }
    ]
}