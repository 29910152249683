<st-loading-page
  *ngIf="state == 'loading'"
  source="https://go2dev.s3.amazonaws.com/fixed/loading-3.lottie"
/>

<app-select-stage-learn
  *ngIf="state == 'page'"
  (eventPage)="onEventPage($event)"
  [unit]="data.currentUnit"
  [stages]="data.stages"
/>
