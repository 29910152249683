import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesModule } from 'go-components';
import { LoadingPageComponent } from './loading-page.component';

const components = [LoadingPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ImagesModule],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoadingPageModule {}

export { LoadingPageComponent };
