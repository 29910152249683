import { Component, Input } from '@angular/core';
import { GeneralModalComponent } from '../modais.module';

@Component({
  selector: 'go-correct-modal',
  templateUrl: './correct-modal.component.html',
  styleUrl: './correct-modal.component.scss',
})
export class CorrectModalComponent extends GeneralModalComponent {
  override goComponentId: string = 'correct-modal';

  @Input() title: string = 'VERY GOOD!';
  @Input() description?: string;
}
