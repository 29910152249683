import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AudioComponent } from './audio/audio.component';
import { TitleComponent } from './title/title.component';
import { SubtitleComponent } from './subtitle/subtitle.component';
import { EnunciatedComponent } from './enunciated/enunciated.component';
import { LongtextComponent } from './longtext/longtext.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { OnlyimageComponent } from './onlyimage/onlyimage.component';
import { SelectComponent } from './select/select.component';
import { SelectimageComponent } from './selectimage/selectimage.component';
import { CombinationComponent } from './combination/combination.component';
import { TextboxComponent } from './textbox/textbox.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { VideoComponent } from './video/video.component';
import { SelectvoiceComponent } from './selectvoice/selectvoice.component';
import { BlocksComponent } from './blocks/blocks.component';

import { ButtonsModule } from '../buttons/buttons.module';
import { BlocksModule } from '../blocks/blocks.module';
import { IconsModule } from '../icons/icons.module';

import { WidgetOptions } from './options.interface';
import { GeneralChallengeComponent } from './general-challenge/general-challenge.component';

const components = [
  // GeneralChallengeComponent,
  AudioComponent,
  BlocksComponent,
  TitleComponent,
  SubtitleComponent,
  EnunciatedComponent,
  LongtextComponent,
  MultiselectComponent,
  OnlyimageComponent,
  SelectComponent,
  SelectimageComponent,
  SelectvoiceComponent,
  CombinationComponent,
  TextboxComponent,
  ChecklistComponent,
  VideoComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, BlocksModule, IconsModule],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChallengesModule {}

export {
  GeneralChallengeComponent,
  AudioComponent,
  BlocksComponent,
  TitleComponent,
  SubtitleComponent,
  EnunciatedComponent,
  LongtextComponent,
  MultiselectComponent,
  OnlyimageComponent,
  SelectComponent,
  SelectimageComponent,
  SelectvoiceComponent,
  CombinationComponent,
  TextboxComponent,
  VideoComponent,
  ChecklistComponent,
  WidgetOptions,
};
