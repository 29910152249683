import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, GuardResult, MaybeAsync, RedirectCommand } from '@angular/router';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { rootRoutes } from '../routes/root-routes';
import { childRoutes } from '../routes/child-routes';

@Injectable({
  providedIn: 'root'
})
export class StudentAndTeacherGuard implements CanActivate {
  
  constructor(private auth: AuthApiService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    const currentPath = next?.routeConfig?.path;
    if (!currentPath) {
      return true;
    }

    const loggedUser = this.auth.getLoggedUser();
    if (!loggedUser) {
      return new RedirectCommand(this.router.parseUrl(childRoutes.login));
    }

    const userProfile = loggedUser?.profile;
    let redirectToHome = false;
    if (currentPath == rootRoutes.student && userProfile != 'student') {
      console.debug('warning: current user is not a student: ' + userProfile);

      redirectToHome = true;
    }

    if (currentPath == rootRoutes.teacher && userProfile != 'teacher') {
      console.debug('warning: current user is not a teacher: ' + userProfile);

      redirectToHome = true;
    }

    if (redirectToHome) {
      return new RedirectCommand(this.router.parseUrl(childRoutes.home));
    }

    return true;
  }

}