import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePageComponent } from './profile-page.component';
import { MenusModule, ProgressesModule, IconsModule } from 'go-components';

const components = [ProfilePageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, MenusModule, ProgressesModule, IconsModule],
  exports: components,
})
export class ProfilePageModule {}

export { ProfilePageComponent };
