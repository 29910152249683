import { Component, Input, output } from '@angular/core';
import { LoginPageData } from './login-page.data';
import { LoginPageAuthType } from './login-page.auth.type';
import { LoginPageEvent } from './login-page.event';
import { LoggedUser } from '../../domain/user/logged.user';

@Component({
    selector: 'st-login-page',
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {

    alert?: string | any;
    eventPage = output<LoginPageData>();

    onEventPage(event: LoginPageData): void { }

    showAlert(alert?: string | any) {
        this.alert = alert;
    }

    clearAlert() {
        this.alert = null;
    }

    //? Transforma string no formato apresentado em tela.
    //? Ex.: "**ONDE** você vai ministrar sua aula **AGORA**?"
    transformStringToHtml(input: string): string {
        // Regex para encontrar palavras entre ** e substituir
        const regex = /\*\*(.*?)\*\*/g;
        return input.replace(regex,
            '<b class="text-baloochettan-h1 go-text-color-purple700">$1</b>'
        );
    }

    @Input() logged = false;
    @Input() loggedUser?: LoggedUser;

    email?: string;
    password?: string;

    emailChange(email: string) {
        this.email = email;
        this.emitEventPage('email-input-change');
    }

    passwordChange(password: string) {
        this.password = password;
        this.emitEventPage('password-input-change');
    }

    clearEmailAndPasswordAndRequestLogoff() {
        this.email = '';
        this.password = '';
        this.emitEventPage('logoff-request');
    }

    emitEventPage(event: LoginPageEvent, type: LoginPageAuthType = 'basic') {
        this.eventPage.emit({
            event: event,
            type: type,
            email: this.email,
            password: this.password
        });
    }

}