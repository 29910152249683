import { AuthMockApi } from './auth/api';
import { CoursesClassesMockApi } from './courses/api';
import { UnitsCoursesMockApi } from './courses/units/api';
import { UnitsDetailMockApi } from './courses/units/units-detail/api';

export const mockApiServices = [
    AuthMockApi,
    CoursesClassesMockApi,
    UnitsCoursesMockApi,
    UnitsDetailMockApi
];