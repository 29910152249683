import { Component, OnInit } from '@angular/core';
import { SelectUnitsPageModule, Unit, UnitOption } from "student-lib";
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { AuthApiService } from '../../api/auth/auth.api.service';
import { Router } from '@angular/router';
import { CoursesClassesApiService } from '../../api/courses/courses-classes.api.service';
import { childRoutes } from '../../core/routes/child-routes';
import { keyareas } from '../../shared/all-key-storages';
import { Radio } from 'go-components';
import { ModaisModule } from "go-components";
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-select-unit-to-learn',
    standalone: true,
    templateUrl: './select-unit-to-learn.component.html',
    styleUrl: './select-unit-to-learn.component.scss',
    imports: [SelectUnitsPageModule, ModaisModule, NgIf]
})
export class SelectUnitToLearnComponent extends AbstractStudentPageComponent<void> implements OnInit {

    options: UnitOption[] = [];
    mustStartOneCourseFirst = false;

    constructor(authApi: AuthApiService, private api: CoursesClassesApiService, private router: Router) {
        super(authApi);
    }

    goSelectCourse() {
        this.router.navigateByUrl(childRoutes.selectCourseStudent);
    }

    selectedUnit(evt: UnitOption) {
        this.storeUnitId('selected', evt.id);
    }

    ngOnInit(): void {
        const lastStartedCourseId = this.retrieveStringFromStorage(keyareas.student.lastStartedCourseId);
        if (!lastStartedCourseId) {
            this.mustStartOneCourseFirst = true;
            return;
        }

        const lastSelectedUnitId = this.retrieveStringFromStorage(keyareas.student.lastSelectedUnitId);
        this.api.loadUnits(lastStartedCourseId)
            .then(v => {
                v.forEach(item => {
                    this.options.push(this.toUnitOption(item, lastSelectedUnitId == item.id))
                })
            })
    }

    goBack() {
        this.router.navigateByUrl(childRoutes.studentHome);
    }

    continue(option?: UnitOption) {
        if (!option) {
            return;
        }

        this.storeUnitId('started', option.id);

        this.router.navigateByUrl(childRoutes.selectStageStudent);
    }

    private storeUnitId(type: 'selected' | 'started', id: string) {
        const key = type == 'selected' ? keyareas.student.lastSelectedUnitId : keyareas.student.lastStartedUnitId;

        this.storeString(key, id);
    }

    private toUnitOption(v: Unit, selected: boolean = false): UnitOption {
        return { id: v.id, option: this.toRadio(v, selected) };
    }

    private toRadio(v: Unit, selected: boolean = false): Radio {
        return {
            isSelected: selected,
            name: 'unique',
            status: <any> v.status,
            title: v.title,
        }
    }

}
