import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ThemeColorsTypes } from '../../core/models/enums/colors.enum';
import { WidgetOptions } from '../../challenges/options.interface';

@Component({
  selector: 'go-select-block',
  templateUrl: './select-block.component.html',
  styleUrl: './select-block.component.scss',
})
export class SelectBlockComponent implements OnInit, OnChanges {
  readonly goComponentId: string = 'go-select-block';

  @Input() item!: WidgetOptions;
  @Input() name?: string = 'select';
  @Input() itensSelected: string[] = [''];
  @Input() correction: boolean = false;

  @Output() itemClick = new EventEmitter<{
    item: WidgetOptions;
  }>();

  boxClasses!: string;
  radioClasses!: string;
  innerRadioClasses!: string;
  textClasses!: string;
  iconColor!: ThemeColorsTypes;
  iconCorrection: 'check-radio' | 'close-white' = 'check-radio';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.width = '100%';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyElementClasses();
  }

  applyElementClasses() {
    let selected = this.itensSelected.includes(this.item.id);

    if (selected) {
      if (this.correction) {
        if (this.item.right) {
          this.correctStyle();
        } else {
          this.wrongStyle();
        }
      } else {
        this.selectedStyle();
      }
    } else {
      if (this.correction) {
        if (this.item.right) {
          this.correctStyle();
        } else {
          this.blockedStyle();
        }
      } else {
        this.defaultStyle();
      }
    }
  }

  //? Função de click
  onItemClick() {
    this.itemClick.emit({ item: this.item });
  }

  //? Função para verificar se o texto existe para decidir se mostra o ícone de áudio
  emptyStr(str?: string | null): boolean {
    return str === null || str === undefined || str.trim() === '';
  }

  //? Estilo do elemento marcado corretamente
  private correctStyle() {
    this.boxClasses =
      'go-background-color-turquoise100 go-border-color-turquoise500';
    this.radioClasses =
      'go-background-color-turquoise500 go-border-color-turquoise700';
    this.innerRadioClasses = '';
    this.textClasses = 'text-poppins-body-semibold go-text-color-turquoise700';
    this.iconColor = 'turquoise700';
    this.iconCorrection = 'check-radio';
  }

  //? Estilo do elemento marcado incorretamente
  private wrongStyle() {
    this.boxClasses = 'go-background-color-salmon100 go-border-color-salmon500';
    this.radioClasses =
      'go-background-color-salmon500 go-border-color-salmon700';
    this.innerRadioClasses = '';
    this.textClasses = 'text-poppins-body-semibold go-text-color-salmon700';
    this.iconColor = 'salmon700';
    this.iconCorrection = 'close-white';
  }

  //? Estilo do elemento selecionado
  private selectedStyle() {
    this.boxClasses =
      'active go-background-color-purple50 go-border-color-purple500';
    this.radioClasses =
      'go-background-color-purple100 go-border-color-purple500';
    this.innerRadioClasses = 'go-background-color-purple500';
    this.textClasses = 'text-poppins-body-semibold go-text-color-purple500';
    this.iconColor = 'purple700';
  }

  //? Estilo cinza do bloco
  private blockedStyle() {
    this.boxClasses = 'go-background-color-whiteColor go-border-color-gray100';
    this.radioClasses = '';
    this.innerRadioClasses = '';
    this.textClasses = 'text-poppins-body-semibold go-text-color-gray300';
    this.iconColor = 'gray300';
  }

  //? Estilo padrão do bloco
  private defaultStyle() {
    this.boxClasses = 'go-background-color-gray50 go-border-color-gray300';
    this.radioClasses = 'go-background-color-gray100 go-border-color-gray300';
    this.innerRadioClasses = 'go-background-color-gray300';
    this.textClasses = 'text-poppins-body-semibold go-text-color-gray900';
    this.iconColor = 'gray950';
  }
}
