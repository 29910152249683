import { Component, Input, model } from "@angular/core";
import { MenuBarState, MenuItem } from "go-components";
import { AbstractPageComponent } from "./abstract-page.component";
import { LoggedUser, PageWithMenu } from "student-lib";
import { defaultMenuItemsApp } from "./default-menu-items-app";
import { keyareas } from "./all-key-storages";
import { AuthApiService } from "../api/auth/auth.api.service";

@Component({ selector: 'app-abstract-user-page-component', template: '' })
export abstract class AbstractUserPageComponent<T> extends AbstractPageComponent<T> implements PageWithMenu {
    
    @Input() menuItens: MenuItem[] = defaultMenuItemsApp;
    menuBarState = model<MenuBarState>('normal');

    constructor(protected authApi: AuthApiService) {
        super();

        this.menuBarState.set(this.getLastMenuBarState());
        this.menuBarState.subscribe(v => {
          this.storeCurrentMenuBarState(v);
        });
    }

    getCurrentLoggedUserName(): string | null {
        const user = this.getCurrentLoggedUser();
        if (!user) {
            return null;
        }

        return user.user.name;
    }

    getCurrentLoggedUser(): LoggedUser | null {
        return this.authApi.getLoggedUser();
    }

    getLastMenuBarState(): MenuBarState {
        let state: MenuBarState;

        const storedItem = this.retrieveStringFromStorage(keyareas.global.lastMenubarState);
        if (storedItem == false) {
            return this.menuBarState();
        }
        
        state = <MenuBarState> storedItem;
        this.storeCurrentMenuBarState(state);

        return state;
    }

    storeCurrentMenuBarState(state: MenuBarState): void {
        this.storeString(keyareas.global.lastMenubarState, state);
    }

}