import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { keyareas } from '../../shared/all-key-storages';

import { AuthApiService } from '../../api/auth/auth.api.service';
import { CoursesClassesApiService } from '../../api/courses/courses-classes.api.service';
import {
  LoadingPageModule,
  SelectLearningStepsPageModule,
  Stage,
  StageItem,
  Step,
} from 'student-lib';
import { AbstractStudentPageComponent } from '../core/abstract-student-page.component';
import { StepsService } from '../../shared/services/steps.service';
import { childRoutes } from '../../core/routes/child-routes';
import { Radio } from 'go-components';

@Component({
  selector: 'app-go-steps',
  standalone: true,
  imports: [SelectLearningStepsPageModule, LoadingPageModule, NgIf],
  templateUrl: './go-steps.component.html',
  styleUrls: ['./go-steps.component.scss'],
})
export class GoStepsComponent
  extends AbstractStudentPageComponent<any>
  implements OnInit
{
  escapePath = childRoutes.studentHome;
  state: 'loading' | 'steps' = 'loading';
  stageId!: string;
  stepId!: string;

  loadedSteps!: Step[];
  currentStep!: Step;
  currentStage!: Stage;

  explorer: Radio[] = [];
  interpreter: Radio[] = [];
  communicator: Radio[] = [];

  selectedType: 'explorer' | 'interpreter' | 'communicator' | null = null;
  isButtonDisabled: boolean = true;

  constructor(
    authApi: AuthApiService,
    private coursesClassesApi: CoursesClassesApiService,
    private route: ActivatedRoute,
    private router: Router,
    private stepService: StepsService
  ) {
    super(authApi);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateRouteData();
      });

    this.updateRouteData();
  }

  async updateRouteData(): Promise<void> {
    let stageId = this.route.snapshot.params['stageId'];

    const lastSelectedCourse = this.retrieveStringFromStorage(
      keyareas.student.lastSelectedCourseId
    );
    if (!lastSelectedCourse) {
      this.router.navigate(['/']);
      return;
    }

    let loadedStage = this.stepService.getStage();
    if (!loadedStage) {
      loadedStage = await this.coursesClassesApi.loadStage(
        lastSelectedCourse,
        stageId
      );
      this.stepService.setStage(loadedStage);
    }

    let loadedSteps = this.stepService.getSteps(stageId);
    if (!loadedSteps) {
      loadedSteps = await this.coursesClassesApi.loadSteps(
        lastSelectedCourse,
        stageId
      );
      this.stepService.setSteps(loadedSteps);
      console.log('Loaded pages from API for step:', stageId);
    } else {
      console.log('Loaded pages from service for step:', stageId);
    }

    this.stageId = stageId;
    this.stepId = loadedSteps[0].id;
    this.loadedSteps = loadedSteps;
    this.currentStep = loadedSteps[parseInt(this.stepId) - 1];
    this.currentStage = loadedStage;
    this.state = 'steps';

    this.applyModulesSeparator(loadedSteps);
  }

  private applyModulesSeparator(steps: Step[]) {
    let number = 0;
    steps.map((step) => {
      number++;
      if (step.module.order == 1) {
        let currentItem = this.pushModuleItem(step, this.explorer, number);
        if (currentItem) {
          this.explorer.push(currentItem);
        }
      }
      if (step.module.order == 2) {
        let currentItem = this.pushModuleItem(step, this.interpreter, number);
        if (currentItem) {
          this.interpreter.push(currentItem);
        }
      }
      if (step.module.order == 3) {
        let currentItem = this.pushModuleItem(step, this.communicator, number);
        if (currentItem) {
          this.communicator.push(currentItem);
        }
      }
    });
  }

  private pushModuleItem(
    step: Step,
    moduleArray: Radio[],
    number: number
  ): Radio | null {
    let checkItem = moduleArray.some((el) => el.title == step.title);
    if (!checkItem) {
      let percent: number = 0;
      if (step.countPages > 0) {
        percent = (step.completedPages / step.countPages) * 100;
      }

      return {
        id: step.id,
        name: 'step',
        title: step.title,
        number: number,
        percent: percent,
        isSelected: false,
        status: step.status,
      };
    }

    return null;
  }

  onCardSelected({
    indexItem,
    type,
  }: {
    indexItem: number;
    type: string;
  }): void {
    this.explorer.forEach((item) => (item.isSelected = false));
    this.interpreter.forEach((item) => (item.isSelected = false));
    this.communicator.forEach((item) => (item.isSelected = false));

    if (indexItem !== -1) {
      switch (type) {
        case 'explorer':
          this.explorer[indexItem].isSelected = true;
          this.selectedType = 'explorer';
          break;
        case 'interpreter':
          this.interpreter[indexItem].isSelected = true;
          this.selectedType = 'interpreter';
          break;
        case 'communicator':
          this.communicator[indexItem].isSelected = true;
          this.selectedType = 'communicator';
          break;
      }
      this.isButtonDisabled = false;
    } else {
      this.selectedType = null;
      this.isButtonDisabled = true;
    }
  }

  onContinue(): void {
    let findSelected: Radio | undefined;
    if (this.selectedType != null) {
      switch (this.selectedType) {
        case 'explorer':
          findSelected = this.explorer.find((el) => el.isSelected == true);
          if (findSelected) this.redirectToChallenges(findSelected.id);
          break;

        case 'interpreter':
          findSelected = this.interpreter.find((el) => el.isSelected == true);
          if (findSelected) this.redirectToChallenges(findSelected.id);
          break;

        case 'communicator':
          findSelected = this.communicator.find((el) => el.isSelected == true);
          if (findSelected) this.redirectToChallenges(findSelected.id);
          break;
      }
    }
    // Lógica para continuar
  }

  private redirectToChallenges(stageId?: string) {
    if (stageId) {
      const userLoggedInfo = this.retrieveStringFromStorage(
        keyareas.auth.userLogged
      );
      if (userLoggedInfo) {
        let userData = JSON.parse(userLoggedInfo);
        let page = 1;
        this.router.navigate([
          `${userData.profile}/challenges/${stageId}/${page}`,
        ]);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
}
