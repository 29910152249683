// Core classes
export * from './lib/core/alert/alert.module';

// Core pages
export * from './lib/pages/core/item-card';
export * from './lib/pages/core/page-with-menu';

// Domains
export * from './lib/domain/core/core.model';
export * from './lib/domain/course/course';
export * from './lib/domain/school/school';
export * from './lib/domain/user/user';

// Infra pages
export * from './lib/pages/loading-page/loading-page.module';
export * from './lib/pages/login-page/login-page.module';

// Student pages
export * from './lib/pages/exames/quiz.module';
export * from './lib/pages/challenge-page/challenge-page.module';
export * from './lib/pages/student-pages/select-course-to-learn-page/select-course-to-learn-page.module';
export * from './lib/pages/student-pages/select-units-page/select-units-page.module';
export * from './lib/pages/student-pages/select-stage-page/select-stage-page.module';
export * from './lib/pages/student-pages/select-learning-steps-page/select-learning-steps-page.module';
export * from './lib/pages/student-pages/profile-page/profile-page.module';

// Teacher pages
export * from './lib/pages/teacher-pages/select-school-page/select-school-page.module';

// Mock API data
export * as mockApiData from './lib/mock-api-data';
