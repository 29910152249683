import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavItem } from '../navigation.interface';
import { AbstractNavigationComponent } from '../abstract.navigation.component';

@Component({
  selector: 'go-general-navigation',
  templateUrl: './general-navigation.component.html',
  styleUrl: './general-navigation.component.scss',
})
export class GeneralNavigationComponent implements AbstractNavigationComponent, OnInit, AfterViewInit {

  readonly goComponentId: string = 'general-navigation';

  @ViewChild('aside') aside: any;
  @ViewChild('a') link: any;

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() progress!: number;

  @Input() navItens: NavItem[] = [];
  @Input() report: boolean = true;
  @Input() expanded: boolean = true;

  @Input() width: string = '100%';

  statusMenu: 'minimized' | 'normal' | 'expanded' = 'normal';
  navigationClasses: string = '';

  ngOnInit(): void {
    this.applyStyleClassesMenu();
  }

  ngAfterViewInit(): void {
    var el: ElementRef<HTMLBaseElement> = this.aside;

    if (this.width != '100%') {
      el.nativeElement.style.width = this.width;
    }
  }

  //? Função para tratar o texto do menu
  transformText(
    value: string,
    limit: number = 30,
    completeWords: boolean = false,
    ellipsis: string = '...'
  ): string {
    if (!value) return '';
    if (value.length <= limit) return value;

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.substr(0, limit) + ellipsis;
  }

  //? Aplica a alteração de status do menu
  toggleMenu(event: Event) {
    event.preventDefault();

    this.statusMenu = this.statusMenu == 'minimized' ? 'normal' : 'minimized';
    this.applyStyleClassesMenu();
  }

  //? Função de ação de report
  reportAction(event: Event) {
    event.preventDefault();

    alert('Botão de report');
  }

  //? PRIVATE FUNCTIONS
  private applyStyleClassesMenu() {
    if (this.statusMenu == 'minimized') {
      this.statusMenu = 'minimized';
      this.navigationClasses = 'minimized';
    } else {
      this.statusMenu = 'normal';
      this.navigationClasses = '';
    }
  }
}
