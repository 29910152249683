import { Component, Input } from '@angular/core';

@Component({
  selector: 'st-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.css'
})
export class AlertComponent {

  @Input() alert?: string | any;

}
