import { MenuItem } from 'go-components';
import { childRoutes } from '../../core/routes/child-routes';

export const defaultStudentMenuItems: MenuItem[] = [
  {
    icon: 'home',
    text: 'Página inicial',
    link: childRoutes.studentHome,
    status: 'normal',
  },
  {
    text: 'Sair',
    icon: 'sign-out-alt',
    link: childRoutes.logoff,
    status: 'normal',
  },
];
