<div class="go-circular-progress">
  <svg viewBox="0 0 36 36" class="circular-chart">
    <path
      class="circle-bg"
      [style.stroke]="innerColor"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      class="circle"
      [style.stroke]="barColor"
      [attr.stroke-dasharray]="percent + ', 100'"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <text
      x="18"
      y="18"
      class="percentage text-poppins-caption-bold go-text-color-gray700"
    >
      {{ percent }}%
    </text>
  </svg>
</div>
