import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractImageComponent } from '../abstract.image.component';

@Component({
  selector: 'go-general-image',
  templateUrl: './general-image.component.html',
  styleUrl: './general-image.component.scss',
})
export class GeneralImageComponent
  implements AbstractImageComponent, AfterViewInit, OnInit
{
  readonly goComponentId: string = 'general-image';
  @ViewChild('box', { static: false }) div!: ElementRef;

  @Input() template?: string = 'default';
  @Input() value!: string;
  @Input() label?: string = 'Imagem';
  @Input() textAlign?: 'left' | 'right' | 'center' = 'center';

  @Input() width: string = '200px';
  @Input() height?: string;
  @Input() marginBottom?: string = '16px';

  @Input() pageStatus?: string;
  @Input() pageType?: string;

  type: 'image' | 'lottie' = 'image';
  imagePath!: string;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.applyImageType();
  }

  ngAfterViewInit(): void {
    this.applyStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.imagePath = this.value;
    this.applyImageType();
    this.reloadLottieAnimation();
  }

  private applyStyles() {
    if (this.div) {
      const el: ElementRef<HTMLDivElement> = this.div;
      el.nativeElement.style.width = this.width;
      if (this.height) {
        el.nativeElement.style.height = this.height;
      }
    }
  }

  private applyImageType() {
    this.type =
      this.value.endsWith('.lottie') || this.value.endsWith('.json')
        ? 'lottie'
        : 'image';
  }

  private reloadLottieAnimation() {
    if (this.type === 'lottie' && this.div) {
      const el: ElementRef<HTMLDivElement> = this.div;
      const lottieContainer =
        el.nativeElement.querySelector('dotlottie-player');
      if (lottieContainer) {
        this.renderer.removeChild(el.nativeElement, lottieContainer);
        const newLottieContainer =
          this.renderer.createElement('dotlottie-player');
        this.renderer.setAttribute(newLottieContainer, 'src', this.imagePath);
        this.renderer.setAttribute(
          newLottieContainer,
          'background',
          'transparent'
        );
        this.renderer.setAttribute(newLottieContainer, 'direction', '1');
        this.renderer.setAttribute(newLottieContainer, 'playMode', 'normal');
        this.renderer.setAttribute(newLottieContainer, 'loop', '');
        this.renderer.setAttribute(newLottieContainer, 'autoplay', '');
        this.renderer.appendChild(el.nativeElement, newLottieContainer);
      }
    }
  }
}
