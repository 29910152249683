export default [
    {
        "id": "eb9e95a3-0986-4b8b-9ed3-d985e6bb8e24",
        "createdAt": "2024-06-01T13:54:14.077Z",
        "updatedAt": "2024-06-01T13:54:14.077Z",
        "name": "601",
        "format": "FREE",
        "course": {
            "id": "d73f7303-32c4-423e-8225-4c8bae71133f",
            "createdAt": "2024-05-28T11:30:15.975Z",
            "updatedAt": "2024-06-14T14:17:27.797Z",
            "title": "6º Ano",
            "status": "DRAFT"
        },
        "school": {
            "id": "7b1315d2-f697-48d4-996a-6f80b3914dea",
            "createdAt": "2024-06-01T13:49:14.831Z",
            "updatedAt": "2024-06-01T13:49:14.831Z",
            "code": "20-0011",
            "name": "Colégio Estadual Ângelo Gusso",
            "address": "Rua Sem Nome, 234, Bairro Boa Vista",
            "email": "ceangelogusso@sed.pr.gov.br",
            "phone": null
        }
    }
];