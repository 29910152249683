import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevToolsComponent } from './dev-tools.component';

@NgModule({
  declarations: [DevToolsComponent],
  imports: [CommonModule],
  exports: [DevToolsComponent]
})
export class DevToolsModule { }
