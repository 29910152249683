import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { AbstractMockApi } from '../../abstract.mock-api';
import { mockApiData } from 'student-lib';

@Injectable({
    providedIn: 'root'
})
export class UnitsCoursesMockApi extends AbstractMockApi {

  static readonly courseClassIdParam = ':courseClassId';

  constructor(api: ApiService) {
    super(api, `/courses/${UnitsCoursesMockApi.courseClassIdParam}/units`);
  }

  override registerHandlers(): void {
    this.mock.onGet(this.api.getFakePrefixUrl() + this.contextPath)
      .reply(({request}) => {
        return this.okResponse(mockApiData.unitsData.default);
      }
    );
  }

}