import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChallengePageComponent } from './challenge-page.component';
import {
  ButtonsModule,
  IconsModule,
  NavigationsModule,
  ProgressesModule,
  ChallengesModule,
  ModaisModule,
} from 'go-components';

const components = [ChallengePageComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    ButtonsModule,
    IconsModule,
    NavigationsModule,
    ProgressesModule,
    ChallengesModule,
    ModaisModule,
  ],
  exports: components,
})
export class ChallengePageModule {}

export { ChallengePageComponent };
