import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ThemeColors,
  ThemeColorsTypes,
} from '../../core/models/enums/colors.enum';
import { CardColors } from '../card-colors.types';
import { AbstractCardComponent } from '../abstract.card.component';

@Component({
  selector: 'go-general-card',
  templateUrl: './general-card.component.html',
  styleUrl: './general-card.component.scss',
})
export class GeneralCardComponent
  implements AbstractCardComponent, AfterViewInit, OnInit, OnChanges
{
  readonly goComponentId: string = 'general-card';

  @ViewChild('input') input: any;

  @Input() number?: string;
  @Input() cover?: string;
  @Input() title?: string = 'Título do card';
  @Input() link?: string;

  @Input() partialPages: number = 0;
  @Input() totalPages: number = 0;
  @Input() percent?: number = 0;

  @Input() isSelected: boolean = false;
  @Input() color: CardColors = 'blue';
  @Input() status?: 'normal' | 'concluded' | 'disabled' = 'normal';

  @Output() mainButtonClick = new EventEmitter<void>();

  backgroundColor!: string;
  borderColor!: string;
  backgroundInfoColor!: string;
  borderInfoColor!: string;
  textInfoColor!: string;

  ngOnInit(): void {
    this.applyComponentStyleClasses();
  }

  ngAfterViewInit(): void {
    var el: ElementRef<HTMLInputElement> = this.input;
    this.updateSelection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSelected']) {
      this.updateSelection();
    }
  }

  //? Função de definição de classes do component
  private updateSelection(): void {
    if (this.status == 'concluded') {
      this.backgroundColor = ThemeColors['turquoise500'];
      this.borderColor = ThemeColors['turquoise700'];

      this.backgroundInfoColor = ThemeColors['turquoise100'];
      this.borderInfoColor = ThemeColors['turquoise700'];
      this.textInfoColor = ThemeColors['turquoise700'];

      return;
    }

    if (this.status == 'disabled') {
      this.backgroundColor = ThemeColors['gray200'];
      this.borderColor = ThemeColors['gray700'];

      this.backgroundInfoColor = ThemeColors['gray200'];
      this.borderInfoColor = ThemeColors['gray700'];
      this.textInfoColor = ThemeColors['gray800'];

      return;
    }

    let selectedColors: {
      backgroundColor: ThemeColorsTypes;
      borderColor: ThemeColorsTypes;
      backgroundInfoColor: ThemeColorsTypes;
      borderInfoColor: ThemeColorsTypes;
      textInfoColor: ThemeColorsTypes;
    } = {
      backgroundColor: `${this.color}500`,
      borderColor: `${this.color}700`,

      backgroundInfoColor: `${this.color}100`,
      borderInfoColor: `${this.color}700`,
      textInfoColor: `gray900`,
    };

    if (this.isSelected) {
      this.backgroundColor = ThemeColors[selectedColors.backgroundColor];
      this.borderColor = ThemeColors[selectedColors.borderColor];

      this.backgroundInfoColor = ThemeColors['whiteColor'];
      this.borderInfoColor = ThemeColors['gray500'];
      this.textInfoColor = ThemeColors[selectedColors.textInfoColor];
    } else {
      this.backgroundColor = ThemeColors[selectedColors.backgroundColor];
      this.borderColor = ThemeColors[selectedColors.borderColor];

      this.backgroundInfoColor =
        ThemeColors[selectedColors.backgroundInfoColor];
      this.borderInfoColor = ThemeColors[selectedColors.borderInfoColor];
      this.textInfoColor = ThemeColors[selectedColors.textInfoColor];
    }
  }

  private applyComponentStyleClasses(): void {
    this.updateSelection();
  }
}
