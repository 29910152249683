import { CardColors } from 'go-components';

interface TitleAndColor {
  title: string;
  color: CardColors;
}

const colorsByStageTitle: TitleAndColor[] = [
  {
    title: 'timetoconnect',
    color: 'blue',
  },
  {
    title: 'didyouknow',
    color: 'turquoise',
  },
  {
    title: 'quiztime',
    color: 'salmon',
  },
  {
    title: 'learningsteps',
    color: 'purple',
  },
  {
    title: 'takingitfurther',
    color: 'orange',
  },
  {
    title: 'closing',
    color: 'pink',
  },
  {
    title: 'review',
    color: 'green',
  },
  {
    title: 'checkpoint',
    color: 'brown',
  },
  {
    title: 'finalassesment',
    color: 'blue',
  },
];

export class StageItemColorsMapper {
  toCardColor(title: string): CardColors {
    const find = colorsByStageTitle.find(
      (c) => c.title == this.removeSpecialCharsAndSpaces(title)
    );

    return find ? find.color : 'blue';
  }

  private removeSpecialCharsAndSpaces(text: string): string {
    if (!text || text.length === 0) {
      return '';
    } else {
      return text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    }
  }
}
