import { Injectable } from '@angular/core';
import { Stage, Step } from 'student-lib';

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  private loadedStage?: Stage;
  private loadedSteps?: Step[];

  setStage(stage: Stage): void {
    this.loadedStage = stage;
  }

  getStage(): Stage | undefined {
    return this.loadedStage;
  }

  setSteps(steps: Step[]): void {
    this.loadedSteps = steps;
  }

  getSteps(stageId: string): Step[] | undefined {
    if (this.loadedStage != null && this.loadedStage.id === stageId) {
      return this.loadedSteps;
    }
    return undefined;
  }
}
