import { Routes } from '@angular/router';
import { LoginComponent } from '../../shared/login/login.component';
import { PageNotFoundComponent } from '../../shared/page-not-found/page-not-found.component';
import { SelectCourseToLearnComponent } from '../../student/select-course-to-learn/select-course-to-learn.component';
import { SelectLearningStepsComponent } from '../../student/select-learning-steps/select-learning-steps.component';
import { SelectStageToLearnComponent } from '../../student/select-stage-to-learn/select-stage-to-learn.component';
import { SelectUnitToLearnComponent } from '../../student/select-unit-to-learn/select-unit-to-learn.component';
import { StudentHomeComponent } from '../../student/student-home/student-home.component';
import { TeacherHomeComponent } from '../../teacher/teacher-home/teacher-home.component';
import { StudentAndTeacherGuard } from '../guards/student-and-teacher.guard';
import { rootRoutes } from './root-routes';

import { GoChallengesComponent } from '../../student/go-challenges/go-challenges.component';
import { GoStepsComponent } from '../../student/go-steps/go-steps.component';
import { GoExamesComponent } from '../../student/go-exames/go-exames.component';

const homeRoutes: Routes = [
  { path: rootRoutes.home, redirectTo: 'auth', pathMatch: 'full' },
];

const authRoutes: Routes = [
  { path: rootRoutes.auth, redirectTo: 'login', pathMatch: 'full' },
  {
    path: rootRoutes.auth,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'logoff', component: LoginComponent },
    ],
  },
];

const studentRoutes: Routes = [
  { path: rootRoutes.student, redirectTo: 'home', pathMatch: 'full' },
  {
    path: rootRoutes.student,
    canActivate: [StudentAndTeacherGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: StudentHomeComponent },
      { path: 'steps/:stageId', component: GoStepsComponent },
      { path: 'challenges/:stageId/:page', component: GoChallengesComponent },
      { path: 'quiz/:stageId', component: GoExamesComponent },
      { path: 'quiz/:stageId/:page', component: GoExamesComponent },
    ],
  },
];

const teacherRoutes: Routes = [
  { path: rootRoutes.teacher, redirectTo: 'home', pathMatch: 'full' },
  {
    path: rootRoutes.teacher,
    canActivate: [StudentAndTeacherGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: TeacherHomeComponent },
    ],
  },
];

const infraRoutes: Routes = [{ path: '**', component: PageNotFoundComponent }];

export const allRoutes: Routes = [
  ...homeRoutes,
  ...authRoutes,
  ...studentRoutes,
  ...teacherRoutes,
  ...infraRoutes,
];
