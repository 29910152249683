import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icons/icons.module';
import { ProgressesModule } from '../progresses/progresses.module';

import { GeneralNavigationComponent } from './general-navigation/general-navigation.component';

import { NavItem } from './navigation.interface';
import { AbstractNavigationComponent } from './abstract.navigation.component';

const components = [GeneralNavigationComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule, ProgressesModule],
  exports: components,
})
export class NavigationsModule {}

export { GeneralNavigationComponent, NavItem, AbstractNavigationComponent };
