<label class="box" [ngClass]="boxClasses" (click)="onItemClick()">
  <div class="mark-icon" [ngClass]="radioClasses">
    <div *ngIf="!correction" class="mark" [ngClass]="innerRadioClasses"></div>
    <go-icon *ngIf="correction" [icon]="iconCorrection" />
  </div>
  <h3 *ngIf="!emptyStr(item.text)" class="text" [ngClass]="textClasses">
    {{ item.text }}
  </h3>
  <div *ngIf="emptyStr(item.text) && !emptyStr(item.audio)" class="audio">
    <go-icon icon="volume-up" [pathColor]="iconColor" />
  </div>
</label>
