import { Component } from "@angular/core";
import { AbstractUserPageComponent } from "../../shared/abstract-user-page.component";
import { defaultStudentMenuItems } from "./student-menu-items";

@Component({ selector: 'app-abstract-student-page-component', template: '' })
export abstract class AbstractStudentPageComponent<T> extends AbstractUserPageComponent<T> {

    override menuItens = defaultStudentMenuItems;
    
    getStudentName(): string | null {
        return this.getCurrentLoggedUserName();
    }

}