import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { ButtonsModule, InputsModule } from 'go-components';
import { LoginPageAuthType } from './login-page.auth.type';
import { LoginPageData } from './login-page.data';
import { LoginPageEvent } from './login-page.event';

const components = [LoginPageComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ButtonsModule, InputsModule],
  exports: components
})
export class LoginPageModule { }

export { LoginPageComponent, LoginPageAuthType, LoginPageData, LoginPageEvent }